.password {
  align-self: center;
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-base);
  justify-content: flex-start;
  width: 100%;
}

.target,
.password :global(.peak-form-field) {
  padding: 0;
  width: 100%;
}

.feedback {
  background-color: var(--color-bg-error);
  color: var(--color-status-negative);
  flex: 1;
  list-style-type: none;
  padding: 15px;
}

.feedback li {
  margin-inline: 0;
  margin-block: 3px;
}

.passwordPopover[data-placement^='top'] .arrow {
  border-color: var(--color-controls-error-border) transparent transparent
    transparent;
}

.passwordPopover[data-placement^='bottom'] .arrow {
  border-color: transparent transparent var(--color-controls-error-border)
    transparent;
}

.passwordPopover[data-placement^='right'] .arrow {
  border-color: transparent var(--color-controls-error-border) transparent
    transparent;
}

.passwordPopover[data-placement^='left'] .arrow {
  border-color: transparent transparent transparent
    var(--color-controls-error-border);
}
