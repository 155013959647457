@import 'animate.css/source/flippers/flipInX.css';

.root {
  position: relative;
  width: 100%;
  height: 100%;
  padding-inline: var(--spacing-08);
  overflow-y: auto;
}

@media (--min-width-md) {
  .root {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.content {
  padding: var(--spacing-32);
  text-align: center;
}

.image {
  max-width: 100%;
}

.flipInX {
  animation-duration: 0.75s;
  animation-name: flipInX;
}

.feedback {
  max-width: rem(600px);
}

@media (--min-width-md) {
  .feedback {
    margin-inline: auto;
  }
}
