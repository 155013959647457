.root {
  margin-block-end: 30px;
}

.title {
  font-size: var(--font-size-md);
  margin-block-end: 15px;
  font-weight: var(--font-weight-bold);
}

.textarea {
  margin-block-start: 15px;
}

.translations {
  padding-inline: 0;
  padding-block: 15px 0;
}

.customise {
  align-items: center;
  display: flex;
  margin-block-start: 15px;
}

.label {
  font-weight: var(--font-weight-bold);
}
