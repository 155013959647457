.container {
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  height: 100%;
}

.loadingContainer {
  align-items: center;
  composes: container;
  justify-content: center;
}

.content {
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
}

.container div[role='tabpanel'] {
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  max-height: 100%;
  overflow: hidden;
}
