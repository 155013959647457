:root {
  --color-graph-trend: var(--color-viz-04);
  --color-graph-point: var(--color-viz-04);
}

.root,
.graph {
  height: 100%;
  position: relative;
  width: 100%;
}

.graph svg {
  overflow: visible;
}

.root.v2 .line {
  stroke: var(--color-graph-trend);
}

.line {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  stroke: var(--color-graph-trend);
  fill: none;
  stroke-width: 4;
}

.axis path,
.axis line {
  fill: none;
  stroke: var(--color-viz-grid-line-emphasis);
  shape-rendering: crispEdges;
}

.axis text {
  color: var(--color-text-secondary);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-bold);
}

.root.v2 .dot {
  stroke: var(--color-graph-point);
  fill: var(--color-graph-point);
}

.root.v2 .dot:hover {
  fill: var(--color-viz-03);
}

.dot {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  stroke: var(--color-graph-point);
  fill: var(--color-graph-point);
  transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.dot:hover {
  box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.3);
  fill: var(--color-viz-03);
  stroke-width: 2px;
}

.grid line {
  stroke: var(--color-viz-grid-line);
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
}

.grid path {
  stroke-width: 0;
}

.text {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
}

@media (--handhelds) {
  .text {
    font-size: rem(13px);
  }

  .axis text {
    font-size: var(--font-size-3xs);
  }
}
