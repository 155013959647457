.root {
  width: 100%;
}

.container {
  margin-inline: auto;
  margin-block: 0;
  max-width: 820px;
  padding-inline: 20px;
  padding-block: 30px;
}

@media (--handhelds) {
  .container :global(.peak-radio-group) {
    display: block;
  }
}

.spinner {
  display: flex;
  justify-content: center;
}
