:root {
  --card-min-height: 54px;
  --card-padding-top-bottom: 15px;
  --card-max-width: 720px;
}

.card {
  display: flex;
  flex-basis: auto;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 0;
  padding: 15px;
  transition: box-shadow 300ms ease-in-out;
  min-height: calc(
    var(--card-min-height) + (var(--card-padding-top-bottom) * 2)
  );
  width: 100%;
  margin-block-end: -1px;
}

.card:hover {
  box-shadow: var(--depth-2-box-shadow);
}

.card:hover h3 {
  text-decoration: underline;
}

.card.hasDescription .content h3 {
  margin: 0;
}

.link,
.link:hover,
.link:focus,
.link:active {
  align-items: center;
  color: inherit;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  text-decoration: none;
}

.inner {
  display: flex;
  flex-basis: 0;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}

.media {
  display: none;
  margin-inline: auto;
  margin-block: 0;
  text-align: center;
  min-width: 150px;
  height: 84px;
}

.thumbnail {
  border-radius: 4px;
  display: block;
  height: 100%;
}

.image {
  width: 100%;
  height: 100%;
}

.content {
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
  overflow: hidden;
  width: 100%;
}

.content .header {
  margin-block-end: 15px;
  padding-inline-end: 15px;
}

.description {
  font-size: var(--font-size-base);
  color: var(--color-text-secondary);
  margin-inline: 0;
  margin-block: 10px;
  padding-inline-end: 15px;
}

.resourceDetails {
  font-size: var(--font-size-2xs);
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.authorThumbnailContainer {
  text-align: center;
  width: 14px;
  height: 14px;
  margin-inline-end: 5px;
}

.authorThumbnail {
  background-color: var(--color-viz-neutral-fill);
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  display: block;
  height: 100%;
}

.authorTitle {
  color: var(--color-text-tertiary);
  padding-inline-end: 15px;
}

.sourceTitle {
  height: 15px;
  color: var(--color-text-tertiary);
}

.mobileCtaContainer {
  display: none;
}

.resourceTypeTitle {
  color: var(--color-text-tertiary);
}

.resourceContainer {
  position: relative;
  max-width: var(--card-max-width);
}

.resourcesIconContainer {
  position: absolute;
  inset-inline-start: -78px;
  width: 40px;
  height: 40px;
  background-color: var(--color-bg-card);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-border);
}

.playButton {
  width: 40px;
  height: 40px;
  background-color: var(--color-bg-card);
  border-radius: 50%;
  border: 1px solid var(--color-border);
  position: absolute;
  inset-block-start: 39px;
  inset-inline-start: 70px;
}

@media (--tablets-min) {
  .card {
    padding: 15px;
  }

  .content .duration {
    display: none;
  }

  .inner {
    flex-direction: row;
  }

  .media {
    display: block;
    margin-inline: 0 23px;
    margin-block: 0 0;
    text-align: start;
  }

  .resourceContainer {
    width: 100%;
  }
}

@media (--handhelds-tablets) {
  .card {
    border-radius: 0;
  }

  .content .header {
    font-size: var(--font-size-md);
  }

  .content {
    display: block;
    margin-block-end: 0;
    margin-inline-start: -5px;
  }

  .resourceContainer {
    width: 100%;
  }
}

@media (--tablets) {
  .card {
    width: 100%;
  }

  .resourceContainer {
    max-width: none;
  }
}

@media (--handhelds) {
  .mobileCtaContainer {
    display: flex;
    flex-direction: row;
    margin-block-start: 20px;
    align-self: flex-end;
    align-items: center;
  }

  .iconContainer {
    margin-inline-end: 5px;
  }

  .ctaHeader {
    margin: 0;
  }
}
