.content {
  white-space: pre-wrap;
}

.attributesIntro {
  margin-block-start: var(--spacing-16);
}

.attributes {
  font-weight: bold;
  margin-block: var(--spacing-16);
}

.contentUrl {
  text-decoration: underline;
}

.acceptDisclaimer {
  margin-block-start: var(--spacing-16);
}
