.container {
  align-items: center;
  display: inline-flex;
  font-size: var(--font-size-xs);
  justify-content: flex-start;
}

.container.link {
  cursor: pointer;
}

.link .name {
  border-block-end: 1px solid transparent;
}

.link .name:hover {
  border-block-end-color: var(--color-text-primary);
}

.title .name {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
}

.title .name:hover {
  border-block-end-color: transparent;
}

.badge {
  align-items: center;
  background-color: var(--color-viz-neutral);
  border: 0;
  border-radius: var(--border-radius-md);
  color: var(--color-text-inverted);
  display: inline-flex;
  font-size: var(--font-size-xs);
  justify-content: flex-start;
  line-height: 1.5;
  margin-inline-start: 10px;
  padding-inline: 5px;
  padding-block: 0;
  text-decoration: none;
  text-transform: none;
  white-space: nowrap;
  font-weight: var(--font-weight-bold);
}

.badge.value {
  background-color: var(--color-bg-info);
  color: var(--color-text-primary);
}

.badge.value .label {
  color: var(--color-text-primary);
}

.label {
  color: var(--color-text-inverted);
  margin-inline-start: 2px;
}

.badge.tiny {
  margin-inline-start: 4px;
  padding-inline: 3px;
  padding-block: 2px;
}

.badge.tiny .label {
  font-size: var(--font-size-3xs);
  line-height: 1.33;
}

.badge.small .label {
  font-size: var(--font-size-2xs);
  line-height: 1.54;
}

.icon {
  height: 14px;
  width: 14px;
}

.action .name {
  font-size: var(--font-size-2xs);
  font-weight: var(--font-weight-bold);
}

.comment .name {
  font-size: var(--font-size-2xs);
}

.iconOnly {
  line-height: 1;
  margin-inline-start: 0;
  padding-inline: 3px;
  padding-block: 2px;
}

.iconOnly .icon {
  height: 12px;
  width: 12px;
}

.name.large {
  font-size: var(--font-size-lg);
}

.tinyIcon {
  height: 0.75rem;
  width: 0.75rem;
}
