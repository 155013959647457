.option {
  height: 100%;
  padding: var(--spacing-16);
}

.container {
  align-items: center;
  display: flex;
}

.logoPlaceholder {
  align-items: center;
  background-color: var(--color-border);
  border-radius: 2px;
  border: solid 1px var(--color-border);
  display: flex;
  font-size: var(--font-size-2xs);
  height: 77px;
  justify-content: center;
  width: 77px;
  font-weight: var(--font-weight-bold);
}

.logo {
  flex: 0 0 77px;
  margin-inline-end: var(--spacing-16);
}

.logo img {
  display: block;
  width: 100%;
}

.content {
  flex: 2;
}

.optionTitle {
  margin-block-end: 5px;
  font-weight: var(--font-weight-bold);
}

.warning {
  color: var(--color-status-negative);
  font-size: var(--font-size-2xs);
  line-height: 1.3;
  padding-block-start: var(--spacing-16);
  font-weight: var(--font-weight-bold);
}

.actions {
  margin-block-start: 40px;
}
