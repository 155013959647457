.placeholder {
  background-color: var(--color-controls--disabled-bg);
  border-radius: 50%;
  height: 23px;
  width: 23px;
}

.header.placeholder {
  height: 45px;
  width: 45px;
}
