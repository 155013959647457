.field {
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
}

.field.date div[class*='peak-form-media--date'] {
  z-index: 0;
}

.field.date,
.field.number {
  display: block;
}

.placeholder {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.placeholderText {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  color: var(--color-controls-placeholder-text);
}

.placeholderClear {
  cursor: pointer;
  display: table-cell;
  margin-inline-end: 16px;
  position: relative;
  text-align: center;
  vertical-align: middle;
}

.clear {
  display: block;
  height: 20px;
  width: 20px;
  color: var(--color-controls-placeholder-text);
}

.icon {
  margin-inline-end: 4px;
}
