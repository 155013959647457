.header {
  background-color: var(--color-bg-navigation);
  position: sticky;
  inset-block-start: 0;
  z-index: 1;
}

.container {
  border-block-end: solid 1px var(--color-border);
  padding-inline: var(--spacing-16);
}

.logo {
  width: rem(50px);
}
