.logo g {
  fill: none;
}

.aboutContainer {
  margin-block: var(--spacing-08);
}

.sidebar {
  display: grid;
  gap: var(--spacing-08);
  max-width: 300px;
  width: 100%;
  padding-block-end: var(--spacing-24);
}

@media (--tablets-min) {
  .sidebar {
    padding-block-end: 0;
  }
}

@media (--handhelds) {
  .sidebar {
    width: 100%;
  }
}
