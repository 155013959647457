.root {
  margin-inline: auto;
  margin-block: 0;
  max-width: 1180px;
  padding-inline: 60px;
  padding-block: 48px 120px; /* padding-bottom for support widget */
  width: 100%;
}

@media (--handhelds) {
  .root {
    padding-block-start: var(--spacing-16);
    padding-inline: var(--spacing-16);
  }
}
