.root {
  align-items: center;
  display: flex;
  line-height: 1;
}

.label {
  color: var(--color-text-hint);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
  margin-inline-end: var(--spacing-16);
}

.wrapper {
  background-color: var(--color-viz-graph-bg);
  border-radius: rem(4px);
  box-shadow: inset 0 0 0 1px var(--color-viz-grid-line);
  flex: 3;
  height: rem(8px);
  overflow: hidden;
}

.progress {
  background-color: var(--color-status-highlight);
  border-radius: rem(4px) 0 0 rem(4px);
  height: 100%;
  transition: width 0.4s ease;
}
