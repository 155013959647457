:root {
  --z-index-y: 1;
}

.root {
  display: flex;
  margin-block-end: 20px;
}

.y {
  display: flex;
  height: 100%;
  z-index: var(--z-index-y);
}

.y .text {
  transform: rotate(-90deg);
  align-self: center;
}

.y .bar {
  width: 6px;
  border-radius: 2px;
  height: 120px;
  background: var(--color-viz-neutral);
}

.x {
  min-width: 160px;
  position: relative;
  inset-block-start: 18px;
  inset-inline-start: -6px;
  margin-block-start: auto;
  text-align: center;
}

.x .bar {
  height: 4px;
  background: var(--color-controls--selected-marker);
  border-radius: 2px;
}

.plot {
  position: relative;
  background: var(--color-controls--selected-bg);
  inset-block-start: -20px;
  inset-inline-start: 6px;
}

.plot > div {
  width: 6px;
  height: 6px;
  position: absolute;
  background: var(--color-status-negative);
  border-radius: 50%;
}

.plot:not(.inverted) {
  width: calc(100% - 6px);
  height: 73px;
  border-block-start: 2px dashed var(--color-viz-grid-line);
  border-block-end: 2px dashed var(--color-viz-grid-line);
}

.plot.inverted {
  height: 116px;
  width: 73px;
  border-inline-start: 2px dashed var(--color-viz-grid-line);
  border-inline-end: 2px dashed var(--color-viz-grid-line);
  inset-block-start: -116px;
  inset-inline-start: 44px;
  position: absolute;
}

.plot.inverted > div {
  position: relative;
}

.plot > div.green {
  background: var(--color-status-highlight);
}

.text {
  font-weight: var(--font-weight-bold);
}

.text.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.x .ellipsis {
  max-width: 160px;
  inset-block-start: 4px;
  position: relative;
}

.y .ellipsis {
  max-width: 60px;
}
