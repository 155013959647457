.root {
  width: 100%;
  display: flex;
  max-height: 130px;
  border-block-end: 1px solid var(--color-border);
  padding: 15px;
  margin-block-start: -1px;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.root,
.root.draft .thumbnail,
.root.draft .content {
  transition: ease all 0.2s;
}

.root:hover {
  box-shadow: 0 2px 25px 0 rgba(44, 47, 55, 0.13);
  z-index: 2;
}

.root.draft .thumbnail,
.root.draft .content {
  opacity: 0.5;
}

.content {
  max-width: calc(100% - (144px * 2));
  padding-inline: 0 24px;
  padding-block: 0 0;
}

.label {
  display: flex;
}

.title {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-bold);
  color: var(--color-text-primary);
  padding-inline: 0 0;
  padding-block: 10px 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description {
  font-size: var(--font-size-base);
  color: var(--color-text-secondary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.translationLabel {
  padding-inline: 0 0;
  padding-block: 10px 5px;
}

.actions {
  display: flex;
  place-content: flex-end flex-end;
  align-items: center;
  margin-inline-start: auto;
}

.actions button {
  margin-inline-start: 10px;
}

.actions .draft {
  font-size: var(--font-size-2xs);
  font-weight: 600;
  color: var(--color-text-tertiary);
  margin-inline-end: 10px;
}

@media (--handhelds) {
  .root {
    display: block;
    max-height: none;
  }

  .content {
    max-width: 100%;
  }
}
