.container {
  display: grid;
  gap: var(--spacing-32);
  grid-template-columns: 30% 1fr;
}

@media (--handhelds) {
  .container.mobile {
    grid-template-columns: 1fr;
  }
}

@media (--handhelds-tablets) {
  .container.tablet {
    grid-template-columns: 1fr;
  }
}
