.root,
.container {
  display: flex;
  flex-direction: column;
}

.root {
  background-color: var(--color-bg-card);
  height: 100vh;
  width: 100vw;
}

.poweredBy,
.container {
  width: 100%;
}

.poweredBy {
  flex: 0 0 auto;
  padding: var(--spacing-16);
}

.container {
  align-items: center;
  flex: 3;
  justify-content: center;
}

.title {
  font-size: rem(30px);
  margin-block-end: var(--spacing-32);
}

.image {
  margin-block-end: var(--spacing-16);
}

.container p {
  margin: 0;
}
