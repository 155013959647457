@import '../../variables.css';

:root {
  --animation-duration: 0.3s;
  --animation-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.menuSlideIn {
  animation-duration: var(--animation-duration);
  animation-fill-mode: forwards;
  animation-timing-function: var(--animation-function);
  background-color: var(--color-bg-card);
  display: flex;
  flex-direction: column;
  height: 100%;
  position: fixed;
  inset-inline-start: 0;
  inset-block-start: 0;
  width: 100%;
  max-width: var(--nav-side-collapsed-width);
  z-index: 999;
  overflow: hidden;
}

.menuSlideIn.enter {
  animation-name: slide-in-right;
}

.menuSlideIn.exit {
  animation-name: slide-out-right;
}

[dir='rtl'] .menuSlideIn.enter {
  animation-name: slide-in-left;
}

[dir='rtl'] .menuSlideIn.exit {
  animation-name: slide-out-left;
}

.container {
  height: 100%;
}

.header {
  height: var(--nav-top-height);
  width: 100%;
  border-block-end: 1px solid var(--color-border);
  display: flex;
  align-items: center;
  padding-inline-start: var(--spacing-16);
}

.header button {
  -webkit-font-smoothing: inherit; /* reset style from peak-btn-reset */
  display: flex;
  align-items: center;
}

.header .actionContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header .actionContainer svg {
  display: block;
}

.header .title {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
  line-height: 1;
  margin-inline-start: var(--spacing-08);
}

.topLevelMenu {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 100%;
  transition: transform;
  transition-duration: var(--animation-duration);
  transform: translateX(-100%);
  transition-timing-function: var(--animation-function);
}

.topLevelMenuInner {
  height: 100%;
}

[dir='rtl'] .topLevelMenu {
  transform: translateX(100%);
}

.topLevelMenu.isShown {
  transform: translateX(0);
}

.productMenu {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 100%;
  animation-duration: var(--animation-duration);
  animation-fill-mode: forwards;
  animation-timing-function: var(--animation-function);
}

.productMenu.enter {
  animation-name: slide-in-left;
}

.productMenu.exit {
  animation-name: slide-out-left;
}

[dir='rtl'] .productMenu.enter {
  animation-name: slide-in-right;
}

[dir='rtl'] .productMenu.exit {
  animation-name: slide-out-right;
}

@keyframes slide-in-left {
  0% {
    transform: translate3d(100%, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slide-out-left {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes slide-in-right {
  0% {
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slide-out-right {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-100%, 0, 0);
  }
}
