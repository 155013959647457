.root {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.cta {
  padding-inline-start: 0.6rem;
  padding-block-start: 0.5rem;
}
