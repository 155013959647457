.tabs {
  margin: 0;
}

.tabs.hidden {
  height: 0;
  opacity: 0;
}

.selector {
  min-width: 248px;
}
