.field {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  max-width: 100%;
  padding-inline: 0;
  padding-block: 10px;
  position: relative;
}

@media (--tablets-min) {
  .field {
    flex-basis: 50%;
    padding-inline: 0 20px;
    padding-block: 10px 10px;
  }
}

@media (--desktop) {
  .field {
    flex-basis: 25%;
  }

  .field.columns2 {
    flex-basis: 50%;
  }
}

.field:first-child {
  padding-inline-start: 0;
}

.field.disabled {
  opacity: 0.5;
}
