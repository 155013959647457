.root {
  align-items: stretch;
  display: flex;
  margin-inline: -5px;
  margin-block: 0;
}

.cardWrapper {
  flex: 1;
  padding-inline: var(--spacing-04);
  padding-block: 0;
}

.card {
  align-items: flex-start;
  border-radius: 4px;
  border: solid 1px var(--color-border);
  background-color: var(--color-bg-card);
  display: flex;
  height: 100%;
  padding: var(--spacing-08);
}

.icon {
  margin-inline: 0 10px;
  margin-block: 2px 0;
}

.content {
  font-size: var(--font-size-md);
}

.content strong {
  font-weight: var(--font-weight-bold);
}
