.modal {
  height: calc(100vh - var(--spacing-16));
  width: calc(100vw - var(--spacing-16));

  @media (--min-width-md) {
    height: calc(100vh - var(--spacing-40));
    width: calc(100vw - var(--spacing-64));
    max-width: var(--screen-w-xl);
    max-height: 640px;
  }
}

.modalHeader {
  align-items: flex-start;
  flex-wrap: wrap;
  padding-inline: 0;
  padding-block: var(--spacing-24) 0;
  border: none;
  position: relative;
}

.modalTitleContainer {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: space-between;
  padding-inline: var(--spacing-24);
}

.modalTitle {
  font-size: var(--font-size-lg);
  color: var(--color-text);
  margin-inline-end: var(--spacing-16);
}

.modalContent {
  padding: 0;
  overflow: auto;
  height: 100%;

  @media (--min-width-md) {
    display: grid;
    grid-template-columns: 16rem 1fr;
    grid-template-rows: 100%;
  }
}

.tabListContainer {
  width: 100%;
  padding-inline: var(--spacing-16);
  border-block-end: 1px solid var(--color-border);
  background: var(--color-bg-card);
}

.tabPanelContainer {
  height: 100%;
  width: 100%;
  position: relative;
  display: grid;
  grid-template-rows: 100%;

  @media (--min-width-md) {
    overflow: auto;
  }
}

.graphContainer {
  padding-inline: 0 var(--spacing-24);
  padding-block: var(--spacing-24);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.controlContainer {
  background: var(--color-bg-card);
  padding: var(--spacing-24);
  position: sticky;
  inset-inline-start: 0;
  z-index: 1;

  @media (--min-width-md) {
    border-inline-end: 1px solid var(--color-border);
    inset-inline: 0;
  }
}

.intervalSelectorContainer {
  padding-block-end: var(--spacing-08);
}

.scrollUpHint {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
}

.scrollDownHint {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
}

.toggleWrapper {
  display: flex;
  padding-block: 0.75rem;
}

.footer {
  padding-block: var(--spacing-08) 0;
}

.centeredTabPanelContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: var(--spacing-08);
}
