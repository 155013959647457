.formContainer {
  display: grid;
  gap: var(--spacing-16);
}

.inputContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-16);
}
