.root {
  margin-inline: auto;
  margin-block: 0;
  max-width: rem(580px);
  padding: rem(40px) var(--spacing-16);
}

.check {
  margin-inline: 0;
  margin-block: 30px;
  position: relative;
}

.template {
  margin-block-start: 40px;
}

.upload label {
  width: 100%;
}

.check label {
  white-space: normal;
  font-weight: var(--font-weight-bold);
}

.results {
  align-items: flex-start;
  display: flex;
  margin-inline: 0;
  margin-block: 20px;
}

.results .illustration,
.results .status {
  flex: 1;
}

.results .illustration {
  margin: 0;
}

.statusItem {
  color: var(--color-status-negative);
  font-size: var(--font-size-md);
  line-height: 1.5;
  font-weight: var(--font-weight-bold);
}

.statusItem.added {
  color: var(--color-status-positive);
}

.log {
  border-block-end: 1px var(--color-border) solid;
  padding-inline: 0;
  padding-block: var(--spacing-16);
}

.done {
  margin-inline: 0;
  margin-block: 20px 0;
  text-align: center;
}
