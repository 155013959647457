.root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.root strong,
.root a {
  color: var(--color-text-inverted);
}

.root a {
  margin-inline-start: 15px;
  text-decoration: underline;
}

.root a:hover {
  color: var(--color-text-inverted);
}
