.root {
  background-color: var(--color-btn--tertiary-active-bg);
  box-shadow: inset 0 0 0 1px var(--color-viz-grid-line);
  border-radius: rem(5px);
  height: rem(10px);
  overflow: hidden;
  width: 100%;
  min-width: 170px;
}

.container {
  display: flex;
  flex-direction: column;
  margin-inline-end: 2rem;
  position: relative;
  width: 100%;
}

.container::after {
  content: '';
  border-inline-end: solid 1px var(--color-border-section);
  width: 100%;
  inset-inline-start: 16px;
  position: absolute;
  height: 100%;
}

.progressBarContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  min-width: 100%;
}

.counterTitle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.counterSubTitle {
  margin-inline-end: 10px;
  word-break: keep-all;
}

.root.small {
  border-radius: rem(4px);
  height: rem(8px);
}

.root.loading {
  background-color: transparent;
}

.progress {
  background-color: var(--color-viz-graph-bg);
  border-radius: rem(5px) 0 0 rem(5px);
  height: 100%;
}

.small .progress {
  border-radius: rem(4px) 0 0 rem(4px);
}

.progress.default {
  background-color: var(--color-status-positive);
}

.progress.warning {
  background-color: var(--color-status-warning);
}

.progress.softLimit {
  background-color: var(--color-status-negative);
}

@media (--handhelds) {
  .progressBarContainer {
    display: none;
  }

  .counterTitle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
