.container {
  margin-block-start: var(--spacing-48);
  gap: var(--spacing-32);
  padding: 0;
}

.header {
  display: grid;
  gap: var(--spacing-16);
}

.title {
  display: flex;
  gap: var(--spacing-16);
  align-items: center;
}

.content {
  margin-block-end: var(--spacing-24);
}
