.container {
  display: inline-flex;
  align-items: center;
  font-size: var(--font-size-base);
  position: relative;
}

.custom.container {
  font-size: inherit;
  overflow: visible;
}

.container.full > div {
  max-width: 100%;
}

.container.full .label {
  max-width: 100%;
}

.iconWrapper {
  display: flex;
  position: relative;
}

.iconWrapper.alignIcon {
  margin-block-end: 10px;
}

.iconWrapper.alignIconLarge {
  margin-block-end: 27px;
}

.classification {
  position: absolute;
  inset-block-start: -5px;
  inset-inline-start: 20px;
}

.lg .classification {
  inset-block-start: -4px;
  inset-inline-start: 32px;
}

.sm .classification {
  inset-block-start: -2px;
  inset-inline-start: 15px;
}

.xs .classification {
  inset-block-start: -5px;
  inset-inline-start: 10px;
}

.label {
  align-items: center;
  display: inline-flex;
  font-size: inherit;
  line-height: 1;
  margin-block-end: 0;
  max-width: 150px;
  overflow: hidden;
}

.custom .label,
.lg .label {
  max-width: 100%;
}

.custom > span,
.label > span {
  max-width: 100%;
}

.label.withSubdriver {
  display: inline-block;
}

.driver {
  display: inline;
  font-weight: var(--font-weight-bold);
  color: var(--color-text-primary);
}

.ellipsis .driver {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sm .driver {
  color: var(--color-text-secondary);
}

.label.withSubdriver .driver {
  border-block-end: none;
}

.label.withSubdriver .driver::before {
  content: '›';
  margin-inline: 5px;
  font-size: var(--font-size-md);
}

.label.withTooltip .subdriver {
  color: var(--color-text-tertiary);
}

.iconless.md .icon {
  margin-inline-end: 0;
}

.sm .label {
  font-size: var(--font-size-2xs);
}

.sm .iconWrapper {
  margin-inline-end: 5px;
}

.md .iconWrapper {
  margin-inline-end: 10px;
}

.container.priorityCard .label.withSubdriver,
.container.priorityCard .label {
  z-index: 6;
  position: relative;
}

.container.priorityCard .label.withSubdriver .subdriver {
  color: var(--color-text-tertiary);
}

.modifier.manual > g {
  fill: var(--color-text-primary);
}

.modifier > g {
  fill: var(--color-status-negative);
}

.count {
  color: var(--color-text-tertiary);
}

@media (--tablets-min) {
  .container {
    font-size: var(--font-size-xs);
  }

  .container.md {
    font-size: var(--font-size-sm);
  }

  .container.large {
    font-size: var(--font-size-md);
  }

  .container.xl {
    font-size: var(--font-size-lg);
  }

  .label {
    height: auto;
    line-height: var(--line-height-base);
  }

  .iconWrapper {
    margin-inline-end: 15px;
  }

  .label.withTooltip .driver,
  .label.hoverOnly .driver {
    border-block-end: 1px dotted transparent;
  }

  .label.withTooltip .driver,
  .label.hoverOnly .driver:hover {
    border-block-end-color: var(--color-text-tertiary);
  }

  .driver {
    display: block;
  }

  .label.withSubdriver {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .label.withSubdriver .driver::before {
    margin-inline-start: 0;
  }
}

@media (--handhelds) {
  .classification {
    inset-block-start: 8px;
    inset-inline-start: 8px;
  }

  .container.xl,
  .count {
    font-size: var(--font-size-md);
  }
}
