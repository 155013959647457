:root {
  --content-height: 320px;
  --content-width: 560px;
  --content-width-mobile: 100vw;
}

.root {
  width: var(--content-width);
}

.header,
.footer {
  align-items: center;
  display: flex;
  padding-inline: 20px;
  padding-block: 10px;
}

.header {
  align-items: center;
  display: flex;
  border-block-end: 1px solid var(--color-border);
}

.title {
  color: var(--color-text-primary);
  flex: 2;
  font-size: var(--font-size-md);
  margin: 0;
  font-weight: var(--font-weight-bold);
}

.searchTitle button {
  margin-inline-start: 10px;
}

.back {
  align-items: center;
  color: var(--color-text-tertiary);
  cursor: pointer;
  display: flex;
  font-size: var(--font-size-base);
  margin-inline-start: -6px;
  text-decoration: none;
}

.back svg {
  margin-inline-end: 4px;
}

.search {
  flex: 0 0 240px;
}

.content,
.loader {
  height: var(--content-height);
}

.segmentsLoader {
  padding: 20px;
}

.content {
  height: var(--content-height);
  overflow: hidden;
}

.loader,
.segmentsLoader {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-40);
  color: var(--color-text-hint);
}

.wrapper {
  align-items: flex-start;
  display: flex;
  transition: transform 0.35s;
}

.wrapper.isLoading {
  pointer-events: none;
}

.wrapper.hasSegments {
  transform: translate3d(calc(var(--content-width) * -1), 0, 0);
}

.wrapper .list {
  flex: 0 0 var(--content-width);
}

.list {
  max-height: var(--content-height);
  overflow-y: auto;
}

.attribute,
.segment {
  border-block-end: 1px solid rgba(226, 230, 234, 0.45);
}

.attribute.hidden {
  display: none;
}

.footer {
  align-items: center;
  display: flex;
  border-block-start: 1px solid var(--color-border);
}

.cancel {
  flex: 2;
}

.select {
  align-items: center;
  display: flex;
}

.deselect {
  margin-inline-end: var(--spacing-16);
}

.emptyContainer {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.emptySegments {
  padding: 40px;
}

.emptyText {
  color: var(--color-text-secondary);
  text-align: center;
}

[dir='rtl'] .wrapper.hasSegments {
  transform: translate3d(var(--content-width), 0, 0);
}

[dir='rtl'] .back svg {
  transform: scaleX(-1);
}

@media (--handhelds) {
  .root {
    height: 100%;
  }

  .root,
  .header,
  .footer {
    width: var(--content-width-mobile);
  }

  .content,
  .loader {
    height: calc(100vh - 124px);
  }

  .list {
    max-height: calc(100vh - 124px);
  }

  .wrapper .list {
    flex: 0 0 auto;
    width: var(--content-width-mobile);
  }

  .wrapper.hasSegments {
    transform: translate3d(calc(var(--content-width-mobile) * -1), 0, 0);
  }

  [dir='rtl'] .wrapper.hasSegments {
    transform: translate3d(var(--content-width-mobile), 0, 0);
  }

  .deselect {
    display: none;
  }
}
