.root {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
}

.root.small {
  font-size: var(--font-size-xs);
}

.root.tiny {
  font-size: var(--font-size-3xs);
}
