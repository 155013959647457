.animating {
  opacity: 0.5;
  transition: none;
}

.link {
  display: none;
  font-size: var(--font-size-2xs);
  color: var(--color-text-tertiary) !important;
  text-decoration: underline;
  margin-block-start: 15px;
  font-weight: var(--font-weight-bold);
}

.empty {
  margin-inline: auto;
  margin-block: 30px;
  max-height: 100%;
  max-width: 360px;
  padding-block: 10px;
  text-align: center;
}

.emptyImage {
  height: 90px;
  width: 180px;
}

.improveIcon {
  color: var(--color-text-inverted);
  margin-inline-end: 14px;
}

.footerLink {
  margin-block-start: 20px !important;
}

.seeAllButton {
  align-items: center;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  font-size: var(--font-size-base);
  height: 24px;
  justify-content: center;
  margin-block-end: 24px;
  padding: 0;
  font-weight: var(--font-weight-bold);
  text-decoration: underline;
}

.seeAllButton:hover,
.seeAllButton.focused {
  opacity: 0.8;
}

.seeAllButton:not(.focused):focus {
  outline: 0;
}

.seeAllButton.expanded .arrow {
  transform: rotate(180deg);
}

.seeAllButton .menuIcon {
  margin-inline-end: 12px;
}

@media (--tablets-min) {
  .link {
    display: inline;
  }

  .footerLink {
    margin-block-start: 20px !important;
  }

  .priority > div > div:first-child {
    justify-content: space-between;
  }

  .seeAllButton {
    padding-inline: 5px;
    padding-block: 0;
    margin-block-end: 0;
  }
}
