.labelContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.label {
  flex: 1 0 auto;
  max-width: 100%;
}

.labelClock .label {
  max-width: 60%;
}

.label label {
  white-space: normal;
}

.toggler {
  align-items: center;
  display: flex;
  margin-block-end: 5px;
}

.content {
  flex: 1 1 auto;
}

.content :global(.peakon-select__menu) {
  z-index: 2;
}

.scheduleLabel {
  color: var(--color-text-secondary);
  opacity: 0;
  transition: opacity 0.5s;
  width: 100%;
}

.scheduleButton {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  max-width: 50%;
  padding-block-end: rem(2px);
}

.scheduleButton.disabled {
  cursor: not-allowed;
}

.scheduleButton.disabled .scheduleLabel,
.scheduleButton.disabled .clock {
  color: var(--color-text-tertiary);
}

.scheduleBadge {
  align-items: center;
  color: var(--color-text);
  display: inline-flex;
  font-size: var(--font-size-2xs);
  justify-content: flex-end;
  max-width: 100%;
  min-height: 26px;
}

.scheduleBadge.planned {
  background-color: var(--color-bg-card);
  border: 1px solid var(--color-status-highlight);
  border-radius: var(--border-radius-base);
  padding-inline: 4px 2px;
  padding-block: 0 0;
}

.scheduleBadge.planned .scheduleLabel {
  color: var(--color-status-highlight);
  opacity: 1;
}

.scheduleBadge.planned .clock {
  color: var(--color-status-highlight);
}

.scheduleButton:hover .scheduleLabel {
  opacity: 1;
}
