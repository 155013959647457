.breadcrumb {
  align-items: center;
  display: flex;
  height: 45px;
  justify-content: center;
  margin-inline-end: 15px;
  width: 45px;
}

.breadcrumb > div {
  font-size: var(--font-size-md) !important;
}

.title h3,
.title > div {
  display: inline;
}
