.root {
  align-items: center;
  display: flex;
  flex: 0 0 70px;
  margin-inline: auto;
  margin-block: 0;
  max-width: 640px;
  width: 100%;
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
}

.root.large {
  font-size: var(--font-size-lg) !important;
}

.unassigned {
  color: var(--color-text-secondary);
}

.noMaxWidth {
  max-width: none;
}

@media (--handhelds) {
  .unassigned,
  .count,
  .root {
    font-size: var(--font-size-md);
  }
}
