.root {
  display: flex;
  flex-direction: column;
  min-height: 2.625rem;
  border-radius: 0.3rem;
  transition: ease all 0.15s;
  padding-inline-start: 0.6rem;
}

.inputLabel {
  padding-inline-start: 2rem;
}

.root:not(.editing):hover {
  background: var(--color-bg-navigation);
}

.itemContainer {
  display: flex;
  width: 100%;
  align-items: center;
}

.checkbox {
  display: flex;
  align-items: center;
  margin-inline-end: 1rem;
}

.text {
  width: 100%;
}

.inputContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.input {
  display: flex;
}

.inputField {
  flex-grow: 1;
  margin-inline-end: 0.25rem;
}

.saveIcon {
  margin-inline-end: 2.5rem;
}

.completed {
  text-decoration: line-through;
}

.content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.action {
  width: 2.5rem;
}

@media (--handhelds) {
  .root {
    padding-inline-start: 0;
  }
}
