:root {
  --course-header-image-container-width: 164px;
  --course-text-color: var(--color-text-primary);
}

.contentWrapper {
  display: flex;
  flex-direction: row;
}

.contentHeading {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-inline-start: 10px;
}

.backButton {
  align-items: center;
  color: var(--course-text-color);
  cursor: pointer;
  display: inline-flex;
  flex-grow: 1;
  justify-content: flex-start;
  margin-inline: 0 10px;
  margin-block: 0 18px;
  transition: color 100ms ease-in-out;
}

.backButton:active,
.backButton:visited {
  color: var(--course-text-color);
}

[dir='rtl'] .arrow {
  transform: scaleX(-1);
}

.arrow path {
  fill: var(--course-text-color);
}

.backButton:hover {
  color: var(--color-text-tertiary);
  outline: none;
}

.backButton:hover .arrow path {
  fill: var(--color-text-tertiary);
}

@media (--handhelds-tablets) {
  .backButton {
    align-self: flex-start;
    padding-block-start: 15px;
    padding-inline-start: 10px;
    margin: 0;
  }
}
