.grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: var(--spacing-24);
  margin-block: var(--spacing-24);
}

.topicsIcon {
  margin-inline-end: var(--spacing-12);
}

@media (--tablets-min) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (--desktop) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
    margin-block-start: 0;
  }
}
