.root {
  width: 100%;
  margin-inline: auto;
  max-width: 928px;
  padding-block-start: var(--spacing-16);
}

/**
 *
 * NOTE: This is mapped directly to the value in `useIsCollapsible` since we don't have it as a breakpoint for it :(
 *
 */

.totalCommentsButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-08);
  /**
  *
  * NOTE: This reads a bit confusing here but adding:
  * flex property help to wrap the icon within the button and doesn't lead to the icon having a width of 0px
  * max-width is to keep the maximum width of the button to fit the content and not stretch out 
  * min-width help in to not allow the button to shrink below 190px even though the content(text) might be shorter in some languages
  *
  */
  flex: 0 0 min-content;
  max-width: min-content;
  min-width: 190px;
  height: 50px;
  border: 1px solid var(--color-text-primary);
  border-radius: var(--border-radius-card);
  padding-inline: var(--spacing-08);
  padding-block: var(--spacing-04);
  font-weight: var(--font-weight-bold);
  background-color: var(--color-text-inverted);
}

.totalComments {
  display: flex;
  flex-direction: column;
}

.totalCommentsButton:hover {
  background-color: var(--color-text-primary);
  color: var(--color-text-inverted);
}

.totalCommentsTitle {
  font-size: var(--font-size-xs);
  white-space: nowrap;
}

.totalCommentsCount {
  font-size: var(--font-size-md);
}

.totalCount {
  width: 100%;
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
}

@media (--tablets-min) {
  .totalCount {
    text-align: end;
  }
}

@media (min-width: 1200px) {
  .root {
    padding-block-start: 0;
  }
}

/**
 *
 * Page wide margin for mobile
 *
 * TODO: Pull this to a page type?
 * HACK: Magic number for breakpoint to align with the max-width of the page content
 *
 */

@media (max-width: 938px) {
  .root {
    padding-inline: var(--spacing-16);
  }
}

/**
 *
 * Custom css for things we don't have components for
 *
 */

.errorPage {
  max-width: 600px;
  margin-inline: auto;
  padding-block-start: 5vh;
  padding-inline: var(--spacing-24);
}

.intervalFilter {
  min-width: 150px;
}

.clock {
  color: var(--color-text-tertiary);
}

.title {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-bold);
  color: var(--color-text-tertiary);
}

@media (--tablets-min) {
  .title {
    align-items: flex-end;
  }
}

.lastRoundDate {
  width: 100%;
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
}

@media (--tablets-min) {
  .lastRoundDate {
    text-align: end;
  }
}

.comments {
  width: 100%;
  max-width: 600px;
  margin-inline: auto;
}

.listReset {
  list-style-type: none;
  padding-inline-start: 0;
  margin-block: 0;
}

.themesNavList {
  display: flex;
  gap: var(--spacing-04);
}
