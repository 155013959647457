.container {
  height: 100%;
  position: relative;
  width: 100%;
  padding-block-end: 30px;
}

.content {
  align-items: center;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  justify-content: flex-start;
  margin-block-end: 30px;
  position: relative;
  z-index: 1;
}

.contentHeader {
  display: flex;
  align-self: flex-start;
  color: var(--color-text-primary);
}

.list {
  max-width: 720px; /*max-width of the resources card*/
}

.list > div:not(:last-of-type) {
  border-block-end: 1px solid var(--color-border);
}

.lessonsContainer {
  width: 100%;
}

.lessonsContainer .contentHeader {
  margin-block-start: 50px;
}

.lessons {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.footer {
  margin-inline: 0 0;
  margin-block: 60px 30px;
  padding: 30px;
  max-width: 720px;
  text-align: center;
}

.licenseButton {
  background-color: transparent;
  border: none;
  font-size: var(--font-size-base);
  color: var(--color-text-secondary);
  cursor: pointer;
  text-decoration: underline;
}

.modalContent {
  word-wrap: break-word;
}

.lessonsWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.lessonsColumn {
  min-width: calc(25% + 30px);
}

@media (--tablets) {
  .lessons {
    max-width: 900px;
  }

  .lessonsColumn {
    display: none;
  }
}

@media (--tablets-min) {
  .lessons {
    background-color: transparent;
    padding-inline: 0;
    padding-block: 0 60px;
    width: 100%;
  }

  .header {
    margin-inline: 0;
    margin-block: 200px 0;
  }
}

@media (--handhelds) {
  .lessons {
    width: 100%;
  }

  .lessonsColumn {
    display: none;
  }
}

@media (--large-screens) {
  .lessonsColumn {
    min-width: calc(25% + 70px);
  }
}

@media (--desktop) {
  .lessonsColumn {
    min-width: calc(25% + 50px);
  }
}

@media (max-width: 1800px) {
  .lessonsColumn {
    min-width: calc(25% + 35px);
  }
}

@media (max-width: 1392px) {
  .lessonsColumn {
    min-width: calc(25% + 30px);
  }
}

@media (--handhelds-tablets) {
  .lessonsHeader,
  .resourcesHeader {
    font-size: var(--font-size-md);
    margin-inline-start: 15px;
  }

  .resourcesHeader {
    margin-block-end: 10px;
  }

  .lessonsWrapper {
    margin-block-end: -10px;
  }
}
