.spinner {
  align-items: center;
  color: var(--color-status-highlight);
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-inline: auto;
  margin-block: 0;
  max-width: 1200px;
  padding-inline: 20px;
  padding-block: 40px;
  width: 100%;
}

.main {
  flex: 1;
}

.actions {
  margin-block-start: 20px;
  width: 100%;
}

.actions > *:not(:last-child) {
  margin-block-end: var(--spacing-08);
}

.page div[role='tabpanel'],
.pageContent {
  /*
  * The employee page content has overflow hidden https://github.com/peakon/dashboard/blob/master/apps/app-manager/src/components/employees/EmployeePage/styles.css#L31
  * Since since class and the employee page one are applied on the same div, the order import matters. The !important is needed to ensure this one is applied rather than the employee page one.
  */
  /* stylelint-disable-next-line */
  overflow: visible !important;
}

@media (--tablets-min) {
  .content {
    flex-direction: row;
    align-items: flex-start;
  }

  .main {
    padding-inline-start: 40px;
  }
}

.tabsList {
  border-block-end: 1px solid var(--color-border-section);
}
