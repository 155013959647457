:root {
  --marketing-image-width-height: 420px;
}

.root {
  width: 100%;
  margin-inline: auto;
  margin-block: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

.root img {
  width: var(--marketing-image-width-height);
}

.root,
.root img {
  transition: ease all 0.15s;
}

a.externalLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

a.externalLink:hover {
  text-decoration: none;
}

.marketingContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

.title {
  font-size: var(--font-size-lg);
  color: var(--color-text-primary);
  margin-block: 40px 20px;
  font-weight: var(--font-weight-bold);
}

.readMore {
  font-size: var(--font-size-md);
}

.title,
.readMore {
  max-width: 360px;
  text-align: center;
}

.root a.title:hover {
  text-decoration: none;
}

@media (max-height: 430px) {
  .root img {
    width: calc(var(--marketing-image-width-height) / 2);
  }

  .title {
    font-size: var(--font-size-md);
    margin-block: 20px 10px;
  }

  .readMore {
    font-size: var(--font-size-base);
  }
}

@media (max-height: 600px) {
  .root img {
    width: calc(var(--marketing-image-width-height) / 1.5);
  }

  .title {
    font-size: var(--font-size-md);
    margin-block: 20px 10px;
  }
}

@media (max-width: 1110px) {
  .root img {
    width: calc(var(--marketing-image-width-height) / 1.5);
  }
}
