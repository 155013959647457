.root {
  align-items: center;
  color: var(--color-status-highlight);
  display: flex;
  line-height: 1;
  user-select: none;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-sm);
}

.icon {
  align-items: center;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  margin-inline-end: 8px;
  overflow: hidden;
}

.label {
  display: block;
}
