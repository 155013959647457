.header {
  background-color: var(--color-bg-info);
  padding: var(--spacing-32);
}

.cover {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-16);
  padding-block: var(--spacing-32) var(--spacing-08);
  text-align: center;
}

.badge {
  background-color: var(--color-status-highlight);
  border-radius: 2px;
  color: var(--color-text-inverted);
  display: block;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-2xs);
  padding: 4px;
}

.actionBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-16);
  padding: var(--spacing-16);
}

.footer {
  display: flex;
  align-items: center;
  gap: var(--spacing-20);
  padding: var(--spacing-32);
}

.footer a {
  color: var(--color-text-secondary);
}

.footer a,
.footer a:hover,
.footer a:active,
.footer a:visited,
.footer a:focus {
  color: var(--color-text-tertiary);
  text-decoration: underline;
}

.mainHeader {
  align-items: center;
  display: flex;
  width: 100%;
}

.logo {
  flex: 2;
}

.languageSelector {
  flex: 0 0 140;
  width: 140px;
}

.menuItems {
  list-style-type: none;
  padding-inline: 0;
  margin-block: 0;
}

.menuItems a {
  display: block;
  color: var(--color-text);
  line-height: 1;
  padding: var(--spacing-16);
  border-block-start: 1px solid var(--color-controls-border);
  font-size: var(--font-size-sm);
  transition: background-color 0.2s ease;
}

.menuItems a.active,
.menuItems a:hover {
  background-color: var(--color-controls--selected-bg);
  color: var(--color-text-primary);
  text-decoration: none;
}

.main {
  max-width: 920px;
  margin-inline: auto;
  margin-block: var(--spacing-64);
  padding-inline: var(--spacing-16);
}

.content {
  margin-block-start: var(--spacing-32);
}

@media (--min-width-sm) {
  .languageSelector {
    flex-basis: 200px;
    width: 200px;
  }

  .main {
    display: flex;
    align-items: flex-start;
    gap: var(--spacing-64);
  }

  .menu {
    position: sticky;
    flex: 0 0 180px;
    width: 180px;
  }

  .content {
    margin-block-start: 0;
  }
}

@media (--min-width-md) {
  .main {
    gap: calc(2 * var(--spacing-64));
  }

  .menu {
    flex-basis: 240px;
    width: 240px;
  }
}
