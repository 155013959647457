.image {
  display: block;
  text-align: center;
}

@media (--handhelds) {
  .image img {
    height: 60px;
  }
}
