.list {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-inline: -30px;
  margin-block: 0;
}

.list label {
  align-items: center;
  display: flex;
  min-width: 260px;
  padding-inline: 30px;
  padding-block: 17px;
}

.spinner {
  display: flex;
  justify-content: center;
}
