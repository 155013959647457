.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: stretch;
  padding: 10px;
}

.filterHeader {
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.04);
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: space-around;
  padding-inline: 12px;
  padding-block: 0 15px;
}

.filter {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 1;
  margin-inline-start: 20px;
  width: 100%;
}

.filter:first-of-type {
  margin-inline-start: 0;
}

.sections {
  margin-inline: auto;
  margin-block: 0;
  max-width: 1200px;
  padding-block-end: 60px;
  width: 100%;
}

.sections.locked {
  overflow: hidden;
}

.section {
  margin-inline: 10px;
  margin-block: 5px 30px;
}

.sections.sectionsAppear,
.sections.sectionsEnter {
  opacity: 0.1;
  transform: translateY(20%);
}

.sections.sectionsAppear.sectionsAppearActive,
.sections.sectionsEnter.sectionsEnterActive {
  opacity: 1;
  transform: translateY(0);
  transition:
    transform 500ms ease-in-out,
    opacity 500ms ease-in-out;
}

.sections.sectionsExit {
  opacity: 1;
  transition: opacity 500ms ease-in-out;
}

.sections.sectionsExit.sectionsExitActive {
  opacity: 0;
}

.empty {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  text-align: center;
}

.grid {
  place-content: baseline center;
  display: flex;
  flex-basis: auto;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: wrap;
  margin-inline: auto;
  margin-block: 0;
  padding-inline: 5px;
  padding-block: 10px 60px;
  width: 100%;
}

.grid.gridEnter {
  opacity: 0.7;
  transform: translateY(20%);
}

.grid.gridEnter.gridEnterActive {
  opacity: 1;
  transform: translateY(0);
  transition:
    transform 500ms ease-in-out 200ms,
    opacity 500ms ease-in-out 200ms;
}

.gridItem {
  display: flex;
  flex-basis: auto;
  flex-basis: calc(25% - 30px);
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  margin-inline: 15px;
  margin-block: 0 0;
}

@media (--tablets-min) {
  .grid {
    justify-content: flex-start;
  }

  .gridItem {
    flex-basis: calc(33% - 30px);
  }
}

@media (--desktop) {
  .sections {
    padding-inline: 0;
    padding-block: 30px;
  }

  .section {
    margin-inline: 10px;
    margin-block: 5px 60px;
  }

  .grid {
    max-width: 1300px;
    padding-inline: 0;
    padding-block: 30px;
  }

  .gridItem {
    flex-basis: calc(33% - 30px);
  }
}

@media (--large-screens) {
  .gridItem {
    flex-basis: calc(25% - 30px);
  }
}
