.root {
  max-width: rem(580px);
  margin-inline: auto;
  padding: var(--spacing-40) var(--spacing-16);
}

.reviewChangesBar {
  /* reserve the space to avoid overlapping when scrolling to bottom */
  margin-block-start: var(--spacing-48);
}
