.filterIcon svg {
  color: var(--color-icon-decorative);
}

.mobileFilters {
  display: none;
}

@media (--handhelds) {
  .mobileFilters {
    display: flex;
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;
    padding-inline: var(--spacing-16);
    padding-block: 0;
    margin-block-start: var(--spacing-16);
  }

  .mobileFilters.compact {
    padding: 0;
  }
}
