.root {
  margin-block-end: 75px; /* height of review changes bar, ensures the content can be scrolled up and doesn't get stuck behind the review change bar */
}

.rowWrap {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-32);
  margin-block-start: var(--spacing-32);
}

.section {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}

@media (--min-width-md) {
  .section {
    flex-basis: calc(50% - var(--spacing-16));
  }
}

.section span {
  margin-block-end: var(--spacing-04);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-body);
  color: var(--color-text-body);
}

.questionLimitInput {
  margin-block-start: auto;
}
