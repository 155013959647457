.item {
  align-items: center;
  color: var(--color-text-primary) !important;
  display: flex;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-bold);
  justify-content: center;
  line-height: 1;
  min-height: rem(30px);
  padding-inline: rem(12px);
  padding-block: rem(3px);
}

:global(.peak-radio-group-item) input:checked + .item::after {
  inset-block-start: calc(100% - 3px);
}
