@import '../../../../styles/animations.css';

:root {
  --acknowledgement-button-size: 32px;
  --tooltip-bg-color: var(--color-bg-inverted);
}

.root {
  align-items: center;
  color: var(--color-ack-thanks-for-sharing-light);
  border-radius: 100%;
  display: flex;
  height: var(--acknowledgement-button-size);
  justify-content: center;
  position: relative;
  width: var(--acknowledgement-button-size);
}

.root.disabled {
  pointer-events: none;
}

.icon {
  transition: transform 0.08s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.root:hover .icon {
  transform: scale3d(1.5, 1.5, 1.5) translateY(-4px);
}

.tooltip {
  animation: pop-upwards 0.3s forwards linear;
  background-color: var(--tooltip-bg-color);
  border-radius: 100px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
  color: var(--color-text-inverted);
  display: none;
  font-size: var(--font-size-xs);
  margin-inline: auto;
  margin-block: 0;
  padding-inline: 8px;
  padding-block: 3px;
  position: absolute;
  inset-block-start: -45px;
  white-space: nowrap;
  width: auto;
  font-weight: var(--font-weight-bold);
}

.root:hover .tooltip {
  display: inline-block;
}
