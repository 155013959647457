.date {
  align-items: center;
  display: flex;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-sm);
  justify-content: space-between;
  line-height: 1;
}

.type {
  color: var(--color-status-negative);
  font-size: var(--font-size-xs);
  font-weight: normal;
}

.type.automatic {
  color: var(--color-text-tertiary);
}

.title {
  font-weight: var(--font-weight-bold);
}
