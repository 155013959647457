.root {
  width: 100%;
}

.improveBanner {
  padding-inline: 10px;
  padding-block: 0;
}

.insights {
  padding-block-end: 80px;
}

.section {
  border-block-end: 1px solid var(--color-border-section);
  margin-block-end: 40px;
  padding-block-end: 40px;
}

.banner {
  margin-block-start: var(--spacing-32);
}

@media (--handhelds-tablets) {
  /* stylelint-disable-next-line selector-class-pattern -- global styling */
  .root :global(.l-flex-row) {
    display: block;
  }
}

@media (--handhelds) {
  /* stylelint-disable-next-line selector-class-pattern -- global styling */
  .root :global(.l-flex-row) {
    display: block;
  }
}
