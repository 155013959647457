.root {
  background-color: var(--color-bg-card);
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);
  cursor: pointer;
  position: relative;
  transition: all 0.2s;
  width: 100%;
}

.root:active,
.root:hover {
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 12%);
}

.root,
.icon,
.indicator {
  align-items: center;
  display: flex;
}

.icon {
  justify-content: center;
}

.icon,
.indicator {
  flex: 0 0 70px;
  width: 70px;
}

.content {
  flex: 2;
  padding-inline: 0;
  padding-block: 15px;
}

.title {
  font-size: var(--font-size-md);
  margin-block-end: 5px;
  font-weight: var(--font-weight-bold);
}

.text {
  display: block;
}

.indicator {
  justify-content: flex-end;
  padding-inline-end: 5px;
  color: var(--color-text-primary);
}
