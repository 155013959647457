.root {
  border-radius: 2px;
}

.header {
  align-items: center;
  border-block-end: solid 1px var(--color-border);
  border-radius: 2px 2px 0 0;
  display: flex;
  padding-inline: 0 0;
  padding-block: 15px 15px;
}

.avatar {
  flex: 0 0 30px;
  margin-inline-end: 15px;
}

.name {
  flex: 2;
  font-weight: var(--font-weight-bold);
}

.responses {
  align-items: center;
  color: var(--color-text-tertiary);
  display: flex;
  font-size: var(--font-size-2xs);
  line-height: 1;
  margin-inline-start: 10px;
}

.peopleIcon {
  height: 0.75rem;
  width: 0.75rem;
}

.responseCount {
  margin-inline-start: 3px;
}

.attributeContainer {
  align-items: center;
  display: flex;
  margin-inline: 0;
  margin-block: 5px 0;
}

.attribute {
  display: inline-flex;
  margin: 0;
}

.toolbar,
.add {
  align-items: center;
  display: flex;
}

.add {
  margin-inline-start: var(--spacing-16);
}

.separator {
  border-block-start: solid 0 3px 3px 0 rgba(0, 0, 0, 0.05);
  padding-block-start: 20px;
}

.empty {
  padding: 20px;
  text-align: center;
}

.noData {
  color: var(--color-text-tertiary);
}

.link {
  color: var(--color-text-link);
  cursor: pointer;
  display: inline;
  text-decoration: underline;
  font-weight: var(--font-weight-bold);
}

.cta {
  margin-block-start: 5px;
}

@media (--handhelds) {
  .header {
    /*flex-direction: column;*/
    flex-wrap: wrap;
  }

  .toolbar {
    margin-inline: 0;
    margin-block: var(--spacing-24) 0;
    width: 100%;
    justify-content: space-between;
  }
}
