@import '../../../../../../styles/variables.css';

.list {
  border-inline-end: 1px solid var(--color-border-section);
  box-shadow: 2px 0 0 0 rgba(0, 0, 0, 0.02);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  overflow-y: auto;
  width: var(--attributes-width);
  color: var(--color-text-tertiary);
  padding: 0;
  list-style: none;
}

[dir='rtl'] .list {
  box-shadow: -2px 0 0 0 rgba(0, 0, 0, 0.02);
}
