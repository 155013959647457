.root {
  display: grid;
  grid-template-columns: 1fr repeat(2, auto) 1fr;
  grid-column-gap: var(--spacing-16);
  place-items: center;
  text-align: center;
  border-block-end: 1px solid var(--color-border);
  width: 100%;
  height: var(--nav-top-height);
  background-color: var(--color-bg-info);
}

.root.loading {
  grid-template-columns: 1fr repeat(2, auto);
  grid-column-gap: 0;
}

.text {
  grid-column-start: 2;
}

.closeView {
  margin-inline: auto var(--spacing-16);
}

@media (--handhelds) {
  .root {
    grid-template-columns: repeat(2, auto);
  }

  .text {
    display: none;
  }
}
