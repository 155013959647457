@import '../Navigation/variables.css';

.skip {
  position: fixed;
  z-index: 10;
  inset-block-start: 0;
  inset-inline-start: 50%;
  transform: translate(-50%, -100%);
  padding: var(--spacing-24);
  border: 1px solid var(--color-border);
  background-color: var(--color-bg-card);
  transition: transform 0.2s;
}

.skip:focus-within {
  transform: translate(-50%, 0);
  border-radius: 0 0 1rem 1rem;
  box-shadow: var(--depth-4-box-shadow);
}
