.ctaContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
}

.actionIcon {
  margin-inline: 0 10px;
  margin-block: 0 -8px;
}

.actionsInfo {
  overflow: hidden;
}

.improveMessageContainer {
  font-size: var(--font-size-base);
}

.actionButton {
  z-index: 5;
}

.button {
  align-items: center;
  display: flex;
  font-weight: bold;
  margin-inline-start: rem(4px);
  text-decoration: underline;
}

.actionsButtonContainer,
.actionsButtonContainer a {
  pointer-events: all;
}

.improveMessageContainer .actionIcon svg g {
  fill: var(--color-text-primary);
}

.emphasis {
  text-decoration: underline;
}

@media (--handhelds) {
  .improveMessageContainer {
    margin-block-end: 10px;
  }

  .actionsButtonContainer {
    width: 100%;
  }

  .ctaContainer {
    height: 100%;
  }

  .actionIcon {
    margin-inline: 2px 8px;
    margin-block: 0 -8px;
  }

  .actionButton {
    width: 100%;
  }
}

/** line breaks for small screens **/
@media (max-width: 351px) {
  .actionIcon {
    float: left;
  }

  [dir='rtl'] .actionIcon {
    float: right;
  }
}
