.root {
  background-color: var(--color-viz-neutral-fill);
  border-radius: 2px;
  font-size: rem(15px);
  margin-block-end: var(--spacing-32);
  padding: 20px;
  text-align: center;
}

.root a,
.root strong {
  font-weight: var(--font-weight-bold);
}

.root a,
.root a:hover,
.root a:active,
.root a:visited,
.root a:focus {
  color: var(--color-text-primary);
  text-decoration: underline;
}
