.container,
.content {
  width: 100%;
}

.content {
  overflow-y: auto;
  position: relative;
  border-inline-start: 1px solid var(--color-border-section);
  height: 100%;
}

.loader {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  color: var(--color-status-highlight);
  padding-block-start: var(--spacing-32);
}

.contentWrapper {
  max-height: 100%;
  overflow: hidden;
}

@media (--handhelds) {
  .impactNav {
    display: flex;
  }
}
