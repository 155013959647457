.header {
  margin-block-end: var(--spacing-32);
}

.topHeader {
  background-color: var(--color-bg-card);
  box-shadow: var(--nav-box-shadow);
  width: 100%;
  height: var(--nav-top-height);
  position: sticky;
  inset-block-start: 0;
  z-index: var(--nav-top-zindex);
}

.logoContainer {
  background-color: var(--color-bg-card);
  padding: var(--spacing-16);
  border-block-end: 1px solid var(--color-border);
}

.logoList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

@media (--handhelds) {
  .logoList {
    grid-template-columns: 0 1fr 1fr;
  }
}

.centerLogo {
  display: flex;
  justify-self: center;
  align-items: center;
  max-height: 40px;
}

@media (--handhelds) {
  .centerLogo {
    justify-self: start;
  }
}

.centerLogoImage {
  height: 100%;
}

.endLogo {
  justify-self: end;
  display: flex;
}
