.responseRateContainer {
  width: 1%;
}

.responseRateContainer > div {
  display: flex;
  align-items: center;
  padding-inline-end: var(--spacing-16);
}

.responseRateContainer > div > span {
  width: 5ch;
}

.roundTableRow {
  transition: opacity 100ms ease-out;
  opacity: 1;
}

.disabledRow {
  pointer-events: none;
  opacity: 0.6;
}

.roundTableRow .dropdownContainerCell {
  padding: 0;
}

.dropdownContainerCell > div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.dropdownContainerCell > div > * {
  margin-inline: var(--spacing-04);
}
