.avatar {
  margin-inline-end: rem(12px);
}

.mainLoader {
  margin: auto;
}

.nextPageLoader {
  display: flex;
  justify-content: center;
  color: var(--color-text-secondary);
}
