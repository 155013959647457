@import '../../../../../../styles/z-index.css';

.root,
.sharedRoot {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-inline: 16px;
  padding-block: 16px 12px;
  position: relative;
  transition: ease box-shadow 0.1s;
  word-break: break-word;
  outline: none;
}

.actionHeading {
  margin-inline-end: var(--spacing-24);
}

.coverButton {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 100%;
}

.root:not(.readOnly):hover {
  box-shadow: 0 1px 11px 0 rgba(0, 0, 0, 0.15);
}

.root:not(.readOnly):hover .title {
  text-decoration: underline;
}

.sharedTitle {
  font-size: var(--font-size-sm);
  color: var(--color-text-primary);
  display: inline;
  word-break: break-word;
  font-weight: var(--font-weight-bold);
}

.sharedTitle span {
  display: flex;
}

.sharedTitle span::after {
  content: '';
  display: inline-block;
  width: 10%;
}

.meta {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  margin-inline: 0 0;
  margin-block: 0 16px;
}

.meta .item:not(:first-of-type) {
  margin-inline-start: 12px;
}

.meta .item {
  display: flex;
  align-items: center;
  font-size: var(--font-size-xs);
  color: var(--color-text-tertiary);
  padding-inline: 0;
  padding-block: 12px 0;
}

.meta .item > svg {
  width: 20px;
  height: 20px;
  margin-inline-end: 4px;
}

.metaSuggested {
  margin: 0;
}

.checklist {
  text-transform: lowercase;
}

.footer,
.sharedFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-block-start: 1px solid var(--color-border);
  padding-block-start: 12px;
}

.footer.suggested,
.sharedFooter.suggested {
  margin-block-start: 16px;
}

.footer.suggested:hover span {
  color: var(--color-text-primary);
}

.footer.suggested svg {
  height: 24px;
  width: 24px;
}

.date {
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-bold);
  color: var(--color-text-tertiary);
}

.date .overdue {
  color: var(--color-status-negative);
}

.cta,
.sharedCta {
  display: flex;
  align-items: center;
}

.cta span,
.sharedCta span {
  padding-inline-end: 8px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-bold);
}

.cta.completed [data-hint] {
  width: 24px;
  height: 24px;
  cursor: pointer !important; /* needs to be important because of the tooltip */
}

.cta.archived,
.cta.markAsDone,
.sharedCta.archived,
.sharedCta.markAsDone {
  color: var(--color-text-secondary);
}

.cta.archived:hover {
  color: var(--color-text-tertiary);
}

.cta.markAsDone:hover {
  color: var(--color-text-tertiary);
}

.dismiss {
  position: absolute;
  inset-inline-end: 12px;
  inset-block-start: 12px;
}

.suggested {
  display: flex;
  align-items: center;
  gap: var(--spacing-08);
  font-weight: var(--font-weight-bold);
}

.suggested span {
  color: var(--color-text-secondary);
  padding-inline-start: 8px;
  font-weight: var(--font-weight-bold);
}

.filler {
  position: absolute;
  inset-inline: 0;
  inset-block: 0;
  z-index: var(--z-index-10);
  pointer-events: all;
}

.titleWithDismiss h4 {
  width: calc(100% - 30px);
}

/* This is a utility class for this file needed after position:relative was removed from <UnstyledButton /> */
.relative {
  position: relative;
}
