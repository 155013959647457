.root {
  padding-block: 0.75rem;
  padding-inline: var(--spacing-40);
}

.root,
.list,
.button {
  align-items: center;
  display: flex;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list.hasOverflow {
  position: absolute;
  inset-inline-end: -9999px;
  inset-block-start: -9999px;
}

.item {
  font-size: rem(9px);
  font-weight: var(--font-weight-bold);
  line-height: 1.4;
  padding-inline-end: var(--spacing-24);
}

.button {
  color: var(--color-text-heading);
  position: relative;
}

.button.expand {
  font-size: var(--font-size-sm);
}

.name {
  margin-inline-start: var(--spacing-08);
  white-space: nowrap;
}

.name.multiLine {
  white-space: normal;
  width: rem(250px);
}

.chevron {
  color: var(--color-icon-decorative);
  display: block;
  position: absolute;
  height: 8px;
  inset-block-start: 50%;
  inset-inline-end: -16px;
  transform: translateY(-50%);
  width: 8px;
}

[dir='rtl'] .chevron {
  transform: scale(-1, 1) translateY(-50%);
}

.spinnerWrapper {
  height: 30px; /* set the height to follow avatar's height */
  display: flex;
  justify-content: center;
  align-items: center;
}
