.scoreCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-block-end: var(--spacing-16);
}

.mainPart {
  display: flex;
  justify-content: space-between;
}

@media (--handhelds) {
  .mainPart {
    flex-direction: column;
  }
}

.questionSetArrow {
  margin-block: auto;
  margin-inline-end: 10px;
}

.questionSetArrowWithConnector {
  margin-block-end: 5px;
}

.graph {
  margin-block: 15px 30px;
  width: 100%;
}

@media (--handhelds) {
  .graph {
    order: -1;
    margin: 0;
    overflow: hidden;
  }

  .graph::after {
    content: '';
    flex-basis: 100%;
    height: 0;
  }
}

.questionScoreCard {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
}

@media (--handhelds) {
  .questionScoreCard {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .questionScoreCard .graph {
    overflow: unset;
    margin-block: var(--spacing-24) var(--spacing-32);
    width: 103%;
  }
}

.graphTopLabelStart {
  margin-inline: var(--spacing-16);
}

.graphTopLabelEnd {
  margin-inline: var(--spacing-16);
}

@media (--handhelds) {
  .graphTopLabelStart,
  .graphTopLabelEnd {
    height: 100%;
    margin: var(--spacing-08);
    font-size: var(--font-size-md);
    flex-grow: 10;
  }

  .graphTopLabelEnd {
    text-align: end;
    margin-inline-end: calc(var(--spacing-16) * -1);
  }
}

.categoryName {
  width: 100%;
  background-color: var(--color-bg-card);
  border-radius: var(--border-radius-card) 0 0 0;
}

.categoryNameWithPriorities {
  border-radius: var(--border-radius-card) 0 0 var(--border-radius-card);
}

.categoryNameWithConnector {
  display: flex;
  flex-direction: column;
}

.iconAndText {
  display: flex;
  margin-block: auto;
}

.iconAndTextWithConnector {
  margin-block-end: 0;
}

.categoryName:hover {
  background: var(--color-bg-info);
}

.categoryName:hover .questionSetArrow {
  margin-inline-end: 0;
}

.rootLink {
  width: 34%;
  display: flex;
}

@media (--handhelds) {
  .rootLink {
    width: 100%;
    padding-block: var(--spacing-16) var(--spacing-08);
    padding-inline: 0;
  }
}

.link,
.link:hover,
.link:focus {
  text-decoration: none;
  padding-inline: var(--spacing-16);
  padding-block: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  height: 100%;
}

.connectorLine {
  margin-inline-start: 30px;
  height: 50%;
  border-inline-start: 1px solid var(--color-controls-active-border);
}

@media (--handhelds) {
  .connectorLine {
    display: none;
  }
}
