.list {
  list-style-type: none;
  padding-inline-start: 0;
  transition: all 0.2s;
}

.list.hidden {
  transform: translateX(-100%);
  visibility: hidden;
}

[dir='rtl'] .list.hidden {
  transform: translateX(100%);
}

.wrapper {
  box-sizing: border-box;
  transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0)
    scaleX(var(--scale-x, 1)) scaleY(var(--scale-y, 1));
  transform-origin: 0 0;
  touch-action: manipulation;
  border-block-end: 1px solid var(--color-border-section);
  position: relative;
  width: 100%;
  outline: none;
  list-style: none;
  border-block-start: 1px solid var(--color-border-section);
}

.wrapper.isDragging {
  opacity: var(--dragging-opacity, 0.5);
  z-index: 0;
}
