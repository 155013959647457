.fullScreenContainer {
  display: grid;
  place-items: center;
  min-height: 100vh; /* fallback for browsers that don't support dvh */
  min-height: 100dvh;
}

.errorContainer {
  max-width: 30rem;
  text-wrap: balance;
}

.illustration {
  max-width: 16rem;
}

.illustration > * {
  max-width: 100%;
}
