.steps {
  background-color: var(--color-bg-card);
}

.header {
  box-shadow: inset 0 3px 3px 0 rgba(0, 0, 0, 0.05);
}

.employeesPanel {
  overflow: hidden;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex: 2;
}

.warning {
  padding-inline: 20px;
  padding-block: 20px 0;
}

.filters {
  padding: 20px;
}

.actions {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  flex: 1;
  min-height: 48px;
}
