.emptyStateText {
  text-align: center;
  max-width: 350px;
  display: block;
}

.illustration {
  width: 240px;
  max-width: 100%;
}
