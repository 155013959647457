.root {
  overflow: auto;
  padding-block-end: var(--spacing-40);
}

.root table {
  width: 100%;
}

.root th,
.root td {
  border-inline-end: rem(1px) solid var(--color-table-border);
  overflow: visible;
  padding: 0;
  padding-inline-end: rem(10px);
}

.root tr th {
  border-block-end: rem(1px) solid var(--color-table-border);
  box-shadow: 0 rem(2px) rem(4px) rgb(0 0 0 / 8%);
  padding: 0.75rem;
  position: sticky;
  text-align: start;
  inset-block-start: 0;
  z-index: 1;
}

.root thead th:first-child {
  width: 60%;
  padding-inline-start: var(--spacing-40);
}

.root thead th:last-child {
  width: 25%;
}

.root td.name:not(.clickable) {
  padding-block: 0.75rem;
  padding-inline: rem(80px) 0;
}

.root td.name.clickable {
  padding: 0;
}

td.name .nameButton {
  padding-block: 0.75rem;
  padding-inline: var(--spacing-40) rem(10px);
}

td.name.child.expandable.current .nameButton {
  padding-inline-start: var(--spacing-40);
}

td.name.child.expandable .nameButton {
  padding-inline-start: rem(80px);
}

td.name.child.clickable:not(.expandable) .nameButton {
  padding-inline-start: rem(120px);
}

td.name.expanded {
  background-color: var(--color-controls--selected-bg);
}

td.name.expanded::after {
  background-color: var(--color-controls--selected-marker);
  content: '';
  position: absolute;
  inset-block: 0;
  inset-inline-start: 0;
  width: rem(4px);
}

td.name.expanded .chevronRight {
  rotate: 90deg;
}

.root tr:last-child td {
  border-block-end: rem(1px) solid var(--color-table-border);
}

.root tr:hover td {
  background-color: var(--color-bg-card) !important;
}

.root tr td.name.clickable:hover {
  background-color: var(--color-controls-hover-bg) !important;
}

.root td.breadcrumb,
.root tr:hover td.breadcrumb {
  padding: 0;
  background-color: var(--color-bg-primary) !important;
}

.name,
.nameButton {
  width: 100%;
  display: inline-flex;
  align-items: center;
  column-gap: rem(10px);
  position: relative;
  white-space: normal;
}

.name .nameButton:focus {
  box-shadow: var(--focus-border);
}

.chevronRight {
  transition: rotate 0.2s;
  width: rem(30px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-icon-decorative);
  flex-shrink: 0;
}

[dir='rtl'] .chevronRight {
  rotate: 180deg;
}

.employeeCount,
.managers {
  text-align: end;
}

.managers button {
  display: inline-flex !important;
}

.nodeManager,
.remainingManagersCountWrapper {
  display: inline-block;
  margin-inline-end: rem(5px);
  position: relative;
  vertical-align: middle;
}

.nodeManagerIconContainer {
  position: absolute;
  inset-block-start: rem(23px);
  inset-inline-end: rem(-3px);
  background-color: var(--color-bg-card);
  border: rem(1px) solid var(--color-border);
  display: flex;
}

.nodeManagerIcon {
  height: 0.75rem;
  width: 0.75rem;
}

.remainingManagersCount {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: var(--font-weight-bold);
  background-color: var(--color-viz-neutral-fill);
  border-radius: 50%;
  /* Pixel values to match the <Avatar/> size */
  width: 30px;
  height: 30px;
  font-size: var(--font-size-sm);
}

.editManagersButton {
  vertical-align: middle;
}

.chevronIcon {
  height: 0.75rem;
  width: 0.75rem;
}
