.root {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ruler {
  border: 0;
  border-block-start: 1px solid var(--color-border-section);
  color: var(--color-text-tertiary);
  flex-grow: 1;
}

.caption {
  color: var(--color-text-tertiary);
  font-size: var(--font-size-md);
  padding-inline: 15px;
  font-weight: var(--font-weight-bold);
}
