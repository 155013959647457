.root {
  position: relative;
  align-items: center;
  color: var(--color-text-primary);
  display: flex;
  font-size: var(--font-size-sm);
  height: 28px;
  line-height: 1;
  padding: 5px;
  font-weight: var(--font-weight-bold);
}

.label {
  transition: color 0.2s ease;
  font-weight: var(--font-weight-bold);
}

.active .label,
.root:hover .label {
  color: var(--color-status-highlight);
}

.active .icon,
.root:hover .icon {
  color: var(--color-status-highlight);
}

.icon {
  margin-inline-end: 4px;
  transition: color 0.2s ease;
}

[dir='rtl'] .icon {
  margin-inline: 4px 0;
  transform: scaleX(-1);
}
