@import '../../../../../../../../../../styles/variables.css';

.root.expanded {
  align-items: center;
  display: flex;
  justify-content: center;
}

.nps {
  align-items: center;
  display: flex;
  justify-content: center;
}

.item {
  align-items: center;
  display: flex;
  padding-inline: 15px;
  padding-block: 0;
}

.bullet {
  background-color: var(--color-viz-neutral-fill);
  border-radius: 100%;
  height: 6px;
  margin-inline-end: 7px;
  width: 6px;
}

.item.promoters .bullet {
  background-color: var(--color-viz-positive-fill);
}

[data-theme='deuteranopia'] .item.promoters .bullet {
  background-color: var(--color-viz-positive-fill--deuteranopia);
}

.item.detractors .bullet {
  background-color: var(--color-viz-negative-fill);
}

.item.included {
  justify-content: center;
  margin-block-start: 7px;
}

.root.expanded .item.included {
  margin-block-start: 0;
}

.item.included .bullet {
  background-color: var(--color-bg-card);
  border-radius: 0;
  border: 1px solid var(--color-border);
}

.label {
  color: var(--color-text-body);
  font-size: var(--font-size-3xs);
  font-weight: var(--font-weight-bold);
}

.helpBadge {
  margin-inline-start: 6px;
}
