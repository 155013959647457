.form {
  display: flex;
  flex-direction: column;
  margin-block-start: 30px;
  font-weight: var(--font-weight-bold);
  font-weight: 700;
}

.sso button {
  width: 100%;
  margin-block-end: 25px;
}

.hidden {
  opacity: 0;
  position: absolute;
  pointer-events: none;
}
