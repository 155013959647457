@media (--handhelds) {
  .actions {
    display: block;
  }

  .actions,
  .actions > button {
    margin-block-start: var(--spacing-16);
  }

  .actions > button {
    display: block;
    width: 100%;
  }
}
