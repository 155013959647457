.segmentContainer {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-04);
  align-items: center;
}

.disabledText {
  color: var(--color-status-negative);
}

tr td.menuContainer {
  padding-inline-end: var(--spacing-04);
}

tr td.menuContainer > div {
  display: flex;
  justify-content: flex-end;
}
