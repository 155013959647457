@import 'animate.css/source/flippers/flipInX.css';
@import 'animate.css/source/flippers/flipOutX.css';

.container {
  padding-block-start: var(--spacing-32);
}

.enter,
.exit {
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

.enter.enterActive {
  animation-name: flipInX;
}

.exit.exitActive {
  animation-name: flipOutX;
}

.certificate {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-block-end: 10px;
}

.certificate > span > div:last-of-type {
  margin-block-start: 15px;
}

.input {
  display: flex;
  flex-direction: row;
  padding-inline: 0 0;
  padding-block: 10px 10px;
}

.title {
  margin-block-end: var(--spacing-16);
}

.formField.formFieldButton {
  display: flex;
  flex-direction: column;
  margin-inline-start: 25px;
}

.formField.certificateField {
  width: 40%;
}

.smimeSet {
  display: flex;
  flex-direction: column;
}

.smimeSet label {
  margin-block-start: 20px;
  color: var(--color-status-positive);
  font-weight: var(--font-weight-bold);
}

.smimeSet button {
  margin-block-start: 20px;
  max-width: 200px;
}

.toggle {
  display: flex;
  flex-direction: row;
  margin-block-start: var(--spacing-16);
  padding-block-end: var(--spacing-16);
}
