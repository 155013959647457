@import '@peakon/components/src/Popover/styles';

.root {
  margin-block: calc(var(--popover-padding) * -1) var(--popover-padding);
  width: 100%;
}

.question {
  align-items: center;
  box-shadow: inset 0 1px 0 0 var(--color-border);
  color: var(--color-text);
  display: flex;
  padding-inline: 0;
  padding-block: var(--popover-padding);
  text-decoration: none;
  width: 100%;
}

.question > span {
  display: block;
}

a.question {
  cursor: pointer;
}

div.question {
  padding-inline-end: var(--popover-padding);
}

.question:last-child {
  box-shadow:
    inset 0 1px 0 0 var(--color-border),
    inset 0 -1px 0 0 var(--color-border);
}

.question:hover,
.question:active,
.question:focus,
.question:visited {
  color: var(--color-text);
  text-decoration: none;
}

.icon {
  color: var(--color-text-tertiary);
  width: 40px;
  font-size: var(--font-size-md);
  line-height: 1;
  text-align: center;
  font-weight: var(--font-weight-bold);
}

.text {
  white-space: normal;
  /* beware of overflowing children... */
  width: calc(var(--popover-max-width) - 40px - 32px);
}

.indicator {
  width: 32px;
  line-height: 1;
  text-align: center;
}

[dir='rtl'] .indicator {
  transform: scaleX(-1);
}

.indicator path {
  fill: var(--color-text-tertiary);
}

.loader {
  align-items: center;
  display: flex;
  height: 44px;
  justify-content: center;
}
