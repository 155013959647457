.form {
  margin-block-start: 20px;
  max-width: 600px;
}

.setting {
  display: flex;
  flex-direction: column-reverse;
  margin-block-end: 20px;
}

@media (--tablets-min) {
  .setting {
    display: inline-flex;
    flex-direction: row;
    align-items: flex-start;
  }
}

.toggle {
  display: flex;
  align-items: flex-start;
}

@media (--tablets-min) {
  .label {
    margin-inline-start: 10px;
    margin-block-start: 0;
    font-weight: bold;
  }
}
