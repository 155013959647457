.slideInContent {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.main {
  align-items: stretch;
  background-color: var(--color-bg-primary);
  display: flex;
  flex: 3;
  padding: var(--spacing-16);
  overflow: hidden auto;
  position: relative;
}

.container {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.form {
  flex: 0 0 auto;
}

@media (--handhelds-tablets) {
  .main {
    overflow-y: scroll;
  }
}
