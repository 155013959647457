.mentions {
  font-size: var(--font-size-base);
  line-height: 1.45;
}

/* stylelint-disable-next-line selector-class-pattern -- external library react-mentions */
.mentions .mentions__control {
  min-height: 70px;
}

/* stylelint-disable-next-line selector-class-pattern -- external library react-mentions */
.mentions .mentions__control .mentions__input {
  overflow: hidden;
}

/* stylelint-disable-next-line selector-class-pattern -- external library react-mentions */
.mentions .mentions__control .mentions__input::placeholder {
  color: var(--color-text-tertiary);
  font-size: var(--font-size-sm);
  opacity: 1;
}

/* stylelint-disable-next-line selector-class-pattern -- external library react-mentions */
.mentions__suggestions__list {
  animation: pop-mentions 0.25s forwards cubic-bezier(0.165, 0.84, 0.44, 1);
  background-color: var(--color-bg-card);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-sm);
  inset-block-end: 130px;
  box-shadow: 0 1px 40px 0 rgba(0, 0, 0, 0.13);
  max-height: 50vh;
  max-width: 516px;
  overflow-y: auto;
  /* stylelint-disable-next-line */
  position: fixed !important;
  inset-inline-end: 0;
  width: 40vw;
  z-index: 701;
}

@media (--handhelds-tablets) {
  /* stylelint-disable-next-line selector-class-pattern -- external library react-mentions */
  .mentions__suggestions__list {
    width: 100%;
  }
}

/* stylelint-disable-next-line selector-class-pattern -- external library react-mentions */
.mentions__suggestions__item {
  border-block-end: 1px solid var(--color-border);
  color: var(--color-text-primary);
  font-size: var(--font-size-sm);
  cursor: pointer;
  line-height: 1;
  padding: var(--spacing-16);
}

/* stylelint-disable-next-line selector-class-pattern -- external library react-mentions */
.mentions__suggestions__item:last-child {
  border-block-end: 0;
}

/* stylelint-disable-next-line selector-class-pattern -- external library react-mentions */
.mentions__suggestions__item--focused {
  background-color: var(--color-controls-hover-bg);
}

.mention {
  background-color: var(--color-conversation-note-border);
  color: var(--color-text);
  /* stylelint-disable-next-line */
  font-weight: var(--font-weight-bold) !important;
  position: relative;
  z-index: 1;
}

:global([data-kind='reply']) .mention {
  background-color: var(--color-conversation-user-border);
}

.user {
  align-items: center;
  display: flex;
  line-height: 1.45;
}

.avatar {
  margin-inline-end: 10px;
}

.ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@keyframes pop-mentions {
  0% {
    opacity: 0;
    transform: scale(1) rotateX(-40deg);
  }

  50% {
    opacity: 1;
  }

  70% {
    transform: scale(1.05) rotateX(0);
  }

  100% {
    opacity: 1;
    transform: scale(1) rotateX(0);
  }
}
