.overlay {
  background-color: rgba(0, 0, 0, 0.4);
  cursor: default;
  height: 100vh;
  inset: 0;
  mix-blend-mode: multiply;
  overflow: auto;
  pointer-events: auto;
  position: absolute;
  z-index: var(--z-index-9);
}

.step {
  background-color: var(--color-bg-card);
  border-radius: var(--border-radius-card);
  box-shadow: var(--depth--4-box-shadow);
  opacity: 1;
  pointer-events: auto;
  position: absolute;
  transform: translate3d(0, 0, 0);
}

.root {
  background-color: var(--color-bg-card);
  border: 1px solid var(--color-border);
  border-radius: 16px;
  box-shadow: var(--depth-4-box-shadow);
  width: 560px;
  padding: var(--spacing-24);
}

.root.animate {
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-name: slideInUp;
  animation-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
}

.popper {
  z-index: var(--z-index-10);
}

.arrow {
  position: absolute;
  width: 32px;
  height: 32px;
  border-style: solid;
  visibility: visible;
  content: '';
}

.popper[data-popper-placement^='top'] .arrow {
  border-width: 30px 17px 0 17px;
  border-color: var(--color-bg-card) transparent transparent transparent;
  inset-block-end: -24px;
}

.popper[data-popper-placement^='bottom'] .arrow {
  border-width: 0 17px 30px 17px;
  border-color: transparent transparent var(--color-bg-card) transparent;
  inset-block-start: -24px;
}

.popper[data-popper-placement^='left'] .arrow {
  border-width: 17px 0 17px 34px;
  border-color: transparent transparent transparent var(--color-bg-card);
  inset-inline-end: -24px;
}

.popper[data-popper-placement^='right'] .arrow {
  border-width: 17px 34px 17px 0;
  border-color: transparent var(--color-bg-card) transparent transparent;
  inset-inline-start: -24px;
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.header svg {
  display: block;
}

.content {
  padding-block: var(--spacing-24) var(--spacing-32);
}

.content p {
  font-size: var(--font-size-sm);
  margin-block: 0 var(--spacing-16);
}

.content p:last-child {
  margin-block-end: 0;
}

.footer {
  align-items: center;
  border-block-start: 0;
  display: flex;
  justify-content: space-between;
}

.progress {
  width: 302px;
}

.action {
  width: 100%;
  display: inline-flex;
  justify-content: flex-end;
}

.actions button {
  margin-inline-end: var(--spacing-08);
}

.actions button:last-child {
  margin-inline-end: 0;
}

@media (--handhelds) {
  .root {
    /* stylelint-disable-next-line */
    inset-inline: auto !important;
    margin-inline: var(--spacing-04);
    width: calc(100vw - var(--spacing-08));
  }

  .popper[data-popper-placement='bottom-start'] .root {
    border-start-start-radius: 0;
    margin-inline-start: 0;
  }

  .popper[data-popper-placement='bottom-start'] .arrow {
    inset-block-start: -30px;
  }

  .footer {
    flex-direction: column;
  }

  .actions,
  .progress {
    width: 100%;
  }

  .actions {
    align-items: center;
    display: flex;
    margin-block-start: var(--spacing-24);
  }

  .actions > button {
    display: block;
    flex: 1;
    width: 100%;
  }
}

@keyframes slideInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
    visibility: visible;
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
