.root {
  margin-inline: -10px;
  margin-block: 0 var(--spacing-04);
}

.rootCreateMode {
  margin-block-end: var(--spacing-32);
}

.fields {
  padding: var(--spacing-24);
  display: flex;
  justify-content: space-between;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: var(--color-border);
}

.field {
  width: 100%;
}

.field > label {
  display: block;
}

.field span {
  text-transform: capitalize;
  font-size: rem(15px);
  font-weight: 500;
  color: var(--color-text-primary);
}

.root input,
.rootCreateMode input {
  display: block;
  width: 100%;
}

.variationsList {
  padding: var(--spacing-24);
}

.variationsCreate h2,
.variationsList h2 {
  font-weight: var(--font-weight-bold);
  font-size: rem(18px);
  color: var(--color-text-primary);
  margin: 0;
}

.variationsListEmpty {
  padding-block: var(--spacing-08) 0;
}

.variationsList table {
  margin-inline: 0;
  margin-block: var(--spacing-20) 0;
  width: 100%;
}

.variationsList table thead td {
  color: var(--color-text-hint);
  font-size: var(--font-size-2xs);
  font-weight: 700;
  cursor: default;
}

.variationsList table thead td:first-child {
  width: 125px;
}

.variationsList table tbody td {
  padding-inline: 0 var(--spacing-20);
  padding-block: var(--spacing-12);
  cursor: default;
}

.variationsWordDeleteButton {
  display: inline-block;
  width: 18px;
  height: 18px;
  cursor: pointer;
  position: relative;
  inset-inline-start: var(--spacing-12);
}

.variationsWord {
  display: inline-block;
  border-radius: 16px;
  border: 1px solid var(--color-btn--warning-border);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-base);
  color: var(--color-btn--warning-text);
  padding-inline: var(--spacing-12);
  padding-block: var(--spacing-04);
  margin: var(--spacing-04);
  cursor: default;
}

.variationsCreate {
  padding: var(--spacing-24);
  border-block-start: 1px solid var(--color-border);
}

.variationsCreateForm {
  display: flex;
  justify-content: flex-start;
  gap: var(--spacing-08);
  padding-inline: 0;
  padding-block: var(--spacing-16) 0;
  width: 100%;
}

.variationsCreateLanguage {
  min-width: 178px;
  white-space: nowrap;
  z-index: 10;
}

.variationsCreateWords {
  width: 100%;
}

.inputs {
  display: flex;
  gap: var(--spacing-08);
}

.input {
  width: 100%;
}

.addButton {
  align-self: flex-end;
}

.footer {
  display: flex;
  padding: var(--spacing-24);
}

.footerSpaceBetween {
  justify-self: space-around;
}

.footerEndAlign {
  justify-content: end;
}

.createButtons button {
  margin-inline: var(--spacing-08) 0;
  margin-block: 0 0;
}

.deleteButton {
  margin-inline-end: auto;
}

.createHeader {
  font-weight: var(--font-weight-bold);
  font-weight: bold;
  font-size: rem(22px);
  color: var(--color-text-primary);
  padding: var(--spacing-24);
}

.fieldLabel {
  margin-block-end: var(--spacing-16);
}

.fieldTitle {
  font-size: var(--font-size-md);
}

@media (--handhelds) {
  .root {
    max-width: 100vw;
  }

  .variationsCreateForm {
    flex-direction: column;
  }

  .variationsCreateWords {
    margin-block-start: var(--spacing-08);
  }
}
