.container {
  display: inline-flex;
  align-items: center;
  flex: 1;
}

.label {
  flex: 2;
  margin-inline: 12px 90px;
  margin-block: 0 0;
  text-align: start;
}
