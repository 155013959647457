.loginContainer {
  display: flex;
  justify-content: center;
}

.login {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.formContainer,
.successContainer {
  max-width: 320px;
  width: 95vw;
}

.header {
  max-width: 320px;
  width: 95vw;
  margin-block-start: rem(60px);
}

.inputFieldContainer {
  font-weight: 700;
  margin-block-end: 10px;
}

.inputField {
  margin-block-end: 10px;
}

.warning {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-status-negative);
  background-color: rgba(255, 85, 0, 0.08);
  height: 42px;
  max-width: 320px;
  font-weight: var(--font-weight-bold);
}

@media (max-width: 815px) {
  .loginContainer {
    width: 100%;
  }

  .login {
    align-items: center;
  }
}

@media (--handhelds) {
  .header {
    margin-block-start: 10%;
  }
}
