.tabContainer {
  padding-block: var(--spacing-32);
  padding-inline: var(--spacing-16);
}

.largeContainer {
  max-width: rem(1200px);
  margin: auto;
}

.spinner {
  color: var(--color-status-highlight);
  margin-block: var(--spacing-40);
  display: flex;
  align-items: center;
  flex-direction: column;
}
