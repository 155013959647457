.template {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 30px;
  padding-block: 24px;
  background: var(--color-bg-alert);
  border: 1px solid var(--color-border);
}

.templateIcon {
  margin-inline-end: 16px;
}

.templateIcon,
.templateIcon svg {
  width: 48px;
  height: 48px;
}

.helpContent {
  max-width: 312px;
  padding-inline: 0;
  padding-block: 24px 0;
}

.importResult {
  padding-block-start: var(--spacing-16);
}

@media (--handhelds) {
  .helpContent {
    max-width: 100%;
  }
}
