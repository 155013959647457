/**
 *
 * We disable the class pattern rule here to avoid having to manipulate the
 * property names in typescript. If we use something like `capitalize(property)`
 * it gets reduced to type "string" (unless we add extra types for this) and
 * that breaks the `styles[className]`.
 *
 */

/* stylelint-disable selector-class-pattern */
.flex {
  display: flex;
}

.s_gap {
  gap: var(--flex__s-gap);
}

.s_flexBasis {
  flex-basis: var(--flex__s-flexBasis);
}

.s_flexShrink {
  flex-shrink: var(--flex__s-flexShrink);
}

.s_flexGrow {
  flex-grow: var(--flex__s-flexGrow);
}

.s_flexDirection {
  flex-direction: var(--flex__s-flexDirection);
}

.s_flexWrap {
  flex-wrap: var(--flex__s-flexWrap);
}

.s_alignItems {
  align-items: var(--flex__s-alignItems);
}

.s_alignSelf {
  align-self: var(--flex__s-alignSelf);
}

.s_justifyContent {
  justify-content: var(--flex__s-justifyContent);
}

.s_justifySelf {
  justify-self: var(--flex__s-justifySelf);
}

@media (--tablets-min) {
  .m_gap {
    gap: var(--flex__m-gap);
  }

  .m_flexBasis {
    flex-basis: var(--flex__m-flexBasis);
  }

  .m_flexShrink {
    flex-shrink: var(--flex__m-flexShrink);
  }

  .m_flexGrow {
    flex-grow: var(--flex__m-flexGrow);
  }

  .m_flexDirection {
    flex-direction: var(--flex__m-flexDirection);
  }

  .m_flexWrap {
    flex-wrap: var(--flex__m-flexWrap);
  }

  .m_alignItems {
    align-items: var(--flex__m-alignItems);
  }

  .m_alignSelf {
    align-self: var(--flex__m-alignSelf);
  }

  .m_justifyContent {
    justify-content: var(--flex__m-justifyContent);
  }

  .m_justifySelf {
    justify-self: var(--flex__m-justifySelf);
  }
}

@media (--desktop) {
  .l_gap {
    gap: var(--flex__l-gap);
  }

  .l_flexBasis {
    flex-basis: var(--flex__l-flexBasis);
  }

  .l_flexShrink {
    flex-shrink: var(--flex__l-flexShrink);
  }

  .l_flexGrow {
    flex-grow: var(--flex__l-flexGrow);
  }

  .l_flexDirection {
    flex-direction: var(--flex__l-flexDirection);
  }

  .l_flexWrap {
    flex-wrap: var(--flex__l-flexWrap);
  }

  .l_alignItems {
    align-items: var(--flex__l-alignItems);
  }

  .l_alignSelf {
    align-self: var(--flex__l-alignSelf);
  }

  .l_justifyContent {
    justify-content: var(--flex__l-justifyContent);
  }

  .l_justifySelf {
    justify-self: var(--flex__l-justifySelf);
  }
}
