.root {
  align-items: stretch;
  display: flex;
  justify-content: space-between;
  padding-inline: var(--spacing-16);
  position: sticky;
  inset-block-start: 0;
  z-index: 2;
  flex-wrap: wrap;
}

.root.justifyContentEnd {
  justify-content: end;
}

.left {
  display: flex;
  align-items: center;
  padding-block: var(--spacing-08);
}

.right {
  padding-block: var(--spacing-08);
}

.search {
  display: inline-block;
  flex: rem(220px);
}

.searchForm {
  position: relative;
}

.searchForm,
.searchForm input {
  height: 100%;
  width: 100%;
}

.searchForm input {
  padding-inline-end: rem(35px);
}

.searchForm input::-ms-clear,
.searchForm input::-ms-reveal {
  display: none;
  height: 0;
  width: 0;
}

.message {
  color: var(--color-text-primary);
  display: inline-block;
  font-size: var(--font-size-xs);
  padding-inline: var(--spacing-08);
  padding-block: 0;
  width: rem(250px);
}

.actions {
  align-items: stretch;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  position: relative;
}

.buttonAdd {
  margin-inline-end: var(--spacing-08);
}

.buttonAdd.reversedMargin {
  margin-inline: var(--spacing-08) 0;
}

.modalWrapper {
  width: calc(100% - rem(40px));
  margin: auto;
}
