.root {
  font-size: var(--font-size-2xs);
  font-weight: var(--font-weight-bold);
}

.root.light,
.root.noBorder {
  background-color: transparent;
  color: var(--color-text-tertiary);
  display: block;
  font-family: var(--font-family-base);
  line-height: 1.27;
  margin-inline-start: 0;
  overflow: hidden;
  padding-inline: var(--spacing-04);
  padding-block: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.root.light {
  border-radius: var(--border-radius-base);
  border: 1px solid var(--color-border-section);
}
