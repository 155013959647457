.badge {
  background-color: var(--color-bg-primary);
  width: rem(40px);
  height: rem(40px);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
}
