.variationsWord {
  display: inline-block;
  border-radius: 16px;
  border: 1px solid var(--color-btn--warning-border);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-base);
  color: var(--color-btn--warning-text);
  padding-inline: var(--spacing-12);
  padding-block: var(--spacing-04);
  margin: var(--spacing-04);
  cursor: default;
}
