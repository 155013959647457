.container {
  margin-block-start: var(--spacing-48);
}

.header {
  display: grid;
  gap: var(--spacing-16);
}

.content {
  margin-block-end: var(--spacing-24);
}
