@import 'animate.css/source/fading_entrances/fadeIn.css';

.root,
.label {
  align-items: center;
  display: flex;
}

.root {
  width: 100%;
}

.label {
  flex-wrap: wrap;
}

.avatar {
  padding-inline-end: var(--spacing-08);
}

.ellipsis .avatar {
  padding-inline-end: var(--spacing-16);
}

.context {
  flex: 2;
}

.name {
  margin-inline-end: var(--spacing-04);
}

.ellipsis .name,
.ellipsis .reporting {
  max-width: rem(130px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}

.badge {
  display: flex;
  height: 16px;
  gap: var(--spacing-04);
}

.ellipsis .badge > span {
  max-width: rem(90px);
}

.reporting {
  color: var(--color-text-inverted);
  font-size: var(--font-size-2xs);
  margin-block-start: rem(2px);
}

.selected .reporting {
  color: var(--color-text-secondary);
}

.light .label {
  color: var(--color-text-inverted);
}

.light .attribute {
  border: 1px solid hsla(0, 0%, 100%, 0.25);
  color: hsla(0, 0%, 100%, 0.7);
}

.small .label {
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-bold);
}

.standalone {
  gap: var(--spacing-08);
}

.standalone .avatar {
  padding: 0;
}

.standalone .context {
  flex: unset;
}

.standalone .label {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-04);
  font-weight: var(--font-weight-bold);
}

.checkmark {
  margin-inline-start: auto;
}
