.root {
  margin-inline: auto;
  margin-block: 0;
  max-width: rem(580px);
  padding: var(--spacing-40);
}

.root h3 {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-lg);
  margin-block-end: var(--spacing-08);
  text-align: center;
}

.container {
  align-items: flex-start;
  display: flex;
  margin-inline: 0;
  margin-block: rem(20px);
}

.container > div {
  flex: 1;
}

.content {
  padding-block-start: rem(20px);
}

.content p {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-md);
  line-height: 1.5;
}

.deleted {
  color: var(--color-status-highlight);
}

.skipped {
  color: var(--color-status-negative);
}

.warnings {
  margin-block-end: var(--spacing-32);
}

.logs {
  list-style-type: none;
  margin-block: 0;
  padding-inline: 0;
  text-indent: 0;
}

.log {
  border-block-end: 1px solid var(--color-border);
  padding-inline: 0;
  padding-block: var(--spacing-16);
}

.actions {
  text-align: center;
}
