.noEmployees {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-block: var(--spacing-24);
}

.noEmployees p {
  color: var(--color-text-tertiary);
}
