.list {
  display: flex;
  flex-direction: column;
  /* 20px is the margin of the container as defined in the components: https://github.com/peakon/components/blob/master/src/SlideIn/styles.css#L91*/
  margin: var(--spacing-24) calc(20px * -1);
}

.list .item {
  height: 66px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background: var(--color-table-row);
  padding: var(--spacing-16) var(--spacing-24);
  border-block-end: 1px solid var(--color-border);
}

.list .item:last-child {
  border-block-end: none;
}

.item .info {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-inline: var(--spacing-08);
}

.info > p {
  margin: 0;
  margin-block-end: 2px;
}

.segmentPickerButton > div:nth-child(2) {
  width: 420px !important;
  position: absolute !important;
  inset: 0 30px auto auto !important;
  transform: translate(10px, 68px) !important;
}

.segmentPickerButton > div:nth-child(2) > div,
.segmentPickerButton [data-test-id='attribute-row'] {
  width: 400px;
}

.segmentPickerButton [data-test-id='attribute-row'] > div {
  justify-content: space-between;
}

.segmentPickerButton [data-test-id='attribute-row'] > div > div:first-child {
  max-width: 100%;
  flex: 6;
}

.loader {
  display: flex;
  justify-content: center;
  padding-block: var(--spacing-40);
}
