@import '../../../shared/src/components/Navigation/variables.css';
@import '../../../shared/src/styles/hint.css';
@import '../styles/variables.css';
@import 'animate.css/animate.css';

html,
body {
  margin: 0;
}

body {
  /* Avoid iOS automatic font size adjustment
   * https://stackoverflow.com/questions/6210788/how-to-avoid-ios-automatic-font-size-adjustment
   */
  -webkit-text-size-adjust: 100%;
  background-color: var(--color-bg-primary);
}

body.multiNavIsCollapsible .appContent {
  /*
    moves content down to make space for the mobile context switcher
    without breaking scrolling
  */
  margin-block-start: var(--context-switcher-toggler-height);
  padding: 0;
  overflow-x: hidden;
  /* fallback with vh */
  height: calc(
    100vh - var(--nav-top-height) - var(--context-switcher-toggler-height)
  );
  height: calc(
    100dvh - var(--nav-top-height) - var(--context-switcher-toggler-height)
  );
}

body.multiNavIsCollapsible.withRounds .appContent {
  /*
    moves content down to make space for the mobile context switcher
    without breaking scrolling
  */
  margin-block-start: calc(
    var(--context-switcher-toggler-height) + var(--context-switcher-round)
  );
  padding: 0;
  overflow-x: hidden;
  /* fallback with vh */
  height: calc(
    100vh - var(--nav-top-height) - var(--context-switcher-toggler-height) -
      var(--context-switcher-round)
  );
  height: calc(
    100dvh - var(--nav-top-height) - var(--context-switcher-toggler-height) -
      var(--context-switcher-round)
  );
}

@media (max-width: 1199px) {
  body.multiNavIsCollapsible.withProxyView .appContent {
    margin-block-start: calc(
      var(--context-switcher-toggler-height) +
        var(--context-switcher-proxy-view-height) + 4px
    );
    height: calc(
      100% - var(--context-switcher-toggler-height) - var(--spacing-32)
    );
  }

  body.multiNavIsCollapsible.withProxyView.withRounds .appContent {
    margin-block-start: calc(
      var(--context-switcher-toggler-height) + var(--context-switcher-round) +
        var(--context-switcher-proxy-view-height) + 4px
    );
    height: calc(
      100% - var(--context-switcher-toggler-height) -
        var(--context-switcher-round) - var(--spacing-32)
    );
  }
}

body.multiNavIsCollapsible .navigation {
  /*
    removes width of side navigation on mobile
    so content will take up the whole width
  */
  width: initial;
}

.app {
  height: 100vh; /* fallback with vh */
  height: 100dvh;
  overflow: hidden;
}

.static {
  background-color: var(--color-bg-card);
}

.static .app {
  height: auto;
  overflow: inherit;
  position: relative;
}

.signup {
  align-items: stretch;
  display: flex;
  height: 100%;
  justify-content: center;
}

.appContent {
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  outline: none;
}

/* stylelint-disable-next-line selector-max-id */
iframe#ul-frame {
  /* Force the sprig intercept survey to be below the export notification */
  z-index: 800 !important;
}
