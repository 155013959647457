.title {
  display: block;
  font-size: var(--font-size-3xs);
  text-transform: uppercase;
  font-weight: var(--font-weight-bold);
  white-space: nowrap;
}

.total {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
}
