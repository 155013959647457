.button {
  margin-inline-end: 20px;
}

.button > button {
  height: 100%;
}

.what {
  display: block;
  max-width: 260px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.teamSuggestionsUnreadBadge {
  background-color: var(--color-status-highlight);
  color: var(--color-text-inverted);
  padding: calc(var(--spacing-04) / 2) calc(var(--spacing-04) * 1.5);
  border-radius: 100vh;
  margin-inline-start: var(--spacing-08);
}
