.title {
  margin-block-end: 20px;
}

.checkbox {
  margin-block-end: 10px;
}

.checkbox label {
  font-weight: var(--font-weight-bold);
}

.row {
  padding-block-end: 40px;
}

.label {
  display: block;
  font-size: var(--font-size-2xs);
  padding-block-end: 5px;
  font-weight: var(--font-weight-bold);
}

.translations {
  margin-block-start: 15px;
}

.inputUpload {
  width: 420px;
}

@media (--handhelds) {
  .inputUpload {
    width: 100%;
  }
}
