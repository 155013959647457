.root {
  margin-inline: auto;
  margin-block: 40px 0;
  max-width: 325px;
  text-align: center;
  width: 100%;
}

.root.v2 img {
  height: 180px;
  max-width: 288px;
}

.title {
  font-size: var(--font-size-md);
  margin-block: var(--spacing-24) var(--spacing-16);
  font-weight: var(--font-weight-bold);
}

.root.v2 .title {
  font-size: var(--font-size-xl);
  margin-block-end: 8px;
}

.description {
  font-size: var(--font-size-sm);
  margin-inline: 0 0;
  margin-block: 0 24px;
}

.text {
  color: var(--color-text-secondary);
  margin-block-end: var(--spacing-16);
}

.text:last-child {
  margin-block-end: 0;
}
