.dialogWrapper {
  position: fixed;
  z-index: 1000; /* will render underneath toasts */
  inset: var(--spacing-16);
  pointer-events: none; /* prevent backdrop from capturing pointer */
  display: flex;
  /* placement: bottom left */
  align-items: flex-end;
  justify-content: flex-start;
}

.dialog {
  pointer-events: all;
  max-width: 380px;
  width: 100%;
  background-color: var(--color-bg-card);
  border-radius: var(--border-radius-card);
  box-shadow: var(--depth-4-box-shadow);
}
