.container {
  padding: var(--spacing-16);
}

.container :global(.peak-form-field) {
  margin-block-end: var(--spacing-16);
}

.title {
  font-size: var(--font-size-md);
  font-weight: 600;
}

.textArea {
  height: rem(300px);
  resize: vertical;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-block-end: var(--spacing-16);
}

.buttonContainer > button:not(:last-child) {
  margin-inline-end: var(--spacing-08);
}

.container table {
  width: 100%;
  font-size: var(--font-size-xs);
  white-space: nowrap;
  box-shadow: var(--depth-1-box-shadow);
}

.container table th {
  font-weight: normal;
  color: var(--color-text-tertiary);
  text-align: start;
}

.container table th,
.container table td {
  padding: var(--spacing-16);
}

.container table td {
  border-block-start: 1px solid var(--color-border);
}

.container table tbody tr:nth-child(odd) td {
  background-color: var(--color-table-row-alt);
}

.container table th:not(:last-child),
.container table td:not(:last-child) {
  border-inline-end: 1px solid var(--color-border);
}

.container table tfoot tr td {
  background-color: var(--color-table-row);
}

.spinner {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-block: var(--spacing-20);
}
