.root {
  align-items: flex-start;
  display: flex;
}

.label {
  font-size: var(--font-size-md);
  max-width: 148px;
}

.noData .label {
  max-width: 100%;
}

.label strong {
  font-weight: var(--font-weight-bold);
}

.root svg {
  flex: 0 0 24px;
  height: 24px;
  margin-inline: 0 var(--spacing-08);
  margin-block: 0 0;
}

.small .label {
  font-size: var(--font-size-base);
}

.small svg {
  margin-inline: 0 10px;
  margin-block: 2px 0;
}

@media (--handhelds) {
  .label {
    max-width: 100% !important;
  }
}
