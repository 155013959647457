@import '../variables.css';

.root {
  background-color: var(--color-bg-card);
  box-shadow: var(--nav-box-shadow);
  width: 100%;
  height: var(--nav-top-height);
  position: sticky;
  inset-block-start: 0;
  z-index: var(--nav-top-zindex);
}

.topNavigation {
  display: flex;
  justify-content: space-between;
}

.toggler {
  height: var(--nav-top-height);
  width: var(--nav-top-height);
  display: flex;
  justify-content: center;
  align-items: center;
}

.toggler button svg {
  display: block;
}

.tabs {
  display: flex;
  list-style: none;
}

.iconsArea {
  display: flex;
  align-items: center;
  margin-inline-end: var(--spacing-08);
  list-style: none;
  padding: 0;
}

.iconLink {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline: var(--spacing-08);
  width: rem(48px);
  height: rem(48px);
  border-radius: rem(24px);
  cursor: pointer;
}

.iconLink svg {
  color: var(--color-icon-decorative);
}

.iconLink.active {
  background-color: var(--color-controls--selected-bg);
}
