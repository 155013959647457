.root {
  margin-inline: auto;
  border-radius: var(--spacing-08);
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--color-bg-card);
  padding-inline: var(--spacing-08);
  min-width: 830px;
}

.modalRoot {
  inset-block: 40px 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
}

@keyframes modalAnimation {
  from {
    transform: translate(0%, -110%);
    opacity: 0;
  }

  to {
    transform: translate(0%, 0%);
    opacity: 1;
  }
}

.rootGodMode {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  padding-inline: 20px;
  padding-block: 0;
  border-radius: var(--spacing-08);
  animation-name: modalAnimation;
  animation-timing-function: ease-in-out;
  animation-duration: 0.7s;
}

.row {
  display: flex;
  padding-inline: var(--spacing-08);
  padding-block: var(--spacing-04);
  width: fit-content;
  margin-inline: auto;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.27);
}

.title {
  padding-block-start: var(--spacing-16);
}

.subTitle {
  padding: var(--spacing-12);
}

.loading {
  display: flex;
  justify-content: center;
  padding: var(--spacing-32);
}

.rowAlternate {
  background-color: var(--color-border);
}

.scale {
  width: 400px;
  display: flex;
  justify-content: flex-end;
}

.scale label {
  min-height: 40px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.scale div[role='radiogroup'] {
  width: 100%;
}

.featureName {
  width: 400px;
  margin-block: auto;
  font-size: var(--font-size-sm);
  display: flex;
  align-items: center;
}

.featureName svg {
  margin-block-start: var(--spacing-04);
}

.saveButtons {
  flex: 0 0 auto;
  margin-inline-start: auto;
  margin-block: 10px 15px;
}

.saveButtons > button {
  margin-inline-start: var(--spacing-08);
}

.flipsContainer {
  flex: auto;
  min-height: 0;
  overflow: auto;
}

.search {
  width: 300px;
}

.flipsHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-block: var(--spacing-08);
}

.flipsHeader > button {
  margin-inline-end: var(--spacing-24);
}

.flipsRoot {
  height: 100%;
}

.tabsRoot {
  height: calc(100vh - 254px);
  display: flex;
  flex-direction: column;
}

.toggleTranslationKeys {
  padding-block: var(--spacing-16);
}
