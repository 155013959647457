.modalContent {
  padding: 0;
}

.modalActions button {
  margin-inline-start: 8px;
}

.flows {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 64px;
  background: var(--color-bg-navigation);
}

.flow:first-of-type {
  border-inline-end: 1px solid var(--color-border);
}

.flow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% / 2);
}

.flow span {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-md);
}

.flow:not(.active) {
  border-block-end: 2px solid transparent;
  color: var(--color-text-tertiary);
}

.flow.active {
  border-block-end: 2px solid var(--color-status-highlight);
  color: var(--color-status-highlight);
}

.flow.active .order {
  border: 1px solid var(--color-status-highlight);
}

.flow .order {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid var(--color-border);
  border-radius: 100%;
  background: var(--color-bg-card);
  margin-inline-end: 14px;
}

.modalFooter {
  justify-content: space-between;
}

.row {
  margin-block-end: 24px;
}

.row p {
  margin-block-start: 0;
}

.label {
  display: inline-block;
  font-size: var(--font-size-2xs);
  font-weight: var(--font-weight-bold);
  margin-block-end: 8px;
}

.toggleContainer {
  align-items: center;
  height: 32px;
  display: flex;
  width: 100%;
}

.toggleWrapper {
  display: flex;
  justify-content: flex-end;
  flex: 2;
}

.actions {
  margin-inline-end: var(--spacing-16);
}

@media (--handhelds) {
  .toggleContainer {
    height: auto;
    flex-direction: column;
    gap: var(--spacing-16);
    align-items: flex-start;
  }

  .actions {
    display: flex;
    gap: var(--spacing-08);
    flex-direction: row;
  }
}
