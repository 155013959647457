.video {
  width: 100%;
  max-width: 430px;
}

.videoSpinner {
  position: absolute;
  inset-block-start: 30%;
  inset-inline-start: 50%;
  transform: translate(-50%, -50%);
}
