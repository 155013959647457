.description {
  padding-block: var(--spacing-16) var(--spacing-32);
}

.cardInner {
  padding: var(--spacing-24);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--spacing-16);
}

@media screen and (min-width: 768px) {
  .cardInner {
    flex-direction: row;
  }
}

.icon {
  color: var(--color-status-highlight);
  height: 24px;
  width: 24px;
}

.cardTextContainer {
  flex: 1;
}

.pickerContainer {
  flex: 2;
  background-color: var(--color-bg-info);
  border-radius: var(--spacing-08);
}

.cardSeparator {
  padding: var(--spacing-16);
}
