.container {
  display: flex;
  align-items: center;
  min-width: 0;
  flex-wrap: nowrap;
  flex-grow: 0;
  flex-shrink: 1;
  gap: calc(var(--spacing-24) / 2);
}

.container > div {
  flex-shrink: 1;
}

.container .avatar {
  flex-shrink: 0;
}

.content {
  display: flex;
  align-items: center;
  min-width: 0;
}

.content a {
  display: flex;
}

.footer {
  align-items: center;
  display: inline-flex;
}

.size {
  display: none;
  margin-inline: 3px 0;
  margin-block: 4px 0;
}

.name {
  color: var(--color-text);
  font-size: var(--font-size-base);
  margin-inline-end: 10px;
  max-width: 110px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: var(--font-weight-bold);
}

.badge {
  display: flex;
  max-width: 80px;
}

.container.inline .content {
  flex-direction: row;
}

.container.inline .badge {
  margin-inline-start: 5px;
}

.container.simple .content {
  align-items: center;
  flex-direction: column;
  margin-inline-start: 0;
  max-width: 100%;
}

.container.simple.footer .content {
  align-items: flex-start;
}

.container.simple .content > span {
  max-width: 100%;
}

.container.inline .name,
.container.simple .name {
  margin-block-end: 0;
}

.container a .name {
  text-decoration: underline;
}

.container.simple .size {
  display: flex;
  align-items: center;
  margin-inline-start: 0;
}

@media (--tablets-min) {
  .content {
    align-items: flex-start;
    flex-direction: column;
  }

  .badge {
    max-width: 120px;
  }

  .name {
    font-size: var(--font-size-sm);
    margin-inline-end: 0;
  }

  .size {
    display: block;
  }
}

@media (--tablets-min) {
  .name {
    width: 200px;
    max-width: 100%;
  }
}
