.root {
  width: 100%;
}

.improveBanner {
  padding-inline: 10px;
  padding-block: 0;
}

.insights {
  padding-block-end: 80px;
}

.section {
  border-block-end: 1px solid var(--color-border-section);
  margin-block-end: 40px;
  padding-block-end: 40px;
}

.loader {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}
