.table {
  background-color: var(--color-bg-card);
  table-layout: fixed;
  min-width: 100%;
}

.table thead {
  background-color: var(--color-bg-card);
  box-shadow: var(--depth-1-box-shadow);
}

.table thead th {
  text-align: start;
}

.nameCell,
.segmentCell {
  width: 18rem;
  max-width: 18rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table > tbody > tr > td:first-child {
  box-shadow: inset 6px 0 4px -4px rgba(0, 0, 0, 0.08);
}

.table > tbody > tr:nth-child(odd) td {
  background-color: var(--color-table-row-alt);
}

.numericCell {
  text-align: end;
  width: 6rem;
}

.noResults {
  background-color: var(--color-bg-primary);
  box-shadow: none;
  padding: var(--spacing-32);
  text-align: center;
}

/* we need to use ::after here because otherwise
border will flicker when the table header is sticky
https://stackoverflow.com/a/45042852 */
.table tr th::after,
.table tr td::after {
  border-block-end: 1px solid var(--color-border);
  border-inline-end: 1px solid var(--color-border);
  content: '';
  height: 100%;
  inset-block-start: 0;
  inset-inline-start: 0;
  position: absolute;
  width: 100%;
}

.table tr th::after {
  z-index: -1;
}

.table tr:last-child th::after,
.table tr:last-child td::after {
  border-block-end: 0;
}

.table tr,
.table tr th,
.table tr td {
  position: relative;
}

.table tr th,
.table tr td {
  padding: var(--spacing-16);
}

.headingSmall {
  color: var(--color-text-hint);
  font-size: var(--font-size-xs);
  transition: color 0.3s;
}

.headingSmall:hover {
  color: var(--color-text);
}

.withSort {
  cursor: pointer;
}

.table .withSort:not(.sortActive):hover path:first-child {
  fill: var(--color-controls--selected-marker);
}

.sortContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.sortIndicator {
  margin-inline-start: var(--spacing-08);
}

.sortIndicator path {
  fill: var(--color-text-tertiary);
  transition: fill 0.3s;
}

.table th:not(.sortActive):hover .sortIndicator path:first-child,
.sortIndicator.asc path:first-child {
  fill: var(--color-controls--selected-marker);
}

.sortIndicator.desc path:last-child {
  fill: var(--color-controls--selected-marker);
}

.virtuosoScrollContainer {
  height: 100%;
  position: absolute;
  overflow: auto;
  inset: 0;
}
