@import '../../styles/z-index.css';

.root {
  min-height: rem(64px);
  /*
    since app-navigation will fill the entire screen on mobile we need to set that to pointer-events none to allow scrolling
    and enable pointer events on the children only.
  */
  pointer-events: all;
}

@media (max-width: 1199px) {
  .root {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.menu {
  max-height: calc(100vh - 140px);
  min-width: rem(250px);
  width: 100%;
  max-width: rem(500px);
  background-color: var(--color-bg-inverted);
  overflow-y: hidden;
}

.menu > ul {
  padding: 0;
}

.menu.search {
  min-width: rem(360px);
  border-width: 0;
}

.menu div[role='menuitem'],
.menu li {
  margin-inline: 2px;
  margin-block: 0;
  transition: border-color 200ms ease-in-out;
}

.menu div[role='menuitem']:focus,
.menu li:focus {
  box-shadow: var(--focus-border);
  outline: 0;
}

.menu li:focus,
.menu li:hover,
.menu li:active {
  background-color: rgba(255, 255, 255, 0.2);
}

.viewingAsManager {
  background-color: var(--color-bg-info);
  font-size: var(--font-size-3xs);
  padding-inline: var(--spacing-20);
  padding-block: var(--spacing-08);
  border-block-end: 1px solid var(--color-border);
  box-shadow: var(--nav-box-shadow);
  width: 100%;
  z-index: 2;
}

@media (--handhelds) {
  .menu {
    border-radius: 0;
    max-height: 100vh;
    max-width: 100vw;
    width: 100%;
  }
}
