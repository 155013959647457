.root {
  margin-block-start: var(--spacing-16);
}

.cardInner {
  padding: var(--spacing-16);
}

.row {
  align-items: flex-start;
  display: flex;
}

.col {
  flex: 1;
  padding-inline-end: var(--spacing-16);
}

.col:last-child {
  padding-inline-end: 0;
}

.col.small {
  max-width: 140px;
}

.col :global(.peakon-select__control) {
  height: 44px;
}

.actions {
  align-items: center;
  display: flex;
  margin-block-start: rem(20px);
}

.checkbox {
  flex: 2;
  padding-inline-start: 45px;
  transform: translateY(-23px);
}

.checkbox label {
  font-weight: var(--font-weight-bold);
  padding-inline-start: 25px;
}

@media (--handhelds) {
  .row,
  .actions {
    flex-direction: column;
  }

  .col {
    flex: 0 0 100%;
    padding-block-end: var(--spacing-16);
    padding-inline-end: 0;
    width: 100%;
  }

  .col.small {
    max-width: 100%;
  }

  .checkbox {
    padding-inline-start: 0;
    transform: 0;
  }

  .actions > button {
    width: 100%;
  }
}
