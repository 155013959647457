.container {
  padding: 20px;
}

.title {
  color: var(--color-text-tertiary);
  font-size: rem(19px);
  font-weight: var(--font-weight-bold);
}

.emphasis {
  color: var(--color-text);
}

.table {
  margin-inline: 0;
  margin-block: 20px;
  max-width: 660px;
  width: 100%;
}

.heading,
.cell {
  padding: 15px;
  text-align: start;
}

.danger {
  color: var(--color-status-negative);
  font-weight: var(--font-weight-bold);
}

.heading,
.attribute {
  font-weight: var(--font-weight-bold);
}

.row {
  background-color: var(--color-bg-card);
  border: solid 1px var(--color-border);
  border-block-end: 0;
}

.row:last-child {
  border-block-end: solid 1px var(--color-border);
}

.cell {
  border-inline-end: solid 1px var(--color-border);
}

.cell:last-child {
  border-inline-end: 0;
}

.tzgroup {
  color: var(--color-text-tertiary);
  display: inline-block;
  font-size: var(--font-size-2xs);
  margin-inline-start: 5px;
}

.employee {
  align-items: center;
  display: flex;
}

.employeeName {
  display: inline-block;
  margin-inline-start: 5px;
}
