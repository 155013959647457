.container {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
}

.number {
  font-family: var(--font-family-base);
  font-size: var(--font-size-2xs);
  color: var(--color-text-tertiary);
  font-weight: normal;
  line-height: 1.09;
  margin-inline-start: 2px;
  margin-block-start: 1px;
}

.reverse {
  flex-direction: row-reverse;
}

.reverse .number {
  margin-inline: 0 2px;
}

.employeesIcon {
  height: 0.75rem;
  width: 0.75rem;
}
