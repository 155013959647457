.header h3 {
  font-size: var(--font-size-sm);
  font-weight: normal;
  margin-block-start: var(--spacing-04);
}

.content {
  padding: 0;
}

.nodes {
  list-style: none;
  margin: 0;
  padding: 0;
}

.node {
  align-items: center;
  box-shadow: inset 0 -1px 0 var(--color-border-section);
  display: flex;
  height: 72px;
  padding: var(--spacing-24);
  padding-inline-start: rem(52px);
  position: relative;
}

.node::after,
.node::before {
  content: '';
  position: absolute;
}

.node::after {
  border-block-start: 1px solid var(--color-border-section);
  height: 1px;
  inset-inline-start: 30px;
  width: 22px;
}

.node::before {
  border-inline-end: 1px solid var(--color-border-section);
  height: 100%;
  inset-inline-start: 30px;
  width: 1px;
}

.node:first-child::before {
  height: 50%;
  inset-block-start: 50%;
}

.node:last-child::before {
  height: 50%;
  inset-block-end: 50%;
}

.avatar {
  margin-inline-end: rem(12px);
}

.name {
  font-weight: var(--font-weight-bold);
}
