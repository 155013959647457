.button {
  align-items: center;
  display: flex;
  padding: var(--spacing-16);
}

.content {
  font-size: var(--font-size-2xs);
  margin-inline-start: var(--spacing-16);
}

.content strong {
  display: block;
  font-size: var(--font-size-base);
  margin-block-end: var(--spacing-04);
}

@media (--handhelds) {
  .root :global(.peak-radio-group) {
    display: block;
  }
}
