@import '../../variables.css';

.root {
  height: var(--nav-top-height);
  display: flex;
  align-items: center;
}

.root:hover {
  text-decoration: none;
}

.root:focus {
  text-decoration: none;
}

.root svg {
  margin-inline: var(--spacing-16);
}

.logoWorkmark {
  color: var(--color-status-highlight);
  font-size: var(--font-size-lg);
  height: rem(32px);
  line-height: 1.6;
  border-inline-start: 1px solid var(--color-border);
  padding-inline: var(--spacing-16);
}

@media (max-width: 1200px) {
  .logoWorkmark {
    font-size: var(--font-size-sm);
  }
}
