.root {
  margin-inline: auto;
  max-width: 325px;
  text-align: center;
  width: 100%;
}

.image {
  margin-block-end: var(--spacing-20);
}
