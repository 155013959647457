.container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.context {
  display: flex;
  font-size: var(--font-size-2xs);
  justify-content: flex-start;
  margin-block-end: 5px;
}

.translate {
  align-items: center;
  display: inline-flex;
  padding-inline: 20px;
  padding-block: 0;
}

.localeSeparator {
  margin-inline: 3px;
  margin-block: 0;
}

.separator {
  border-block-start: 1px solid var(--color-border-section);
  display: block;
  margin-inline: 0;
  margin-block: 15px 18px;
  width: 100%;
}

.footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
