.syncInfo {
  height: rem(320px);
  max-height: rem(320px);
  overflow: scroll;
}

.syncInfoPre {
  margin: 0;
  font-family: inherit;
}

.logsTableContainer {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.logsTable {
  width: 100%;
  font-size: var(--font-size-xs);
  border-radius: var(--border-radius-lg);
  color: var(--color-text-body);
  background-color: var(--color-table-row);
  border-spacing: 0;
  border-collapse: separate;
  border: 1px solid var(--color-table-border);
  white-space: nowrap;
}

.logsTableHeaderCell,
.logsTableDataCell {
  width: 50%;
}

.logsTableHeaderCell {
  padding: var(--spacing-16);
  font-weight: bold;
  color: var(--color-text-heading);
  text-align: start;
}

.logsTableDataCell {
  height: rem(40px);
  padding-inline: var(--spacing-16);
}

.logsTableRow:nth-child(odd) .logsTableDataCell {
  background-color: var(--color-table-row-alt);
}

.logsTableRow:last-of-type .logsTableDataCell:first-of-type {
  border-bottom-left-radius: var(--border-radius-lg);
}

.logsTableRow:last-of-type .logsTableDataCell:last-of-type {
  border-bottom-right-radius: var(--border-radius-lg);
}

.logsTableHeaderCell:not(:last-child),
.logsTableDataCell:not(:last-child) {
  border-inline-end: 1px solid var(--color-table-border);
}
