.root {
  position: sticky;
  inset-block-start: 0;
}

.header,
.footer {
  padding-inline: 24px;
  padding-block: 21px;
}

.header {
  border-block-end: 1px solid var(--color-border);
}

.headerIcon {
  color: var(--color-icon-decorative);
}

.frequency > div {
  display: flex;
}

.questions,
.duration {
  align-items: center;
  display: flex;
}

.content {
  background-color: var(--color-bg-alert);
  padding: 24px;
  transition: background-color 0.2s ease;
}

.loading .content {
  background-color: var(--color-bg-card);
}

.invalid .content {
  background-color: var(--color-bg-alert);
}

.rangeInfo {
  margin-block-start: var(--spacing-08);
}

.footer {
  border-block-start: 1px solid var(--color-border);
}

.icon {
  margin-block-end: var(--spacing-12);
}

.icon svg {
  display: block;
  margin-inline-start: -3px;
}

.questionLimit {
  background-color: var(--color-bg-alert);
  line-height: 1.5;
  padding-inline: var(--spacing-20);
  padding-block: var(--spacing-16);
}

.remove {
  color: var(--color-status-negative);
}
