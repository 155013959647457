.root {
  color: var(--color-text-primary);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-bold);
}

.root,
.meta,
.branding,
.type {
  align-items: center;
  display: flex;
}

.status {
  color: var(--color-text);
}

.type {
  margin-inline-end: var(--spacing-08);
}

.type .icon {
  height: 20px;
  margin-inline-end: 14px;
  width: 20px;
  color: var(--color-icon-decorative);
}

.type .icon svg {
  display: block;
  width: auto;
  height: 100%;
}

.branding {
  margin-inline-start: 3px;
}

.separator {
  background-color: var(--color-border-section);
  border-radius: 100%;
  height: 2px;
  margin-inline-end: 3px;
  width: 2px;
}
