.field {
  align-items: center;
  display: flex;
}

.field > div {
  margin-inline: var(--spacing-08);
  margin-block: 0;
}

.error {
  color: var(--color-status-negative);
  padding-block-start: var(--spacing-08);
}
