.container.high > div > div {
  background-color: var(--color-viz-positive-fill);
  border: 1px solid var(--color-viz-positive);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.05);
}

.container.medium > div > div {
  background-color: var(--color-viz-warning-fill);
  border: 1px solid var(--color-viz-warning);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.05);
}

.container.low > div > div {
  background-color: var(--color-viz-negative-fill);
  border: 1px solid var(--color-viz-negative);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.05);
}

.container {
  min-width: 100px;
}
