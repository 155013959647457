.root {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  padding-inline: 0;
  padding-block: var(--spacing-64) var(--spacing-40);
  flex-direction: column;
}

.content {
  max-width: 306px;
  text-align: center;
}

.title {
  margin-block-start: var(--spacing-40);
  font-size: var(--font-size-md);
}
