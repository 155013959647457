.container {
  padding-inline: var(--spacing-16);
}

.valueEditTitle {
  padding-inline: var(--spacing-08);
  padding-block: var(--spacing-16);
  margin-inline-start: var(--spacing-08);
  border-block-end: 1px solid var(--color-border);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-base);
  color: var(--color-brand-primary-dark);
}

.valueEditMain {
  padding-block: var(--spacing-16) var(--spacing-32);
  border-block-end: 1px solid var(--color-border);
}

.editValueFooter {
  width: 100%;
  border-block-start: 1px solid var(--color-border);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-inline: var(--spacing-16);
  padding-block: var(--spacing-08);
}

.rootEdit:hover .editValueFooter {
  background-color: var(--color-table-row--hover);
}

.advancedContainer {
  padding-block-end: var(--spacing-16);
}

.advancedToggler {
  display: flex;
}

.ruler {
  flex: 2;
  border: 0;
  border-block-start: 1px solid var(--color-border-section);
  margin-inline: 0;
  margin-block: 15px;
}

.toggleTranslations {
  display: flex;
  flex-direction: row;
}

.toggleTranslations > span {
  margin-block: auto;
}

.toggleTranslationsButton > button {
  color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: var(--font-size-sm);
  font-weight: initial;
  margin-inline-start: var(--spacing-24);
}

.editValueCreateFooter {
  width: 100%;
  background-color: transparent;
  padding-block: var(--spacing-08);
  padding-inline: auto var(--spacing-16);
  border-block-start: 1px solid var(--color-border);
}

.footerCancelCreateButtons {
  width: fit-content;
  margin-inline-start: auto;
}

.footerCancelCreateButtons > button {
  margin-inline-start: var(--spacing-16);
}

@media (--handhelds) {
  .valueEditMain :global(.peak-col-sm-8) {
    margin-block-start: var(--spacing-08);
  }

  .toggleTranslations {
    margin-block-end: var(--spacing-16);
  }
}
