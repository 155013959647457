.questionSetText {
  margin: var(--spacing-08);
  font-weight: var(--font-weight-bold);
  border-block-end: 1px dotted var(--color-text-secondary);
}

.questionSetPopoverTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
}

.popupTitle {
  margin-block-end: var(--spacing-08);
  padding-block-start: var(--spacing-08);
}

.popupSubTitle {
  color: var(--color-text-tertiary);
  font-size: var(--font-size-xs);
  margin-block: var(--spacing-08);
  font-weight: var(--font-weight-bold);
  margin-inline-start: 10px;
}

.popupText {
  margin-inline: 10px;
  margin-block-end: var(--spacing-16);
}

.popupSectionContainer {
  margin: -0.625rem;
}
