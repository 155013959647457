.axis {
  font-weight: var(--font-weight-bold);
  fill: var(--color-text-body);
}

.legend {
  color: var(--color-text-body);
  display: flex;
  justify-content: center;
  gap: var(--spacing-08);
  margin-block-start: var(--spacing-16);
}

.legendItem {
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: var(--spacing-04);
  text-transform: capitalize;
}

.legendItem::before {
  /* Restore list semantics after removing them with `list-style-type: none`  */
  content: '\200B';
}

.legendIcon {
  width: 12px;
  height: 12px;
}

.detractors {
  fill: var(--color-viz-negative-fill);
  stroke: var(--color-viz-negative);
}

.passives {
  fill: var(--color-viz-neutral-fill);
  stroke: var(--color-viz-neutral);
}

.promoters {
  fill: var(--color-viz-positive-fill);
  stroke: var(--color-viz-positive);
}
