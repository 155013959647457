.root {
  border-radius: 0 0 3px 3px;
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.14);
}

.header {
  cursor: pointer;
  padding: var(--spacing-16);
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 !important;
  border: none !important;
}

.title {
  cursor: pointer;
  margin: 0;
  margin-inline: 0 !important;
  padding: var(--spacing-16);
  border: none !important;
  color: var(--color-text-inverted);
  font-size: var(--font-size-2xs);
  text-transform: uppercase;
  font-weight: var(--font-weight-bold);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
}

.indicator {
  color: var(--color-text-inverted);
}

.expanded .indicator {
  transform: scaleY(-1);
}

.items {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
  padding: 0;
}

.expanded .items {
  max-height: rem(240px);
}

.item {
  align-items: center;
  cursor: pointer;
  display: flex;
  line-height: 1;
  padding: var(--spacing-16);
}

.item,
.item label {
  color: var(--color-text-inverted);
  font-weight: var(--font-weight-bold);
}

.item label {
  padding-block: 0;
}

.arrowRight {
  color: var(--color-text-inverted);
}

[dir='rtl'] .arrowRight {
  transform: scaleX(-1);
}

@media (--handhelds) {
  .rounds {
    display: none;
  }
}

.settingsMenu {
  height: auto;
}

.settingsMenu.expanded,
.settingsMenu:hover + .settingsSubmenu {
  background-color: rgba(
    255,
    255,
    255,
    0.2
  ); /* same background color with menu */
}

.settingsSubmenu {
  height: 0;
  border: none !important;
}

.settingsSubmenu.expanded {
  height: auto;
}
