.firstRow,
.secondRow {
  display: flex;
  align-items: center;
  gap: var(--spacing-08);
}

.secondRow {
  /* size of avatar + gap in flex container = 32 */
  padding-inline-start: calc(rem(24px) + var(--spacing-08));
  justify-content: space-between;
}

.icon {
  display: block;
  margin-inline-end: rem(-6px);
}

.segmentLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: var(--spacing-08);
}

.segmentLink:hover {
  text-decoration: none;
}

.segmentLink:hover .segmentName {
  text-decoration: underline;
}

.avatar {
  width: rem(24px);
  height: rem(24px);
}

.attributeBadge {
  font-weight: 100;
}
