.action {
  margin-block-end: 10px;
}

.action:last-child {
  margin-block-end: 0;
}

.action.v2 {
  margin-block-end: 8px;
}
