.header {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-08);
}

.title {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-08);
}

@media (--min-width-sm) {
  .header {
    flex-direction: row;
    justify-content: space-between;
  }

  .title {
    flex-direction: row;
  }
}
