@import '../../../../../../../../styles/variables.css';

.relativeWrapper {
  display: flex;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  min-height: 0;
  padding-inline: 0;
  padding-block: 18px 0;
  position: relative;
  width: var(--attributes-width);
}

.relativeWrapper.forced {
  animation: slidein 0.5s forwards;
  background-color: var(--color-bg-card);
  position: fixed;
  inset-block: var(--navbar-mobile-height) 0;
  transform: translateX(calc(var(--attributes-width) * -1));
  z-index: 402;
}

.container {
  align-items: stretch;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  max-width: 100%;
  overflow-y: hidden;
  min-height: 0;
}

.wrapper {
  overflow: hidden auto;
  position: relative;
}

.searchBox {
  border-block-end: 1px solid var(--color-border-section);
  padding-inline: 20px;
  padding-block: 0 11px;
}

.searchBox :global(.peak-form-field) {
  margin-block: 4px -4px;
}

.header {
  align-items: center;
  display: flex;
  flex-basis: auto;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: space-between;
  padding-inline: 20px;
  padding-block: 10px;
}

.title {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-bold);
}

.section {
  border-block-end: 1px solid var(--color-border-section);
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  padding-inline: var(--spacing-24);
  padding-block: var(--spacing-16);
}

.section:last-child {
  border-block-end: 0;
}

.sectionTitle {
  font-size: var(--font-size-xs);
  padding-block-end: var(--spacing-16);
  font-weight: var(--font-weight-bold);
}

.filter {
  margin-inline: 0;
  margin-block: 5px;
}

.options > div[role='button'] {
  margin-inline-start: 0;
}

@keyframes slidein {
  100% {
    transform: translateX(0);
  }
}
