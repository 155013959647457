.root.other .header {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
}

.link {
  display: none;
  font-size: var(--font-size-2xs);
  text-decoration: underline;
  margin-block-start: 15px;
}

.header,
.sharedHeader {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
  color: var(--color-text-primary);
  white-space: pre-wrap;
}

.sharedSection {
  padding-block-end: 24px;
}

.sharedHeader {
  display: flex;
  justify-content: flex-start;
  margin-block-end: 12px;
  width: 100%;
}

.sort {
  margin-inline-start: auto;
}

.title {
  flex: auto;
  color: var(--color-text-primary);
  font-size: rem(13px);
  flex-grow: 1;
}

.filterContainer {
  display: flex;
  flex-grow: 0;
  width: 33%;
  align-items: flex-end;
}

.dashboardCard {
  margin-block-start: -1px;
}

.subheader {
  margin-block-end: 16px;
}

.suggestedPriorityCounter {
  background-color: var(--color-status-highlight);
  color: var(--color-text-inverted);
  border: 1px solid var(--color-status-highlight);
  border-radius: 50%;
  width: 17px;
  height: 17px;
  font-size: var(--font-size-2xs);
  display: inline-block;
  margin-block-end: 12px;
  margin-inline-end: 8px;
  text-align: center;
}

.loadingHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-block-end: 12px;
  align-items: center;
}

.suggestedHeader {
  color: var(--color-text-primary);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-bold);
}

.employee {
  color: var(--color-text-secondary);
  font-weight: var(--font-weight-bold);
}

.acceptedPrioritiesContainer,
.suggestedPrioritiesContainer,
.strengthsContainer,
.otherContainer {
  padding-inline: 0;
  padding-block: 0 12px;
  width: 100%;
}

.suggestedPrioritiesContainer {
  box-shadow: var(--depth--1-box-shadow);
  padding: 12px;
  margin-block-end: 24px;
}

.listContainer {
  margin-block-end: 12px;
}

.strengthsContainer,
.otherContainer {
  margin-block-start: 12px;
}

.prioritiesHeader {
  padding-inline: 12px 12px;
  padding-block: 0 0;
  margin-block-end: 16px;
  color: var(--color-text-secondary);
}

.emptyPrioritiesContainer {
  display: flex;
  flex-direction: column;
  place-items: center center;
  text-align: center;
  margin-block-end: 24px;
  padding: 24px;
}

.emptyHeader {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
}

.emptyContent {
  margin-block-end: 10px;
  color: var(--color-text-secondary);
}

.suggestedPrioritiesContainer,
.emptyPrioritiesContainer {
  background-color: transparent;
  border: 1px solid var(--color-border-section);
  border-radius: var(--border-radius-card);
  padding: 12px;
  width: 100%;
}

.driversIcon {
  margin-inline: 2px 8px;
  margin-block: 0 0;
}

.seeAllButton {
  align-items: center;
  background-color: transparent;
  border: 0;
  color: var(--color-text-primary);
  cursor: pointer;
  display: inline-flex;
  font-size: var(--font-size-md);
  height: 24px;
  justify-content: center;
  margin-inline: 0 0;
  margin-block: 12px 24px;
  padding: 0;
  font-weight: var(--font-weight-bold);
}

.seeAllButton span {
  text-decoration: underline;
}

.seeAllButton:hover,
.seeAllButton.focused {
  opacity: 0.8;
}

.seeAllButton:not(.focused):focus {
  outline: 0;
}

.seeAllButton.expanded .arrow {
  transform: rotate(180deg);
}

.seeAllButton .menuIcon {
  margin-inline-end: 12px;
}

.otherContainer .header {
  margin-block-end: 16px;
}

@media (--tablets-min) {
  .link {
    display: inline;
  }
}

@media (--handhelds) {
  .seeAllButton {
    margin: 0;
  }
}
