.root,
.badges {
  align-items: center;
  display: flex;
}

.label {
  color: var(--color-text-tertiary);
  margin-inline-end: 10px;
}

.badges {
  flex: 3;
  flex-wrap: wrap;
  margin: -5px;
}

.badge {
  margin: 5px;
}

@media (--handhelds) {
  .root {
    display: block;
  }

  .label {
    margin-block-start: var(--spacing-04);
    margin-inline-end: 0;
  }

  .badges {
    margin-block-start: var(--spacing-04);
  }
}
