.root {
  width: 100%;
  border: 0;
  margin: 0;
  padding: 0;
}

.content {
  width: 100%;
  max-width: rem(600px);
  margin-inline: auto;
}

.reason {
  text-align: start;
}
