.emptyState {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-inline: auto;
  margin-block: 40px 0;
  max-width: 325px;
  width: 100%;
  text-align: center;
}

.emptyStateLink {
  text-decoration: underline;
}
