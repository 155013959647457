.content {
  background-color: var(--color-bg-primary);
  flex-grow: 1;
}

.columnHeader::-webkit-scrollbar,
.rowHeader::-webkit-scrollbar {
  display: none;
}

.columnHeader,
.rowHeader {
  scrollbar-width: none;
}

.cornerHeader {
  background-color: var(--color-bg-primary);
  border-inline-start: 1px solid var(--color-border);
  border-top-left-radius: 8px;
  box-shadow: 2px 1px 3px 2px rgba(0, 0, 0, 0.1);
  z-index: 3;
}

[dir='rtl'] .cornerHeader {
  border-top-left-radius: 0;
  border-top-right-radius: 8px;
  box-shadow: -2px 1px 3px 2px rgba(0, 0, 0, 0.1);
}

.rowHeader {
  box-shadow: 4px 0 5px -1px rgba(0, 0, 0, 0.1);
  border-inline-start: 1px solid var(--color-border);
  z-index: 2;
}

[dir='rtl'] .rowHeader {
  box-shadow: -4px 0 5px -1px rgba(0, 0, 0, 0.1);
}

.columnHeader {
  border-block-end: 1px solid var(--color-border);
  border-block-start: 1px solid var(--color-border);
  box-shadow: 5px 0 3px 3px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

.scores {
  z-index: 1;
}

.columnPlaceholder {
  display: flex;
  flex-direction: row;
}

.scores:focus {
  outline: 0;
}

.wrapper {
  height: 100%;
  inset-inline: 273px 0;
  position: absolute;
  z-index: 1;
}

.row.empty {
  background-image: none !important;
  box-shadow: none !important;
  background-color: var(--color-bg-primary) !important;
}

.row.expanded {
  box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.1);
}

.row.inner {
  box-shadow: 0 -3px 2px 0 rgba(0, 0, 0, 0.1);
}

.row.expanded.inner {
  box-shadow:
    1px 2px 5px 0 rgba(0, 0, 0, 0.1),
    0 -3px 2px 0 rgba(0, 0, 0, 0.1);
}

/* stylelint-disable-next-line selector-class-pattern -- to be consistent with react-virtualized */
:global(.Grid__row) {
  background-image: linear-gradient(
    90deg,
    var(--color-border) 1px,
    transparent 1px
  );
  background-size: 120px 100%;
  box-shadow:
    inset 1px 1px 0 0 rgba(255, 255, 255, 0.1),
    inset -1px -1px 0 0 rgba(0, 0, 0, 0.1);
  background-position: -1px;
}

/* stylelint-disable-next-line selector-class-pattern -- to be consistent with react-virtualized */
[dir='rtl'] :global(.Grid__row) {
  box-shadow:
    inset -1px 1px 0 0 rgba(255, 255, 255, 0.1),
    inset 1px -1px 0 0 rgba(0, 0, 0, 0.1);
}

/* stylelint-disable-next-line selector-class-pattern -- to be consistent with react-virtualized */
:global(.Grid__row).attrition {
  background-image: linear-gradient(
      90deg,
      var(--color-bg-primary) 1px,
      transparent 0
    ),
    linear-gradient(90deg, var(--color-border) 1px, transparent 0);
  background-size:
    100% 1px,
    120px 56px;
  background-position:
    59px 0,
    59px 0,
    0 0;
}

/* stylelint-disable-next-line selector-class-pattern -- to be consistent with react-virtualized */
[dir='rtl'] :global(.Grid__row).attrition {
  background-position:
    -61px 0,
    -1px 0,
    0 0;
}
