.root {
  display: grid;
  gap: var(--spacing-32);
}

.container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: var(--spacing-64);
}

@media (--handhelds-tablets) {
  .container {
    justify-content: space-around;
    grid-template-columns: 1fr;
    padding-block: var(--spacing-32);
    padding-inline: var(--spacing-16);
  }
}
