.title {
  align-items: center;
  display: flex;
}

.title > svg {
  margin-inline-end: var(--spacing-08);
}

.actions {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04);
  padding-block-start: var(--spacing-16);
}

.loader {
  display: flex;
  justify-content: center;
  color: var(--color-status-highlight);
  padding-block: var(--spacing-24);
}

.hasError input[class='duet-date__input'] {
  border: 1px solid var(--color-controls-error-border);
}

.errorMessage {
  color: var(--color-controls-error-border);
  font-weight: var(--font-weight-bold);
  padding-block-start: var(--spacing-04);
}
