:root {
  --tab-list-height: 3rem;
  --collapsed-tab-list-height: 3rem;
}

.panelSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-block-end: 1px solid var(--color-border);
  padding: var(--spacing-24);
  gap: var(--spacing-16);
}

.content {
  padding: 0;
  height: 100%;
}

.contentContainer {
  height: 100%;
}

.tabListContainer {
  /* We need a fixed height for the virtuosolist */
  /* Used in .surveyedEmployeesTab */
  height: var(--tab-list-height);
  display: flex;
  justify-content: center;
  border-block-end: 1px solid var(--color-border);
  min-width: max-content;
  padding-inline-start: var(--spacing-24);
}

.tabListContainer.isCollapsed {
  height: var(--collapsed-tab-list-height);
  padding-inline: 0;
  border-block-end: none;
}

.collapsedTabSelectorContainer {
  padding: var(--spacing-08);
  width: 100%;
}

.virtousoCustomScrollParent {
  position: absolute;
  inset: 0;
  overflow: auto;
}

.surveyedEmployeesTab {
  position: absolute;
  inset: 0;
  /* The height from the .tabListConainer */
  inset-block-start: var(--tab-list-height);
  display: flex;
  flex-direction: column;
}

.surveyedEmployeesTab.isTabListCollapsed {
  inset-block-start: var(--collapsed-tab-list-height);
}

.scoreAccuracy {
  display: block;
  padding-block-start: var(--spacing-16);
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
}

.scoreAccuracyExplanation > p {
  margin-block-end: var(--spacing-08);
}

.header {
  margin: var(--spacing-08) 12px;
}

.header > *:not(:last-child) {
  margin-block-end: var(--spacing-04) !important;
}

.surveyedEmployeeList {
  height: 100%;
  position: relative;
}
