:root {
  --page-search-default-width: 190px;
  --page-search-input-height: 60px;
}

.section {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  overflow: hidden;
  padding-inline: 15px 5px;
  padding-block: 0 0;
  width: calc(var(--page-search-default-width) + 15px + 16px + 10px);
  transition: background-color 300ms ease-in-out;
  background-color: transparent;
  position: relative;
}

.field {
  align-items: center;
  display: inline-flex;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 0;
}

.searchIcon {
  flex-shrink: 0;
  margin-inline-end: 10px;
  color: var(--color-text-secondary);
}

.crossIcon {
  position: absolute;
  height: 24px;
  width: 24px;
  inset-inline-end: 8px;
}

.searchInput {
  background-color: transparent;
  background-image: none;
  border: none;
  color: var(--color-text);
  display: block;
  font-family: var(--font-family-base);
  font-size: var(--font-size-base);
  line-height: 1;
  margin: 0;
  min-height: var(--page-search-input-height);
  min-width: var(--page-search-default-width);
  padding-inline-end: 10px;
  width: 100%;
  -webkit-appearance: none; /* removes macOS Safari default styling */
  appearance: none;
}

/* clears the ‘X’ from Chrome */
/* stylelint-disable-next-line */
.searchInput::-webkit-search-decoration,
.searchInput::-webkit-search-cancel-button,
.searchInput::-webkit-search-results-button,
.searchInput::-webkit-search-results-decoration {
  display: none;
}

.searchInput::placeholder {
  color: var(--color-text-primary);
}

@media (--handhelds) {
  .field {
    border: 1px solid var(--color-controls-border);
    border-radius: 4px;
    display: flex;
    padding: 15px;
    width: 100%;
  }

  .searchInput {
    min-height: 0;
  }
}
