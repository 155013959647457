:root {
  --color-graph-trend: var(--color-viz-04);
  --color-graph-point: var(--color-viz-04);
}

.root {
  margin-inline: var(--spacing-16);
  font-size: var(--font-size-sm);
}

.container {
  padding: var(--spacing-48);
}

.stories {
  display: flex;
  gap: var(--spacing-32);
  min-height: 150px;
}

.separator {
  margin-block: var(--spacing-48) var(--spacing-16);
}

.graph {
  height: 500px;
}

.graph .loader,
.graph .empty {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.graph .empty svg {
  margin-inline-end: var(--spacing-16);
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actionSeparator {
  margin-block: var(--spacing-16) var(--spacing-32);
}

.legend {
  display: flex;
  gap: var(--spacing-24);
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
}

.trend,
.point {
  display: flex;
  align-items: center;
}

.trend .fill {
  width: 16px;
  height: 4px;
  background: var(--color-graph-trend);
  border-radius: 4px;
}

.point .fill {
  width: 8px;
  height: 8px;
  background: var(--color-graph-point);
  border-radius: 50%;
}

.fill {
  display: inline-flex;
  margin-inline: var(--spacing-08);
  margin-block: 0;
}

.explanation {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-block-start: var(--spacing-16);
}

.explanation p {
  max-width: 476px;
}

@media (--handhelds) {
  .root {
    margin-inline: var(--spacing-08);
  }

  .container {
    padding: var(--spacing-16);
  }

  .stories {
    flex-direction: column;
    gap: var(--spacing-24);
  }

  .separator {
    display: none;
  }

  .legend {
    gap: var(--spacing-08);
  }

  .actions {
    margin-block: var(--spacing-12);
  }
}
