.formContainer {
  padding: 20px;
}

.row {
  margin-block-end: 15px;
}

.label {
  align-items: center;
  display: flex;
  font-size: var(--font-size-xs);
  margin-block-end: 10px;
  font-weight: var(--font-weight-bold);
}

.row.main {
  margin-block-end: 30px;
}

.title {
  font-size: var(--font-size-md);
  margin-block-end: 5px;
  font-weight: var(--font-weight-bold);
}

.description {
  display: flex;
  font-size: var(--font-size-xs);
  margin-block-end: 10px;
}
