:root {
  --course-header-image-container-width: 164px;
  --course-text-color: var(--color-text-primary);
}

.header {
  align-items: flex-start;
  color: var(--course-text-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-inline: 15px;
  padding-block: 18px;
  position: relative;
  width: 100%;
}

.contentWrapper {
  display: flex;
  flex-direction: row;
}

.contentHeading {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-inline-start: 10px;
}

.mediaWrapper {
  height: 90px;
  width: 90px;
  position: relative;
}

.imageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 90px;
  width: 90px;
  transition:
    height 300ms ease-in-out,
    width 300ms ease-in-out;
  border: 3px solid white;
  border-radius: 3px;
}

.imageWrapper img {
  max-width: 82px;
}

.completedWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: var(--color-text-inverted);
  position: absolute;
  inset-block: 0;
  inset-inline: 0;
  height: inherit;
  width: inherit;
  background: rgba(41, 67, 75, 0.5);
  border: 3px solid white;
  font-weight: var(--font-weight-bold);
}

.content {
  align-items: flex-start;
  display: flex;
  gap: var(--spacing-12);
  flex-direction: column;
  flex-grow: 2.5;
}

.driver {
  display: flex;
  align-items: center;
  margin-block-end: 5px;
}

.driver span[data-test-id='category-label'] {
  text-transform: uppercase;
}

.driverText {
  font-size: var(--font-size-2xs);
  font-weight: var(--font-weight-bold);
  color: var(--color-text-primary);
  margin-inline-start: 10px;
  text-transform: uppercase;
}

.description {
  font-size: var(--font-size-base);
  line-height: 1.77;
  color: var(--color-text-body);
  max-width: 700px;
}

.whatYouWillLearnHeader {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.whatYouWillLearnHeader svg {
  width: 20px;
  height: 20px;
}

.whatYouWillLearnHeader span {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  color: var(--color-text-body);
  margin-inline-start: 10px;
}

.metaContainer {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.lessonsMeta,
.durationMeta,
.resourcesMeta {
  display: inline-flex;
  font-weight: 600;
  position: relative;
  margin-inline-end: 7px;
}

.resourcesMeta {
  margin-inline-start: 25px;
}

.numberOfLessons,
.numberOfResources {
  align-items: center;
  display: inline-flex;
  font-size: var(--font-size-base);
  color: var(--color-text-tertiary);
  margin-inline-end: 5px;
}

.numberOfLessons span {
  margin-inline-start: 5px;
}

.numberOfResources span {
  margin-inline-start: 5px;
}

.resourceHeader {
  margin-inline-start: 10px;
}

.lessons {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-inline: 10px;
  padding-block: 20px;
  width: 100%;
}

.lessonLink {
  margin-block-end: 30px;
}

.lessonLink,
.lessonLink:hover,
.lessonLink:focus,
.lessonLink:active {
  color: inherit;
  max-width: 577px;
  text-decoration: none;
  width: 100%;
}

.progressLine {
  position: absolute;
  inset-inline-start: -17px;
  width: 1px;
  height: 20px;
  background-color: var(--color-viz-neutral);
}

@media (--desktop) {
  .header {
    padding-inline: 10px;
    padding-block: 35px;
  }
}

@media (--handhelds-tablets) {
  .image {
    background-size: initial;
    object-fit: cover;
  }

  .metaContainer {
    margin-block-start: 10px;
  }
}

@media (--handhelds) {
  .imageWrapper img {
    max-width: 52px;
  }
}

@media (max-width: 400px) {
  .metaContainer {
    display: flex;
    flex-wrap: wrap;
  }

  .progressLine {
    display: none;
  }

  .resourcesMeta {
    margin-inline-start: 2px;
    margin-block-start: 2px;
    width: 100%;
  }
}

@media (--tablets-min) {
  .content {
    margin-inline-start: 34px;
  }

  .header {
    flex-direction: row;
  }

  .content .heading {
    max-width: 720px;
  }

  .contentHeading {
    margin-inline-start: 0;
  }

  .contentWrapper {
    flex-direction: row;
  }

  .mediaWrapper,
  .imageWrapper {
    height: 164px;
    width: 164px;
  }
}

@media (--tablets) {
  .header {
    padding-inline: 10px;
    padding-block: 15px;
  }
}
