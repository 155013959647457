.root h3 {
  font-weight: normal;
  font-size: var(--font-size-md);
}

.main {
  align-items: flex-start;
  display: flex;
}

.download {
  padding: var(--spacing-32) 0;
}

.description,
.kioskDescription {
  padding-block: var(--spacing-16);
}
