.categoryWrapper {
  display: flex;
  align-items: center;
}

.categoryWrapper > svg {
  margin-inline-end: var(--spacing-04);
}

.categoryWrapper p {
  margin: 0;
}
