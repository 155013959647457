:root {
  --card-height: 106px;
  --image-size: var(--card-height);
}

.card {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  min-height: var(--card-height);
}

.card:hover,
.card:focus,
.card:active {
  text-decoration: none;
}

.card:hover .title {
  text-decoration: underline;
}

.content {
  width: calc(100% - var(--image-size) - 30px);
  padding-inline-end: 17px;
}

.imageContainer {
  width: var(--image-size);
  height: var(--image-size);
  min-width: var(--image-size);
  min-height: var(--image-size);
  border-radius: var(--border-radius-card);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.image {
  height: 100%;
  min-height: 100%;
  border-radius: inherit;
}

.imageCourse {
  width: 70%;
  margin-inline: 7px;
  margin-block: 0;
  height: auto;
  min-height: auto;
}

.title {
  margin-inline: 0;
  margin-block: 4px;
  color: var(--color-text-primary);
}

.description {
  font-size: var(--font-size-sm);
  line-height: 1.4;
  margin-inline: 0;
  margin-block: 4px;
  color: var(--color-text-secondary);
}

.meta {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-xs);
  line-height: 1.2;
  color: var(--color-text-tertiary);
  margin-inline: 0;
  margin-block: 4px;
}

.playIcon {
  position: absolute;
  inset-block-start: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.playIcon path:first-of-type {
  opacity: 0.7;
}
