.formContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.info {
  margin-inline: 0;
  margin-block: 30px;
  text-align: center;
}

.row {
  margin-block-end: 15px;
}

.fileName {
  margin-block-end: 15px;
  font-weight: var(--font-weight-bold);
}

.template {
  border-block-start: 1px solid var(--color-border);
  margin-block-start: 40px;
  padding-block-start: 40px;
  text-align: center;
}

.template .title {
  font-size: var(--font-size-base);
  margin-block-end: 10px;
  font-weight: var(--font-weight-bold);
}

.download {
  margin-block-start: 10px;
}
