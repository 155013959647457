.row {
  margin-block-end: 20px;
}

.row:last-child {
  margin-block-end: 0;
}

.title {
  font-size: var(--font-size-2xs);
  margin-block-end: 10px;
  font-weight: var(--font-weight-bold);
}
