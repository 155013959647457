.link {
  align-items: center;
  cursor: pointer;
  display: flex;
  line-height: 1;
  margin-inline-end: -7px;
  color: var(--color-text-primary);
}

.linkArrow {
  color: var(--color-text-primary);
  height: 20px;
  padding-block-start: 2px;
  transition: transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  width: 20px;
}

[dir='rtl'] .linkArrow {
  transform: scaleX(-1);
}

.gray .linkArrow path {
  fill: var(--color-text-tertiary);
}

.link.active .linkArrow,
.link:hover .linkArrow {
  transform: translate3d(3px, 0, 0);
}

[dir='rtl'] .link.active .linkArrow,
[dir='rtl'] .link:hover .linkArrow {
  transform: translate3d(-3px, 0, 0) scaleX(-1);
}

@media (--intermediate) {
  .label {
    display: none;
  }
}
