.label {
  align-items: center;
  display: flex;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-bold);
  color: var(--color-text-secondary);
  margin-block-end: 10px;
}

.label small {
  color: var(--color-text-tertiary);
  line-height: 1;
  margin-inline-start: 5px;
}

.helpBadge {
  margin-inline-start: 5px;
}

.tooltip {
  text-align: start;
  width: 260px;
}

.warning {
  padding-inline: 20px;
  padding-block: 20px 0;
}

.growTooltip p {
  margin-inline: 0;
  margin-block: 0 var(--spacing-16);
}

.growTooltip p:last-child {
  margin: 0;
}
