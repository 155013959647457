.root {
  position: relative;
  display: flex;
  align-items: center;
}

.infoIcon {
  display: block;
  color: var(--color-icon-decorative);
}

.tooltip {
  font-size: var(--font-size-xs);
  text-align: start;
  padding-block: var(--spacing-04);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
}

.heading {
  font-weight: var(--font-weight-bold);
}

.reasons {
  margin: 0;
  padding-inline-start: 15px;
}
