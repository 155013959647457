.root {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card {
  border: 0;
  border-inline-start: solid 1px var(--color-border);
  border-radius: 0 var(--border-radius-card) var(--border-radius-card) 0;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

[dir='rtl'] .card {
  border-inline-start: 0;
  border-inline-end: solid 1px var(--color-border);
  border-radius: var(--border-radius-card) 0 0 var(--border-radius-card);
}

.card.disabled {
  pointer-events: none;
}

.card:hover {
  box-shadow:
    inset 0 0 0 0 rgba(0, 0, 0, 0),
    0 2px 25px 0 rgba(44, 47, 55, 0.13);
}

.card.muted {
  border-inline-start-color: transparent;
  box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.02);
}

.graph {
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: flex-end;
  margin-inline: -20px 0;
  margin-block: var(--spacing-32) 0;
}

[dir='rtl'] .graph {
  margin-inline: 0 -20px;
}

.hidden {
  animation: fadeOut 0.3s forwards ease-in;
}

.fadeIn {
  animation: fadeIn 0.3s forwards linear;
  opacity: 0;
}

.title {
  align-items: center;
  display: flex;
  line-height: 1;
  height: 24px;
}

.scoreChangeTitle {
  max-width: 180px;
}

.details {
  align-items: center;
  display: flex;
  flex: 1;
  height: 16px;
  justify-content: flex-end;
  line-height: 1;
  text-transform: none;
}

.content {
  display: flex;
  flex: 2;
  flex-direction: column;
}

.expand {
  align-items: center;
  display: flex;
  height: 16px;
  justify-content: flex-end;
  line-height: 1;
  margin-inline-start: 5px;
  text-transform: none;
  position: relative;
  color: var(--color-text-primary);
}

@keyframes fadeIn {
  from {
    opacity: 0.1;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
