.root {
  margin-block-start: var(--spacing-32);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-08);
}

.content {
  display: grid;
  gap: var(--spacing-32);
  grid-template-columns: 1fr 1fr;
}

.subTitle {
  color: var(--color-text-secondary);
  font-weight: var(--font-weight-bold);
}

.subTitle,
.info {
  margin: 0;
}

.valueWrapper {
  align-items: stretch;
  display: flex;
}

.valueWrapper p {
  margin: 0;
}

.graph {
  margin-inline-end: var(--spacing-08);
  width: 25px;
}

.value {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  line-height: 1;
  margin-block: var(--spacing-16);
}

.extraInfo,
.info {
  color: var(--color-text-secondary);
  font-size: var(--font-size-2xs);
  font-weight: var(--font-weight-bold);
}

.extraInfo {
  align-items: center;
  display: flex;
  line-height: 1;
  margin-block-end: var(--spacing-04);
}

:not(.extraInfo) + .extraInfo {
  margin-block-start: var(--spacing-08);
}

.icon {
  display: block;
  margin-inline-end: var(--spacing-08);
}

.dateOverride {
  max-width: 230px;
}
