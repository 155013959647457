.virtousoCustomScrollParent {
  position: absolute;
  inset: 0;
  overflow: auto;
}

.header {
  padding-block: 10px 5px;
  width: 100%;
}

.total,
.description {
  color: var(--color-text-tertiary);
}

.total {
  margin-block-start: 1px;
}

.description {
  border-block-start: 1px solid var(--color-border);
  margin-block-start: 14px;
  padding-block-start: 16px;
}

.loading {
  padding-block-start: 50%;
  transform: translateY(-50%);
}

.listLoader {
  padding-block: var(--spacing-16);
  display: flex;
  justify-content: center;
}

.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-block-start: 20%;
}

.empty span {
  font-size: var(--font-size-2xs);
  font-weight: var(--font-weight-bold);
  color: var(--color-text-secondary);
  text-transform: uppercase;
  text-align: center;
}

.row {
  align-items: center;
  background: var(--color-table-row);
  box-shadow: inset 0 1px 0 0 var(--color-table-border);
  display: flex;
  padding-inline: 20px;
  padding-block: 18px;
  transition: ease background 0.15s;
}

a.row {
  /* stylelint-disable-next-line */
  text-decoration: none !important; /* this gets overwritten in prod bundle */
}

.row:hover {
  background: var(--color-table-row-alt);
}

.row:focus {
  /* stylelint-disable-next-line */
  box-shadow: var(--focus-border) !important;
}

.error {
  margin: var(--spacing-16) var(--spacing-48);
  text-align: center;
}

.accessDeniedIcon {
  display: flex;
  justify-content: center;
  margin: var(--spacing-16);
}

.loader {
  display: flex;
  justify-content: center;
  padding-block: var(--spacing-08);
}

.slideInContent {
  height: 100%;
  position: relative;
  inset: 0;
  display: flex;
  flex-direction: column;
}

.stickyHeaderContent {
  background-color: var(--color-bg-card);
  border-block-end: 1px solid var(--color-border);
}

.listContainer {
  height: 100%;
  position: relative;
}
