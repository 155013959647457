.root {
  font-size: var(--font-size-xs);
}

.root strong {
  font-weight: var(--font-weight-bold);
}

.root.large {
  font-size: var(--font-size-md);
}
