@import '../../../../../../../../styles/variables.css';

.container {
  display: flex;
  flex: 1 1 auto;
}

.scroller {
  max-width: var(--app-max-width);
}

.sections {
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  padding-inline: 20px;
  padding-block: 10px;
  position: relative;
}

.sectionRow {
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}

.timestamps {
  align-items: center;
  display: flex;
  height: 20px;
  position: absolute;
  inset-inline-end: 20px;
  inset-block-start: 20px;
}

.timestamps > span {
  margin-inline-end: 10px;
}

.effectiveValue {
  padding-inline: 0;
  padding-block: 8px;
}

@media (--tablets-min) {
  .sectionRow {
    flex-direction: row;
  }
}

.field {
  display: flex;
  flex-basis: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  max-width: 100%;
  padding-inline: 0;
  padding-block: 10px;
}

.field.disabled {
  opacity: 0.5;
}

.field.label {
  flex-direction: column;
}

.field:first-child {
  padding-inline-start: 0;
}

@media (--tablets-min) {
  .field {
    flex-basis: 50%;
    max-width: 50%;
    padding-inline: 0 20px;
    padding-block: 10px 10px;
  }
}

@media (--desktop) {
  .field {
    flex-basis: 20%;
    max-width: 20%;
  }
}
