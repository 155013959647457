.root {
  display: grid;
  min-height: 100vh;
  place-items: center;
}

.spinner {
  color: var(--color-status-highlight);
}

.logo {
  width: 120px;
}
