.target {
  align-items: center;
  display: flex;
}

.target:hover {
  text-decoration: none;
}

.root {
  line-height: 1;
}

.label {
  flex: 1;
  padding-inline-end: var(--spacing-16);
}

.icon {
  margin-inline-end: var(--spacing-04);
}

.separator {
  margin-inline: var(--spacing-04);
  margin-block: 0;
}

.meta {
  flex: 1;
  padding-inline-start: var(--spacing-16);
}

.time {
  display: inline-block;
}

.contextName {
  margin-inline-start: var(--spacing-04);
}

.round {
  align-items: stretch;
  display: flex;
  justify-content: space-between;
}

.details {
  align-items: center;
  display: flex;
}

.tableRowBtn {
  padding-inline-start: var(--spacing-08);
}
