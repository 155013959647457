.content {
  word-break: break-word;
}

.footer button:last-child {
  margin-inline-start: rem(10px);
}

.optOut {
  flex: 2;
}

.optOut label {
  line-height: 1;
}

@media (--handhelds) {
  .container {
    width: 100%;
  }

  .content {
    flex: 2;
  }

  .footer {
    flex-direction: column;
  }

  .optOut {
    margin-block-end: rem(24px);
  }
}
