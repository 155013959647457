.teams {
  animation-duration: 0.3s;
  padding-inline: 20px;
  padding-block: 0 20px;
}

.team {
  margin-block-end: 15px;
}

.team:last-child {
  margin-block-end: 0;
}

.divider {
  margin-block: var(--spacing-32);
}
