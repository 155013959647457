.contentContainer {
  padding-inline: var(--spacing-12);
  padding-block: var(--spacing-12);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-16);
  max-width: 320px;
}

.contentContainer:nth-of-type(odd) {
  background-color: var(--color-table-row-alt);
  border-block-end: 1px solid var(--color-border);
}

.contentContainer:hover {
  background-color: var(--color-table-row-alt);
  border-block-end: 1px solid var(--color-border);
}

.companyIcon {
  display: block;
  background-position: center;
  background-size: cover;
  border-radius: var(--border-radius-md);
  height: 100%;
}

.arrow {
  margin-inline-start: auto;
}

[dir='rtl'] .arrow {
  transform: rotate(180deg);
}
