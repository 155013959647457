.full {
  max-width: 100%;
}

.group {
  color: var(--color-text-hint);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-xs);
  margin-block-end: var(--spacing-04);
}

.toggler {
  font-weight: var(--font-weight-bold);
  color: var(--color-text-primary);
  border-block-end: 1px dotted var(--color-text-tertiary);
}

.title {
  color: var(--color-text-primary);
}

.title a {
  color: inherit;
}

.info {
  color: var(--color-text-tertiary);
}

.readMoreLink {
  display: block;
  margin-block-start: var(--spacing-08);
}

/* fix for <UnstyledButton> which used to have position: relative; */
.relative {
  position: relative;
}
