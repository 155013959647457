.main {
  padding-block-start: var(--spacing-32);
}

.avatarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-block-end: var(--spacing-64);
}

.formInput {
  margin-block-end: var(--spacing-16);
}

.formGroup {
  margin-block-end: var(--spacing-16);
}

.heading {
  margin-block-end: var(--spacing-08);
}

.formSelect {
  display: flex;
}
