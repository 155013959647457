.placeholder {
  color: var(--color-text-tertiary);
}

.group {
  color: var(--color-text-tertiary);
  font-size: rem(11px);
}

.fixed {
  position: absolute;
  width: 210px;
}
