.root {
  align-items: center;
  background-color: var(--color-bg-card);
  border: 1px solid var(--color-border);
  border-radius: 24px;
  color: var(--color-text-primary);
  gap: var(--spacing-04);
  display: inline-flex;
  font-size: var(--font-size-xs);
  height: 32px;
  line-height: 1;
  justify-content: center;
  padding-inline: 8px 12px;
  padding-block: 4px 4px;
  transition: background-color 0.2s ease;
  font-weight: var(--font-weight-bold);
}

.root:hover,
.root:active,
.root:visited,
.root:focus {
  color: var(--color-text-primary);
  text-decoration: none;
}

.root:hover {
  background-color: var(--color-bg-primary);
}

.root span {
  white-space: pre;
}

.root svg {
  color: var(--color-icon-decorative);
}
