.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-08);
  height: 100%;
}

.content {
  max-width: 340px;
}
