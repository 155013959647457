.root {
  padding-inline: 0;
  padding-block: var(--spacing-24) var(--spacing-48);
}

.title {
  font-size: rem(21px);
  font-weight: normal;
  margin-inline: 0;
  margin-block: var(--spacing-16);
  text-align: center;
}

.image {
  display: block;
  margin-inline: auto;
  margin-block: 0;
}

.root p {
  margin: 0;
  font-size: var(--font-size-md);
  font-weight: bold;
}

.root h3 {
  color: var(--color-text-tertiary);
}

.created,
.updated {
  color: var(--color-status-positive);
}

.skipped {
  color: var(--color-status-negative);
}

.download {
  display: inline-block;
  color: var(--color-text);
  font-weight: var(--font-weight-bold);
  margin-block-start: var(--spacing-16);
  text-decoration: underline;
}

.warnings {
  margin-block-start: var(--spacing-16);
}

.warningsTitle {
  text-align: center;
}

.warningRow {
  border-block-end: 1px solid var(--color-border-section);
  padding-inline: 0;
  padding-block: var(--spacing-16);
}

.actions {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-block-start: var(--spacing-32);
}
