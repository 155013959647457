.confettiWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.confettis {
  height: rem(10px);
  inset-inline-start: 50%;
  position: absolute;
  inset-block-start: 50%;
  transform: translate(rem(-5px), rem(-5px));
  width: rem(10px);
}
