.formContainer {
  padding: 20px;
}

.heading {
  align-items: center;
  display: flex;
  font-size: var(--font-size-md);
  margin-block-end: 8px;
  font-weight: var(--font-weight-bold);
}

.description {
  font-size: var(--font-size-2xs);
  margin-block: -3px 10px;
}

.hint {
  color: var(--color-text-secondary);
  font-size: var(--font-size-2xs);
  margin-block-start: 10px;
}

.toggle {
  align-items: center;
  display: flex;
}

.toggle > div:first-child {
  flex: 0 0 42px;
}

.row {
  margin-block-end: var(--spacing-16);
}

.row.main {
  margin-block-end: var(--spacing-32);
}

.label {
  align-items: center;
  display: flex;
  font-size: var(--font-size-2xs);
  margin-block-end: 10px;
  font-weight: var(--font-weight-bold);
}

.helpBadge {
  margin-inline-start: 5px;
}

.tooltip {
  text-align: start;
  width: 260px;
}

.tooltip.large {
  width: 360px;
}

.helpIcon {
  vertical-align: bottom;
}

.separator {
  border-block-start: 1px solid var(--color-border);
  display: block;
  margin-inline: 0;
  margin-block: 20px;
  width: 100%;
}

.title {
  margin-block-end: 10px;
}

.checkbox {
  padding-inline-start: 10px;
}

.root {
  align-items: center;
  display: flex;
}

.in {
  align-self: flex-end;
  padding-inline: 20px 20px;
  padding-block: 0 0;
}
