.root {
  display: flex;
  align-items: center;
}

.label {
  margin-inline-end: rem(10px);
}

.wrapper {
  background-color: var(--color-viz-graph-bg);
  border-radius: rem(100px);
  box-shadow: inset 0 0 0 1px var(--color-viz-grid-line);
  height: rem(7px);
  overflow: hidden;
  width: rem(105px);
}

.block .wrapper {
  width: 100%;
}

.progress {
  background-color: var(--color-viz-positive-fill);
  border-radius: rem(5px) 0 0 rem(5px);
  box-shadow: inset 0 0 0 1px var(--color-viz-positive);
  height: rem(7px);
  transition: width 0.4s ease-in-quint;
}

.warning {
  background-color: var(--color-viz-07);
  box-shadow: inset 0 0 0 1px var(--color-viz-08);
}

.critical {
  background-color: var(--color-viz-negative-fill);
  box-shadow: inset 0 0 0 1px var(--color-viz-negative);
}

.isFull {
  border-radius: rem(2px);
}
