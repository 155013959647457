@import '../../../../../../../../../../styles/variables.css';

.container {
  align-items: center;
  background-color: var(--color-bg-navigation);
  inset-block-end: 0;
  box-shadow:
    0 -3px 0 0 rgba(0, 0, 0, 0.02),
    inset 0 1px 0 0 var(--color-border);
  display: flex;
  height: 75px;
  justify-content: flex-start;
  opacity: 0;
  padding-inline: 20px;
  padding-block: 0;
  pointer-events: none;
  position: fixed;
  transition:
    opacity 300ms ease-in-out,
    transform 300ms ease-in-out;
  width: 100%;
  z-index: var(--z-index-2--dropdown);
  transform: translateY(75px);
}

.container.dirty {
  pointer-events: initial;
  opacity: 1;
  transform: translateY(0);
}

.container.elevated.dirty {
  inset-block-end: 48px;
}

.container button:last-child {
  margin-inline: 10px 0;
  margin-block: 0 0;
}

.reviewButton {
  align-items: center;
  display: flex;
}

.arrow {
  align-items: center;
  display: flex;
  height: 100%;
  margin-inline-start: 5px;
}
