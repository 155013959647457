.root {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-bold);
  color: var(--color-text-primary);
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex-wrap: wrap;
  gap: var(--spacing-08);
}

.word {
  background: var(--color-btn--warning-active-bg);
  border-radius: 12px;
  padding-inline: var(--spacing-12);
  padding-block: 2px;
  text-transform: capitalize;
}
