.root {
  display: flex;
}

.iconWrapper {
  width: 24px;
  height: 24px;
}

.root.segment,
.root.segment .risk {
  height: 18px;
}

.root.segment .iconWrapper {
  margin-inline-start: 10px;
}

.root.segment .text {
  display: none;
}

.text {
  margin-inline-start: 5px;
}

.text span {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-bold);
  color: var(--color-text-primary);
}

.dotted {
  border-block-end: 1px dotted var(--color-border-section);
}

.lowercased {
  text-transform: lowercase;
}

.padLeft {
  padding-inline-start: 3px;
}

.risk {
  display: flex;
}

.root.column {
  justify-content: center;
}

.root.column.segment,
.root.column.segment .risk {
  height: 100%;
}

.root.segment.column .risk {
  flex-direction: column-reverse;
  justify-content: flex-start;
}

.root.column .risk {
  align-items: center;
  flex-direction: column;
}

.root.segment .iconWrapper,
.root.column .text {
  margin-inline-start: 0;
}
