/*  course height is 128px as per design */
:root {
  --course-height: 128px;
}

/* Min-width is 264px as per design*/
.cardContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--spacing-16);
  gap: var(--spacing-16);
  min-height: 100%;
  width: 264px;
}

.course {
  background-position: center;
  background-size: cover;
  border-radius: var(--border-radius-sm);
  display: flex;
  flex-direction: column;
  min-height: var(--course-height);
}

.progress {
  padding-block-start: var(--spacing-16);
  display: flex;
}

.playTourIcon {
  display: block;
}

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-block-start: var(--spacing-08);
}

.icon img {
  max-height: 60px;
}

.separator {
  margin-inline: var(--spacing-04);
}

.courseInfo {
  display: flex;
  flex-wrap: wrap;
}
