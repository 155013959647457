.consentIntro {
  margin: 0;
  margin-block-end: var(--spacing-16);
}

.cardSections > :not(:last-child) {
  margin-block-end: var(--spacing-48);
}

.buttonsFooter {
  display: flex;
  justify-content: flex-end;
}

.buttonsFooter > :not(:last-child) {
  margin-inline-end: var(--spacing-16);
}

.consentMessageWrapper textarea {
  resize: vertical;
  max-height: 50vh;
  min-height: rem(140px);
}

.attributesDisclaimer {
  display: flex;
  align-items: center;
}

.attributesDisclaimer > :not(:last-child) {
  margin-inline-end: var(--spacing-08);
}

.urlLabelDescription {
  font-weight: normal;
}
