.container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.container .arrow {
  color: var(--color-text-primary);
}

.titleDriver {
  display: flex;
  align-items: center;
}

.link,
.link:hover,
.link:focus {
  text-decoration: none;
}

@media screen and (max-width: 991px) {
  .container {
    justify-content: flex-start;
  }
}

.sectionTitle {
  margin-block-end: 0;
}
