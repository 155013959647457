.root {
  color: var(--color-text);
  display: block;
  height: 100%;
  position: relative;
  text-align: center;
  text-decoration: none;
}

.root:hover,
.root:active,
.root:visited,
.root:focus {
  color: var(--color-text);
  text-decoration: none;
}

.root::after {
  box-shadow: 0 2px 25px 0 rgba(44, 47, 55, 0.13);
  content: '';
  height: 100%;
  inset-inline-start: 0;
  opacity: 0;
  position: absolute;
  inset-block-start: 0;
  transition: opacity 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  width: 100%;
}

.root:hover::after {
  opacity: 1;
}

.icon {
  display: flex;
  align-items: center;
  height: rem(64px);
  width: rem(64px);
}
