.root {
  cursor: pointer;
  padding: rem(16px);
  position: relative;
}

.container,
.actions {
  align-items: center;
  display: flex;
}

.title {
  display: flex;
  align-items: center;
  margin-block-end: rem(8px);
}

.title > h2 {
  font-size: var(--font-size-lg);
  margin-inline-start: rem(16px);
  font-weight: var(--font-weight-bold);
}

.subtitle {
  margin-inline: rem(48px) rem(0);
  margin-block: rem(0) rem(8px);
  font-weight: var(--font-weight-bold);
  display: flex;
  align-items: center;
}

.content {
  flex: 2;
  padding-inline-end: 20px;
}

.text {
  word-break: break-word;
}

.badges {
  align-items: center;
  display: flex;
  margin-block-start: rem(8px);
}

.withIcon .text,
.withIcon .badges,
.withIcon .target {
  margin-inline-start: rem(48px);
}

.badge {
  margin-inline-end: 5px;
}

.badge:last-child {
  margin-inline-end: 0;
}

.toggle {
  margin-inline-end: 10px;
}

.title > div:first-child > div:last-child {
  max-width: 240px;
}

@media (--handhelds) {
  .container {
    display: block;
  }

  .content {
    padding-inline-end: 0;
  }

  .categoryIcon {
    display: none;
  }

  .subtitle,
  .title > h2,
  .withIcon .text,
  .withIcon .badges,
  .withIcon .target {
    margin-inline-start: 0;
  }

  .title h2 {
    font-size: var(--font-size-md);
  }

  .actions {
    margin-block-start: var(--spacing-16);
  }
}
