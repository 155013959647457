.tooltip {
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.3);
  background: var(--color-bg-card);
  position: relative;
  color: var(--color-text-secondary);
  border-radius: var(--border-radius-base);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
}

.tooltip.isRtl {
  direction: rtl;
}

.tooltipHeading {
  padding: var(--spacing-16);
  position: relative;
  border-block-end: 1px solid var(--color-border-section);
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
}

.tooltipBody {
  padding-block: 0 var(--spacing-16);
  padding-inline: var(--spacing-16);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.toolipBodyIndented {
  margin-block-start: var(--spacing-16);
  padding-inline-start: var(--spacing-16);
}

.legend {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  padding-block: var(--spacing-08) var(--spacing-24);
  gap: var(--spacing-16);

  @media (--min-width-md) {
    flex-direction: row;
  }
}

.legendItem {
  display: flex;
  gap: var(--spacing-08);
  align-items: center;
}

.participationBarLegendIcon {
  height: 0.75rem;
  width: 0.75rem;
  border-radius: var(--border-radius-sm);
  background-color: var(--color-viz-04);
}

.benchmarkLegendIcon {
  width: 1rem;
  height: 0.25rem;
  background-color: var(--color-benchmark-bg);
  border-radius: var(--border-radius-sm);
  display: flex;
  align-items: center;
  justify-content: center;
}

.benchmarkLegendIcon::after {
  content: '';
  display: block;
  height: 0.125rem;
  width: 0.75rem;
  border: 1px dashed var(--color-benchmark-highlight);
}

.averageLegendIcon {
  width: 1rem;
  height: 0.25rem;
  border-radius: var(--border-radius-sm);
  background-color: var(--color-btn--action-hover-bg);
}

.legendLabel {
  color: var(--color-text-secondary);
  font-size: var(--font-size-xs);
  line-height: 1.4;
}
