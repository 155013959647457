.wrapper {
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 0;
}

.employee {
  align-items: center;
  /* stylelint-disable-next-line */
  display: flex !important; /* Override react-select inline style */
  justify-content: flex-start;
}

.avatar {
  display: flex;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  margin-inline-end: 10px;
}

.content {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 1;
  min-width: 0;
  overflow: hidden;
}

.name {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 1;
}

.email {
  color: var(--color-text-tertiary);
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 1;
  font-size: var(--font-size-2xs);
  width: 100%;
}

.option:hover {
  background-color: var(--color-bg-card);
}
