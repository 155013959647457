.root {
  max-width: 1200px;
  display: flex;
  flex-direction: column;
}

.story {
  padding-inline: 0;
  padding-block: 30px;
}

.section {
  display: flex;
  padding-inline: 0;
  padding-block: 30px;
}

.header {
  width: 25%;
}

.header,
.content {
  padding-inline: 10px;
  padding-block: 0;
}

.content {
  display: flex;
  flex-direction: column;
  width: 75%;
  padding-block-end: var(--spacing-08);
}

.content .resource:not(:last-of-type) {
  border-block-end: 1px solid var(--color-border);
}

.resource > div {
  max-width: 100%;
}

.courses {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.course {
  display: flex;
  flex-basis: 268px;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 0;
  max-width: 268px;
  margin-block-end: 20px;
}

.course:not(:first-of-type),
.course:not(:last-of-type) {
  margin-inline: 0 10px;
  margin-block: 0 20px;
}

.course:first-of-type {
  margin-inline-start: 0;
}

.actionsContainer {
  display: flex;
  flex-direction: row;
}

.suggested,
.actions {
  flex: 1;
}

.actions.single {
  max-width: 414px;
}

.suggestedHeader,
.actionHeader {
  margin-block-end: 10px;
}

.suggestedHeader {
  display: flex;
}

.companyActionsHeader {
  margin-inline: 0 0;
  margin-block: 20px 10px;
}

.actionHeader {
  display: flex;
  justify-content: space-between;
}

.suggestedHeader h3,
.actionHeader h3 {
  margin: 0;
}

.suggested {
  margin-inline: 0 20px;
  margin-block: 0 0;
}

.customActionsList {
  max-height: 58px;
  overflow-y: hidden;
  transition: max-height 300ms ease-in-out;
}

.customActionsList.expanded {
  max-height: 600px;
  transition: max-height 300ms ease-in-out;
}

.suggestedAction {
  margin-block-end: 10px;
  max-width: 398px;
}

.empty {
  display: inline-flex;
  align-items: center;
}

.empty span {
  font-size: var(--font-size-md);
  color: var(--color-text-tertiary);
  font-weight: var(--font-weight-bold);
}

.empty svg {
  margin-inline-start: 5px;
}

.actionCardContainer {
  margin-block-end: var(--spacing-08);
}

.spinner {
  margin-block-start: 30px;
}

@media (--handhelds-tablets) {
  .section {
    flex-direction: column;
    padding-inline: 0;
    padding-block: 15px;
  }

  .header,
  .content {
    width: 100%;
  }

  .header {
    margin-block-end: 10px;
  }

  .content h3 {
    margin-inline: 0;
    margin-block: 15px;
  }

  .course {
    margin-inline: 0;
    margin-block: 15px;
    max-width: initial;
    max-width: 268px;
    width: 100%;
  }

  .actionsContainer {
    flex-direction: column;
  }

  .actionsContainer .suggested,
  .actionsContainer .actions {
    margin-inline: 0 0;
    margin-block: 0 20px;
  }

  .suggestedHeader {
    align-items: center;
    margin: 0;
  }

  .actions,
  .suggestedAction {
    max-width: initial;
  }
}

.emptyResources {
  padding: 55px;
}
