.root {
  align-items: center;
  display: flex;
  min-height: 32px;
}

.label {
  flex: 2;
}

.label span {
  word-break: break-word;
}

.count,
.action {
  flex: 1;
}

.count {
  color: var(--color-text-secondary);
  display: block;
  padding-inline: 20px;
  padding-block: 0;
  text-align: center;
  text-decoration: underline;
}

.count.noEmployees {
  text-decoration: none;
}

.count > span {
  cursor: pointer;
}

.count.noEmployees > span {
  cursor: default;
}

.action {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}

.automatic {
  align-items: center;
  color: var(--color-text-tertiary);
  display: flex;
  font-size: var(--font-size-2xs);
  line-height: 1;
  font-weight: var(--font-weight-bold);
}

.automaticIcon {
  height: 16px;
  margin-inline-end: 5px;
  color: var(--color-text-tertiary);
}
