.container {
  width: 100%;
  max-width: 928px;
  margin: 0 auto;
}

.accordionContainer {
  margin-block: var(--spacing-08);
  border-block-start: 1px solid var(--color-border);
  border-block-end: 1px solid var(--color-border);
}

.accordionItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-inline: var(--spacing-24);
  padding-block: var(--spacing-16);
  border-block-end: 1px solid var(--color-border);
}

.accordionItem:first-child {
  padding-block-start: 0;
}

.accordionItem:last-child {
  border-block-end: none;
  padding-block-end: 0;
}
