.placeholder {
  display: flex;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 1;
}

.avatar {
  align-items: center;
  display: flex;
  justify-content: center;
}
