.grid {
  display: grid;
  padding-block-start: var(--spacing-16);
  gap: var(--spacing-40);
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  justify-content: flex-start;
}

.disabled {
  cursor: pointer;
}

.link {
  display: flex;
  flex-basis: 250px;
  flex-grow: 0;
  flex-shrink: 0;
  text-decoration: none;
  transition:
    box-shadow 0.2s cubic-bezier(0.165, 0.84, 0.44, 1),
    transform 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  width: 250px;
}

.link:hover {
  box-shadow: 0 2px 25px 0 rgba(44, 47, 55, 0.13);
  text-decoration: none;
  transform: scale(1.06);
}

.link:focus-visible {
  text-decoration: none;
  transform: scale(1.06);
  box-shadow: var(--focus-base);
  outline: var(--focus-outline);
}

@media (--desktop) {
  .grid {
    justify-content: flex-start;
  }
}

/* fix for <UnstyledButton> which used to have position: relative; */
.relative {
  position: relative;
}
