.container {
  max-width: 1200px;
  padding: var(--spacing-32);
  margin: 0 auto;
}

.container > * + * {
  margin-block-start: var(--spacing-32);
}

.tableWrapper {
  overflow-x: auto;
  border-radius: var(--border-radius-card);
}
