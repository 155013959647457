.option {
  align-items: center;
  box-shadow: inset 0 0 0 1px var(--color-controls-border);
  border-radius: 5px;
  background-color: var(--color-controls-bg);
  cursor: pointer;
  display: flex;
  margin-block-end: 5px;
  padding-inline: 20px;
  padding-block: 15px;
  transition:
    background-color 0.15s,
    box-shadow 0.15s;
}

.option.checked {
  box-shadow: inset 0 0 0 2px var(--color-controls-active-border);
}

.option:last-child {
  margin-block-end: 0;
}

.content {
  margin-inline-start: 20px;
}

.title {
  font-weight: var(--font-weight-bold);
}

.text {
  font-size: var(--font-size-2xs);
}

input {
  width: auto;
}
