.navActions {
  border: none;
  padding: 0;
  height: auto;
}

.scoreModeToggler {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-08);
}

.main {
  align-items: flex-start !important;
  display: flex;
  height: auto !important;
  padding-inline: 0 !important;
  padding-block: var(--spacing-24) !important;
  font-size: var(--font-size-md);
}

.icon {
  display: block;
  margin-inline-end: 18px;
}

.icon.driverIcon {
  height: 48px;
  width: 48px;
}

.navMobile {
  display: none;
}

.onlySort {
  justify-content: flex-end;
}

.categoryInfo {
  padding-block-end: 10px;
  color: var(--color-text-secondary);
  max-width: 100ch;
  font-size: var(--font-size-sm);
}

.hideOnDesktop {
  display: none;
}

.dropdownContainer {
  display: flex;
  align-items: center;
  padding-inline: var(--spacing-08);
  border-inline-end: 1px solid var(--color-border-section);
}

.dropdownContainer:first-child {
  padding-inline-start: 0;
}

.addBorder {
  border-inline-start: 1px solid var(--color-border-section);
}

@media (--handhelds) {
  .navActions {
    margin: 0;
    justify-content: space-between;
    margin-block: var(--spacing-04) var(--spacing-16);
    padding-inline: var(--spacing-16) var(--spacing-24);
  }

  .export,
  .breadcrumbs {
    display: none;
  }

  .scoreModeToggler {
    margin: 0;
  }

  .main {
    flex-direction: column !important;
  }

  .icon {
    margin-inline-end: 0;
    margin-block: 3px 10px;
  }

  .navMobile {
    display: flex;
  }

  .hideOnDesktop {
    display: block;
  }

  .hideOnMobile {
    display: none;
  }
}

@media (--tablets) {
  .navActions {
    margin: 0;
    justify-content: space-between;
    margin-block: var(--spacing-04) var(--spacing-16);
    padding-inline: var(--spacing-16) var(--spacing-24);
  }
}
