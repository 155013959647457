.rightColumn {
  border-inline-start: 1px solid var(--color-border);
  display: flex;
  justify-content: center;
  align-items: center;
}

.column {
  padding: var(--spacing-16);
}

.row {
  display: flex;
  flex-wrap: nowrap;
}

.labelText {
  white-space: nowrap;
}

@media (--handhelds) {
  .row {
    flex-direction: column;
    align-items: flex-start;
  }

  .rightColumn {
    border-block-start: 1px solid var(--color-border);
  }
}
