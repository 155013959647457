.root {
  align-items: center;
  display: inline-flex;
  width: 100%;
}

.input {
  align-items: center;
  display: flex;
  flex: 1;
}

.input .inputField[type='number'] {
  padding-inline-end: var(--spacing-04);
}

.input > div {
  flex: 0 0 auto;
}

.invalid input,
.invalid .select {
  border: 1px solid var(--color-status-negative) !important;
}

.input input,
.select {
  margin-inline-end: 3px;
  width: 60px;
}

.select {
  margin-inline-start: 5px;
}
