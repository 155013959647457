.wrapper {
  align-items: center;
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
}

.select {
  flex: 1;
}

.select:first-child {
  padding-inline-end: 5px;
}

.select:last-child {
  padding-inline-start: 5px;
}

.option {
  align-items: center;
  display: flex;
}

.restrictedIcon {
  height: 0.75rem;
  width: 0.75rem;
}
