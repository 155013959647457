:root {
  --topic-card-height: rem(170px);
  --topic-card-no-context-height: rem(130px);
  --topic-card-locale-height: rem(113px);
  --topic-card-width: rem(220px);
}

.root {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  justify-content: flex-start;
}

.container {
  position: relative;
  width: 100%;
}

.wrapper {
  display: block;
  color: var(--color-text);
  height: var(--topic-card-height);
  text-decoration: none;
}

.contextless .wrapper {
  height: var(--topic-card-no-context-height);
}

.wrapper.withLocale {
  margin-block-end: 0;
}

.wrapper.expanded {
  height: 100%;
  margin-block-end: 0;
}

.wrapper:hover,
.wrapper:focus,
.wrapper:visited {
  text-decoration: none;
  color: var(--color-text);
}

.card {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  transition-duration: 0.2s;
  transition-property: box-shadow, border;
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  width: 100%;
  border-radius: var(--border-radius-card);
}

.wrapper.expanded .card {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.wrapper.expanded .card .inner,
.wrapper.expanded .card .summary {
  flex: 1;
}

.container.hovered .wrapper:not(.expanded) .card {
  inset-block-end: auto;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  height: auto;
  overflow: hidden;
  position: relative;
  z-index: 3;
}

.card.loading {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: borderAnimate;
  animation-timing-function: ease-in;
  border-block-start-style: solid;
  border-block-start-width: 2px;
  cursor: initial;
}

.card.highlighted,
.container.hovered .wrapper:not(.expanded) .card.highlighted {
  border-block-start: 2px solid var(--color-status-highlight);
}

.card.headless {
  margin-block-start: 16px;
}

.card .inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-inline: 0;
  padding-block: 20px;
  flex-shrink: 0;
  height: var(--topic-card-height);
  width: 100%;
}

.contextless .card .inner {
  height: var(--topic-card-no-context-height);
}

.header,
.footer,
.summary {
  padding-inline: rem(20px);
  padding-block: 0;
}

.highlightedHeader {
  align-items: center;
  display: inline-flex;
  justify-content: flex-start;
  margin-block-end: 4px;
  width: 100%;
}

.highlightedHeaderIcon {
  height: 0.75rem;
  width: 0.75rem;
}

.header {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.headerRow {
  display: flex;
  justify-content: space-between;
}

.cross {
  position: absolute;
  z-index: 5;
  inset-inline-end: var(--spacing-08);
  inset-block-start: var(--spacing-08);
  opacity: 0;
}

.crossIcon {
  padding: 2px;
}

.context {
  margin-block-start: 8px;
}

.localeFooter {
  border-block-start: 1px solid var(--color-border);
  flex-grow: 1;
  height: 36px;
  padding-inline-start: 20px;
  width: 100%;
}

.localeFooter .localeContainer {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
}

.localeIcon {
  height: 12px;
  width: 12px;
  margin-inline-end: 6px;
  color: var(--color-text-secondary);
}

.localeFooter .localeText {
  align-items: center;
  border-inline-end: 1px solid var(--color-border);
  color: var(--color-text-secondary);
  display: inline-flex;
  flex-grow: 1;
  font-size: var(--font-size-2xs);
  height: 100%;
  margin-block-end: -1px;
}

.localeButton {
  align-items: center;
  color: var(--color-text-link);
  display: flex;
  flex-grow: 1;
  height: 100%;
  justify-content: center;
  max-width: 100px;
  min-width: 80px;
  font-weight: var(--font-weight-bold);
}

.footer {
  display: flex;
  flex-direction: row;
}

.footerItem {
  display: flex;
  flex-direction: column;
  margin-inline-end: 30px;
}

.footerNps .footerItemContent {
  align-items: center;
  justify-content: flex-start;
}

.footerSummary {
  flex: 1 1 100%;
  margin-block-start: 18px;
}

.footerSummary.translated {
  margin-block-start: 15px;
}

.footerSummary .footerItemContent {
  display: block;
  height: 100%;
  margin-block-start: 3px;
}

.footerSummary p {
  margin: 0;
}

.footerItemContent {
  align-items: flex-end;
  display: inline-flex;
  height: 100%;
  height: 31px;
}

.score {
  display: inline-block;
  font-size: rem(26px);
  line-height: 1;
  font-weight: var(--font-weight-bold);
}

.footerComments .commentCount {
  width: 28px;
}

.count {
  align-items: flex-end;
  display: inline-flex;
  font-size: var(--font-size-xl);
  line-height: 1;
}

.count span {
  margin-inline-end: 3px;
}

.count svg {
  margin-block-end: 1px;
}

.mostCommentsIcon {
  height: 0.75rem;
  width: 0.75rem;
}

.footerComments .commentCount path {
  stroke: var(--color-text-primary);
}

.commentCount text {
  fill: var(--color-text-primary);
  font-size: rem(8px);
  font-weight: var(--font-weight-bold);
}

.summary {
  border-block-start: 1px solid var(--color-border);
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.container:focus-within .cross,
.wrapper.expanded ~ .cross,
.container.hovered .cross {
  opacity: 1;
}

@media (--tablets-min) {
  .root:hover .cross {
    opacity: 1;
  }

  .root.highlightedZero {
    margin-block-start: 31px;
  }

  .root.highlightedOne {
    margin-block-start: 16px;
  }

  .footerSummary {
    flex: 2 1 0%;
    margin-block-start: 0;
  }

  .footerItem {
    margin-inline-end: 16px;
  }
}

@media (--desktop) {
  .wrapper {
    height: var(--topic-card-height);
  }

  .wrapper.withLocale {
    height: calc(var(--topic-card-height) + var(--topic-card-locale-height));
  }

  .contextless .wrapper.withLocale {
    height: calc(
      var(--topic-card-no-context-height) + var(--topic-card-locale-height)
    );
  }

  .wrapper:not(.expanded) .card {
    inset-block: 0;
    cursor: pointer;
    inset-inline: 0;
    position: absolute;
  }

  .container.hovered .wrapper:not(.expanded) .card {
    z-index: 3;
  }

  .wrapper.expanded .card .inner {
    max-width: 330px;
  }

  .wrapper.expanded .card .summary {
    border-block-start: none;
    border-inline-start: 1px solid var(--color-border);
  }
}

@keyframes borderAnimate {
  0% {
    border-block-start-color: rgba(0, 0, 0, 0.05);
  }

  50% {
    border-block-start-color: var(--color-border);
  }

  100% {
    border-block-start-color: rgba(0, 0, 0, 0.05);
  }
}

@media (--handhelds) {
  .footerItemContent {
    font-size: var(--font-size-base);
  }
}
