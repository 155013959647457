.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 30px;
}

.icon {
  margin-inline-end: 12px;
}

[dir='rtl'] .icon {
  transform: scaleX(-1);
}

@media (--handhelds) {
  .label {
    display: none;
  }

  .icon {
    margin-inline-end: 0;
  }
}
