.calculation {
  /* flex-shrink needed for IE to compute heights properly */
  flex-shrink: 0;
}

.calculationTitle {
  font-weight: bolder;
  font-size: 0.6875rem;
  text-transform: uppercase;
  padding: 1.25rem;
}

.headerWrapper {
  padding-block: rem(20px);
  padding-inline: rem(30px) rem(20px);
  box-shadow: inset 0 -1px 0 0 var(--color-border);
  /* Needed to reset safari's browser-agent styles */
  margin: 0;
}

.headerWrapper.expandable {
  padding-block: rem(20px);
  padding-inline: rem(16px) rem(8px);
}

/* specificity needed to overwrite the component's default stylings */

.calculation.root .header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.calculation.root .benchmark {
  display: flex;
  align-items: center;
}

.calculation.root h3 {
  margin-block-end: 0;
  font-weight: var(--font-weight-normal);
}

.calculation.root .benchmarkScore {
  font-size: 1.1875rem;
}

.calculation.root .icon {
  margin-inline-start: rem(8px);
}

.calculation.root .content {
  background-color: var(--color-bg-primary);
  margin: 0;
  padding: 0;
}

.benchmark svg {
  margin-inline-end: rem(8px);
}

.benchmarkDetails {
  padding: 1.25rem;
  padding-block-start: 0;
}

.info {
  margin-inline: 0 0;
  margin-block: 0 0.8125rem;
}

.updatedAt {
  display: flex;
  justify-content: space-between;
  border-block-end: 1px solid var(--color-border-section);
  padding-block-start: var(--spacing-08);
}
