.row {
  align-items: center;
  border-block-end: 1px solid var(--color-border-section);
  display: flex;
  justify-content: space-between;
  min-height: 66px;
  padding: var(--spacing-16);
}

.name {
  color: var(--color-text-primary);
  display: flex;
  flex: 0 1 auto;
  font-weight: var(--font-weight-bold);
}

.name::before,
.name::after {
  content: '"';
}
