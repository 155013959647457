.content {
  padding-inline: 20px;
  padding-block: 15px;
}

.item {
  align-items: center;
  display: flex;
  margin-block-end: 5px;
}

.item:last-child {
  margin-block-end: 0;
}

.value {
  min-width: 40px;
  padding-inline-end: 17px;
  font-weight: var(--font-weight-bold);
}

.label {
  flex: 2;
}

.distribution {
  margin-block-start: 13px;
}

.nps {
  align-items: center;
  display: flex;
  font-size: var(--font-size-2xs);
  margin-block-end: 6px;
}

.nps:last-child {
  margin-block-end: 0;
}

.npsIcon {
  height: 24px;
  width: 24px;
}

.npsPercentage {
  color: var(--color-text-tertiary);
  margin-inline: 16px 13px;
  margin-block: 0 0;
  min-width: 24px;
  font-weight: var(--font-weight-bold);
}

.nps.promoters .npsPercentage {
  color: var(--color-score-positive);
}

[data-theme='deuteranopia'] .nps.promoters .npsPercentage {
  color: var(--color-score-positive--deuteranopia);
}

.nps.detractors .npsPercentage {
  color: var(--color-score-negative);
}
