.root {
  margin-block-start: var(--spacing-12);
}

.item {
  display: flex;
  align-items: center;
  justify-content: start;
}

.item:not(:last-child) {
  margin-block-end: var(--spacing-04);
}

.icon {
  height: 24px;
  margin-inline-end: var(--spacing-04);
}

.label {
  font-size: var(--font-size-xs);
}

.neutral {
  color: var(--color-status-warning);
}
