.root {
  margin-inline: auto;
  margin-block: 0;
  max-width: 760px;
}

.row {
  margin-block-start: 30px;
}

.row:first-child {
  margin-block-start: 0;
}

.row label {
  color: var(--color-text-primary);
  font-weight: var(--font-weight-bold);
}

.field {
  align-items: center;
  display: flex;
}

.field > div:first-child {
  flex: 2;
}

.hint {
  color: var(--color-text-secondary);
  padding-inline-start: 20px;
}

.actions {
  margin-block-start: 40px;
}

.company {
  font-size: var(--font-size-lg);
  line-height: 1;
  font-weight: var(--font-weight-bold);
}

.delete {
  margin-block-start: 40px;
}

.delete a {
  color: var(--color-status-negative);
  cursor: pointer;
  text-decoration: underline;
}

@media (--handhelds) {
  .field {
    align-items: flex-start;
    flex-direction: column;
  }

  .hint {
    margin-block-start: var(--spacing-08);
    padding-inline-start: 0;
  }
}
