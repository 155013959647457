.root {
  align-items: center;
  display: flex;
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
}

.root.tiny {
  font-size: var(--font-size-2xs);
}

.root.large {
  font-size: var(--font-size-lg);
}

.avatar {
  margin-inline-end: rem(15px);
}

.root.tooltip .avatar {
  margin-inline-end: 15px;
}

.root.action {
  font-size: var(--font-size-2xs);
  font-weight: var(--font-weight-bold);
}

.root.slideIn {
  font-size: rem(22px);
  font-weight: var(--font-weight-bold);
}

.root.actionTitle {
  font-size: rem(21px);
  font-weight: var(--font-weight-bold);
}

.root.slideIn .avatar {
  margin-inline-end: 10px;
}

.root.notification {
  align-items: flex-start;
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-bold);
  width: 100%;
}

.root.actionTitle .footer {
  align-items: flex-start;
  flex-direction: column;
  margin-inline-start: 0;
  max-width: 100%;
}

.actionTitle .avatar {
  margin-block-end: 12px;
}

.avatar.notificationNoBadge {
  margin-block-end: 15px;
}

.root.tooltip .name {
  font-size: rem(13px);
}

.badge {
  display: flex;
  overflow: hidden;
}

.notification .badge {
  margin-inline-start: 0;
}

.count::before {
  content: ' ';
}

.list .name {
  font-size: var(--font-size-base);
}

@media (--handhelds) {
  .root.large {
    font-size: var(--font-size-md);
  }
}
