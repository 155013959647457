.source {
  margin-block-start: 8px;
  display: flex;
  align-items: center;
}

.source span {
  font-size: var(--font-size-base);
  color: var(--color-text-secondary);
  font-weight: var(--font-weight-bold);
}

.source .companyName {
  margin-inline: var(--spacing-08);
}
