.group {
  position: relative;
  margin-block-end: var(--spacing-16);
}

.collapsibleHeader {
  padding: 0;
  padding-inline-end: var(--spacing-16);
}

.collapsibleHeaderInner {
  flex: 1;
  display: flex;
  height: 4rem;
  align-items: center;
  justify-content: space-between;
  padding-inline-start: var(--spacing-16);
}

.collapsibleHeaderInner h3 {
  font-size: var(--font-size-sm);
  margin: 0;
}

.collapsibleHeaderInner .count {
  padding-inline-end: var(--spacing-08);
}

.collapsibleContent {
  margin: 0 !important;
  padding: 0 !important;
}

.groupItem {
  list-style: none;
  padding: 0;
  padding-inline: var(--spacing-16);
  padding-block: var(--spacing-16) var(--spacing-16);
  border-block-start: 1px solid var(--color-border);
}

.groupItemTable {
  font-size: var(--font-size-xs);
  margin-block-end: var(--spacing-16);
}

.groupItemTable th,
.groupItemTable td {
  padding-inline: 0;
  padding-block: var(--spacing-04);
}

.groupItemTable th {
  font-weight: normal;
  color: var(--color-text-tertiary);
  text-align: start;
  padding-inline-end: var(--spacing-32);
}
