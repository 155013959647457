.header {
  justify-content: flex-start !important;
  padding-inline: 6px;
  padding-block: 0;
}

.header button {
  max-width: 252px;
}

.empty {
  align-items: center;
  color: var(--color-text-tertiary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-inline: auto;
  margin-block: 30px;
  max-height: 100%;
  padding-inline: 0;
  padding-block: 10px;
  text-align: center;
  width: 100%;
}

.emptyTitle {
  color: var(--color-text);
  font-size: var(--font-size-md);
  text-align: center;
  margin-block-start: var(--spacing-24);
  font-weight: var(--font-weight-bold);
}
