.container {
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.07);
}

.inner {
  align-items: center;
  display: flex;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  height: 64px;
  justify-content: space-between;
  padding-inline: 20px;
  padding-block: 15px;
}
