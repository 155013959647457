.root {
  width: 100%;
}

.empty,
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  padding: var(--spacing-64);
}

.empty {
  flex-direction: column;
  gap: var(--spacing-16);
  width: 100%;
  max-width: 398px;
  margin-inline: auto;
  margin-block: var(--spacing-64);
  font-size: var(--font-size-sm);
  text-align: center;
}

.image {
  margin-block-end: var(--spacing-16);
}

.filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-inline: var(--spacing-16);
  margin-block: var(--spacing-24);
  overflow-x: auto;
  gap: var(--spacing-16);
}

.selectors {
  display: flex;
  gap: var(--spacing-16);
}

div[id='category-filter-target'] {
  min-height: 44px;
}
