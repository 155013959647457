.count {
  align-items: center;
  border-radius: 40px;
  color: var(--color-text-inverted);
  display: inline-flex;
  justify-content: center;
  flex-grow: 0;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-bold);
  height: 18px;
  line-height: 1;
  margin-inline-start: 5px;
  min-width: 18px;
  padding-inline: 4px;
  padding-block: 1px;
  text-align: center;
}

.count.large {
  font-size: var(--font-size-sm);
  height: 24px;
  line-height: 1;
  margin-inline-start: 8px;
  min-width: 24px;
  padding-inline: 8px;
  padding-block: 2px;
}

.count.right {
  margin-inline: 0 5px;
}

.count.right.large {
  margin-inline: 0 8px;
}

.count.primary {
  background-color: var(--color-status-highlight);
}

.count.danger {
  background-color: var(--color-status-negative);
}
