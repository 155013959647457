@import 'animate.css/source/flippers/flipInX.css';

.root {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: flipInX;
  margin-block-start: var(--spacing-32);
}

.text {
  margin: 0;
  margin-block-start: var(--spacing-04);
}
