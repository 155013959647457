.root {
  height: 100%;
  padding: 25px;
  width: 100%;
  background-color: var(--color-bg-card);
  border-radius: var(--border-radius-card);
  box-shadow: var(--depth-1-box-shadow);
}

.title {
  margin-block-end: 20px;
}

.title a {
  color: var(--color-text);
}

@media (--handhelds) {
  .root {
    border-radius: 0;
    padding: 20px;
  }
}
