.root,
.content {
  padding: 5px;
}

.root {
  align-items: center;
  background-color: var(--color-bg-info);
  border-radius: 10px;
  display: inline-flex;
  font-size: var(--font-size-2xs);
  line-height: 1;
  font-weight: var(--font-weight-bold);
}

.custom {
  background-color: var(--color-bg-alert);
}

.customized {
  background-color: var(--color-bg-alert);
}

.translations {
  padding: 0;
  color: var(--color-text-primary);
}

.content {
  align-items: center;
  display: flex;
}

.icon {
  display: block;
  margin-inline-end: 5px;
  height: 0.75rem;
  width: 0.75rem;
}

.count {
  align-items: center;
  background-color: var(--color-bg-card);
  border-radius: 50%;
  display: flex;
  height: 15px;
  justify-content: center;
  margin-inline: 0 3px;
  margin-block: 0 0;
  padding-inline: 4px;
  padding-block: 0;
}
