.badge {
  display: inline-block;
  padding-inline: var(--spacing-04);
  font-size: var(--font-size-3xs);
  line-height: 1.4;
  border-width: 1px;
  border-style: solid;
  border-radius: var(--border-radius-sm);
  border-color: var(--color-text-body);
  color: var(--color-text-body);
}

.primary {
  font-weight: bold;
}

.secondary {
  border-color: var(--color-border-section);
}
