.icon {
  color: var(--color-ai-decorative);
}

.summaryList {
  padding-inline-start: var(--spacing-16);
}

.summaryListItem {
  padding-block: var(--spacing-04);
  white-space: pre-line;
}
