.root {
  border-block-start: 1px solid var(--color-border);
}

.kinds {
  align-items: center;
  border-block-end: 1px solid var(--color-border);
  display: flex;
  padding-inline: var(--spacing-16);
  padding-block: 0;
}

.button {
  position: relative;
  border-block-end: 2px solid transparent;
  color: var(--color-text-tertiary);
  flex: 0 0 auto;
  font-weight: var(--font-weight-bold);
  margin-inline-end: var(--spacing-16);
  padding-inline: 0;
  padding-block: 10px;
  text-decoration: none;
  transition:
    border-color 0.2s,
    color 0.2s;
}

.button:last-child {
  margin-inline: var(--spacing-16) 0;
}

.button:hover,
.button:focus {
  color: var(--color-text);
  text-decoration: none;
  cursor: pointer;
  border-color: var(--color-text-secondary);
}

.button.active {
  border-color: var(--color-controls-active-border);
  color: var(--color-brand-primary-dark);
  font-weight: var(--font-weight-bold);
}

.info {
  background-color: var(--color-bg-info);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  color: var(--color-text-primary);
  flex: 0 0 40px;
  font-size: var(--font-size-sm);
  margin-inline: 0;
  margin-block: var(--spacing-16);
  padding: rem(12px);
}

.info.note {
  background-color: var(--color-bg-alert);
  border: 1px solid var(--color-border);
  color: var(--color-text-primary);
}

.container {
  padding-inline: var(--spacing-16);
  padding-block: 0;
}

.form {
  position: relative;
}

.textarea {
  border: 1px solid var(--color-controls-border);
  border-radius: var(--border-radius-md);
  height: 100%;
  max-height: 50vh;
  min-height: 88px;
  padding-inline: var(--spacing-16);
  padding-block: var(--spacing-08) var(--spacing-16);
  overflow: auto;
  position: relative;
  width: 100%;
}

.textarea textarea {
  border: 0 !important;
  box-shadow: none !important;
  font-size: var(--font-size-base) !important;
  line-height: 1.45 !important;
  padding: 0 !important;
  resize: none;
}

.textarea:focus {
  background: transparent !important;
}

.actions {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin-block-start: var(--spacing-08);
  padding-block-end: var(--spacing-16);
}

.mentionsInfo {
  color: var(--color-text-tertiary);
  font-size: var(--font-size-3xs);
  font-weight: var(--font-weight-bold);
  margin-inline-start: var(--spacing-16);
}
