.slidesWrapper {
  width: 100%;
  overflow-x: hidden;
}

.slides {
  display: flex;
  align-items: center;
  transition: transform 0.3s ease-in-out;
}

.slide {
  flex-shrink: 0;
  width: 100%;
  padding: var(--spacing-16);
  display: flex;
  justify-content: center;
}

.slideInner {
  width: 100%;
  /* based on the width of the medium modal (768px) */
  max-width: calc(768px - var(--spacing-64) - var(--spacing-64));
  text-wrap: balance;
}

.actions {
  flex: 1;
}

.prevButtonContainer {
  /* 
    fix the width to match IconButton in case the IconButton in hidden
    to prevent design from shifting when changing slides
  */
  width: 2rem;
}

.indicator {
  border-radius: 4px;
  display: inline-block;
  height: 8px;
  width: 8px;
  border: 1px solid var(--color-controls-border);
}

.indicator.isActive {
  background-color: var(--color-controls--selected-marker);
  border-width: 0;
}
