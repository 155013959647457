.root {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding-inline: var(--spacing-24) 0;
  padding-block: var(--spacing-20);
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block-end: var(--spacing-16);
  padding-inline-end: var(--spacing-24);
  width: 100%;
}

.questionUpgradeDueGroup {
  display: flex;
  align-items: center;
  color: var(--color-text-hint);
  gap: var(--spacing-08);
  margin-inline-end: var(--spacing-16);
}

.actions {
  margin-inline: 0;
  margin-block: auto;
}

.content {
  flex: 2;
  padding-inline: 15px;
  padding-block: 0;
  margin-inline-end: 10px;
}

.active {
  display: inline-flex;
  margin-inline: 0 12px;
  margin-block: auto auto;
  color: var(--color-text-secondary);
}

.description {
  color: var(--color-text-secondary);
  border-block-start: 1px solid var(--color-border);
  margin-inline-start: 42px;
  padding-block-start: 16px;
  padding-inline-end: 57px;
  width: calc(100% - 42px);
  max-width: 100%;
}

.alert {
  width: 100%;
  padding-inline: 0 var(--spacing-24);
  margin-block-start: var(--spacing-16);
}

@media (--handhelds) {
  .root {
    padding: var(--spacing-16);
  }

  .wrapper {
    padding-inline-end: 0;
  }

  .groupIcon {
    display: none;
  }

  .description {
    margin-inline-start: 0;
    padding-inline-end: 0;
    width: 100%;
  }

  .content {
    padding-inline-start: 0;
  }
}
