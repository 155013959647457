.target {
  align-items: center;
  background-color: transparent;
  border-radius: 0;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  height: 36px;
  justify-content: space-between;
  outline: none;
  overflow: hidden;
  transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s,
    background-color ease-in-out 0.15s;
  width: 100%;
}

.text {
  flex: 1;
}

.target.disabled {
  opacity: 0.6;
  pointer-events: none;
  touch-action: none;
}

@media (--handhelds) {
  .text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-inline: 8px;
    padding-block: 0;
  }

  .target.active {
    background-color: var(--color-bg-card);
    border-color: var(--color-ack-thanks-for-sharing);
  }

  .target {
    color: var(--color-text-primary);
  }
}

[data-test-id='slide-in-content'] .target {
  border: 1px solid var(--color-controls-border);
  border-radius: var(--border-radius-md);
}

@media (--tablets-min) {
  .target {
    min-height: 40px;
    min-width: 150px;
    padding-inline: 0.5rem;
    padding-block: 0 0;
    transition: background-color ease-in-out 0.15s;
  }
}
