.employeeList {
  border-block-start: 1px solid var(--color-border);
  max-height: 200px;
  overflow-y: auto;
}

.employeeList ul {
  padding-inline-start: 0;
  list-style: none;
}

.employeeList li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: var(--spacing-08);
  padding-inline-end: var(--spacing-08);
  border-block-end: 1px solid var(--color-border);
}

.employeeList p {
  margin: 0;
}

.employeeEmail {
  color: var(--color-text-tertiary);
}
