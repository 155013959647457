.inputWrapper {
  display: flex;
  align-items: stretch;
  padding: 0;
}

.countrySelect {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
  flex: 1;
  margin-inline-end: -1px;
  padding-inline: var(--spacing-08) var(--spacing-04);
  text-align: end;
}

.countrySelect:global(.focused),
.countrySelect:focus,
.countrySelect:focus-visible {
  border-color: var(--color-controls-active-border) !important;
  z-index: 1;
}

.phoneField {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.invalid {
  border-color: var(--color-controls-error-border) !important;
}

.validation {
  --color-text-body: var(--color-controls-error-border);

  margin-block-start: var(--spacing-04);
}

.validationIcon {
  margin-inline-end: 0.125rem;
}

.requiredTag {
  font-weight: var(--font-weight-bold);
  color: var(--color-controls-error-border);
  vertical-align: super;
  font-size: 1.25em;
  line-height: 0.8;
}

@media (--min-width-sm) {
  .countrySelect {
    flex: 0 0 auto;
    max-width: rem(70px);
  }
}
