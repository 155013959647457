.root h1 {
  font-size: rem(28px);
  color: var(--color-text-primary);
}

.root {
  display: flex;
  flex-direction: column;
  vertical-align: top;
  width: 100%;
  max-width: 320px;
  height: 410px;
}

.header {
  margin-block-end: 8px;
}

.subHeader {
  font-size: rem(18px);
  color: var(--color-text-secondary);
  margin-block-start: 10px;
  font-weight: var(--font-weight-bold);
}

.backButtonContainer {
  position: relative;
  inset-inline-start: -3px;
  inset-block-end: 30px;
  height: 25px;
  width: 25px;
}

[dir='rtl'] .directionalIcon {
  transform: scaleX(-1);
}

@media (--handhelds) {
  .root h1 {
    font-size: var(--font-size-xl);
  }

  .header {
    margin-block-end: 20px;
  }

  .backButtonContainer {
    inset-block-end: 15px;
  }
}

/* for small screens */
@media (max-width: 320px) {
  .root h1 {
    font-size: var(--font-size-lg);
  }

  .subHeader {
    font-size: var(--font-size-sm);
  }
}
