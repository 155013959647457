.root {
  display: flex;
  gap: var(--spacing-12);
  flex-basis: 100%;
}

.root h4 {
  margin: 0;
}

.root p,
.content p {
  margin: 0;
  font-size: var(--font-size-sm);
}

.helpButton,
.helpButtonIcon {
  width: 16px;
  height: 16px;
}

.content {
  width: 100%;
}

.separator {
  margin-block: var(--spacing-08);
}
