.root,
.icons {
  align-items: center;
  display: flex;
}

.icon {
  border: 2px solid var(--color-text-inverted);
  border-radius: 100%;
  z-index: 5;
}

.icon:nth-child(2),
.icon:nth-child(3),
.icon:nth-child(4),
.icon:nth-child(5) {
  margin-inline-start: -5px;
}

.icon:nth-child(2) {
  z-index: 4;
}

.icon:nth-child(3) {
  z-index: 3;
}

.icon:nth-child(4) {
  z-index: 2;
}

.icon:nth-child(5) {
  z-index: 1;
}
