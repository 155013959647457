.root {
  display: flex;
  gap: var(--spacing-04);
  align-items: flex-end;
}

.inputField {
  flex-grow: 1;
}

.copyBtn {
  flex-grow: 0;
  flex-shrink: 0;
}
