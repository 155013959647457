.root {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: auto;
  justify-content: space-between;
  width: 100%;
}

.priorityTypeIcon {
  margin-inline-end: var(--spacing-04);
}

.label {
  font-weight: bold;
}

.cta {
  margin-inline-start: auto;
}

.suggestedRejectedContainer {
  width: inherit;
  display: flex;
}

.suggestedSecondary {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.suggestedSlideInTrigger {
  position: relative;
  height: 24px;
}

.suggestedSlideInTrigger svg {
  color: var(--color-icon-decorative);
}

.suggestedSecondary button {
  font-size: var(--font-size-2xs);
}

.suggestedSecondary .cta {
  display: flex;
  gap: var(--spacing-04);
}

.rejectedSecondary {
  display: flex;
  align-items: center;
  font-size: var(--font-size-2xs);
  margin-inline-start: rem(8px);
  width: 100%;
  justify-content: space-between;
}

.rejectedSecondary div:first-of-type {
  display: flex;
  align-items: center;
}

.rejectedSecondary > div:first-of-type svg {
  width: 16px;
  height: 16px;
}

.rejectedSecondary svg {
  margin-inline-end: 4px;
}

.statusPrimary,
.statusSecondary {
  display: flex;
  align-items: center;
}

.statusPrimary {
  font-weight: bold;
}

.statusPrimary svg {
  margin-inline-end: 12px;
}

.statusPrimary svg.statusGrey {
  color: var(--color-text-tertiary);
}

.statusSecondary {
  flex: 3;
  margin-inline-start: rem(4px);
}

.statusSuggested,
.statusRejectedName strong {
  font-weight: var(--font-weight-bold);
}

.statusWhyTooltip ul {
  margin: 0;
  padding-inline: 20px 0;
  padding-block: 0 10px;
  font-size: var(--font-size-2xs);
  color: var(--color-text-secondary);
}

.statusWhyTooltip strong {
  font-weight: var(--font-weight-bold);
}

.statusWhyTooltip a {
  width: 100%;
}

@media (--handhelds) {
  .root {
    flex-direction: column;
  }

  .cta {
    margin-inline-start: 0;
    margin-block-start: rem(8px);
    width: 100%;
  }

  .suggestedSecondary button {
    margin-inline-start: unset;
    flex: 1;
  }

  .priorityTakeAction {
    height: 100%;
    padding-inline: 14px;
    padding-block: 12px;
  }

  .storiesContainer ul {
    padding-inline-start: 24px;
  }

  .story {
    width: 100%;
  }

  .suggestedRejectedContainer {
    height: 100%;
    flex-direction: column;
    padding-inline: 0 0;
    padding-block: 8px 8px;
  }

  .suggestedSecondary {
    flex-wrap: wrap;
    display: flex;
    padding: 12px;
  }

  .statusPrimary {
    border-block-end: 1px solid var(--color-border);
    padding: 12px;
    max-width: none;
  }

  .statusSecondary {
    padding-inline-start: unset;
    padding-inline-end: unset;
  }

  .rejectedSecondary {
    padding: 12px;
    display: flex;
    flex-wrap: wrap;
  }

  .rejectedDate,
  .rejectedButton {
    width: 100%;
  }

  .rejectedDate {
    margin-block-end: 12px;
  }

  .priorityChangeContainer {
    padding: 12px;
  }

  .priorityPrimary svg {
    margin-block-start: -1px;
  }
}

@media (--tablets) {
  .statusPrimary {
    width: 50%;
  }
}
