.title {
  max-width: 180px;
  margin-block-end: 0;
  line-height: 1;
}

.subtitle {
  color: var(--color-text-secondary);
  font-weight: var(--font-weight-bold);
}

.secondary .subtitle {
  color: var(--color-text);
}

h3.secondary {
  font-size: var(--font-size-sm);
}
