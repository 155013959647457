.root {
  align-items: center;
  color: var(--color-text-tertiary);
  display: flex;
  font-size: var(--font-size-2xs);
  text-transform: uppercase;
  font-weight: var(--font-weight-bold);
}

.indicator {
  background-color: var(--color-status-negative);
  border-radius: 100%;
  margin-inline-end: rem(8px);
}

.active .indicator {
  background-color: var(--color-status-positive);
}

.medium .indicator {
  height: 8px;
  width: 8px;
}

.small .indicator {
  height: 4px;
  width: 4px;
}
