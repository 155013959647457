.statusToggle {
  position: relative;
  inset-block-start: 18px;
  inset-inline-end: 40px;
  height: 0;
}

.header {
  font-weight: var(--font-weight-bold);
}

.collapsibleHeader {
  padding: var(--spacing-12) var(--spacing-16);
}

.collapsibleContent {
  padding-inline: var(--spacing-16) !important;
}

.collapsibleContent.expanded {
  border-block-start: 1px solid var(--color-border);
}

.footer {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-block-start: 1px solid var(--color-border);
  padding-inline: 12px;
  padding-block: 12px 0;
  text-align: end;
}

.link {
  font-size: var(--font-size-2xs);
  text-transform: uppercase;
  font-weight: var(--font-weight-bold);
}

.content {
  border-radius: 0;
  padding-inline: 56px;
  padding-block: 0 16px;
}

.item {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-inline: 0;
  padding-block: 13px;
}

.item:last-child {
  padding-block-end: 17px;
}

.column {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: center;
}

.column.labels {
  align-items: flex-start;
  margin-block-end: 10px;
  padding-inline-end: 10px;
  gap: var(--spacing-04);
}

.spinner {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 90px;
  color: var(--color-status-highlight);
}

.warningContainer {
  margin: var(--spacing-16) 0;
}

@media (--large-screens) {
  .collapsibleContent {
    padding-inline: 56px !important;
    padding-block: 0 !important;
  }

  .item {
    flex-direction: row;
  }

  .question .item {
    flex-direction: column;
  }

  .column {
    width: 50%;
  }

  .column.labels {
    justify-content: flex-start;
    margin-block-end: 0;
    width: calc(50% - 10px);
  }

  .collapsibleContent.question,
  .collapsibleContent.question.expanded {
    padding: 0 !important;
  }
}
