.actions {
  display: flex;
  flex-basis: auto;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: flex-end;
  margin-inline-end: var(--spacing-16);
  gap: var(--spacing-08);
}

.action {
  display: none;
  flex-direction: column;
  margin-block: 0;
}

@media (--tablets-min) {
  .action {
    display: flex;
  }
}
