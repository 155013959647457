.breadcrumbs {
  display: none;
}

@media (--tablets-min) {
  .breadcrumbs {
    display: flex;
  }
}

.errorContainer {
  margin-block-start: 15%;
}
