.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* fallback for browsers that don't support dvh */
  height: 100dvh;
}

.container {
  display: grid;
  grid-template-rows: 4rem auto;
  height: 100vh; /* fallback for browsers that don't support dvh */
  height: 100dvh;
}

.header {
  display: flex;
  gap: 16px;
  align-items: center;
  padding-inline: 24px;
  background-color: var(--color-bg-card);
  border-block-end: 1px solid var(--color-border);
}

.separator {
  border-inline-end: 1px solid var(--color-border);
  align-self: stretch;
}

.content {
  display: flex;
  gap: var(--spacing-16);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 420px;
  margin: auto;
}
