.bannerActions {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: var(--spacing-16);
}

.illustration {
  width: 100%;
  height: auto;
}
