.accessRegions {
  margin-block-start: var(--spacing-32);
}

.accessRegions label {
  align-items: center;
  display: flex;
  font-weight: var(--font-weight-bold);
  margin-block-end: var(--spacing-16);
}
