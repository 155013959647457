:root {
  --border-style: 1px solid var(--color-border);
}

.root {
  width: 100%;
  height: 100%;
}

.root > *:not(:last-child) {
  border-block-end: var(--border-style);
}

.graphAndScore {
  height: rem(156px);
  display: flex;
}

.graphAndScore .graph {
  width: calc(100% - rem(115px));
}

.graphAndScore .score {
  width: rem(115px);
  border-inline-start: var(--border-style);
}

.participation {
  padding: var(--spacing-08);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-bold);
  text-align: center;
}

.scoreBars {
  padding: var(--spacing-16);
}

.valuesContainer {
  padding-block-start: var(--spacing-16);
}
