.container {
  display: flex;
  align-items: center;
  min-width: 0;
  max-width: 100%;
}

.icon {
  flex-shrink: 0;
}

.small .icon {
  height: 14px;
  width: 14px;
}

.name {
  color: var(--color-text);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: underline;
}

.small .name {
  font-size: var(--font-size-3xs);
  margin-inline-start: 5px;
  color: var(--color-text-primary);
}

.medium .name {
  font-size: var(--font-size-sm);
  margin-inline-start: 16px;
  font-weight: var(--font-weight-bold);
}

@media (--handhelds) {
  .name {
    margin-inline-start: 6px;
  }
}
