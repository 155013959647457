.modal {
  min-width: 660px;
}

.slides {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.slidesWrapper {
  align-items: center;
  display: flex;
  height: 100%;
  transition: transform 0.6s cubic-bezier(0.65, 0.05, 0.36, 1);
  width: 200%;
}

[dir='rtl'] .slidesWrapper {
  transform: translate3d(50%, 0, 0);
}

[dir='rtl'] .slidesWrapper.step2 {
  transform: translate3d(0%, 0, 0);
}

.slidesWrapper.step2 {
  transform: translate3d(-50%, 0, 0);
}

.slide {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 0 0 50%;
  height: 100%;
  justify-content: center;
  padding-inline: 0;
  padding-block: 38px;
}

.slide svg {
  max-width: 100%;
}

.title,
.text {
  text-align: center;
}

.title {
  font-size: var(--font-size-lg);
  max-width: 420px;
  font-weight: var(--font-weight-bold);
}

.text {
  font-size: var(--font-size-sm);
  margin-block-start: 17px;
  max-width: 420px;
}

.options {
  margin-block-start: 38px;
  width: 100%;
}

@media (--handhelds) {
  .modal {
    min-width: 100%;
  }
}
