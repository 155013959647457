.content {
  align-items: center;
  display: flex;
  padding-inline: 0;
  padding-block: 0 20px;
}

.score,
.benchmark {
  flex: 1;
}

.benchmark {
  margin-block-start: -3px;
}

.placeholder {
  margin-inline-end: 20px;
  max-width: 190px;
}

@media (--handhelds) {
  .score {
    padding-inline-end: 40px;
  }

  .score.simple {
    font-size: rem(75px);
  }

  .benchmark {
    flex: 2;
  }

  .changes,
  .tier {
    display: none;
  }
}

@media (--intermediate) {
  .score.simple {
    font-size: rem(110px);
  }
}

@media (--large-screens) {
  .changes {
    flex: 0 0 300px;
  }
}
