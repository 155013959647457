.root {
  list-style: none;
  padding: 0;
  margin: 0;
}

.item {
  display: flex;
  align-items: center;
  height: rem(28px);
}

.item a {
  color: var(--color-text-primary);
}

.name {
  font-size: var(--font-size-xs);
  flex: 1;
  padding-inline-end: rem(50px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.score {
  font-size: rem(11px);
  font-weight: var(--font-weight-bold);
  margin-inline-end: var(--spacing-04);
}

.diff {
  width: rem(28px);
}

.scoreBar {
  margin-inline-end: var(--spacing-08);
}
