/**
 *
 * Modal
 *
 */

.commentsModal {
  --extended-comments-modal-max-height: 500px;

  margin: var(--spacing-16);
  width: calc(100vw - var(--spacing-16) * 2);

  /**
   *
   * IMPORTANT: Overriding the default modal styles
   *
   * The `Modal` sets `text-align: left` as an inline style so we
   * have to override it here.
   *
   */

  text-align: start;

  /**
   *
   * Arbitrary max-width from the design
   *
   */

  max-width: 896px;
}

.modalHeader {
  border-block-end: 0;
  padding-block-end: 0;
}

.modalContent {
  padding: 0;
}

/**
 *
 * Tabs
 *
 */

.tablist {
  padding-inline: var(--spacing-16);
}

/**
 *
 * Content
 *
 */

.container {
  overflow: auto;

  /**
   *
   * IMPORTANT: REQUIRED MAX-HEIGHT
   *
   * We need a max-height here to avoid the modal growing beyond the height of
   * the `perPage` items size in the table. If the table scroller is taller than
   * that, virtuoso doesn't seem to call the `endReached` multiple times, so we
   * never load the next pages :(
   *
   */

  height: 70vh;
  max-height: var(--extended-comments-modal-max-height);
  display: flex;
  flex-direction: column;
  border-block-start: 1px solid var(--color-border);

  /**
   *
   * Avoid the overflowing table corners bleeding out
   *
   */

  border-bottom-right-radius: var(--border-radius-card);
  border-bottom-left-radius: var(--border-radius-card);
}

@media (--desktop) {
  .container {
    flex-direction: row;
  }
}

.content {
  /**
   *
   * Allow for the spinner to be centered
   *
   */

  position: relative;
  flex: 1 1 80%;
  min-width: 300px;
}

/**
 *
 * Filter
 *
 */

.filter {
  padding: var(--spacing-16);
}

.sidebar {
  display: none;

  /**
   *
   * Arbitrary width from the design
   *
   */

  flex: 0 1 264px;
  border-inline-end: 1px solid var(--color-border);
}

@media (--desktop) {
  .sidebar {
    display: block;
  }
}

.details {
  border-block-end: 1px solid var(--color-border);
}

.summary {
  display: flex;
  align-items: center;
  padding: var(--spacing-16);

  /**
   *
   * Remove the arrow
   *
   */

  list-style: none;
}

/**
 *
 * Remove the arrow
 *
 */

.summary::-webkit-details-marker {
  display: none;
}

.summaryArrow {
  transform-origin: center;
  transition: transform 0.25s;
}

.details[open] .summaryArrow {
  transform: rotate(90deg);
}

/**
 *
 * We have to style the tag here to fix the gap below the svg :/
 *
 */

.summaryArrow svg {
  display: block;
}

.detailsContent {
  border-block-start: 1px solid var(--color-border);
}

.summary,
.detailsContent {
  /**
   *
   * IMPORTANT: https://stackoverflow.com/questions/75347448/box-sizing-not-inherited-through-details-element
   *
   * Address issues with `box-sizing` in details
   *
   */

  box-sizing: border-box;
}

@media (--desktop) {
  .details {
    display: none;
  }
}

/**
 *
 * Graph
 *
 */

.graphContainer {
  padding: var(--spacing-16);
}

@media (--desktop) {
  .graphContainer {
    padding: var(--spacing-24);
  }
}

.paginationButtonNext,
.paginationButtonPrevious {
  display: flex;

  /**
   *
   * Make sure the buttons take up the same space so alignment with the interval matches
   *
   */

  flex: 1 0 1px;
}

.paginationButtonPrevious {
  justify-content: flex-start;
}

.paginationButtonNext {
  justify-content: flex-end;
}

.timeIntervalMobile {
  flex: 0 0 100%;
}

@media (--desktop) {
  .timeIntervalMobile {
    display: none;
  }
}

.timeIntervalDesktop {
  display: none;
}

@media (--desktop) {
  .timeIntervalDesktop {
    display: block;
    flex-grow: 2 0 1px;
    text-align: center;
  }
}

/**
 *
 * Table
 *
 */

.tableScroller {
  /**
   *
   * We need an actual height here for Virtuoso to work
   *
   */

  height: 70vh;
  max-height: var(--extended-comments-modal-max-height);

  /**
   *
   * Avoid the table scroll shadows bleeding out
   *
   */

  overflow: hidden;
}

/**
 *
 * We don't want to have to create an extra component just to pass it to Virtuoso, so we style
 * on the table element directly.
 *
 */

.tableScroller table {
  width: 100%;
}

.tableScroller tbody tr:nth-child(odd) th,
.tableScroller tbody tr:nth-child(odd) td {
  background-color: var(--color-table-row-alt);
}

.noResults {
  padding: var(--spacing-24) var(--spacing-16);
}

.theadTr {
  background-color: var(--color-table-header);
}

.th {
  padding: var(--spacing-08) var(--spacing-16);
  position: relative;
}

/**
 *
 * NOTE: Fake borders to avoid flickering (https://stackoverflow.com/a/45042852)
 *
 * When scrolling with a sticky table header the borders of the th elements flicker
 *
 */

.th::after {
  content: '';
  position: absolute;
  width: 1px;
  inset-block: 0;
  inset-inline-start: 100%;
  transform: translateX(-50%);
  background-color: var(--color-border);
}

.th::before {
  content: '';
  position: absolute;
  inset-inline: 0;
  inset-block-end: -1px;
  height: 1px;
  background-color: var(--color-border);
}

.th:last-child::after {
  display: none;
}

.td {
  padding: var(--spacing-08) var(--spacing-16);
  border: 1px solid var(--color-border);
  text-align: start;
  background-color: var(--color-table-row);
}

.td:first-child,
.td:last-child {
  border-inline-start: 0;
}

.td + .td {
  text-align: end;
}

.th,
.td {
  white-space: nowrap;
}

.tr:nth-child(even) .td {
  background-color: var(--color-table-row-alt);
}

/**
 *
 * Scroll shadows
 *
 */

.scrollShadowTop,
.scrollShadowBottom {
  transition: box-shadow 0.125s;
}

.scrollShadowTop {
  box-shadow: 0 2px 2px 2px transparent;
}

.scrollShadowTop.scrollShadowShow {
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.05);
}

.scrollShadowBottom {
  box-shadow: 0 4px 4px 4px transparent;
  position: relative;
  z-index: 1;
}

.scrollShadowBottom.scrollShadowShow {
  box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.25);
}

/**
 *
 * Loading and error states
 *
 */

.spinner,
.error {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.spinner {
  /**
   *
   * Keep the spinner above the content when fetching after initial load
   *
   */

  position: absolute;
  inset: 0;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.5);
  color: var(--color-status-highlight);
}

.error {
  text-align: center;
  padding-block-end: var(--spacing-32);
}

.error svg {
  max-width: 100%;
}
