.root {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: row;
}

.container {
  padding-inline: 20px 20px;
  padding-block: 10px var(--spacing-40);
}

.section {
  margin-block-start: 30px;
}

.subSection {
  margin-block-start: 20px;
}

.titleLabel {
  font-size: var(--font-size-2xs);
  margin-block-end: 10px;
  font-weight: var(--font-weight-bold);
}

.titleContainer {
  margin-block-end: 10px;
}

.section :global(.peak-form-text) {
  background-color: var(--color-controls-bg);
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  font-weight: 600;
  flex-wrap: wrap;
}

.toggleContainer {
  height: var(--spacing-32);
  display: flex;
  flex-direction: row;
  font-size: var(--font-size-2xs);
  align-items: center;
  align-self: center;
}

.publishLabel {
  margin-inline: 10px 0;
  margin-block: 0 0;
}

.collapsibleHeader {
  background: var(--color-bg-card);
  padding: 0;
  outline: none;
  position: relative;
  cursor: pointer;
  z-index: 0;
}

.collapsibleHeader::after {
  box-shadow: 0 0 var(--spacing-04) 0 rgba(0, 0, 0, 0.2);
  content: '';
  height: 100%;
  inset-inline-start: 0;
  opacity: 0;
  position: absolute;
  inset-block-start: 0;
  transition: opacity 0.2s;
  width: 100%;
}

.collapsibleHeader:hover::after {
  opacity: 1;
  z-index: 1;
}

.collapsibleSectionHeader {
  width: 100%;
}

.collapsibleIcon {
  position: absolute;
  inset-inline-end: 0;
  z-index: 2;
  transition: transform 0.3s;
  padding-inline: 15px 15px;
  padding-block: 0 0;
}

.optional {
  font-size: var(--font-size-2xs);
  margin-block-end: 10px;
}

@media (--handhelds) {
  .footer {
    align-items: flex-end;
    justify-content: flex-end;
  }

  .toggleContainer {
    margin-block-start: var(--spacing-16);
  }
}
