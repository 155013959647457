@import '../../../styles/animations.css';

.root {
  background-color: var(--color-bg-card);
  border-radius: 100px;
  border: solid 1px var(--color-border);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  max-height: none;
  min-width: 0;
}

.root::after {
  border-color: transparent transparent var(--color-bg-card)
    var(--color-bg-card);
  border-style: solid;
  border-width: 4px;
  inset-block-end: -8px;
  box-shadow: -1px 1px 1px 0 rgba(0, 0, 0, 0.1);
  content: '';
  height: 0;
  inset-inline-start: 28px;
  position: absolute;
  transform-origin: 0 0;
  transform: rotate(-45deg);
  width: 0;
}

.root > ul {
  align-items: center;
  display: flex;
  max-width: none;
  overflow: initial;
  padding: 6px;
  margin-inline-start: 6px;
}

.item {
  animation-name: zoom;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  margin-inline-end: 12px;
  opacity: 0;
}

.item:hover,
.item:focus {
  z-index: 2;
}

.item:last-child {
  margin-inline-end: 0;
}

.item:nth-child(2) {
  animation-delay: 0.05s;
}

.item:nth-child(3) {
  animation-delay: 0.06s;
}

.item:nth-child(4) {
  animation-delay: 0.08s;
}

.item:nth-child(5) {
  animation-delay: 0.1s;
}
