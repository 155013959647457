.root {
  align-items: center;
  display: flex;
  font-size: var(--font-size-2xs);
  font-family: var(--font-family-base);
  color: var(--color-text-tertiary);
  margin-inline: -5px 0;
  margin-block: 0 0;
  cursor: help;
}

.time {
  align-items: center;
  display: flex;
  flex: 1;
}

.time:first-child {
  margin-inline-end: 5px;
}

.icon path {
  fill: var(--color-text-tertiary);
}

.tooltip {
  width: rem(350px);
}

.tooltipSmall {
  width: rem(200px);
}
