.driver {
  padding-inline: 24px;
  padding-block: 16px;
  display: flex;
  flex-direction: row;
  border-block-end: 1px solid var(--color-border);
}

.driverIcon {
  margin-inline-end: 16px;
}

.question {
  padding-inline: 0 14px;
  padding-block: 8px 8px;
  margin-inline-start: 23px;
  text-decoration: none;
  color: var(--color-text-secondary);
  display: flex;
  flex-direction: column;
}

.question:hover {
  background-color: var(--color-controls-hover-bg);
  margin-inline-start: 0;
  text-decoration: none;
  color: var(--color-text-primary);
  border-inline-start: 23px solid var(--color-controls-hover-bg);
}

/** Because we dont want the last driver question to have a border. **/
.questionResult > .question:last-of-type {
  border-block-end: none;
}

.questionContent {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.questionSubdriverName {
  padding-block: 6px 3px;
  display: none;
  font-size: var(--font-size-base);
  color: var(--color-text-tertiary);
  font-weight: var(--font-weight-bold);
}

.questionScore {
  width: 40px;
  margin-inline: 16px;
  margin-block-start: 2px;
  text-align: end;
}

.questionDetailArrow {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.questionText {
  flex: 1;
  margin-block-start: 2px;
}

.subdriversWrapper {
  border-block-start: 1px solid var(--color-border);
  margin-inline-start: 23px;
}

.subdriversWrapperBorderless {
  border-block-start: none;
}

.subdriver {
  display: flex;
}

/** Because we dont want the last subdriver question to have a border. **/
.subdriver:last-of-type .question:last-of-type {
  border-block-end: none;
}

.subdriverQuestionsWrapper {
  flex: 1;
}

.subdriverName {
  padding-block-start: 10px;
  width: 164px;
  font-size: var(--font-size-base);
  color: var(--color-text-tertiary);
  font-weight: var(--font-weight-bold);
}

[dir='rtl'] .questionArrow {
  transform: rotate(180deg);
}

@media (--handhelds) {
  .subdriverName {
    display: none;
  }

  .subdriverQuestion {
    margin-inline-start: 0;
  }

  .question:hover {
    background-color: transparent;
    border-inline-start-width: 0;
    border-inline-end-width: 0;
  }

  .question:not(.subdriverQuestion):hover {
    margin-inline-start: 23px;
  }

  .questionScore {
    margin-inline: 0;
    text-align: end;
  }

  .questionSubdriverName {
    display: block;
  }
}
