.gridContainer {
  padding-inline: var(--spacing-16);
}

.smsEnabledDescription p {
  padding-block-start: var(--spacing-16);
}

.content {
  padding-block-start: var(--spacing-4);
  padding-inline: var(--spacing-16);
}

.link {
  margin-block-start: var(--spacing-16);
}

.smsDescription {
  padding-block: var(--spacing-16);
}
