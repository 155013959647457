.titleWrapper {
  text-align: center;
  margin-block-end: var(--spacing-16);
}

.segmentContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-04);
}

.descriptionWrapper {
  margin: var(--spacing-24);
  text-align: center;
}
