.root {
  align-items: flex-start;
  background-color: var(--color-controls-bg);
  border: solid 1px var(--color-controls-border);
  border-radius: var(--border-radius-md);
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06);
  color: var(--color-text-tertiary);
  display: flex;
  padding-inline: 16px;
  padding-block: 10px;
}

.root.withAlert {
  margin-block-start: var(--spacing-08);
}
