.content {
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: space-between;
  opacity: 1;
  overflow-y: auto;
  transition: opacity 0.5s;
  width: 100%;
}

.content.saving {
  opacity: 0.5;
}

.content.dirty {
  /* Why margin and not bottom? IE. */
  margin-block-end: 75px;
}
