.table {
  border-collapse: separate;
  font-variant-numeric: tabular-nums;
  width: 100%;
}

.table td,
.table th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: var(--color-table-row);
}

.tableHead tr th {
  text-align: start;
  font-weight: bold;
  padding: var(--spacing-16);
  border-block-end: 2px solid var(--color-table-border);
}

.tableBody tr td {
  padding-inline: var(--spacing-16);
  padding-block: var(--spacing-04);
}

.tableBody tr td.expandButtonContainer {
  width: 1%;
  padding: var(--spacing-04);
  inset-inline-end: 0;
  position: sticky;
}

.highlight .tableBody tr:hover td:not(.expandedContentContainer td) {
  background-color: var(--color-table-row--hover);
}

tr.expandedContentContainer > td {
  padding: 0;
  box-shadow:
    inset 0 20px 10px -24px rgba(0, 0, 0, 0.55),
    inset 0 -20px 10px -25px rgba(0, 0, 0, 0.55);
}

.striped > tbody > tr:nth-child(odd):not(.expandedContentContainer) td {
  background-color: var(--color-table-row-alt);
}

.bordered > tbody > tr:not(:first-child):not(.expandedContentContainer) td {
  border-block-start: 1px solid var(--color-table-border);
}

.condensed .tableHead tr th {
  padding-block: var(--spacing-08);
}

.condensed .tableBody tr td {
  padding-block: rem(2px);
}
