.wrapper {
  position: relative;
}

.root {
  align-items: center;
  background-color: var(--color-bg-card);
  border-radius: 2px;
  border: 1px dashed var(--color-controls-border);
  display: flex;
  min-height: 208px;
  justify-content: center;
  position: relative;
  text-align: center;
  transition: ease all 0.15s;
  width: 100%;
  padding: var(--spacing-24);
}

.root.dragging {
  border: 1px dashed var(--color-controls-hover-border);
  box-shadow: inset 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

.root.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.progress {
  width: 64px;
  height: 64px;
  margin-inline: auto;
  margin-block: 0 15px;
}

.circularProgressBarPath {
  stroke: var(--color-status-highlight);
  transition: ease all 0.15s;
}

.circularProgressBarTrail {
  stroke: var(--color-viz-neutral-fill);
}

.illustration {
  display: flex;
  justify-content: center;
  margin-block-end: 15px;
}

.root:focus-within {
  box-shadow: var(--focus-base);
  outline: var(--focus-outline);
}

.inputFile {
  cursor: pointer;
  position: absolute;
  inset: 0;
  opacity: 0;
}

.browse p {
  font-size: var(--font-size-md);
  color: var(--color-text-primary);
  margin: 0;
}

.browse p:last-of-type {
  margin-inline: 0 0;
  margin-block: 10px 0;
}

.image,
.thumbnail {
  width: 248px;
  height: 140px;
}

.thumbnail {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.actions {
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;
  margin-inline: 0 15px;
  margin-block: 15px 0;
}

.actions > button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  box-shadow:
    0 1px 2px 0 rgba(0, 0, 0, 0.11),
    inset 0 -1px 1px 0 rgba(0, 0, 0, 0.09);
  background-color: var(--color-bg-card);
  cursor: pointer;
  transition:
    box-shadow 0.15s ease-in-out 0s,
    transform 0.15s ease-in-out 0s,
    background-color 0.15s ease-in-out 0s;
}

.actions > button:first-of-type {
  margin-block-end: 5px;
}

.error {
  margin-block-end: 10px;
}

@media (--handhelds) {
  .root,
  .image,
  .thumbnail {
    width: 100%;
  }
}
