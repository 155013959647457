.root {
  align-items: center;
  background-color: var(--color-bg-error);
  border: solid 1px rgba(255, 85, 0, 0.2);
  border-radius: 2px;
  display: flex;
  height: 99px;
  padding: 15px;
  flex-basis: 75%;
}

.content {
  display: flex;
  flex: 1;
}

.icon {
  padding-inline: 8px 30px;
  padding-block: 20px 0;
}

.attributes {
  align-items: center;
  display: flex;
  width: 100%;
}

.attributes.spaceAround {
  justify-content: space-around;
}

.attributes > div {
  flex-basis: calc(50% - 20px);
}

.attributes > div:last-child {
  padding-inline: 20px 0;
}

@media (--handhelds) {
  .root {
    width: 100%;
  }
}

@media (--handhelds-tablets) {
  .root {
    flex-basis: 100%;
  }
}
