:root {
  --comment-score-width: 68px;
}

.container {
  /* make sure it has higher stacking context than the feedback footer */
  position: relative;
}

.container.disabled {
  opacity: 0.4;
}

.banner {
  display: flex;
  align-items: center;
  padding: var(--spacing-12) var(--spacing-16);
  border-block-end: 1px solid var(--color-border);
}

.header {
  padding: var(--spacing-16);
  display: grid;
  grid-template-columns: min-content auto min-content;
  gap: var(--spacing-08) var(--spacing-12);
  grid-template-areas:
    'icon question dropdown'
    'info info     info';
}

@media (--tablets-min) {
  .header {
    gap: var(--spacing-04) var(--spacing-16);
    grid-template-areas:
      'icon question dropdown'
      'icon info     dropdown';
  }
}

.iconArea {
  grid-area: icon;
}

.questionArea {
  grid-area: question;
}

.dropdownArea {
  grid-area: dropdown;
}

.infoArea {
  grid-area: info;
}

.scoreContainer {
  flex: 0 0 var(--comment-score-width);
}

.footer {
  display: flex;
  align-items: center;
  gap: var(--spacing-08);
  flex-wrap: wrap;
  min-height: 50px;
  padding-inline: var(--spacing-16);
  padding-block: var(--spacing-08);
  border-block-start: 1px solid var(--color-border);
}

.scoreIcon {
  align-items: center;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: center;
  position: relative;
  width: 100%;
}

.scoreWrapper {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.score {
  display: flex;
  align-items: center;
  justify-content: center;

  /**
   *
   * HACK: The height and width here match the internal height of the smiley icon - NOT the height and width of the Icon component
   *
   */

  height: 2.75rem;
  width: 2.75rem;
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  line-height: 1;
  border-radius: 100%;
  background-color: var(--color-bg-info);
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
}

.score > div {
  transform: translate(1px, -2px);
}

.scoreIcon:hover .score {
  opacity: 1;
  user-select: none;
}

.permalink {
  display: block;
}

.permalink:hover,
.permalink:active,
.permalink:visited,
.permalink:focus {
  color: var(--color-text-tertiary);
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-20);
  padding-inline: var(--spacing-16);
  padding-block: 0 var(--spacing-16);
}

.questionValue {
  color: var(--color-status-highlight);
  font-weight: var(--font-weight-bold);
}

.translation {
  border-inline-start: 1px solid var(--color-border-section);
  padding-block: var(--spacing-04);
  padding-inline-start: var(--spacing-08);
}

.translationIcon {
  width: 1em;
  height: 1em;
  color: var(--color-icon-decorative);
}

.googleIcon {
  vertical-align: middle;
  display: inline-block;
}

.expandableCommentContent {
  padding-block-start: var(--spacing-16);
}

.translate {
  background-color: var(--color-bg-primary);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-card);
  padding: var(--spacing-08);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--spacing-08);
}

.translateText {
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 280px;
}

.info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: var(--color-text-tertiary);
  font-size: var(--font-size-xs);
  list-style-type: none;
  padding-inline-start: 0;
}

.infoItem {
  margin-inline-end: 8px;
  padding-inline-end: 10px;
  position: relative;
}

.infoItem.manager {
  margin-inline-start: -4px;
}

.infoItem::after {
  background-color: var(--color-text-tertiary);
  border-radius: 50%;
  content: '';
  height: 4px;
  position: absolute;
  inset-inline-end: 0;
  inset-block-start: calc(50% - 2px);
  width: 4px;
}

.infoItem:last-of-type::after {
  content: none;
}

@media (--handhelds) {
  .scoreIcon {
    justify-content: flex-start;
  }
}
