.root {
  width: 680px;
}

.content {
  align-items: center;
  border-radius: 5px 5px 0 0;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  padding: 45px;
  text-align: center;
}

.image {
  display: block;
}

.titleContainer {
  margin-block: var(--spacing-48) var(--spacing-08);
}

.actions {
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: center;
}

.button {
  display: inline-block;
  min-width: 205px;
}

.link:hover,
.link:focus {
  color: var(--color-text-link);
  text-decoration: underline;
}

.loader {
  margin-block: 45px 9px;
  color: var(--color-status-highlight);
}
