.subtitle {
  font-weight: var(--font-weight-bold);
  margin-inline: 0 0;
  margin-block: 15px 5px;
}

.subtitle:first-of-type {
  margin-block-start: 5px;
}

.item {
  align-items: center;
  border-block-end: 1px solid var(--color-border);
  display: flex;
  height: 59px;
  padding: 5px;
}

.item.group {
  flex-grow: 1;
}

.item:last-of-type {
  border: none;
}

.item.disabled {
  color: var(--color-text-tertiary);
}

.editable:hover {
  box-shadow: inset 0 0 35px -12px var(--color-border);
}

.action {
  display: flex;
  flex-basis: 42px;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: flex-end;
  margin-inline-end: 15px;
}

.icon {
  align-items: center;
  display: flex;
  flex-basis: 51px;
  flex-grow: 0;
  flex-shrink: 0;
}

.loading {
  align-self: center;
  display: flex;
  padding-inline: 11px 16px;
  padding-block: 16px 16px;
}

.content {
  display: flex;
  flex-basis: auto;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: flex-start;
  padding-inline: 15px;
  padding-block: 0;
}

.title {
  align-items: center;
  display: inline-flex;
  font-size: var(--font-size-base);
  text-align: start;
  font-weight: var(--font-weight-bold);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
}

.item.disabled .title {
  color: inherit;
}

.warningContainer {
  margin-block-start: 15px;
}

.adminWarning {
  margin-block-end: 10px;
}

.active {
  margin: 0;
  text-transform: uppercase;
  color: var(--color-status-positive);
}
