.root {
  min-width: 748px;
  margin-block-end: 10px;
  width: 100%;
  border-radius: var(--border-radius-card);
  background-color: var(--color-bg-card);
}

.tableHeight {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

.table.expanded th {
  background-color: var(--color-bg-card);
  position: sticky;
  inset-block-start: calc(var(--spacing-24) * -1);
}

.table.expanded .headingSmall th {
  cursor: pointer;
  inset-block-start: var(--spacing-24);
}

.table.expanded .expand th {
  inset-block-start: 70px;
}

.table.expanded .expand th::after {
  box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.1);
  content: '';
  height: 100%;
  inset-inline-start: -5px;
  position: absolute;
  inset-block-start: 0;
  width: calc(100% + 10px);
  z-index: -1;
}

.table thead tr.expand {
  cursor: pointer;
  outline: 0;
  transition: background-color 0.2s ease;
}

.table thead tr.expand:focus {
  z-index: 2;
}

.table th,
.table td {
  box-shadow:
    inset 0 1px 0 var(--color-border),
    inset 1px 0 0 var(--color-border);
  padding: 15px;
  text-align: start;
}

[dir='rtl'] .table th,
[dir='rtl'] .table td {
  box-shadow:
    inset 0 1px 0 var(--color-border),
    inset -1px 0 0 var(--color-border);
}

.table th {
  font-weight: var(--font-weight-bold);
  word-break: break-word;
}

.table th:first-child,
.table td:first-child {
  box-shadow: inset 0 1px 0 var(--color-border);
}

.table .headingSmall th:first-child {
  box-shadow:
    inset 0 1px 0 var(--color-border),
    inset 1px 0 0 var(--color-border);
}

[dir='rtl'] .table .headingSmall th:first-child {
  box-shadow:
    inset 0 1px 0 var(--color-border),
    inset -1px 0 0 var(--color-border);
}

.table th.mainCell,
.table td.mainCell {
  padding-inline-start: 25px;
}

.table .headingSmall th.sortActive,
.table th.mainCell.sortActive {
  color: var(--color-controls--selected-marker);
}

.table th.mainCell {
  width: 260px;
}

.sortContainer {
  color: var(--color-text-tertiary);
  font-size: var(--font-size-2xs);
  position: relative;
  width: 100%;
}

.sortIndicator {
  position: absolute;
  inset-inline-end: -17px;
  inset-block-start: 50%;
  transform: translateY(-50%);
}

.table th.mainCell .sortIndicator {
  inset-inline-end: -10px;
}

.sortIndicator path {
  fill: var(--color-text-tertiary);
}

.mainCell:not(.sortActive):hover .sortIndicator path:first-child,
.sortIndicator.asc path:first-child {
  fill: var(--color-controls--selected-marker);
}

.headingSmall th:not(.sortActive):hover .sortIndicator path:last-child,
.sortIndicator.desc path:last-child {
  fill: var(--color-controls--selected-marker);
}

.table th.date {
  width: 250px;
}

.heading,
.headingSmall th {
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-bold);
}

.headingSmall th {
  padding-inline: 15px;
  padding-block: 8px;
}

.table th.value,
.table td.value {
  text-align: end;
}

.table th.value {
  font-weight: var(--font-weight-bold);
}

.table td.value.danger {
  color: var(--color-status-negative);
}

.table th.toggler {
  padding-inline: 0;
  padding-block: 8px 0;
  text-align: center;
  width: 48px;
  vertical-align: middle;
}

.table thead tr:first-child th.toggler {
  border-radius: 0 4px 0 0;
}

.table th.toggler.isLoading {
  padding: 0;
  vertical-align: middle;
}

.table th.toggler.isLoading > div {
  transform: scale(0.7);
}

.arrowDown {
  cursor: pointer;
  height: 28px;
  transition: transform 0.15s ease-in;
  width: 28px;
}

.arrowDown.expanded {
  transform: rotate(180deg);
}

.arrowDown path {
  fill: var(--color-text);
}

.row {
  outline: 0;
}

.row:nth-child(odd) {
  background-color: var(--color-table-row-alt);
}

.row:nth-child(even) {
  background-color: var(--color-table-row);
}

.animateContainer {
  border-radius: var(--border-radius-card);
}

.table .row td {
  font-size: var(--font-size-2xs);
}

.pagination {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-inline: 25px;
  padding-block: 16px 6px;
  font-weight: var(--font-weight-bold);
}

.paginationLink {
  position: relative;
  background-color: transparent;
  border: none;
  color: var(--color-text-primary);
  font-size: var(--font-size-base);
  padding: 0;
  transition: color 0.2s linear;
  font-weight: var(--font-weight-bold);
}

.paginationLink:hover {
  color: var(--color-text-secondary);
}

.table td.metricActions {
  padding: 0;
}

@media (--collapsed) {
  .root {
    min-width: auto;
  }

  .table th.mainCell {
    padding-inline-start: 10px;
    width: 160px;
  }

  .table th.mainCell,
  .table th.value {
    font-size: var(--font-size-2xs);
  }
}
