.container {
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 1;
  width: 100%;
}

.content {
  padding-inline: var(--spacing-16);
}

.content > div {
  margin-block-start: 20px;
}

.side {
  display: none;
  margin-block-end: 20px;
  padding-inline-end: 20px;
}

.group {
  margin-block-end: 16px;
}

.link {
  display: none;
  font-size: var(--font-size-2xs);
  font-weight: var(--font-weight-bold);
}

.drivers {
  flex: 2;
}

.footer {
  justify-content: flex-end;
}

@media (--tablets-min) {
  .link {
    display: inline;
  }

  .side {
    display: flex;
  }
}

@media (--desktop) {
  .content {
    padding-inline: 40px;
    padding-block: 40px 58px;
  }
}
