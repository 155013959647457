@import '../../../styles/animations.css';
@import '../../../styles/z-index.css';

/* FIXME: https://github.com/peakon/dashboard/issues/3249 */
.popover {
  box-shadow: none;
  /* stylelint-disable-next-line */
  margin-block: 1px 0 !important;
  z-index: var(--z-index-9--popover);
}

.popoverMenu {
  animation: pop-upwards 0.25s both linear;
  background-color: var(--color-bg-card);
  border: 0;
  border-radius: 4px;
  border-block-start: 0;
  border-block-end: 0;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.23);
  /* stylelint-disable-next-line */
  flex: 1 !important;
  margin-block-start: 2px;
}

.loader,
.empty {
  padding: 20px;
}

.loader {
  display: flex;
  justify-content: center;
}

.popoverContentContainer.inset {
  border-radius: 4px 4px 0 0;
  padding-inline: 0;
  padding-block: 8px;
  position: relative;
}

.popoverContentContainer.inset::before,
.popoverContentContainer.inset::after {
  content: '';
  height: 8px;
  inset-inline-start: 0;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.popoverContentContainer.inset::before {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08);
  inset-block-start: 0;
}

.popoverContentContainer.inset::after {
  box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.08);
  inset-block-end: 0;
}

.popoverContent {
  max-height: 300px;
  overflow-y: auto;
}

@media (min-height: 860px) {
  .popoverContent {
    max-height: 405px;
  }
}

@media (min-height: 1000px) {
  .popoverContent {
    max-height: 560px;
  }
}

.overflow .popoverContent {
  background-color: var(--color-bg-card);
}

.popoverContentContainer.hasValue {
  padding-block-end: 0;
}

.popoverContentContainer.hasValue::after {
  inset-block-end: -8px;
}

.popoverMenu.checkbox .popoverContent {
  padding-inline: 15px;
  padding-block: 10px;
}

.popoverItem {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  min-height: 48px;
  padding-inline: 24px;
  padding-block: 15px;
  position: relative;
  transform: translateZ(0);
  transition:
    background-color 100ms ease-in,
    box-shadow 100ms ease-in,
    color 100ms ease-in-out;
  z-index: 1;
}

.popoverItem.option {
  justify-content: flex-start;
}

.popoverItem::after {
  background-color: var(--color-border);
  inset-block-end: 0;
  content: '';
  height: 1px;
  inset-inline-start: 15px;
  position: absolute;
  width: calc(100% - 30px);
}

.driver .popoverItem::after,
.popoverItem:last-child::after {
  display: none;
}

.popoverItem:focus,
.popoverItem:hover {
  outline: 0;
  background-color: var(--color-controls--selected-bg);
}

.popoverItem:focus {
  box-shadow: inset 4px 0 0 -2px var(--color-controls-active-border);
}

.popoverItem.selected {
  box-shadow: inset 4px 0 0 -2px var(--color-controls-active-border);
  background-color: var(--color-controls--selected-bg);
}

.popoverFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 48px;
  padding: 15px;
}

.targetReset {
  /* stylelint-disable-next-line */
  padding: 0 !important;
  justify-content: flex-start;
}

.select {
  background-color: transparent;
  min-height: 40px;
  padding-inline: 0.5rem;
  padding-block: 0 0;
}

.select:focus {
  /* stylelint-disable-next-line */
  border-color: var(--color-controls-active-border) !important;
}

.select div {
  display: flex;
  align-items: center;
}

.select div[data-arrow=''] {
  flex-basis: 24px;
  height: 24px;
}

.select div[data-arrow=''] svg {
  height: initial;
  width: initial;
}

.select.selection {
  background-color: var(--color-controls--selected-bg);
}

.target {
  position: relative;
  align-items: center;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid var(--color-controls-border-default);
  cursor: pointer;
  display: inline-flex;
  height: 36px;
  justify-content: space-between;
  outline: none;
  padding-inline: 10px;
  padding-block: 0;
  transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s,
    background-color ease-in-out 0.15s;
  width: 100%;
}

.icon {
  margin-inline-end: 10px;
  height: 24px;
  width: 24px;
}

.target:hover {
  border-color: var(--color-controls-hover-border);
}

.target.open {
  border-color: var(--color-controls-active-border);
}

.target.open::after {
  content: '';
  position: absolute;
  inset: -1px;
  border: 2px solid var(--color-controls-active-border);
  pointer-events: none;
  border-radius: 4px;
}

.target.filters {
  background-color: var(--color-bg-card);
  border-color: var(--color-status-highlight);
}

.target:not(.open):not(.filters):focus::before,
.target:not(.open).filters:focus::before {
  content: '';
  position: absolute;
  inset: -5px;
  border: 2px solid var(--color-focus-outer);
  pointer-events: none;
  border-radius: 7px;
}

.targetContent {
  align-items: center;
  display: inline-flex;
  flex-grow: 1;
  font-size: var(--font-size-base);
  justify-content: flex-start;
  max-width: 200px;
  overflow: hidden;
}

.targetCount {
  opacity: 0.6;
}

.targetCount::before {
  content: '•';
  margin-inline: 3px;
  margin-block: 0;
}

.targetIcon {
  display: inherit;
  width: 24px;
  height: 24px;
}

.targetIcon path {
  fill: var(--color-text-primary);
}

.targetArrow {
  transform: rotate(0deg);
  transition: transform 300ms ease-in;
}

.target.open .targetArrow {
  transform: rotate(180deg);
}

.overlay {
  position: absolute;
  inset: 0;
  background-color: var(--color-border);
  opacity: 0.5;
  z-index: 30;
}

.target.disabled {
  opacity: 0.6;
  pointer-events: none;
  touch-action: none;
}

/** iPhone 5 and SE **/
@media screen and (max-width: 320px) {
  .popover {
    /* stylelint-disable-next-line */
    width: calc(100vw - 40px) !important;
  }
}
