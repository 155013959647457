.container {
  border-block-end: 1px solid var(--color-border);
}

.container:last-child {
  border-block-end: 0;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.ruleBasedQuestionsHeader,
.header,
.card {
  position: relative;
  transition: box-shadow 0.2s;
}

.card:not(.subcategory) + .card.subcategory {
  box-shadow: inset 0 3px 3px 0 rgba(0, 0, 0, 0.05);
}

.header:hover,
.card:hover {
  box-shadow: 0 2px 25px 0 rgba(44, 47, 55, 0.13) !important;
}

.header {
  align-items: center;
  border-block-end: 1px solid var(--color-border);
  display: flex;
  cursor: pointer;
}

.header,
.footer {
  padding: rem(16px);
}

.header .icon {
  align-items: center;
  display: flex;
  margin-inline-end: rem(16px);
}

.header:hover button {
  background-color: var(--color-bg-primary);
}

.title {
  flex: 2;
}

.translations {
  margin-block-start: 10px;
}

.empty {
  color: var(--color-text-tertiary);
  font-size: rem(11px);
  padding: 20px;
  text-align: center;
  text-transform: uppercase;
  font-weight: var(--font-weight-bold);
}

.footer {
  border-block-start: 1px solid var(--color-border);
  text-align: end;
}

.addQuestion {
  background-color: transparent;
  border: 0;
  color: var(--color-text-primary);
  cursor: pointer;
  display: inline-block;
  text-transform: uppercase;
  transition: color 0.2s;
  font-weight: var(--font-weight-bold);
}

.addQuestion::before {
  content: '+';
  margin-inline-end: 3px;
}

.addQuestion:hover {
  color: var(--color-text-secondary);
}

@media (--handhelds) {
  .header .icon {
    display: none;
  }
}
