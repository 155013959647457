.root {
  display: flex;
  align-items: flex-start;
}

.icon {
  flex: 0 0 51px;
  padding-inline-end: 33px;
}

.main {
  display: flex;
  font-size: var(--font-size-md);
}

.main,
.items {
  flex: 1;
  padding-inline: 0 33px;
  padding-block: 0 0;
}

.items {
  margin-block: 0 var(--spacing-16);
}

.item {
  display: flex;
  align-items: flex-start;
}

.item:not(:first-of-type) {
  margin-block-start: var(--spacing-16);
}

.bullet {
  background-color: var(--color-viz-neutral);
  border-radius: 6px;
  height: 6px;
  margin-inline: 0 var(--spacing-16);
  margin-block: 6px 0;
  min-width: 6px;
  width: 6px;
}

.bullet.positive {
  background-color: var(--color-viz-positive);
}

.bullet.negative {
  background-color: var(--color-viz-negative);
}

.description {
  flex: 1;
}

.main p,
.description p {
  margin: 0;
}

.root :global(.badge) {
  background-color: rgba(44, 47, 55, 0.45);
  border-radius: 10px;
  color: var(--color-text-inverted);
  font-size: var(--font-size-xs);
  line-height: 1.66;
  padding-inline: 7px;
  padding-block: 1px;
  text-align: center;
}

@media (--handhelds-tablets) {
  .root {
    flex-direction: column;
  }

  .main {
    margin-block-end: 15px;
  }

  .main,
  .items {
    padding-inline: 10px;
    padding-block: 0;
  }

  .items {
    margin-block-start: 15px;
  }
}
