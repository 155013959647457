.root {
  align-items: center;
  display: flex;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-bold);
}

.root.tiny {
  font-size: var(--font-size-2xs);
}

.root.large {
  font-size: var(--font-size-lg);
}

.avatar {
  margin-inline-end: 5px;
}

.root.tooltip .avatar {
  margin-inline-end: 15px;
}

.root.action {
  font-size: var(--font-size-2xs);
  font-weight: var(--font-weight-bold);
}

.root.slideIn {
  font-size: rem(22px);
  font-weight: var(--font-weight-bold);
}

.root.actionTitle {
  font-size: rem(21px);
  font-weight: var(--font-weight-bold);
}

.root.slideIn .avatar {
  margin-inline-end: 10px;
}

.root.notification {
  font-size: var(--font-size-base);
  width: 100%;
  font-weight: var(--font-weight-bold);
}

.notificationLabel {
  flex-direction: column;
  width: 100%;
}

.notificationName {
  display: flex;
  justify-content: space-between;
}

.actionTitleV2 {
  align-items: flex-start;
  flex-direction: column;
  margin-inline-start: 0;
  max-width: 100%;
}

.avatar.actionTitle {
  margin-block-end: 12px;
}

.avatar.notificationNoBadge {
  margin-block-end: 15px;
}

.root.tooltip .name {
  font-size: rem(13px);
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.badge {
  display: flex;
  margin-inline-start: 3px;
  overflow: hidden;
}

.badge.notification {
  margin-inline-start: 0;
}

.count::before {
  content: ' ';
}

.list .name {
  font-size: var(--font-size-base);
  margin-inline-start: 10px;
}

@media (--handhelds) {
  .root.large {
    font-size: var(--font-size-md);
  }
}
