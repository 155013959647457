.list {
  align-items: center;
  border-block-end: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  padding-inline: 16px;
  padding-block: 8px;
}

.list:last-child {
  border-block-end: 0;
}

.label {
  color: var(--color-text-tertiary);
  flex: 0 0 100px;
  font-size: rem(11px);
  padding-inline-end: 15px;
  font-weight: var(--font-weight-bold);
}

.segments {
  flex: 2;
  margin-inline: -8px;
  margin-block: -6px 0;
}

.segment {
  display: inline-block;
  padding-inline: 8px;
  padding-block: 6px 0;
  position: relative;
}

.segment::after {
  color: var(--color-text-tertiary);
  content: '+';
  font-size: rem(11px);
  line-height: 1;
  position: absolute;
  inset-inline-end: -3px;
  inset-block-start: 50%;
  transform: translateY(-30%);
  font-weight: var(--font-weight-bold);
}

.segment:last-child::after {
  display: none;
}

@media (--handhelds) {
  .list {
    align-items: flex-start;
    flex-direction: column;
  }

  .label,
  .segments {
    flex: 0 0 auto;
  }

  .segments {
    margin-inline: 0;
    margin-block: 0;
  }
}
