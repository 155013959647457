.root {
  margin-block-start: 30px;
  margin-inline-start: 10px;
  border-block-start: 1px solid var(--color-border);
  padding-block-start: 30px;
}

.btnContainer {
  padding-block-start: var(--spacing-08);
}

.slideInRoot label {
  margin-block-start: var(--spacing-20);
}
