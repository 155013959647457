.menu {
  color: var(--color-text-primary);
  max-width: 400px;
  width: 100%;
}

.menu:focus {
  outline: 0;
}

.menu .header {
  border-block-end: 1px solid var(--color-border);
  flex: 0 0 49px;
  max-height: 49px;
  padding: 15px;
}

.menu .empty {
  align-items: center;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: fadeIn;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding-inline: 20px;
  padding-block: 60px;
}

.menu .empty .emptyTitle {
  color: var(--color-text-tertiary);
  margin-block-start: 10px;
}

.loader {
  border-block-start: 1px solid var(--color-border);
  padding: var(--spacing-16);
  display: flex;
  justify-content: center;
}

.badge {
  align-items: center;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: rubberBand;
  background-color: var(--color-status-highlight-loud);
  border-radius: 100%;
  color: var(--color-text-inverted);
  display: flex;
  font-size: rem(8px);
  height: 16px;
  justify-content: center;
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: 50%;
  transform: translate(0, -100%);
  width: 16px;
  font-weight: var(--font-weight-bold);
}
