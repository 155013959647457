.root {
  flex: 1;
  position: relative;
  width: 100%;
}

.header {
  margin-block-end: 35px;
}

.container {
  margin-inline: auto;
  margin-block: 0;
  max-width: 1030px;
  padding-inline: 20px;
  padding-block: 0 20px;
}

.bodyTextContainer {
  max-width: 334px;
  text-align: center;
}

.questionSet {
  margin-block-end: 20px;
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
}

.driver {
  margin-block-end: var(--spacing-40);
}

.title {
  display: flex;
  flex-direction: row;
  margin-block-end: 20px;
  align-items: center;
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
}

.section {
  border-block-end: 1px solid var(--color-border-section);
  margin-block-end: var(--spacing-40);
}

.section:last-child {
  border-block-end: none;
}

.count {
  color: var(--color-text-tertiary);
}

.jumpTo {
  align-items: center;
  display: flex;
  height: 60px;
}

.jumpTo.disabled {
  opacity: 0.6;
  pointer-events: none;
  touch-action: none;
}
