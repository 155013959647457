.container label {
  display: block;
}

.container label > div {
  margin-block-start: var(--spacing-08);
}

.option {
  font-weight: initial;
}

.icon {
  min-width: 24px;
  margin-inline-end: var(--spacing-08);
  display: flex;
  justify-content: center;
  align-items: center;
}
