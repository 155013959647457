.root {
  display: flex;
  align-items: center;
}

.root.medium .icon,
.root.medium img {
  width: 24px;
  height: 24px;
}

.root.medium .driver {
  font-size: var(--font-size-sm);
}

.icon {
  margin-inline-end: 8px;
}

.driver {
  font-size: var(--font-size-xs);
}

.driver.dim {
  color: var(--color-text-secondary);
}

.highlight {
  color: var(--color-text-primary);
  font-weight: var(--font-weight-bold);
}
