.title {
  font-size: var(--font-size-2xs);
  margin-block-end: 10px;
  font-weight: var(--font-weight-bold);
}

.changes {
  margin-block-start: 15px;
}

.outcomes {
  margin-block-start: 20px;
}

.subdriver {
  align-items: center;
  display: flex;
  margin-block-end: 10px;
  font-weight: var(--font-weight-bold);
}

.subdriver:last-child {
  margin-block-end: 0;
}

.subdriverLabel {
  margin-inline-start: 10px;
}

.timeframe {
  padding-inline-end: var(--spacing-08);
}
