.card {
  max-width: 700px;
}

.cardHeader {
  display: flex;
  border-block-end: 1px solid var(--color-border);
  padding: var(--spacing-16);
}

.categorySection {
  display: flex;
  gap: var(--spacing-16);
}

.categoryIcon {
  flex-shrink: 0;
}

.formSection {
  display: grid;
  gap: var(--spacing-16);
}
