:root {
  --progress-border: 2px;
  --progress-background: rgba(250, 250, 250, 0.4);
  --progress-bar-color: var(--color-viz-graph-bg);
}

.container {
  background-color: var(--progress-background);
  border-radius: var(--progress-border);
  display: flex;
  flex: 1;
  height: 4px;
  margin: 6px;
}

.range {
  appearance: none;
  background-color: var(--progress-bar-color);
  background-size: 0;
  border-radius: var(--progress-border);
  cursor: pointer;
  margin: 0;
  outline: none;
  padding: 0;
  transition: width 300ms cubic-bezier(0, 0.79, 0.58, 1) 300ms;
  vertical-align: middle;
  width: 100%;
}
