.form {
  display: flex;
  flex-direction: column;
  margin-block-start: 30px;
  height: 120px;
  font-weight: var(--font-weight-bold);
}

.form :global(.peak-form-field) {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.sso button {
  width: 100%;
  margin-block-end: 25px;
}
