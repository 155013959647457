.container {
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  margin-inline: 0;
  margin-block: 5px 10px;
  padding: 10px;
  transition:
    background-color 0.2s,
    border-color 0.2s;
}

.container.notIn {
  background-color: var(--color-bg-error);
  border-color: var(--color-status-negative);
}

.container.in {
  background-color: var(--color-bg-card);
  border-color: var(--color-status-positive);
}

.types {
  margin-block-end: 5px;
  padding-inline: 0;
  padding-block: 10px;
  position: relative;
}

.input {
  margin-inline: 0;
  margin-block: 5px;
}
