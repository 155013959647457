.custom {
  margin-block-start: 10px;
}

.customBadge {
  color: var(--color-text-tertiary);
  display: inline-block;
  font-size: var(--font-size-2xs);
  margin-inline-start: 5px;
  text-transform: uppercase;
  font-weight: var(--font-weight-bold);
}
