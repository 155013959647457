.section {
  border-block-end: 1px solid var(--color-border);
  padding-inline: 0;
  padding-block: var(--spacing-24);
}

.infoCard {
  margin-block: var(--spacing-16);
}

.cardContainer {
  margin-block-end: var(--spacing-16);
}

.fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
