.root {
  align-items: flex-start;
  display: flex;
}

.icon {
  height: 24px;
  margin-inline-end: 5px;
}

.label {
  color: var(--color-text);
  font-size: var(--font-size-xs);
  margin-block-start: rem(4px);
}

.neutral {
  color: var(--color-status-warning);
}
