.root {
  display: flex;
  flex-direction: column;
  padding-inline: 16px;
}

.loader {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: var(--spacing-24);
}
