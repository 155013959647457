.score {
  background-color: var(--color-bg-info);
}

.score.circle {
  width: rem(40px);
  height: rem(40px);
  border-radius: 50%;
  display: inline-block;
  line-height: 2;
  text-align: center;
  font-size: var(--font-size-lg);
  flex: 0 0 auto;
}

.score.square {
  border-radius: 0.25rem;
  display: inline-block;
  text-align: center;
  font-size: var(--font-size-lg);
  width: rem(44px);
  height: rem(28px);
  line-height: 1.3;
}
