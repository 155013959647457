@import '../../../../../../styles/variables.css';

.editor {
  display: flex;
  flex: 1 1 auto;
  position: relative;
  /* hack for aligning react-virtuoso table at the bottom of the screen */
  margin-block-end: -3px;
}

.pageContainer {
  transition:
    max-height 300ms ease-in-out,
    padding 300ms ease-in-out;
}

.container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: auto;
}

.container.dirty {
  margin-block-end: 50px;
}

.header {
  color: var(--color-text-tertiary);
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: space-between;
}

.accessHeader {
  display: block;
  flex-shrink: 0;
  min-height: 0;
  padding-inline: 20px 20px;
  padding-block: 20px 0;
}

.subnav {
  flex-shrink: 0;
  min-height: 0;
  padding-inline-start: 20px;
}

.meta {
  align-items: center;
  display: flex;
  margin-block-start: 5px;
}

.headerCount {
  font-size: var(--font-size-2xs);
  line-height: 1;
  text-transform: uppercase;
  font-weight: var(--font-weight-bold);
}

.content {
  padding: 20px;
}

.separator {
  background-color: var(--color-border-section);
  border-radius: 100%;
  height: 2px;
  margin-inline: 5px;
  margin-block: 0;
  width: 2px;
}

.warning {
  margin-block-end: 24px;
}
