.root {
  display: flex;
  align-items: center;
}

.avatar {
  border: 0;
  width: rem(42px);
  height: rem(42px);
}

.avatar.small {
  width: rem(32px);
  height: rem(32px);
  font-size: var(--font-size-md);
}

.root .avatar {
  margin-inline-end: var(--spacing-08);
}

.info .companyName {
  color: var(--color-text-tertiary);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-normal);
  line-height: 1.1; /* to get the right spacing */
}

.info .employeeName {
  color: var(--color-text-primary);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
}
