.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media (--handhelds-tablets) {
  .root {
    margin-block-end: var(--spacing-32);
  }
}

.modal {
  min-height: 40rem;
  height: calc(100vh - (var(--spacing-64) * 2));
  width: calc(100vw - (var(--spacing-64) * 2));
  max-width: var(--app-max-width);
}

@media (--handhelds-tablets) {
  .modal {
    min-height: auto;
    height: calc(100vh - 2rem);
    width: calc(100vw - 2rem);
  }
}

.modalHeader {
  border-block-end: 0;
}

.modalTitle {
  font-size: var(--font-size-lg);
}

.modalContent {
  padding: 0;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  border-block-end: 1px solid var(--color-border-section);
  align-items: center;
  height: 3.75rem;
  padding-inline-start: var(--spacing-16);
}

.mainSection {
  border-block-end: 1px solid var(--color-border-section);
  display: flex;
  flex-direction: row;
  padding-block: var(--spacing-16);
}

.topPanel {
  margin-block: auto;
  padding-inline: var(--spacing-16);
}

.segmentsRanking {
  margin-block-end: var(--spacing-16);
}

.segmentsRanking > div {
  padding-inline: var(--spacing-16);
}

.segmentsRanking > div:first-child {
  border-block-end: 1px solid var(--color-border-section);
}

.cardPlaceholder {
  min-height: 18.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cardPlaceholder .title {
  padding-inline: var(--spacing-16);
}

.cardPlaceholder .loadingStatusPanel {
  padding-inline: var(--spacing-16);
}

.cardPlaceholder .loadingStatusPanel .heading {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-sm);
}

.loadingBarTrack {
  width: 100%;
  height: 0.5rem;
  border: 1px solid var(--color-viz-grid-line);
  position: relative;
  border-radius: var(--border-radius-base);
  margin-block-start: var(--spacing-08);
}

.loadingBar {
  height: 100%;
  width: 50%;
  border: 1px solid var(--color-viz-04);
  background: var(--color-viz-03);
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  border-radius: var(--border-radius-base);
  animation:
    fillFirstHalf calc(var(--duration) * 0.25),
    fillSecondHalf calc(var(--duration) * 0.75);
  animation-fill-mode: forwards;
  animation-delay: 0s, calc(var(--duration) * 0.25);
}

@keyframes fillFirstHalf {
  0% {
    width: 0;
  }

  100% {
    width: 50%;
  }
}

@keyframes fillSecondHalf {
  0% {
    width: 50%;
  }

  100% {
    width: 95%;
  }
}

.loadingBar.complete {
  width: var(--current-width);
  animation: fillToComplete var(--remaining-to-complete-duration) forwards;
}

@keyframes fillToComplete {
  from {
    width: var(--current-width);
  }

  to {
    width: 100%;
  }
}

.cardPlaceholder .footer {
  height: 3.75rem;
}

.loadingHintDisplay {
  position: relative;
  height: 1rem;
  overflow: hidden;
}

.loadingHint {
  position: absolute;
  inset-inline-start: 0;
  transform: translateY(-100%);
  transition: ease-in-out var(--hint-transition-duration);
  visibility: hidden;
  opacity: 0;
}

.loadingHint.show {
  transform: translateY(0%);
  visibility: visible;
  opacity: 1;
}

.loadingHint.show.leave {
  transform: translateY(100%);
  visibility: hidden;
  opacity: 0;
}

.loadingErrorAlert {
  display: flex;
  padding-inline: var(--spacing-16);
  align-items: center;
  gap: var(--spacing-08);
}
