.container {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  width: 100%;
  height: 100%;
}

.container.disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
}

.container.disabled:hover,
.container.disabled:focus {
  transform: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
}

.header {
  align-items: center;
  border-block-end: 1px solid var(--color-border);
  border-radius: var(--border-radius-base) var(--border-radius-base) 0 0;
  color: var(--color-text-heading);
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-md);
  padding-inline: 15px;
  padding-block: 20px;
  text-align: center;
  font-weight: var(--font-weight-bold);
}

.status {
  margin-block-start: calc(var(--spacing-04) / 2);
  color: var(--color-text-secondary);
  font-size: var(--font-size-2xs);
  font-weight: var(--font-weight-bold);
}

.status.connected {
  color: var(--color-status-positive);
}

.status.disabled {
  color: var(--color-status-negative);
}

.status.outdated {
  color: var(--color-text-disabled);
}

.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  text-align: center;
}

.logo {
  margin-inline: 0;
  margin-block: var(--spacing-16);
}
