.container {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.content {
  display: flex;
  flex: 2;
  flex-direction: column;
  margin-inline-start: 10px;
}

.description {
  display: block;
}
