.barWrapper {
  background-color: var(--color-score-neutral);
  border-radius: 5px;
  height: 4px;
  margin-block-end: 4px;
}

.small .barWrapper {
  border-radius: 2.5px;
  height: 2px;
  margin-block-end: 2px;
}

.isLoading .barWrapper {
  background-color: transparent;
}

.bar {
  border-radius: 5px;
  height: 100%;
  width: 100%;
}

.bar.fill {
  background-color: var(--color-status-highlight);
}

.barWrapper:last-child {
  margin-block-end: 0;
}

.isLoading .bar {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: loading;
  background-color: var(--color-bg-primary);
}

@keyframes loading {
  0% {
    opacity: 0.3;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.3;
  }
}
