.container {
  max-width: 1200px;
  padding: 32px;
  margin: 0 auto;
}

.container > div {
  overflow-x: auto;
}

@media (--handhelds) {
  .container {
    padding: var(--spacing-08);
  }
}
