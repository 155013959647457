.root {
  width: 100%;
  height: 78px;
}

.top {
  display: flex;
  align-items: center;
  height: 26px;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.driver {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding-inline-start: 10px;
}

.percentile {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  margin-inline-start: auto;
}

.score {
  padding-inline-start: 36px;
}
