.field {
  margin-block-start: var(--spacing-08);
}

.translations {
  margin-block-start: var(--spacing-16);
}

.title {
  font-weight: var(--font-weight-bold);
}
