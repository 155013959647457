.root {
  background-color: var(--color-benchmark-bg);
  border-radius: rem(2px);
  color: var(--color-benchmark-highlight);
  font-size: var(--font-size-3xs);
  font-weight: var(--font-weight-bold);
  min-width: rem(28px);
  padding-inline: rem(4px);
  padding-block: rem(1px);
  position: relative;
  text-align: center;
}

.root.compact {
  min-width: initial;
}

.root.positive {
  background-color: var(--color-benchmark-positive-bg);
  color: var(--color-score-positive);
}

[data-theme='deuteranopia'] .root.positive {
  background-color: var(--color-benchmark-positive-bg--deuteranopia);
  color: var(--color-score-positive--deuteranopia);
}

.root.negative {
  background-color: var(--color-benchmark-negative-bg);
  color: var(--color-benchmark-negative-highlight);
}

.score {
  position: relative;
}

.checkersBg {
  pointer-events: none;
  position: absolute;
  inset-block: 0;
  inset-inline: 0;
  opacity: 0.05;
}
