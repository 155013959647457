.divider {
  /* remove once bedrock divider has proper variants */
  --divider-color: var(--color-border);
}

.score {
  /* bespoke large score for true benchmark slide in */
  font-size: 3rem;
  color: var(--color-text-body);
  font-family: var(--font-family-numbers);
  font-variation-settings: 'fill' 100;
  line-height: 1;
}
