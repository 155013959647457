.root {
  margin-block-start: rem(40px);
}

.table {
  margin-block-start: rem(60px);
  width: 100%;
}

.table th {
  background-color: var(--color-bg-card);
  border: solid 1px var(--color-border);
  border-block-end: 0;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-3xs);
  padding: var(--spacing-16);
  text-align: start;
}

.segment {
  animation-name: fadeIn;
  animation-duration: 0.5s;
  background-color: var(--color-bg-card);
  border: solid 1px var(--color-border);
}

.segment td {
  border-inline-end: solid 1px var(--color-border);
  padding-inline: var(--spacing-16);
  padding-block: 10px;
}

.segment td:last-child {
  border-inline-end: 0;
}

.actions {
  text-align: end;
  width: 100px;
}

@media (--handhelds) {
  .tableWrapper {
    overflow: auto;
    width: 100%;
  }
}

.loader {
  display: flex;
  justify-content: center;
  padding-block: var(--spacing-16);
}
