.table {
  font-variant-numeric: tabular-nums;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

.table.canScrollUp .tableHead::after {
  content: '';
  display: block;
  position: absolute;
  inset-inline: 0;
  inset-block-start: 100%;
  height: var(--spacing-04);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.25), transparent);
}

.tableRow {
  border: 1px solid var(--color-table-border);
}

.tableHead {
  border: 1px solid var(--color-table-border);
}

.tableHead.sticky {
  position: sticky;
  inset-block-start: 0;
}

.tableRow:nth-child(odd) .tableCell {
  background-color: var(--color-table-row-alt);
}

.tableHeader {
  border-inline: 1px solid var(--color-table-border);
  padding: var(--spacing-16);
  background-color: var(--color-table-header);
  text-align: start;
  vertical-align: top;
  min-width: 6.25rem;
}

.tableCell {
  text-align: start;
  white-space: nowrap;
  padding: 0.75rem;
  background-color: var(--color-table-row);
  border: 1px solid var(--color-table-border);
}

.tableCell.start {
  text-align: start;
}

.tableCell.center {
  text-align: center;
}

.tableCell.end {
  text-align: end;
}
