.root {
  align-items: center;
  display: flex;
  flex: 1;
}

@media (--handhelds) {
  .root {
    row-gap: var(--spacing-08);
    flex-wrap: wrap;
  }

  .levelInfo {
    flex-basis: 100%;
  }
}

.avatar {
  align-items: center;
  background-color: var(--color-bg-inverted);
  border-radius: 4px;
  color: var(--color-text-inverted);
  display: flex;
  flex-shrink: 0;
  height: 28px;
  justify-content: center;
  width: 28px;
}

.avatar.automatic {
  background-color: var(--color-viz-neutral-fill);
}

.avatar svg {
  display: block;
  height: 12px;
  width: 12px;
}

.avatar svg g {
  color: var(--color-viz-neutral);
}

.nameContainer {
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: center;
  margin-inline-start: 9px;
}

.name {
  color: var(--color-text-primary);
  display: flex;
  flex: 0 1 auto;
  font-weight: var(--font-weight-bold);
}

.details {
  align-items: center;
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  margin-inline-start: -10px;
}

.email {
  color: var(--color-text-tertiary);
  align-items: center;
  display: inline-flex;
  margin-inline: 10px 3px;
  max-width: 190px;
}

.level {
  white-space: nowrap;
}
