.root {
  align-items: center;
  display: flex;
  flex: 2;
  min-width: 0;
}

.info {
  overflow: hidden;
  padding-inline-start: 12px;
  width: 100%;
}

.infoHeader {
  align-items: center;
  display: flex;
}

.name {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-bold);
}

.badge {
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-base);
  font-size: var(--font-size-2xs);
  font-weight: var(--font-weight-bold);
  line-height: 1.63;
  margin-inline-start: 4px;
  padding-inline: 8px;
  padding-block: 0;
}

.secondary {
  color: var(--color-text-tertiary);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (--handhelds-tablets) {
  .root {
    align-items: flex-start;
  }

  .infoHeader {
    align-items: flex-start;
    flex-direction: column;
  }
}
