.root {
  margin-block-end: 30px;
}

.row {
  margin-block-end: 15px;
}

.row:last-child {
  margin-block-end: 0;
}

.label {
  font-size: var(--font-size-2xs);
  margin-block-end: 5px;
  font-weight: var(--font-weight-bold);
}

.readOnly {
  margin-block-start: 30px;
}

.readOnly .row,
.readOnly .field {
  align-items: flex-start;
  display: flex;
}

.readOnly .label {
  flex: 0 0 90px;
  margin-block-end: 0;
  margin-inline-end: 10px;
  overflow: hidden;
  padding-block-start: 6px;
  text-overflow: ellipsis;
  white-space: pre;
}

.readOnly .field {
  flex: 3;
  flex-wrap: wrap;
  margin: -5px;
}

.badge {
  margin: 5px;
}
