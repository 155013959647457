.label {
  font-size: var(--font-size-2xs);
  font-weight: var(--font-weight-bold);
  margin-block-end: 10px;
}

.labelContainer,
.descriptionContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.descriptionContainer {
  margin-block-end: var(--spacing-08);
}

.charsCount {
  font-size: var(--font-size-2xs);
  display: flex;
  justify-content: flex-end;
  max-width: 200px;
}

.charsCountWarning {
  font-size: var(--font-size-2xs);
  color: var(--color-status-negative);
}
