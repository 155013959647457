@import '../../../styles/z-index.css';

.target {
  display: inline-block;
  width: auto;
  min-width: 252px;
}

.iconMenu {
  height: 24px;
  margin-inline-end: 0;
  opacity: 1;
  width: 24px;
}

.popper {
  z-index: var(--z-index-2);
}

.mobileContainer {
  background-color: var(--color-bg-navigation);
  inset-block: 0 auto;
  display: block;
  height: 100vh;
  inset-inline: 0 auto;
  position: fixed;
  width: 100vw;
  z-index: var(--z-index-9--popover);
}
