.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-block: var(--spacing-64);
  height: 100%;
}

.content {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: var(--spacing-08);
}

.content,
.content p {
  width: 398px;
}

.cta {
  display: flex;
  flex-direction: column;
}

.cta button {
  margin-block-end: 24px;
}

.description {
  margin-block-end: var(--spacing-16);
}

@media (--handhelds) {
  .root {
    padding-block: var(--spacing-24);
    width: 100%;
    min-width: rem(280px);
  }

  .content,
  .content p {
    width: 100%;
    padding-inline: var(--spacing-16);
  }
}
