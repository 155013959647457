.root {
  width: 100%;
  align-items: center;
  color: var(--color-text);
  display: flex;
  line-height: 1;
  padding-inline: 20px;
  padding-block: 15px;
  text-decoration: none;
  transition:
    background-color 0.2s,
    color 0.2s;
}

.root.checked {
  background-color: var(--color-controls--selected-bg);
}

.root.employee {
  align-items: flex-start;
}

.avatar {
  margin-inline-end: 10px;
}

.count,
.identifier,
.reportType {
  color: var(--color-text-tertiary);
  font-size: var(--font-size-2xs);
}

.content,
.name {
  align-items: center;
  display: flex;
}

.count {
  margin-inline-start: 4px;
}

.reportType {
  margin-inline-start: 15px;
}

.identifier {
  margin-block-start: 5px;
}

.badge {
  display: flex;
  margin-inline-start: 5px;
  max-width: 160px;
}

.checkmark {
  align-items: center;
  display: flex;
  flex: 2;
  height: 13px;
  justify-content: flex-end;
  overflow: hidden;
}
