.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  height: 100%;
  padding-block: var(--spacing-64);
  margin-inline: auto;
  text-align: center;
  gap: var(--spacing-24);
  max-width: 560px;
}
