.container {
  align-items: flex-start;
  display: flex;
  margin-block-start: var(--spacing-16);
}

.root {
  background-color: var(--color-bg-primary);
  min-height: 247px;
  padding-inline: 40px;
  padding-block: 35px 40px;
}

.content,
.sidebar {
  flex: 0 0 50%;
}

.content {
  padding-inline-end: var(--spacing-32);
}

.title {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
}

.nameTranslation {
  color: var(--color-text-tertiary);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
}

.translations {
  margin-block-start: rem(10px);
}

.description {
  margin-inline: 0;
  margin-block: var(--spacing-16) 0;
}

.title,
.nameTranslation,
.description,
.descriptionTranslation {
  word-break: break-word;
}

.descriptionTranslation {
  color: var(--color-text-tertiary);
  font-size: var(--font-size-xs);
  margin-inline: 0;
  margin-block: rem(5px) 0;
}

.subTitle {
  font-size: var(--font-size-md);
  margin: 0;
}

.actions,
.descriptionTranslations {
  margin-block-start: var(--spacing-16);
}

.actions > *:not(:last-child) {
  margin-inline-end: var(--spacing-16);
}

.auditField {
  margin-block: var(--spacing-24);
}

.sidebar {
  align-items: flex-start;
  border: 1px solid var(--color-border-section);
  border-radius: 8px;
  display: flex;
  margin-block-start: 5px;
  padding: var(--spacing-16);
}

.restrictions.inactive {
  opacity: 0.4;
}

.standard {
  width: rem(250px);
  margin-block: var(--spacing-24);
  position: relative;
  z-index: var(--z-index-2--dropdown);
}

.standardLabel {
  margin-block: var(--spacing-08);
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-12);
}

@media (--handhelds) {
  .row {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (--collapsed) {
  .container {
    flex-direction: column;
  }

  .content,
  .sidebar {
    flex: 1;
    width: 100%;
  }

  .sidebar {
    margin-block-start: rem(30px);
  }

  .restrictions {
    padding-inline-start: 0;
  }
}

@media (--handhelds) {
  .root {
    padding: var(--spacing-16);
  }

  .container {
    flex-direction: column;
  }

  .sidebar {
    margin-block-start: var(--spacing-16);
  }

  .restrictions {
    padding-inline-start: 0;
  }

  .actions > button {
    justify-content: center;
    width: 100%;
  }
}
