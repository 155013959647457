.root {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: row;
}

.container {
  padding-inline: 20px 20px;
  padding-block: 10px 40px;
}

.section {
  margin-block-start: 30px;
}

.subSection {
  margin-block-start: 20px;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  font-weight: 600;
  flex-wrap: wrap;
}

.toggleContainer {
  height: 32px;
  display: flex;
  flex-direction: row;
  font-size: var(--font-size-2xs);
  align-items: center;
  align-self: center;
}

.titleLabel {
  font-size: var(--font-size-2xs);
  font-weight: 600;
  margin-block-end: 10px;
}

.publishLabel {
  margin-inline: 10px 0;
  margin-block: 0 0;
}

.resourceTitle {
  width: 420px;
  height: 42px;
  border-radius: 2px;
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  background-color: var(--color-bg-card);
}

.resourceTypeMessage {
  margin-block-start: var(--spacing-08);
}

.optional {
  font-size: var(--font-size-2xs);
}

.upload {
  margin-block-start: 10px;
}
