.root {
  width: 100%;
  margin-block-start: var(--spacing-16);
}

.title {
  font-weight: var(--font-weight-bold);
  margin: 0;
}

.group {
  margin-block-end: 8px;
}

.group:last-child {
  margin-block-end: 0;
}
