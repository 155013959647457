.root {
  margin-block-start: var(--spacing-32);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-08);
}

.content {
  display: grid;
  gap: var(--spacing-32);
  grid-template-columns: 1fr 1fr 2fr;
}

.info {
  margin: 0;
  padding-block-start: var(--spacing-24);
}

.selectorHint {
  color: var(--color-text-tertiary);
  font-size: var(--font-size-2xs);
  margin-inline-start: var(--spacing-08);
}
