.root {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-text-inverted);
}

.root strong {
  margin-inline-end: 8px;
}

.root p {
  margin: 0;
}
