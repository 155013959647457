.title {
  font-size: var(--font-size-md);
  margin-block-end: 15px;
  font-weight: var(--font-weight-bold);
}

.form,
.loader {
  margin-block-start: 30px;
}

.list {
  margin-block-start: 5px;
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-status-highlight);
}

.text {
  font-size: var(--font-size-sm);
}
