.container {
  box-shadow: inset 0 -1px 0 0 var(--color-border);
  display: flex;
  height: 100%;
  padding-inline: var(--spacing-24) var(--spacing-16);
  padding-block: 10px 8px;
  width: 100%;
  line-height: 1;
}

.container.links {
  padding-inline-start: var(--spacing-08);
}

.content {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding-inline-start: 6px;
  max-width: 200px;
}

.content > p {
  margin: 0;
}

.content.context {
  justify-content: center;
}

.content.context:hover > a {
  text-decoration: none;
}

.toggleButton {
  position: relative;
  height: 1rem;
  padding: 1px;
}

.chevron {
  display: block;
  width: 0.75rem;
  height: 0.75rem;
}

.container.expanded .chevron {
  transform: rotate(90deg);
}

[dir='rtl'] .chevron {
  transform: rotate(180deg);
}

.name {
  color: var(--color-text-primary);
  display: block;
  height: 18px;
  margin-block-end: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.footer {
  align-items: flex-end;
  display: inline-flex;
}

.meta {
  display: flex;
  align-items: flex-end;
  gap: var(--spacing-08);
  flex-grow: 1;
}

.meta.count {
  justify-content: flex-end;
}

.size {
  flex-direction: row-reverse;
}

.size span {
  margin-inline: 0 2px;
}

[dir='rtl'] .size span {
  margin-inline: 2px 0;
}

.segmentNameContainer {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.attributeLineContainer {
  display: flex;
  flex-wrap: nowrap;
  gap: var(--spacing-16);
  justify-content: space-between;
}

.attributeBadgeContainer {
  overflow: hidden;
  cursor: default;
}

.attributeBadgeContainer.interactive {
  cursor: pointer;
}
