.root {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.loader {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: var(--spacing-32);
}

.row {
  align-items: center;
  border-block-end: 1px solid var(--color-border);
  display: flex;
  min-height: 66px;
  padding-inline: 20px;
  padding-block: 15px;
}

.employee {
  flex: 2;
}

.previewEmail {
  opacity: 0;
  text-decoration: underline;
  transition: opacity 0.2s ease;
  font-weight: var(--font-weight-bold);
}

.row:hover .previewEmail {
  opacity: 1;
}
