.timeFrameHeader {
  margin-inline: auto;
  inset-block-start: calc(-1 * (var(--spacing-32) + var(--spacing-04)));
  padding-inline: calc(-1 * var(--spacing-24));
  background-color: var(--color-bg-primary);
  z-index: 100;
  max-width: 928px;
}

.headerCard {
  margin-block-end: var(--spacing-16);
}

.timeframeCard {
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-16);
  margin-block-start: var(--spacing-16);
}

.combobox {
  width: 250px;
  margin-block: auto;
}

.dateValue {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-block: auto;
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
}

.dateLabel {
  display: flex;
  flex-direction: column;
  margin-block: 0;
  color: var(--color-text-tertiary);
}

.tableHeaderRoot {
  padding-inline: var(--spacing-16);
  margin-inline: auto;
  width: 100%;
  max-width: 928px;
}

.tableHeader {
  max-width: 928px;
  margin-inline: auto;
  display: flex;
  border-block-start: 1px solid var(--color-border-section);
  border-block-end: 1px solid var(--color-border-section);
}

.tableCategory {
  width: 34%;
}

.tableHeaderText {
  margin-inline: var(--spacing-16);
  margin-block: auto;
  font-weight: var(--font-weight-bold);
  padding-block: var(--spacing-08);
}

@media (--handhelds) {
  .tableHeader {
    display: none;
  }

  .dateValue {
    align-items: unset;
    margin-block-end: var(--spacing-24);
  }

  .combobox {
    width: unset;
  }

  .stickyHeader {
    inset-block-start: calc(-1 * var(--spacing-24));
  }

  .timeframeCard {
    flex-direction: column-reverse;
  }
}
