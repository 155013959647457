.courseContainer {
  display: flex;
  margin-block-start: 20px;
}

.courseIcon,
.courseIcon svg {
  width: 24px;
  height: 24px;
}

.course {
  margin-inline-start: 8px;
}

.course a {
  color: var(--color-text-secondary);
  text-decoration: underline;
}
