.root {
  background-color: var(--color-bg-navigation);
  border-block-end: solid 1px var(--color-border);
  border-block-start: solid 1px var(--color-border);
  padding-inline: 20px;
  padding-block: 15px;
  position: relative;
  z-index: 1;
}

.title {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
}

.content {
  margin-block-start: 15px;
}
