.sectionBody {
  position: relative;
  padding: var(--spacing-16);
  min-height: rem(200px);
}

input[required] ~ div span {
  /* hides required "dot" in <InputField> */
  display: none;
}

@media (--min-width-sm) {
  .formInput {
    width: rem(268px);
  }
}
