.root {
  margin-block-end: rem(26px);
}

.title {
  align-items: center;
  margin: 0;
  font-weight: var(--font-weight-bold);
}

.configureLink {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline-end: -5px;
}

.configureLink:hover {
  text-decoration: none;
}

.linkText {
  margin-inline-start: auto;
}

.arrow {
  width: 18.5px;
  height: 18.5px;
}

.description {
  margin-block-start: var(--spacing-16);
}

.activeSchedulesContainer {
  flex-basis: 2;
  text-align: end;
  font-weight: var(--font-weight-bold);
}

.activeSchedulesText {
  font-size: var(--font-size-md);
}
