.root {
  background-color: var(--color-bg-inverted);
  border-radius: 0 3px 3px 3px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.23);
  padding: 0;
  width: 100%;
}

.scrollable {
  /*
  338px is the height of header (26) + search (56) + four items (4*64)
  We want to show when it's possible to scroll, so we cut of the last item and show only a max of 3.5 items
  */
  max-height: calc(338px - 32px);
  overflow-y: auto;
}

.header {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 0 3px 0 0;
  color: var(--color-text-inverted);
  display: flex;
  font-size: var(--font-size-2xs);
  margin: 0;
  padding-inline: var(--spacing-16);
  padding-block: 5px;
  position: relative;
  text-transform: uppercase;
  font-weight: var(--font-weight-bold);
}

.icon {
  height: 24px;
  margin-inline-end: 10px;
  width: 24px;
}

.searchToggler,
.proxyView {
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.09);
  color: var(--color-text-inverted);
  padding: var(--spacing-16);
  border-block-end: 0;
}

.searchToggler {
  box-shadow: inset 0 -1px 0 0 rgba(255, 255, 255, 0.13);
  cursor: text;
  min-height: 55px;
}

.proxyView {
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.13);
  font-size: var(--font-size-2xs);
  font-weight: var(--font-weight-bold);
  cursor: pointer;
  padding: 0;
  margin-inline: 0 !important;
  border: none !important;
}

.proxyViewBtn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-16);
}

.searchTogglerBtn {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: text;
}

.searchIcon {
  display: block;
  flex: 0 0 30px;
  margin-inline-end: 3px;
  color: var(--color-text-inverted);
}

.contexts {
  max-height: 270px;
  overflow-y: auto;
}
