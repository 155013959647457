.context {
  display: flex;
  padding-inline: var(--spacing-16);
  min-height: 64px;
  color: var(--color-text-inverted);
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  background-color: rgba(255, 255, 255, 0.1);
  border-block-start: 1px solid rgba(255, 255, 255, 0.25);
}
