@import '../../variables.css';

.link {
  display: flex;
  align-items: center;
  height: var(--nav-top-height);
  padding-inline: var(--spacing-24);
  position: relative;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-bold);
  text-decoration: none;
  color: var(--color-text-tertiary);
}

.link:hover {
  text-decoration: none;
  color: var(--color-text-primary);
}

.link:focus {
  text-decoration: none;
}

.link:focus-visible {
  box-shadow: var(--focus-inset);
  outline: var(--focus-outline);
}

.link.active {
  color: var(--color-text-primary);
}

.link.active::after {
  content: '';
  position: absolute;
  inset-block-end: 0;
  inset-inline: 0;
  border-radius: var(--spacing-04) var(--spacing-04) 0 0;
  height: var(--spacing-04);
  background-color: var(--color-controls-selected);
}

.link.active:focus-visible::after {
  inset-block-end: 0.25rem;
  inset-inline: 0.25rem;
}
