.lastSeenAt {
  color: var(--color-text-tertiary);
  margin-inline: 10px;
  margin-block: 0;
  text-align: start;
}

.small {
  font-size: var(--font-size-2xs);
}

.wrapper {
  align-items: center;
  display: inline-flex;
  text-align: end;
}
