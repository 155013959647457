.root {
  padding: 15px;
}

.form {
  align-items: flex-start;
  display: flex;
  margin-inline: -17px;
  margin-block: 0;
}

.column,
.options label {
  flex: 1;
}

.column > label {
  color: var(--color-text-tertiary);
  margin-block-end: 0;
}

.column {
  padding-inline: 17px;
  padding-block: 0;
}

.label {
  margin-block-end: 0;
}

.tooltip {
  border-block-end: 1px dashed var(--color-border-section);
}

.field {
  margin-block-start: 15px;
}

.actions {
  margin-block-start: 18px;
}

.options {
  align-items: center;
  display: flex;
  height: 42px;
}

.options label {
  display: block;
}

.input {
  margin-inline-end: 10px;
}
