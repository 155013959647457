@import 'animate.css/source/attention_seekers/rubberBand.css';

:root {
  --stick-border-radius: var(--border-radius-card);
  --stick-border: solid 1px var(--color-border);
  --stick-box-shadow: var(--depth-1-box-shadow);
  --stick-color: var(--color-text-tertiary);
  --stick-height: 70px;
  --stick-height-mobile: 108px;
  --stick-height-footer: 32px;
  --stick-height-footer-mobile: 32px;
  --stick-transition: 0.2s box-shadow;
}

.container {
  position: relative;
}

.root,
.footer {
  border: 1px solid transparent; /* order before border-bottom */
  border-block-end: none;
  border-radius: 0;
  color: var(--stick-color);
  flex-shrink: 0;
  max-width: 100%;
  text-decoration: none;
  transition: var(--stick-transition);
}

.root.isLink {
  cursor: pointer;
}

.root {
  display: block;
  height: var(--stick-height-mobile);
  position: relative;
}

.root > * {
  position: relative;
}

.footer {
  align-items: center;
  border-block-start: none;
  box-shadow: var(--depth-1-box-shadow);
  display: flex;
  height: var(--stick-height-footer-mobile);
  justify-content: flex-start;
  position: relative;
  width: 100%;
}

.footer.loading {
  pointer-events: none;
}

.container:first-of-type .root {
  border-top-left-radius: var(--stick-border-radius);
  border-top-right-radius: var(--stick-border-radius);
}

.container:not(:last-of-type) .root:not(.collapsible) {
  border-block-end: var(--stick-border);
}

.footer,
.container:last-of-type .root {
  border-bottom-left-radius: var(--stick-border-radius);
  border-bottom-right-radius: var(--stick-border-radius);
}

.container:last-of-type .root {
  border-block-end: var(--stick-border);
}

.container .root.collapsible {
  border-radius: var(--stick-border-radius) var(--stick-border-radius) 0 0;
}

.collapsibleList .root.innerCard {
  border-radius: 0;
}

.collapsibleList .root {
  border-block-end: 0;
  border-block-start: 0;
  padding-inline-start: 56px;
}

.collapsibleList .root .inner {
  padding-inline-start: 0;
}

.collapsibleList .container:not(:last-child) .root .inner {
  border-block-end: var(--stick-border);
}

.enterDone {
  overflow: inherit !important;
}

.root:hover,
.root:focus {
  background-color: var(--color-bg-card);
  text-decoration: none;
  color: inherit;
}

.footer:hover .collapseArrow,
.footer:hover .collapseText {
  color: var(--color-text-primary);
}

.footer:focus {
  outline: none;
}

.footer.focused {
  background-color: var(--color-bg-card);
}

.innerLink {
  position: absolute;
  inset-block: 0;
  inset-inline: 0;
}

.innerLink:focus {
  background-color: var(--color-bg-primary);
}

.inner {
  display: flex;
  place-content: flex-start space-around;
  height: 100%;
  width: 100%;
  padding-inline: 15px 5px;
  padding-block: 14px 14px;
  pointer-events: none;
}

.section {
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
}

.section.label {
  margin-block-end: 10px;
  max-width: 100%;
  width: 100%;
}

.section.meta {
  align-self: flex-end;
  color: var(--color-text-tertiary);
  justify-content: center;
  max-width: 60px;
  min-height: 40px;
}

.section.label > *,
.section.meta > * {
  pointer-events: all;
}

.iconWrapper {
  display: initial;
  inset-inline-start: -10px;
  position: absolute;
  z-index: 1;
}

.iconWrapper.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: rubberBand;
}

.footer .iconWrapper .icon {
  inset-inline-start: 0;
  position: absolute;
  inset-block-start: -12px;
}

.footer .iconWrapper .icon:nth-child(2) {
  inset-inline-start: 4px;
}

.footer .iconWrapper .icon:nth-child(3) {
  inset-inline-start: var(--spacing-16);
}

.icon {
  align-items: center;
  display: flex;
  height: 20px;
  justify-content: center;
  width: 20px;
}

.content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
}

.content.withDropdown {
  margin-inline-end: 50px;
}

.arrow {
  position: absolute;
  inset-inline-end: 6px;
  align-items: center;
  color: var(--color-text-tertiary);
  display: flex;
  font-size: rem(26px);
  justify-content: flex-end;
  line-height: 0;
}

.arrowIcon {
  height: 24px;
  transition: transform 0.3s ease-in-out;
  width: 24px;
}

[dir='rtl'] .arrowIcon {
  transform: scaleX(-1);
}

.collapseArrow {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-inline-start: 14px;
}

.collapseText {
  color: var(--color-text-tertiary);
  font-size: var(--font-size-xs);
  margin-inline-start: rem(14px);
  font-weight: var(--font-weight-bold);
}

.footer.expanded .arrowIcon {
  transform: rotate(180deg);
}

@media (min-width: 768px) {
  .root {
    height: var(--stick-height);
  }

  .footer {
    height: var(--stick-height-footer);
  }

  .inner {
    align-items: center;
    padding-inline: 16px 10px;
    padding-block: 14px 14px;
  }

  .iconWrapper {
    display: flex;
    inset-inline-start: -12px;
  }

  .icon {
    height: 24px;
    width: 24px;
  }

  .section.label {
    margin-block-end: 0;
    flex-grow: 0;
    width: 33%;
  }

  .collapsibleList .root .section.label {
    width: calc(33% - 25px);
  }

  .collapsibleList .root .iconWrapper {
    inset-inline-start: -12px;
  }

  .collapsibleList .root .iconWrapper.innerIcon {
    inset-inline-start: -69px;
  }

  .arrow {
    display: block;
    position: relative;
    inset-inline-end: inherit;
  }

  .section.meta {
    max-width: 80px;
  }
}
/* screen-w-md */
@media (max-width: 768px) {
  .section.label {
    margin-block-end: var(--spacing-04);
  }

  .section.score {
    margin-inline-start: var(--spacing-08);
  }

  .collapsibleList .root {
    margin: 0;
    padding: 0;
  }

  .collapsibleList .section.label {
    margin-inline-start: 56px;
  }

  .collapsibleList .section.score {
    margin-inline-start: var(--spacing-24);
  }
}
