.root {
  width: 100%;
}

.nav {
  padding-inline: 0;
}

.breadcrumbHeaderWrapper {
  margin-block-end: var(--spacing-20);
}

.breadcrumbWrapper {
  margin-block-end: var(--spacing-04);
  display: flex;
  align-items: center;
}
