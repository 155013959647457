.root {
  width: 100%;
  height: 100%;
  display: grid;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-block: var(--spacing-20) var(--spacing-16);
  padding-inline: var(--spacing-24) var(--spacing-16);
  border-block-end: 1px solid var(--color-border);
}

.infoSection {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  width: calc(100% - var(--spacing-16));
  margin: var(--spacing-08);
  padding-block: var(--spacing-04) var(--spacing-08);
  padding-inline: var(--spacing-16) var(--spacing-08);
  border-radius: var(--border-radius-lg);
}

.infoSection:hover {
  background-color: var(--color-controls-hover-bg);
}

.disabled.infoSection:hover {
  background-color: unset;
}

.infoSectionValue {
  text-transform: capitalize;
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  line-height: 1;
  padding-block-end: var(--spacing-04);
}

.infoSectionValuePercentage {
  font-size: var(--font-size-2xl);
}

.infoSectionLabel {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.infoSectionIcon {
  color: var(--color-icon-decorative);
}

.divider {
  border-block-end: 1px solid var(--color-border);
}

.footerLink {
  padding: var(--spacing-08);
  padding-inline: var(--spacing-24) var(--spacing-16);
}

.cardPlaceholder {
  padding: var(--spacing-16);
}

.cardPlaceholder > div {
  margin-block-end: var(--spacing-08);
}

/* fix for <UnstyledButton> which used to have position: relative; */
.relative {
  position: relative;
}
