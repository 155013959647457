.root {
  margin-block-start: var(--spacing-32);
}

.fieldWrapper,
.field {
  align-items: center;
  display: flex;
}

.fieldWrapper {
  margin-block-end: var(--spacing-16);
}

.field,
.field > div {
  flex: 2;
}

.field > button {
  margin-inline-start: var(--spacing-08);
}

.link {
  font-weight: var(--font-weight-bold);
  margin-inline-start: var(--spacing-32);
  text-decoration: underline;
}

.actions > button:last-child {
  margin-inline-start: var(--spacing-04);
}

.label {
  font-size: var(--font-size-md);
}
