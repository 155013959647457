.root {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.text {
  flex: 3;
}

.content {
  align-items: center;
  display: flex;
}

.icon {
  margin-inline-end: rem(8px);
  color: var(--color-icon-decorative);
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
