.root {
  position: relative;
  max-width: 600px;
  margin-inline: auto;
  padding-block-end: rem(60px);
}

.totalMatching {
  color: var(--color-text-secondary);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-normal);
  margin-inline: 0;
  margin-block: var(--spacing-24);
  text-align: center;
}

.comment {
  border: 0;
}

.commentRoot {
  padding-block-end: var(--spacing-24);
}

.comment.isNew {
  background-color: var(--color-border);
  padding: var(--spacing-16);
}

.comment.isNewFirst {
  border-radius: var(--border-radius-card) var(--border-radius-card) 0 0;
}

.comment.isLastNew {
  border-radius: 0 0 var(--border-radius-card) var(--border-radius-card);
  margin-block-end: var(--spacing-16);
}

.divider {
  margin-inline: 0;
  margin-block: 8px;
}

.new {
  align-items: center;
  background-color: var(--color-bg-primary);
  border-radius: var(--border-radius-card) var(--border-radius-card) 0 0;
  display: flex;
  margin-inline: calc(var(--spacing-16) * -1);
  margin-block: 0;
  padding: var(--spacing-16);
  position: relative;
}

.new.sensitive {
  background-color: rgba(255, 85, 0, 0.1); /* sensitive color */
}

.new .meta {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.unreadCount {
  font-weight: var(--font-weight-bold);
}

.unreadCount > span {
  padding-inline-end: var(--spacing-08);
}

.new .actions {
  position: absolute;
  inset-inline-end: 16px;
}

.loader {
  padding-inline: 0;
  padding-block: var(--spacing-24);
}

.unread span:first-child {
  font-weight: var(--font-weight-bold);
  margin-inline-end: var(--spacing-08);
}

.badge {
  background-color: var(--color-status-highlight);
  border-radius: 10px;
  color: var(--color-text-inverted);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-bold);
  line-height: 1.67;
  padding-inline: rem(7px);
  padding-block: rem(1px);
  text-align: center;
  white-space: nowrap;
}

.badge.sensitive {
  background-color: var(--color-status-negative);
}

@media (--handhelds) {
  .list {
    padding-inline: 16px;
    padding-block: 0;
  }

  .new {
    margin: 0;
    padding: 16px;
  }

  .new .actions {
    position: initial;
    inset-inline-end: initial;
  }

  .totalMatching {
    font-size: var(--font-size-base);
  }
}
