.tableWrapper {
  overflow: hidden;
  transition: max-height 0.35s ease-in-out;
  margin-block-end: 1.25rem;
}

.table {
  width: 100%;
}

.headerCell {
  text-align: initial;
  padding-block-end: 0.58rem;
}

.row {
  box-shadow: inset 0 -1px 0 0 var(--color-border);
}

.table.collapsed .row:nth-child(3) {
  box-shadow: none;
}

.rowCell {
  font-weight: var(--font-weight-bold);
  padding-inline: 0;
  padding-block: 0.625rem;
}

.rowCell:last-child {
  text-align: end;
}

.toggle {
  align-items: center;
  color: var(--color-text-primary);
  display: flex;
  text-decoration: underline;
  padding: 0.25rem;
  margin-inline: -0.25rem -0.25rem;
  margin-block: 0 1.938rem;
}

.toggle.focused {
  outline: auto;
}

.toggle:last-child {
  margin-block-end: 0;
}

.moreIndicator,
.lessIndicator {
  height: 0;
  width: 0;
  border: 4.5px solid transparent;
  border-block-start-color: rgb(204, 204, 204);
  margin-inline: 5px 0;
  margin-block: 4.5px 0;
}

.lessIndicator {
  margin-block-start: -4.5px;
  transform: rotate(180deg);
}
