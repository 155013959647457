.root {
  z-index: 1;
  border-radius: 100%;
}

.root.outline {
  background: var(--color-bg-card);
}

.root.outline,
.root.outline svg {
  height: 20px;
  width: 20px;
}
