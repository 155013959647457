.root {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-inline: 0;
  padding-block: 2px 5px;
  position: relative;
}

.root.sm .base,
.root.sm .score {
  height: 6px;
}

.indicator {
  background-color: var(--color-benchmark-bg);
  border-radius: 1.5px;
  content: '';
  height: 12px;
  position: absolute;
  inset-block-start: 0;
  width: 3px;
  z-index: 2;
}

.indicator::after {
  content: '';
  inset-inline-start: 1px;
  position: absolute;
  inset-block: 0;
  width: 1px;
  background-image: repeating-linear-gradient(
    to bottom,
    transparent 1px 4px,
    var(--color-benchmark-highlight) 0 8px
  );
  background-size: 1px 100%;
  background-repeat: no-repeat;
  background-position: 80%;
}

.base {
  background-color: var(--color-viz-graph-bg);
  border: 1px solid var(--color-viz-grid-line);
  border-radius: 4px;
  height: 8px;
  position: relative;
  width: 100%;
}

.benchmark,
.score {
  height: 100%;
  inset-inline-start: 0;
  position: absolute;
  inset-block-start: 0;
}

.score {
  background-color: var(--color-viz-positive-fill);
  border-radius: 5px 0 0 5px;
  border: 1px solid var(--color-viz-positive);
  z-index: 1;
  inset-block-start: -1px;
  height: 8px;
  inset-inline-start: -1px;
}

[data-theme='deuteranopia'] .score {
  background-color: var(--color-viz-positive-fill--deuteranopia);
  border-color: var(--color-viz-positive--deuteranopia);
}

.below .score {
  background-color: var(--color-viz-negative-fill);
  border-color: var(--color-viz-negative);
}

.benchmark {
  color: var(--color-text);
  line-height: 1;
  position: absolute;
  inset-block-start: 0;
  transform: translate3d(-50%, 15px, 0);
  z-index: 3;
  font-weight: var(--font-weight-bold);
}

.legend {
  align-items: center;
  color: var(--color-text-tertiary);
  display: flex;
  font-size: var(--font-size-3xs);
  font-weight: var(--font-weight-bold);
  line-height: 1;
  margin-block-start: 3px;
  width: 100%;
}

.legend div {
  flex: 1;
}

.legend div:last-child {
  text-align: end;
}

.tooltip {
  animation: bubble;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  background-color: var(--color-bg-navigation);
  border: solid 1px rgba(0, 0, 0, 0.08);
  inset-block-end: 30px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
  color: var(--color-text-primary);
  display: none;
  font-size: var(--font-size-2xs);
  line-height: 1;
  opacity: 0;
  padding-inline: 15px;
  padding-block: 10px;
  position: absolute;
  text-align: center;
  transform: translate3d(-22.5px, 100%, 0);
  z-index: 5;
  font-weight: var(--font-weight-bold);
}

.tooltip::after,
.tooltip::before {
  border: solid transparent;
  inset-block-end: 100%;
  content: ' ';
  height: 0;
  inset-inline-start: 50%;
  pointer-events: none;
  position: absolute;
  width: 0;
}

.tooltip::after {
  border-color: transparent transparent var(--color-bg-card) transparent;
  border-width: 5px;
  margin-inline-start: -5px;
}

.tooltip::before {
  border-color: transparent transparent var(--color-border) transparent;
  border-width: 6px;
  margin-inline-start: -6px;
}

.root:hover .tooltip {
  display: block;
}

@keyframes bubble {
  from {
    opacity: 0;
    transform: translate3d(-22.5px, 90%, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-22.5px, 140%, 0);
  }

  75% {
    transform: translate3d(-22.5px, 120%, 0);
  }

  90% {
    transform: translate3d(-22.5px, 125%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(-22.5px, 130%, 0);
  }
}
