.parentWrapper,
.parentOptionWrapper {
  display: flex;
  flex-direction: column;
}

.directStatus {
  color: var(--color-text-hint);
  font-weight: normal;
}

.parentDirectStatus {
  color: var(--color-text-hint);
  margin-inline-start: rem(14px);
  font-weight: normal;
}

.parentName {
  margin: 0;
}
