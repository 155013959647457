.button {
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid var(--color-btn--secondary-border);
  border-radius: 4px;
  color: var(--color-btn--secondary-text);
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-base);
  -webkit-font-smoothing: subpixel-antialiased;
  margin: 0;
  line-height: 2.3;
  min-width: 120px;
  overflow: visible;
  padding-inline: 10px;
  padding-block: 0;
  text-align: center;
  text-decoration: none;
  transition:
    color,
    background-color,
    border-color,
    0.2s ease;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  font-weight: var(--font-weight-bold);
}

.button:focus {
  outline: none;
}

.button[disabled],
.button.disabled {
  cursor: not-allowed;
  opacity: 0.35;
  pointer-events: none;
}

.button.focused {
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.3);
}

.button:hover,
.button.hovered {
  background-color: var(--color-btn--secondary-hover-bg);
  color: var(--color-text-inverted);
}

.button:active {
  background-color: var(--color-btn--secondary-active-bg);
  color: var(--color-text-inverted);
}

.button:hover .icon {
  color: var(--color-text-inverted);
}

.button:active .icon {
  color: var(--color-text-inverted);
}

.icon {
  margin-inline-end: 10px;
  color: var(--color-text);
  transition: color 0.2s ease;
}

@media (--handhelds) {
  .button {
    min-width: 0;
  }

  .button > span {
    display: none;
  }

  .icon {
    margin-inline-end: 0;
  }
}
