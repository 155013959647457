.root {
  font-size: var(--font-size-2xs);
}

.root.light,
.root.noBorder {
  background-color: transparent;
  color: var(--color-text-tertiary);
  display: inline-block;
  font-family: var(--font-family-base);
  font-size: var(--font-size-2xs);
  line-height: 1.27;
  margin-inline-start: 0;
  overflow: hidden;
  padding-inline: 4px;
  padding-block: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.root.light {
  border-radius: var(--border-radius-base);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.direct {
  margin-inline-start: 3px;
}

.direct::before {
  content: '(';
}

.direct::after {
  content: ')';
}
