.illustration {
  margin-inline: 0 20px;
  margin-block: 0 0;
}

.results {
  align-items: flex-start;
  display: flex;
  margin-inline: 0;
  margin-block: 20px;
}

.results img {
  display: block;
  margin-inline: auto;
  margin-block: 0;
}

.statusItem {
  color: var(--color-status-negative);
  font-size: var(--font-size-md);
  line-height: 1.5;
  font-weight: var(--font-weight-bold);
}

.statusItem.added {
  color: var(--color-status-positive);
}

.statusItem.skipped {
  color: var(--color-text-primary);
}

.log {
  align-items: center;
  border-block-end: 1px var(--color-border) solid;
  display: flex;
  padding-inline: 0;
  padding-block: var(--spacing-16);
}

.message {
  flex: 2;
}

.position {
  background-color: var(--color-bg-secondary);
  color: var(--color-text-secondary);
  font-size: rem(11px);
  margin-inline-start: 20px;
  padding: 2px;
  text-transform: uppercase;
  font-weight: var(--font-weight-bold);
}
