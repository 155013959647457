.title {
  color: var(--color-text-hint);
  font-size: var(--font-size-lg);
  margin-block-end: 20px;
  font-weight: var(--font-weight-bold);
}

.title.tiny {
  font-size: var(--font-size-2xs);
  margin-block-end: 5px;
}

.title.small {
  font-size: var(--font-size-sm);
  margin-block-end: 10px;
}

.title.large {
  font-size: var(--font-size-2xl);
}

.title.text {
  color: var(--color-text-body);
}
