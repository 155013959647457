:root {
  --breadcrumb-step-number-size: 31px;
}

.root,
.step {
  align-items: center;
  display: flex;
}

.step {
  border-inline-end: 1px solid var(--color-border);
  color: var(--color-text-tertiary);
  flex: 1;
  padding: var(--spacing-16);
  transition: color 0.2s;
}

.step:last-child {
  border-inline-end: 0;
}

.step.active {
  color: var(--color-status-highlight);
}

.step.completed {
  color: var(--color-text);
}

.step.link {
  cursor: pointer;
}

.number {
  align-items: center;
  border: 1px solid var(--color-text-secondary);
  border-radius: 100%;
  display: flex;
  height: var(--breadcrumb-step-number-size);
  justify-content: center;
  line-height: 1;
  margin-inline-end: 10px;
  transition: border-color 0.2s;
  width: var(--breadcrumb-step-number-size);
  font-weight: var(--font-weight-bold);
}

.checkmark path {
  fill: var(--color-text);
}

.step.active .number {
  border-color: var(--color-status-highlight);
}

.step.completed .number {
  border-color: var(--color-text);
}

.title {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
}

@media (--handhelds) {
  .step {
    padding: var(--spacing-16);
  }

  .number {
    border: 0;
    height: auto;
    margin-inline-end: var(--spacing-04);
  }

  .title {
    font-size: var(--font-size-base);
  }
}
