.root {
  display: flex;
  align-items: center;
  margin-block-end: 25px;
}

.root span {
  margin-inline: 10px;
  margin-block: 0;
  color: var(--color-text-secondary);
  font-size: var(--font-size-base);
}

.root div {
  height: 1px;
  background: var(--color-border);
  width: 100%;
}
