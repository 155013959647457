.root {
  overflow-y: auto;
}

.root thead tr th {
  font-weight: var(--font-weight-bold);
}

th.rightAligned {
  text-align: end !important;
}

.toggler {
  color: var(--color-text-primary);
}

.metricActions button {
  margin-inline-start: 14px;
}

.expand {
  cursor: default !important; /* parent is too specific, overwrite in this case */
}

.mainCell a {
  display: flex;
}

.firstTd {
  padding: var(--spacing-16) 24px !important;
}

.tableWrapper td {
  background: none !important;
  overflow: none;
}

.tableWrapper tr:last-of-type td:first-of-type {
  border-end-start-radius: var(--border-radius-card);
}

.tableWrapper tr:last-of-type td:last-of-type {
  border-end-end-radius: var(--border-radius-card);
}

.tableWrapper thead:first-of-type tr:first-of-type th:first-of-type {
  border-start-start-radius: var(--border-radius-card);
}
