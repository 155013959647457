.header {
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding-inline: 0 20px;
  padding-block: 0 0;
  width: 100%;
}

.header div:first-child {
  flex: 2;
}

.toolbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-inline: var(--spacing-16);
}

.languageSelector {
  flex-shrink: 0;
  max-width: 300px;
  padding-block: var(--spacing-04);
}

.variations {
  display: flex;
}

.variations > button {
  border-block-end: 2px solid transparent;
  color: var(--color-text-tertiary);
  display: inline-block;
  font-weight: var(--font-weight-bold);
  margin-inline-end: var(--spacing-32);
  padding-block: var(--spacing-16);
  text-decoration: none;
  transition:
    border-color 0.2s,
    color 0.2s;
}

.variations > button:hover,
.variations > button:focus {
  border-color: var(--color-text-secondary);
  color: var(--color-text);
  cursor: pointer;
  text-decoration: none;
}

.variations > button.active {
  border-color: var(--color-text-link);
  color: var(--color-brand-primary-dark);
  font-weight: var(--font-weight-bold);
}

.email {
  background-color: var(--color-bg-card);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-base);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  transform: translate3d(0, 0, 0);
}

.emailHeader {
  background-color: var(--color-bg-primary);
  color: var(--color-text-tertiary);
  display: block;
  height: 24px;
  line-height: 1.5;
  padding-inline: 10px;
}

.subject,
.from {
  border-block-end: 1px solid var(--color-border);
  line-height: 1.3;
  padding-inline: var(--spacing-24);
  padding-block: var(--spacing-16);
}

.subject {
  font-weight: var(--font-weight-bold);
}

.from {
  color: var(--color-text-tertiary);
}

.body {
  background: var(--color-bg-primary);
}

.body :global(.avatar) {
  display: block;
}

@media (--handhelds) {
  .root {
    width: 100%;
  }
}

.spinner {
  color: var(--color-icon-decorative);
  margin-block: var(--spacing-40);
  display: flex;
  flex-direction: column;
  align-items: center;
}
