.isLoading {
  padding-inline: 10px;
}

.target {
  display: inline-block;
  padding: 0;
  width: auto;
}

.iconMenu {
  height: 24px;
  margin-inline-end: 0;
  opacity: 1;
  width: 24px;
}

.label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-inline: 12px;
  padding-block: 0;
  text-align: start;
  font-weight: var(--font-weight-bold);
}

.labelTitle {
  color: var(--color-text-primary);
  display: block;
  font-size: rem(8px);
  line-height: 1;
  margin-block-end: 3px;
  opacity: 0.3;
  text-transform: uppercase;
}

.labelAttribute {
  color: var(--color-text-inverted);
  max-width: 110px;
  text-overflow: ellipsis;
  white-space: pre;
}
