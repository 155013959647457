.duration {
  align-items: center;
  display: inline-flex;
}

.duration.green {
  color: var(--color-status-positive);
}

.duration.light {
  color: var(--color-text-tertiary);
}

.duration.dark {
  color: var(--color-text-tertiary);
}

.duration.dark .clock {
  color: var(--color-text-secondary);
}

.duration.green .clock {
  fill: var(--color-status-positive);
}

.duration.medium {
  font-size: rem(18px);
}

.duration.tiny {
  font-size: var(--font-size-2xs);
}

.duration.small {
  font-size: var(--font-size-base);
}
