.inputError input,
.inputError textarea {
  border-color: var(--color-status-negative);
}

.inputError input:focus,
.inputError textarea:focus {
  border-color: var(--color-status-negative);
  box-shadow: var(--focus-negative);
}

.field {
  display: grid;
  gap: var(--spacing-04);
}

.fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

.error {
  color: var(--color-status-negative);
  font-size: var(--font-size-xs);
  margin: 0;
}
