.root {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.label {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.arrows {
  margin-inline-start: var(--spacing-08);
}

.arrows path {
  fill: var(--color-text-tertiary);
  transition: fill 0.3s;
}

.arrows.active path:first-child {
  fill: var(--color-controls--selected-marker);
}

/* fix for <UnstyledButton> which used to have position: relative; */
.relative {
  position: relative;
}
