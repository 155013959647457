@import '../../../styles/animations.css';

.root.animated {
  animation-duration: 300ms;
  animation-iteration-count: 1;
  animation-name: bounceInRight;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.avatar [data-size='medium'] {
  height: 35px;
  width: 35px;
}

.avatar [data-size='medium'] span {
  font-size: var(--font-size-base);
}

.textWrapper {
  flex: 1;
  max-width: 100%;
}

.text {
  background-color: var(--color-bg-card);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-card);
  color: var(--color-text-primary);
  display: inline-block;
  font-size: var(--font-size-sm);
  max-width: 100%;
  text-align: start;
  white-space: pre-line;
  word-break: break-word;
}

.text strong {
  color: var(--color-text);
  display: inline-block;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-bold);
  white-space: pre;
  word-break: keep-all;
}

.text p {
  margin-inline-end: rem(5px);
}

.text p:last-child {
  margin-block-end: 0;
}

.end .textWrapper {
  text-align: end;
}

.end .text {
  background-color: var(--color-conversation-user-bg);
  border: 1px solid var(--color-conversation-user-border);
  color: var(--color-text-primary);
}

.text span {
  background-color: var(--color-conversation-note-border);
  color: var(--color-text);
  font-weight: var(--font-weight-bold);
  white-space: pre;
  word-break: keep-all;
}

.reply .text span {
  background-color: var(--color-conversation-user-border);
}

.note .text {
  background-color: var(--color-conversation-note-bg);
  border: 1px solid var(--color-conversation-note-border);
  color: var(--color-text-primary);
}
