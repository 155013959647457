.container {
  display: inline-flex;
  align-items: center;
  color: var(--color-text-tertiary);
  font-size: var(--font-size-md);
}

.container strong {
  color: var(--color-text);
  font-weight: var(--font-weight-bold);
}

.title {
  margin-inline-start: 6px;
}
