.field {
  color: var(--color-controls-placeholder-text);
}

.field .input {
  color: var(--color-controls-placeholder-text);
  padding-inline-start: rem(36px);
}

.keepIcon {
  position: absolute;
  inset-inline-start: var(--spacing-08);
  inset-block-start: var(--spacing-08);
  z-index: 1;
}

.clear {
  display: block;
  height: 20px;
  width: 20px;
  color: var(--color-controls-placeholder-text);
}
