.card {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 16px;
  padding-inline-end: 20px;
  border-radius: var(--border-radius-card);
  border: 1px solid var(--color-border);
  background-color: var(--color-bg-card);
}

.card:hover .text {
  text-decoration: underline;
}

.text {
  width: calc(100% - 45px);
  font-size: var(--font-size-md);
  line-height: 1.4;
  color: var(--color-text-primary);
}
