.content {
  animation: slideInRight 0.25s forwards cubic-bezier(0.165, 0.84, 0.44, 1);
  background-color: var(--color-bg-card);
  border: 1px solid var(--color-border);
  color: var(--color-text-tertiary);
  font-family: var(--font-family-base);
  inset-inline-start: 0;
  line-height: 1.45;
  overflow: hidden;
  position: fixed;
  text-transform: none;
  inset-block-start: 0;
  width: 100vw;
  z-index: 900;
}

.main {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main > div:first-child {
  flex: 0 0 50px;
  height: 50px;
}

.main > div:last-child {
  flex: 2;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}

/* TODO: this css has no effect, because the selector is wrong */
/* stylelint-disable-next-line selector-class-pattern */
:global(.app-shareable-dashboard) .content {
  height: calc(100vh - 66px);
  inset-block-start: 66px;
}

.close {
  height: 22px;
  position: fixed;
  inset-inline-end: 15px;
  inset-block-start: 15px;
  z-index: 101;
}

.close g {
  fill: var(--color-text);
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
