.roundCodeContent {
  margin-block-start: var(--spacing-16);
}

.roundCodeActions {
  align-items: center;
  display: flex;
}

.segmentFilter {
  align-items: flex-start;
  display: flex;
}

.employeesLink {
  display: block;
  font-weight: var(--font-weight-bold);
  text-decoration: underline;
}

.segmentFilter > button:last-child,
.roundCodeActions > button:last-child {
  margin-inline-start: var(--spacing-16);
}

.employeesLink,
.exportForm > button {
  margin-block-start: var(--spacing-16);
}

.spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-block: var(--spacing-08);
}
