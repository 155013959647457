.root {
  margin-block-start: var(--spacing-32);
}

.root.withBorder {
  border-block-start: 1px solid var(--color-border-section);
  padding-block-start: var(--spacing-32);
}

.root h2 {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.root h2 button {
  font-size: var(--font-size-sm);
  font-weight: normal;
}

.content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--spacing-32);
}

.root h2,
.content h4 {
  margin-block-end: var(--spacing-16);
}

.value {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-sm);
  line-height: 1;
}

.text,
.linkContainer {
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-bold);
  line-height: 2;
  margin-block-start: var(--spacing-16);
}

.title {
  font-weight: var(--font-weight-bold);
}
