:root {
  --name-column-min-width: 270px;
}

.container {
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  max-height: calc(100% - 56px);
}

.tableContainer {
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  padding-inline: 10px;
  padding-block: 0;
}

.container table {
  width: 100%;
  table-layout: fixed;
}

.container table thead {
  /* overwrite react-virtuoso css */
  z-index: initial !important;
  position: relative !important;
}

.container table tbody {
  border-inline-start: 1px solid var(--color-table-border);
}

@media (--tablets-min) {
  .container {
    max-height: none;
    padding-block-end: 0;
  }

  .tableContainer {
    padding-inline: 20px;
    padding-block: 0;
  }
}

.table.locked {
  overflow-y: hidden !important;
}

.table:last-child {
  padding-block-end: 20px;
}

.table:focus {
  outline: none;
}

.empty {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  justify-content: center;
  padding: var(--spacing-48);
}

.header {
  align-items: center;
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-bold);
  height: 60px;
  justify-content: flex-start;
  margin: 0;
  text-align: start;
  text-transform: none;
  /* To align checkboxes and prevent jumpy items when ticking / unticking checkbox */
  border-inline-start: 3px solid transparent;
}

.headerColumn {
  align-items: center;
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: space-between;
  padding-inline: 10px 20px;
  padding-block: 0 0;
  min-width: 0;
  cursor: initial;
}

.headerColumn:first-of-type {
  min-width: var(--name-column-min-width);
}

.headerColumn:focus {
  box-shadow: var(--focus-border) !important;
}

.row {
  background-color: var(--color-table-row);
  border-block-end: 1px solid var(--color-table-border);
  border-inline-start: 3px solid transparent;
  cursor: pointer;
  text-decoration: none;
  transition: opacity 0.3s;
  position: relative;
  display: flex;
  align-items: center;
}

.rowLink {
  align-items: center;
  color: inherit;
  display: flex;
  text-decoration: none;
}

.rowLink:hover,
.rowLink:active,
.rowLink:visited,
.rowLink:focus {
  color: inherit;
  text-decoration: none;
}

.row:focus,
.row:hover {
  background-color: var(--color-table-row--hover);
}

.row:active {
  box-shadow: inset 0 -1px 4px 1px rgba(0, 0, 0, 0.07);
}

.row.isLoading {
  opacity: 0.3;
}

.row.isShowingSpinner {
  border-inline-start-color: var(--color-border);
}

.row.isSelected {
  border-inline-start: 3px solid var(--color-controls--selected-marker);
}

.arrow {
  align-items: center;
  display: flex;
  height: 100%;
  position: absolute;
  inset-inline-end: 10px;
  inset-block-start: 0;
}

[dir='rtl'] .arrow {
  transform: scaleX(-1);
}

.column {
  align-items: center;
  border-inline-end: 1px solid var(--color-table-border);
  margin: 0;
  padding: 20px;
  flex: 1;
  min-width: 0;
  display: flex;
}

.column:first-of-type {
  margin-inline-start: 0;
  padding-inline-start: 9px;
  min-width: var(--name-column-min-width);
  overflow: hidden;
}

.column:last-of-type {
  padding-inline-end: 25px;
}

/* Header cell */
.checkAll {
  display: flex;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

.label {
  display: flex;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  width: calc(100% - 40px);
}

.sortToggle {
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 24px); /* 24px: icon size */
}

/* Name cell */
.cellName {
  display: flex;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 1;
}

.cellName.bulkless {
  padding-inline-start: 10px;
}

.cellPlaceholder {
  margin-inline-start: 23px;
}

.checkAll,
.checkbox :global(.peak-form-checkbox) {
  margin-inline-end: 1rem;
}

.checkbox {
  align-items: center;
  display: flex;
}

.footer {
  align-items: center;
  color: var(--color-bg-secondary);
  border-block-end: none;
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: center;
}
