.container {
  display: flex;
  flex-direction: column-reverse;
  margin-block-end: 75px;
  position: relative;
}

.content {
  flex-grow: 0;
  flex-shrink: 1;
  width: 100%;
}

.side {
  flex-grow: 1;
  flex-shrink: 0;
  margin-block-end: 30px;
  min-width: 275px;
}

.schedule {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.selector {
  flex-grow: 1;
  flex-shrink: 1;
  margin-block-start: 16px;
}

.image {
  display: none;
  flex-basis: 151px;
  flex-grow: 0;
  flex-shrink: 0;
}

.image > img {
  display: block;
  width: 100%;
}

.title {
  margin-block-end: var(--spacing-16);
}

.questionSetTitle {
  margin-block: var(--spacing-16);
}

.questionSetDescription {
  margin-block: var(--spacing-16);
}

.manualHeader {
  background-color: var(--color-bg-card);
  border-radius: 4px;
  border: 1px solid var(--color-border);
  height: 56px;
  padding-inline: 16px;
  padding-block: 11px 0;
  margin-block-end: 8px;
}

@media (--desktop) {
  .image {
    display: block;
  }

  .selector {
    padding-inline-end: 40px;
    margin-block-end: 34px;
  }

  .container {
    flex-direction: row;
  }

  .side {
    margin-inline-start: 83px;
    max-width: 300px;
  }
}

.spinner {
  color: var(--color-status-highlight);
  display: flex;
  flex-direction: column;
  align-items: center;
}
