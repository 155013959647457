.root {
  align-self: center;
  margin-inline: auto;
  margin-block: rem(32px);
  padding-inline: var(--spacing-16);
  max-width: 928px;
}

.main > div:not(.recommendedContainer) {
  padding: var(--spacing-24);
}

.main {
  display: flex;
}

.search {
  width: 100%;
}

.search p {
  font-size: var(--font-size-sm);
  margin-inline: 0;
  margin-block: 0 var(--spacing-16);
}

.searchTooltip [data-tippy-root] > div {
  transform: translateY(25px);
}

.searchTooltip [data-popper-arrow] {
  inset-inline-start: 50% !important;
  transform: none !important;
}

.field {
  display: flex;
  flex-direction: column;
  margin-inline: 0 0;
  margin-block: 0 var(--spacing-16);
}

.dateRange {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-16);
}

.withTooltip {
  align-items: center;
  display: flex;
  font-size: var(--font-size-xs);
}

.withTooltip button {
  height: 24px;
  margin-inline-start: var(--spacing-04);
}

.withTooltip [data-tippy-root] {
  font-weight: normal;
  max-width: 206px;
}

.inlineField .withTooltip [data-tippy-root] {
  max-width: 289px;
}

.inlineField .withTooltip {
  font-weight: var(--font-weight-bold);
  margin-block-end: var(--spacing-08);
}

.recommendedContainer {
  border: 1px solid var(--color-border);
  border-radius: 8px;
  margin-inline: var(--spacing-24);
  margin-block-start: var(--spacing-24);
  padding-inline: var(--spacing-24);
  padding-block: rem(12px) var(--spacing-24);
  width: 400px;
  height: fit-content;
}

.recommendedContainer p {
  font-weight: var(--font-weight-bold);
  margin-block-start: 0;
}

.recommendedContainer ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.recommendedContainer li:not(:last-of-type) {
  margin-block-end: var(--spacing-16);
}

.recommendedItemButton {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: var(--color-bg-card);
  padding: var(--spacing-16);
  border-radius: 8px;
}

.recommendedItem {
  display: flex;
}

.recommendedItem svg {
  max-width: 16px;
  max-height: 16px;
}

.recommendedItem span {
  margin-inline-start: 10px;
}

.filters {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-16);
  margin-block-start: var(--spacing-24);
}

.actions {
  padding-inline: 0;
  padding-block: rem(32px) 0;
}

@media (--handhelds) {
  .main {
    flex-direction: column-reverse;
  }

  .recommendedContainer {
    width: calc(100% - (2 * (var(--spacing-24))));
    margin-block-end: var(--spacing-16);
  }

  .dateRange {
    gap: var(--spacing-08);
  }

  .filters {
    gap: var(--spacing-08);
  }
}
