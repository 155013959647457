.container {
  padding-inline: var(--spacing-20);
  padding-block-start: rem(100px);
  text-align: center;
}

.titleWrapper {
  margin-block: var(--spacing-32);
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-08);
  align-items: center;
}
