.container {
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 1;
  width: 100%;
}

.container.hidden {
  display: none;
}

.content {
  width: 100%;
  padding-inline: var(--spacing-16);
}

.section {
  border-block-start: 1px solid var(--color-border-section);
  margin-block-start: 40px;
}

.section:first-of-type {
  padding-block-start: 20px;
  margin-block-start: 20px;
  border-block-start: none;
}

@media (--tablets-min) {
  .section {
    padding-block-start: 40px;
    margin-block-start: 40px;
  }
}
