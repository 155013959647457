.segment {
  align-items: center;
  background-color: var(--color-bg-card);
  border-block-end: 1px solid var(--color-border);
  display: flex;
  font-weight: var(--font-weight-bold);
  min-height: rem(56px);
  padding: rem(15px);
  padding-inline-start: rem(93px);
  position: relative;
  height: var(--default-hierarchy-item-height);
}

@media (--handhelds) {
  .segment {
    padding-inline-start: rem(73px);
  }
}

.segment .avatar {
  inset-inline-start: rem(52px);
  height: rem(26px);
  position: absolute;
  z-index: 2;
}

@media (--handhelds) {
  .segment .avatar {
    inset-inline-start: rem(32px);
  }
}

.addMore {
  margin-inline-start: rem(40px);
}

.flexChildWrapper {
  display: flex;
  height: var(--default-hierarchy-item-height);
}

.child {
  width: 100%;
  background-color: var(--color-bg-primary);
  padding: 0;
  padding-inline-start: rem(102px);
  position: relative;
  transition: background-color 0.2s linear;
}

@media (--handhelds) {
  .child {
    padding-inline-start: rem(82px);
  }
}

.child:last-child {
  margin: 0;
}

.child:hover {
  background-color: var(--color-table-row--hover);
}

.child.isDisabled:hover {
  background-color: var(--color-table-row--hover);
}

.child .avatar {
  margin-inline-end: rem(15px);
  position: relative;
}

.checkbox {
  inset-inline-start: rem(20px);
  position: absolute;
  inset-block-start: 50%;
  transform: translateY(-50%);
}

.child.isChecked {
  box-shadow: inset 3px 0 0 0 var(--color-controls-active-border);
}

.child.isNew {
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  animation-timing-function: var(--ease-in-quint);
}

.row {
  align-items: center;
  display: flex;
  padding-block: rem(10px);
  padding-inline: 0 rem(20px);
  position: relative;
  word-break: break-all;
  width: 100%;
}

.row::before {
  background-color: var(--color-controls-border);
  content: '';
  height: 1px;
  inset-inline-start: -36px;
  position: absolute;
  inset-block-start: calc(50% - 1px);
  width: rem(26px);
}

.type {
  align-items: center;
  border-radius: 2px;
  inset-block-end: -3px;
  display: flex;
  height: rem(12px);
  justify-content: center;
  line-height: 1;
  position: absolute;
  inset-inline-end: -3px;
  width: rem(12px);
}

.type.manual,
.type.derived {
  color: var(--color-text-inverted);
  font-weight: var(--font-weight-bold);
}

.type.manual span,
.type.derived span {
  display: block;
}

.type.manual {
  background-color: var(--color-bg-inverted);
}

.type.derived {
  background-color: var(--color-status-warning);
}

.type.suggested {
  background-color: var(--color-placeholder-bg-neutral);
  font-size: var(--font-size-3xs);
}

.type.suggested i {
  display: block;
}

.parentWrapper {
  display: flex;
  flex-direction: column;
}

.directStatus {
  color: var(--color-text-hint);
  font-weight: normal;
}

.noDelete {
  align-items: center;
  color: var(--color-status-negative);
  display: flex;
  font-size: var(--font-size-2xs);
  line-height: 1;
  opacity: 0;
  transition: opacity 0.2s linear;
}

@media (--handhelds) {
  .noDelete {
    opacity: 1;
  }
}

.noDelete span {
  margin-inline-start: 5px;
}

.child:hover .noDelete {
  opacity: 1;
}

.itemWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

@media (--handhelds) {
  .itemWrapper {
    display: flex;
    flex-direction: column;
  }
}
