.form {
  margin-block-start: 20px;
  max-width: 600px;
}

.formRow {
  padding-block-end: 15px;
}

.footer {
  margin-block-start: var(--spacing-16);
  display: flex;
  gap: var(--spacing-16);
}
