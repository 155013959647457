.previewItems {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-16);
}

.infoIcon {
  vertical-align: bottom;
}

/* 
  Since the modal content is wrapped in two divs because of the ViewManager, 
  we need to add some custom styling to make it work properly with the new bedrock modal
*/

@media (min-height: 400px) {
  /* below 400px viewport height the whole modal is scrollable */
  .modalInnerWrapper {
    display: flex;
    overflow: hidden;
  }
}

.modalContentWrapper[aria-hidden='false'] {
  display: flex !important; /* ViewManager.View sets display: block; this is the only way */
  flex-direction: column;
  width: 100%;
}
