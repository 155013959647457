.addTranslation {
  display: flex;
  align-items: flex-end;
  gap: var(--spacing-16);
}

@media (--handhelds-tablets) {
  .addTranslation {
    align-items: flex-start;
    flex-direction: column;
  }
}

.combobox {
  width: 100%;
}
