.header {
  background-color: var(--color-bg-primary);
  border-block-end: solid 1px var(--color-border-section);
  border-block-start: solid 1px var(--color-border-section);
  padding-inline: var(--spacing-24);
  padding-block: var(--spacing-16);
  outline: 0 !important;
  position: relative;
}

.header::after {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  content: '';
  height: 100%;
  inset-inline-start: 0;
  opacity: 0;
  position: absolute;
  inset-block-start: 0;
  transition: opacity 0.2s;
  width: 100%;
}

.header:hover::after {
  opacity: 1;
  z-index: 1;
}

.collapsed .header {
  border-block-end: 0;
}

.toggler {
  flex: 2;
}

.title {
  color: var(--color-text-primary);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
  margin-block-end: 15px;
}

.badges {
  align-items: center;
  display: flex;
}

.and {
  color: var(--color-text-tertiary);
  margin-inline: 8px;
  margin-block: 0;
}

.innerContent {
  margin: 0 !important;
  padding: 0 !important;
}

.warning {
  margin-block-start: var(--spacing-24);
}

.translation {
  border-block-end: 1px solid var(--color-border-section);
  padding-inline: 0;
  padding-block: var(--spacing-24);
}

.translation:last-child {
  border-block-end: 0;
}

.label {
  font-size: var(--font-size-2xs);
  margin-block-end: 10px;
  font-weight: var(--font-weight-bold);
}
