.root {
  width: 100%;
}

.root label {
  color: var(--color-text-primary);
  font-weight: var(--font-weight-bold);
}

:global(.peakon-select__menu-notice--no-options),
.root :global(.peakon-select__placeholder) {
  color: var(--color-text-tertiary);
}

.field {
  align-items: stretch;
  display: flex;
}

.field > div:first-child {
  flex: 2;
}

.field > button {
  height: 40px;
}

.withButton .field :global(.peakon-select__control) {
  border-radius: 4px 0 0 4px;
  border-inline-end-width: 0;
}

.withButton .field > button {
  border-radius: 0 4px 4px 0;
}

[dir='rtl'] .withButton .field :global(.peakon-select__control) {
  border-radius: 0 4px 4px 0;
}

[dir='rtl'] .withButton .field > button {
  border-radius: 4px 0 0 4px;
}

@media (--handhelds-tablets) {
  .select input {
    /* prevent iOS zoom */
    /* stylelint-disable-next-line */
    font-size: var(--font-size-md) !important;
  }
}
