.root,
.form {
  align-items: center;
  display: flex;
}

.form,
.form > div {
  flex: 2;
}

.form > div {
  margin-inline-start: var(--spacing-16);
}

.form > button {
  margin-inline-start: var(--spacing-04);
}
