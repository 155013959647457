.upload {
  width: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.avatar,
.dropzone {
  position: relative;
  width: 8rem;
  height: 8rem;
  margin-block-end: 1rem;
}

.avatar {
  display: flex;
}

.avatar .image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  border-radius: 4rem;
  width: 100%;
  height: 100%;
  background-color: var(--color-placeholder-bg-neutral);
}

.avatar .removeBtn {
  position: absolute;
  inset-block-end: 0;
  inset-inline-end: 0;
}

.dropzone {
  overflow: hidden;
  border-radius: 4rem;
  background-color: var(--color-controls-bg);
  border: 0.0625rem dashed var(--color-controls-border);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: box-shadow 0.2s;
  cursor: pointer;
}

.dropzone > * {
  pointer-events: none;
}

.dropzone:hover,
.dropzone.isDropping {
  border-color: var(--color-controls-active-border);
  box-shadow: inset 0 2px 10px rgba(0, 0, 0, 0.15);
}

.uploadIllustration {
  width: 3.75rem;
  height: 3.5rem;
}
