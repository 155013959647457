.negativeScore {
  color: var(--color-score-negative);
}

.positiveScore {
  color: var(--color-score-positive);
}

.pointerEvents {
  z-index: 5;
  pointer-events: all;
}

.nudgeIncrease {
  background-color: var(--color-bg-card);
  color: var(--color-status-positive);
}

.nudgeDecrease {
  background-color: var(--color-bg-error);
  color: var(--color-status-negative);
}

.nudgeChange {
  display: flex;
  align-items: center;
  padding: 22px;
  font-size: rem(11px);
  height: 6px;
  justify-content: space-between;
  box-shadow: inset 0 3px 3px 0 rgba(0, 0, 0, 0.03);
  border: solid 1px rgba(0, 0, 0, 0.05);
  margin-inline: 0 0;
  margin-block: 0 5px;
}

.nudgeChange a {
  color: var(--color-status-negative);
  text-decoration: underline;
}

.nudgeChange a,
.nudgeChange button {
  z-index: 5;
}

@media (--handhelds) {
  .nudgeChange {
    flex-direction: column;
    padding-inline: 14px;
    padding-block: 12px;
    height: 100%;
  }

  .deprioritise {
    margin-block-start: 10px;
    width: 100%;
  }
}
