.corner {
  align-items: center;
  border-block-end: 1px solid var(--color-border);
  border-block-start: 1px solid var(--color-border);
  height: 100%;
  width: 100%;
}

.content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  padding-inline: 12px;
  padding-block: 0;
  height: 73px;
}

.content > div,
.content button {
  width: 100%;
}

.footer {
  border-block-start: 1px solid var(--color-border);
  color: var(--color-text-tertiary);
  font-size: var(--font-size-3xs);
  height: 18px;
  line-height: 1.8;
  padding-inline: 12px;
  font-weight: var(--font-weight-bold);
  transform: translateY(-1px);
}
