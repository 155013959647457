.root {
  background-color: var(--color-bg-card);
  border-radius: 4px;
  border: solid 1px var(--color-border);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.02);
  width: 100%;
}

.row {
  border-block-end: solid 1px var(--color-border);
  padding: var(--spacing-16);
}

.loadingIndicators,
.loadingLeft {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-16);
}

.loadingIndicators {
  padding: var(--spacing-08);
  padding-inline-start: var(--spacing-16);
}

.priorityChangeContainer {
  align-items: center;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  border: 0;
  box-shadow: inset 0 3px 3px 0 rgba(0, 0, 0, 0.03);
  display: flex;
  font-size: var(--font-size-2xs);
  height: auto;
  justify-content: space-between;
  margin: 0;
  padding-inline: 22px 8px;
  padding-block: var(--spacing-12);
}

.priorityScore {
  font-size: var(--font-size-base);
}

.priorityScore,
.priorityScore strong,
.priorityScore div {
  font-weight: normal;
}

.priorityScore svg {
  margin-inline-end: rem(8px);
}
