.container {
  display: block;
  height: 100%;
  width: 100%;
  box-shadow:
    inset 1px 1px 0 0 rgba(255, 255, 255, 0.1),
    inset -1px -1px 0 0 rgba(0, 0, 0, 0.1);
  background-color: var(--color-bg-card);
}

.isAlternate {
  background-color: var(--color-table-row-alt) !important;
}

[dir='rtl'] .container {
  box-shadow:
    inset -1px 1px 0 0 rgba(255, 255, 255, 0.1),
    inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
}

.container.empty {
  border: none !important;
  box-shadow: none !important;
  background-color: var(--color-bg-primary) !important;
}

.merged {
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
  border-inline-end: none;
}

.container:last-of-type {
  border-inline-end: 1px solid var(--color-border);
}

.container.expanded {
  border-inline-start: 2px solid var(--color-bg-primary);
  box-shadow:
    inset 1px 0 0 0 var(--color-border),
    inset -1px 0 0 0 var(--color-border),
    inset -1px -1px 0 0 var(--color-border);
  z-index: 2;
}

[dir='rtl'] .container.expanded {
  box-shadow:
    inset -1px 0 0 0 var(--color-border),
    inset 1px 0 0 0 var(--color-border),
    inset -1px -1px 0 0 var(--color-border);
}

.container.parent .content {
  justify-content: space-between;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-inline: 8px;
  padding-block: 8px 6px;
  height: 73px;
}

.content svg {
  color: var(--color-icon-decorative);
}

.clickable:hover {
  cursor: pointer;
}

.loader .content {
  flex-shrink: 0;
  justify-content: space-around;
}

.icon {
  height: 24px;
  width: 24px;
}

.title {
  font-size: var(--font-size-2xs);
  font-weight: var(--font-weight-bold);
}

.merged .footer {
  border-inline-end: 1px solid var(--color-border);
}

.footer {
  align-items: center;
  border-block-start: 1px solid var(--color-border);
  color: var(--color-text-tertiary);
  display: inline-flex;
  font-size: var(--font-size-3xs);
  height: rem(18px);
  line-height: 1.8;
  justify-content: flex-start;
  padding-inline: 8px;
  padding-block: 0;
  width: 100%;
  font-weight: var(--font-weight-bold);
  transform: translateY(-1px);
}

.footer svg {
  margin-inline-start: 3px;
}

.tooltip {
  display: inline-flex !important;
}

.infoIcon {
  height: 1.25rem;
  width: 1.25rem;
}

.text {
  padding-inline: 16px;
  padding-block: 14px 16px;
}

.expandButton {
  position: absolute;
  display: block;
  inset-inline-end: 8px;
  inset-block-start: 8px;
  background-color: transparent;
  border: none;
  width: 12px;
  padding: 0;
  cursor: pointer;
}

.expandIcon {
  height: 0.75rem;
  width: 0.75rem;
}

.expandButton:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none;
}
