.root {
  color: var(--color-text-tertiary);
  font-size: var(--font-size-xs);
  line-height: 1;
  margin-inline-start: 5px;
  font-weight: var(--font-weight-bold);
}

.item,
.count {
  align-items: center;
  display: flex;
}

.item {
  line-height: 1;
  margin-block-end: 7px;
}

.item:last-child {
  margin-block-end: 0;
}

.count {
  color: var(--color-text-tertiary);
  margin-inline-end: 10px;
  font-weight: var(--font-weight-bold);
}

.icon {
  margin-inline-end: 5px;
}
