.root {
  align-items: center;
  display: flex;
  line-height: 1;
}

.field {
  margin-inline: 5px;
  margin-block: 0;
  max-width: 55px;
}

.actions {
  margin-inline-start: var(--spacing-24);
}

.actions button {
  min-width: 80px;
}

.actions button:first-child {
  margin-inline-end: 5px;
}
