.container {
  background-color: var(--color-controls-bg);
  border-radius: var(--border-radius-base);
  border: 1px solid var(--color-controls-border);
  display: inline-flex;
  flex-grow: 0;
  padding-inline: 10px 6px;
  padding-block: 4px 4px;
  transition: box-shadow 300ms ease-in;
  width: 100%;
}

.context {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 1;
  max-width: 200px;
}

.context > span {
  display: block;
  font-size: var(--font-size-xs);
  max-width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.context > div:last-child {
  max-width: 60px;
}

.cross {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-inline-start: 10px;
}

.cross:hover,
.cross:focus {
  opacity: 0.6;
}

.cross > svg > path {
  fill: var(--color-text);
}

@media (--tablets-min) {
  .context {
    max-width: 180px;
  }
}
