:root {
  --width: 170px;
}

.root {
  display: flex;
  padding: 15px;
}

.left {
  min-width: var(--width);
  width: auto;
}

.right {
  width: calc(100% - var(--width));
  margin-inline-start: 28px;
  min-height: 36px;
}

.right span {
  font-family: var(--font-family-base);
  font-size: var(--font-size-base);
  color: var(--color-text-secondary);
}

@media (--handhelds) {
  .root {
    display: block;
  }

  .right {
    width: auto;
    max-width: inherit;
  }
}
