.spinnerContainer {
  display: flex;
  justify-content: center;
  margin-block-end: var(--spacing-24);
  color: var(--color-status-highlight);
}

.selectorPicker {
  display: flex;
  justify-content: center;
  gap: var(--spacing-24);
}

.selectorContainer {
  display: flex;
  gap: var(--spacing-16);
  align-items: flex-end;
}
