.root {
  padding-inline: 34px 24px;
  padding-block: 24px 24px;
}

.header {
  padding-block-end: 24px;
}

.metric {
  display: flex;
  justify-content: space-between;
  border-block-start: 1px solid var(--color-border-section);
  border-block-end: 1px solid var(--color-border-section);
  overflow-x: auto;
}

.colWrapper {
  display: flex;
  width: calc(100% - 444px); /* based on description width */
}

.column {
  display: flex;
  flex: 0 0 50%;
  padding: 24px;
  border-inline-end: 1px solid var(--color-border-section);
}

.column:last-of-type {
  border-inline-end: none;
}

.column button {
  margin-block-start: 8px;
  margin-inline-end: 8px;
}

.description {
  min-width: 444px;
  padding-block: 24px;
  border-inline-end: 1px solid var(--color-border-section);
}

.metricHeader {
  color: var(--color-text-tertiary);
  font-weight: var(--font-weight-bold);
}

.observations {
  padding-inline: 0;
  padding-block: 32px;
}

.observations > div:not(:first-of-type) {
  margin-block-start: 16px;
}

.nextLoader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (--handhelds) {
  .metric {
    flex-direction: column;
  }

  .description {
    border: none;
    min-width: auto;
  }

  .colWrapper {
    flex-direction: row;
    width: 100%;
    border-block-start: 1px solid var(--color-border-section);
  }

  .column {
    border: none;
    padding-inline: 0;
    padding-block: var(--spacing-16);
  }

  .column:last-of-type {
    padding-inline-start: var(--spacing-16);
    border-inline-start: 1px solid var(--color-border-section);
  }
}
