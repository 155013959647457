.root {
  display: flex;
  align-items: center;
  height: 100%;
}

.categoryLabel {
  display: flex;
  align-items: center;
}

.categoryLabelChevron {
  font-size: var(--font-size-md);
  margin-inline-end: var(--spacing-04);
  transform: translateY(-1px);
}

.categoryLabelHeading {
  text-decoration: underline;
}

.parentCategoryLabel {
  color: var(--color-text-tertiary);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-xs);
}

.link {
  display: flex;
  align-items: center;
  gap: var(--spacing-08);
}

.link:hover {
  text-decoration: none;
}
