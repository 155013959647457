.content {
  display: block;
  font-size: var(--font-size-base);
  max-height: 280px; /* 51px (item height) * 5,5 so it's cutted off */
  overflow-y: auto;
  position: relative;
  width: 100%;
}

.row {
  align-items: center;
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: space-between;
  line-height: 1.85;
  white-space: normal;
}

.row svg {
  display: block;
  flex: 0 0 23px;
  height: 24px;
  margin-inline-start: 5px;
  width: 23px;
}

.row.disabled {
  cursor: not-allowed;
}

.row.selected {
  background-color: var(--color-controls--selected-bg);
  color: var(--color-text);
}
