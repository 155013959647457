.root {
  margin-block: var(--spacing-24);
  display: flex;
  gap: var(--spacing-24);
  align-items: center;
}

.root * {
  transition: all 100ms ease-out;
}

@media (--handhelds) {
  .root {
    margin-block: var(--spacing-16);
  }
}

@media (--handhelds) {
  .illustration {
    display: none;
  }
}

.illustration {
  width: 120px;
  height: 60px;
}

.illustration svg {
  width: 100%;
  height: 100%;
}

.compact .illustration {
  width: 60px;
  height: 30px;
}

.compact .title {
  font-size: var(--font-size-md);
  margin-block: 0;
}

.compact.root {
  margin-block: 8px;
}
