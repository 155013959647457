.container {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  padding-inline: 20px 0;
  padding-block: 20px 0;
  overflow: hidden;
}

.header,
.loader {
  max-width: 720px;
}

.loader {
  padding: var(--spacing-20);
  display: flex;
  justify-content: center;
}

.header,
.meta {
  align-items: center;
  display: flex;
}

.title,
.count {
  font-size: var(--font-size-md);
  font-weight: 600;
}

.meta,
.select {
  flex: 1;
}

.count {
  color: var(--color-text-primary);
  margin-inline-start: 5px;
}

.empty {
  align-items: center;
  color: var(--color-text-tertiary);
  display: flex;
}

.search {
  max-width: 255px;
  width: 100%;
}

.list {
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  padding-block-start: 20px;
  overflow-x: scroll;
}

.listInner {
  min-width: 600px;
  max-width: 720px;
}

.row {
  align-items: center;
  display: flex;
  padding-inline: 15px;
  padding-block: 20px;
  text-decoration: none;
}

.link {
  cursor: pointer;
  transition: background-color 0.3s linear;
}

.link:hover {
  background-color: var(--color-controls-hover-bg);
}

.link:hover,
.link:active,
.link:visited,
.link:focus {
  text-decoration: none;
}

.link:hover .arrow {
  color: var(--color-text-tertiary);
}

.content {
  display: flex;
  flex: auto;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  max-width: 100%;
}

.arrow {
  align-items: center;
  display: flex;
  color: var(--color-border-section);
}

.resourceTitle {
  color: var(--color-text-primary);
  text-align: start;
  font-weight: var(--font-weight-bold);
}

.resourceTitle.large {
  font-size: var(--font-size-md);
}

.resourceSubtitle {
  color: var(--color-text-tertiary);
  font-size: var(--font-size-base);
  text-align: start;
}

.disabledTooltip {
  position: absolute;
  inset-inline-end: 15px;
  inset-block-start: 50%;
  transform: translateY(-50%);
}

.disabled {
  background-color: var(--color-status-negative);
  border-radius: 100%;
  height: 6px;
  width: 6px;
}

.resourceMeta {
  align-items: center;
  display: flex;
  margin-block-start: 5px;
}

.expanded .resourceSubtitle {
  color: var(--color-text-tertiary);
  font-size: var(--font-size-2xs);
  line-height: 1;
  text-transform: uppercase;
  font-weight: var(--font-weight-bold);
}

.separator {
  background-color: var(--color-border-section);
  border-radius: 100%;
  height: 2px;
  margin-inline: 5px;
  margin-block: 0;
  width: 2px;
}

@media (--min-width-md) {
  .list {
    overflow-x: auto;
  }
}
