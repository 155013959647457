@keyframes pop-upwards {
  0% {
    opacity: 0;
    transform: matrix(0.97, 0, 0, 1, 0, 12);
  }

  20% {
    opacity: 0.7;
    transform: matrix(0.99, 0, 0, 1, 0, 2);
  }

  40% {
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, -1);
  }

  100% {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
}

@keyframes zoom {
  from {
    opacity: 0;
    transform: scale3d(0, 0, 0);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@keyframes bounceInRight {
  from {
    opacity: 0;
    transform: translate3d(1000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-10px, 0, 0);
  }

  75% {
    transform: translate3d(5px, 0, 0);
  }

  90% {
    transform: translate3d(-3px, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
