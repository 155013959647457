.target {
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  display: inline-flex;
  height: 60px;
  justify-content: space-between;
  min-width: 120px;
  outline: none;
  overflow: hidden;
  padding-inline: 10px;
  padding-block: 0;
  transition: background-color ease-in-out 0.15s;
  width: 100%;
}

.order {
  display: inline-flex;
  padding-inline: 20px 20px;
}

.order.asc {
  transform: scaleY(-1);
}

.hint {
  flex-grow: 1;
  font-size: var(--font-size-2xs);
  margin-inline-start: var(--spacing-08);
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

/* stylelint-disable selector-class-pattern -- peak class */
:global(.peak-menu-item.is-selected) .hint,
:global(.peak-menu-item.is-focused) .hint,
:global(.peak-menu-item):hover .hint {
  opacity: 1;
}
/* stylelint-enable selector-class-pattern */
