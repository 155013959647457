.header {
  align-items: center;
  display: inline-flex;
  margin-block-end: 4.5px;
  color: var(--color-text-secondary);
}

.title {
  color: var(--color-text-tertiary);
  margin-block-end: 0;
}

.header button {
  display: inline-flex;
}

.card {
  align-items: center;
  display: flex;
  min-height: 40px;
  padding-inline: 10px;
  padding-block: 8px;
  margin-block-end: 14px;
}

.card .type {
  align-items: center;
  display: inline-flex;
  min-width: 140px;
}

.icon {
  margin-inline-end: 6px;
}

.content {
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-block-end: -5px;
}

.content > div {
  margin-inline-end: 10px;
  margin-block-end: 5px;
}
