.icon {
  width: rem(41px);
  height: rem(41px);
  flex-shrink: 0;
  margin-block-end: var(--spacing-16);
}

.selector {
  max-width: rem(420px);
}

.label {
  margin-block-start: rem(25px);
}

.header {
  display: flex;
  gap: var(--spacing-12);
}
