.segmentContainer {
  display: flex;
  gap: var(--spacing-08);
}

.segmentAvatar {
  flex-shrink: 0;
}

.segmentInformationContainer {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-04);
  align-content: center;
}
