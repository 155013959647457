.root {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-40);
}

.header {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
}
