.root {
  height: calc(100% - 176px);
  padding-block-end: var(--spacing-32);
}

.table {
  width: 100%;
}

.table thead th {
  background-color: var(--color-bg-primary);
  font-size: var(--font-size-2xs);
}

.table thead tr:first-child th:first-child,
.table thead tr:last-child th,
.table tbody td {
  border-block-end: 1px solid var(--color-border);
}

.table thead th,
.table tbody td {
  padding: var(--spacing-04);
}

.table tbody td {
  text-align: center;
}

.table tbody tr {
  height: 60px;
  position: relative;
}

.table tbody tr::after {
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
  content: '';
  height: 100%;
  inset-inline-start: 0;
  opacity: 0;
  position: absolute;
  inset-block-start: 0;
  transition: opacity 0.2s;
  width: 100%;
}

.table tbody tr:hover::after {
  opacity: 1;
}

.tooltip {
  border-block-end: 1px dotted var(--color-border);
  color: var(--color-text-secondary);
  cursor: help;
  display: inline-block;
  font-size: var(--font-size-3xs);
  line-height: 1;
  text-transform: uppercase;
  font-weight: var(--font-weight-bold);
}

.tooltipContent p {
  margin-inline: 0 0;
  margin-block: 0 var(--spacing-16);
}

.tooltipContent p:last-child {
  margin-block-end: 0;
}

.noResults {
  color: var(--color-text-tertiary);
  padding-inline: 0;
  padding-block: var(--spacing-40);
  text-align: center;
}
