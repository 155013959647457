.root button {
  margin-block-start: 13px;
}

.hidden {
  display: none;
}

.forgotPasswordContainer {
  text-align: center;
  margin-block-start: 20px;
}
