.root {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-16);
  border-inline-start: 1px solid var(--color-border-section);
  transition: background-color 0.2s ease;
}

.root:hover,
.root:focus-visible {
  background-color: rgba(41, 67, 75, 0.05);
}
