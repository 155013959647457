.root {
  margin-inline: 0;
  margin-block: var(--spacing-32) 0;
  position: relative;
  width: 100%;
}

.row {
  position: relative;
}

.verticalGap {
  gap: var(--spacing-16) 0;
}

.engagement {
  position: static;
}

.outcomes {
  margin-block-start: 44px;
}

.attrition {
  margin-block-start: 20px;
}

.engagement,
.participation {
  display: flex;
}

@media (--min-width-lg) {
  .participation {
    margin-block-start: 0;
  }
}

@media (--handhelds) {
  .root {
    margin-block-end: 0;
  }

  /* stylelint-disable-next-line selector-class-pattern -- global styling */
  :global(.l-flex-row).row {
    display: block;
  }
}

@media (--handhelds-tablets) {
  /* stylelint-disable-next-line selector-class-pattern -- global styling */
  :global(.l-flex-row).row {
    display: block;
  }
}

@media (--desktop) {
  .row {
    min-height: 350px;
  }
}
