.commentSummariesContainer {
  padding-inline: var(--spacing-16);
}

@media (--min-width-sm) {
  .commentSummariesContainer {
    padding-inline: 0;
    margin-inline: auto;
    max-width: 600px;
  }
}
