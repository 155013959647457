@import '../../../../shared/src/components/Navigation/variables.css';
@import '../../styles/variables.css';

.main {
  display: flex;
  height: calc(100vh - var(--nav-top-height));
  width: 100%;
  z-index: 101;
  position: fixed;
  inset-block-start: var(--nav-top-height);
}

.main.isViewingAsManager {
  padding-block-start: calc(var(--nav-top-height) + 4px);
  /* Border should match apps/shell/src/components/AppChrome/styles.css */
  border-block-end: 4px solid var(--color-btn--primary-border);
}

@media (max-width: 1199px) {
  .main.isViewingAsManager {
    width: calc(100% - 8px); /* 8px: blue border on View As mode */
  }
}

.content {
  display: flex;
  flex: 2;
  padding: var(--spacing-24);
  width: 100%;
  overflow: auto;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

.content.account {
  padding: 0;
  /* overwrite global styling for mobile */
  /* stylelint-disable-next-line */
  margin-block-start: 0 !important;
  /* stylelint-disable-next-line */
  height: calc(100dvh - var(--nav-top-height)) !important;
}

@media (--handhelds-tablets) {
  /* overwrite global styling for mobile */
  .coursePlayer {
    /* stylelint-disable-next-line */
    margin: 0 !important;
    /* stylelint-disable-next-line */
    height: calc(100dvh - var(--nav-top-height)) !important;
  }
}

.navigation {
  width: var(--nav-side-width);
  display: flex;
  flex-direction: column;
}

.navigation > div {
  flex: 1;
}

.welcomeTour {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline-end: var(--spacing-16);
}

.welcomeTour svg {
  color: var(--color-text-hint);
}

[dir='rtl'] .welcomeTour svg {
  transform: scale(-1);
}
