.container {
  display: flex;
  align-items: center;
  flex: 1;
}

.deactivated {
  font-size: var(--font-size-2xs);
  text-transform: capitalize;
  color: var(--color-status-negative);
  margin-inline: 0 var(--spacing-16);
  margin-block: auto auto;
  flex: 2;
  font-weight: var(--font-weight-bold);
}

.toggle {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-inline-end: 20px;
  text-align: end;
}

.wrapperIconWarning {
  margin-inline: 0 var(--spacing-16);
}
