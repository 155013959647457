.filterSegmentContainer {
  display: flex;
  width: max-content;
  align-items: center;
  border: 1px solid var(--color-border);
  border-radius: rem(4px);
  padding: rem(3px);
  background-color: var(--color-bg-card);
  box-shadow: 0 1px 1px 0 rgb(0, 0, 0, 0.06);
  margin-block: rem(2px);
}

.excluded,
.included {
  padding: var(--spacing-04);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
}

.excluded {
  background-color: var(--color-bg-alert);
}

.filterSegmentDivider {
  color: var(--color-text-tertiary);
  padding-inline: var(--spacing-08);
  font-weight: bold;
  margin: 0;
  display: flex;
  align-items: center;
}
