:root {
  --side-width: rem(280px);
}

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.container {
  align-items: stretch;
  display: flex;
  flex: 2;
  max-height: 100%;
  overflow: hidden;
}

.container.verticalLayout {
  flex-direction: column;
  overflow: auto;
}

.container.verticalLayout .content {
  overflow: unset;
}

.content {
  overflow: auto;
  flex: 2;
  border-inline-start: 1px solid var(--color-border-section);
}

.side {
  width: var(--side-width);
  box-shadow: 2px 0 0 0 rgb(0 0 0 / 2%);
  overflow: visible auto;
  text-align: end;
  transition: all 0.2s;
}

.side.hidden {
  width: rem(50px);
  overflow: unset;
}

.loader {
  padding-block-start: rem(100px);
}
