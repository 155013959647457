.button {
  height: 35px;
  position: relative;
  width: 35px;
}

.badge {
  align-items: center;
  background-color: var(--color-status-highlight);
  border-radius: 100%;
  color: var(--color-text-inverted);
  display: flex;
  font-size: var(--font-size-3xs);
  font-weight: var(--font-weight-bold);
  height: 1rem;
  width: 1rem;
  justify-content: center;
  line-height: 1;
  position: absolute;
  inset-inline-end: calc(-1 * var(--spacing-08));
  inset-block-start: calc(-1 * var(--spacing-08));
}

.overlay {
  background-color: var(--color-bg-overlay);
  content: '';
  height: 100%;
  inset-inline-start: 0;
  opacity: 0.2;
  position: fixed;
  transition: opacity 0.2s;
  inset-block-start: 0;
  width: 100%;
  z-index: 500;
}

.enter {
  opacity: 0;
}

.enter.active {
  opacity: 0.2;
}

.exit {
  opacity: 0;
}

.panel.open {
  transform: translate3d(0, 0, 0);
}

.section {
  align-items: stretch;
  display: flex;
  flex-direction: column;
}

.section:first-of-type {
  padding-inline: 0 0;
  padding-block: 0 16px;
}

.section:not(:first-of-type) {
  padding-inline: 0;
  padding-block: 16px;
}

.section.compact {
  padding: 0;
}

.section > div {
  margin-block-end: 10px;
  padding: 0;
  width: 100%;
}

.section > div:last-child {
  margin-block-end: 0;
}
