.root {
  align-items: center;
  display: flex;
  width: 100%;
}

.root :global(.badge) {
  background-color: transparent;
  border-radius: var(--border-radius-base);
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: var(--color-text-primary);
  display: inline-block;
  font-family: var(--font-family-base);
  font-size: var(--font-size-2xs);
  line-height: 1.27;
  margin-inline-start: 0;
  overflow: hidden;
  padding-inline: 4px;
  padding-block: 0;
}

.icon {
  height: 32px;
}

.content {
  flex: 2;
  margin-inline-start: 25px;
}

.text {
  font-size: var(--font-size-md);
}

.text p {
  display: inline;
  margin: 0;
}

.link {
  align-items: center;
  display: flex;
  line-height: 1;
  margin-block-start: 5px;
  text-decoration: underline;
  font-weight: var(--font-weight-bold);
}

.linkArrow {
  height: 18px;
  transition: transform 0.2s ease;
  width: 18px;
}

.link:hover .linkArrow {
  transform: translateX(5px);
}

.link:hover .linkArrow path {
  fill: var(--color-status-highlight);
}
