.root {
  padding-inline: 0;
  padding-block: var(--spacing-16);
}

.container {
  align-items: flex-start;
  display: flex;
  margin-block-start: rem(5px);
}

.comment {
  border: solid 1px var(--color-border);
  border-radius: var(--border-radius-card);
}

.info {
  padding-inline-end: rem(5px);
}
