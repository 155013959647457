.placeholder {
  overflow: hidden;
  display: flex;
  width: 100%;
  max-width: 100%;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: shimmer;
}

.placeholderInner {
  flex-shrink: 1;
  flex-grow: 0;
  flex-basis: var(--placeholder-width, '');
  height: var(--placeholder-height, '');
  font-size: inherit;
  font-weight: inherit;
  background-color: var(--color-controls--disabled-bg);
}

@keyframes shimmer {
  0% {
    opacity: 0.3;
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 0.3;
  }
}
