.hideOnDesktop {
  display: none;
}

.dropdownContainer {
  display: flex;
  align-items: center;
  padding-inline: var(--spacing-08);
  border-inline-end: 1px solid var(--color-border-section);
}

.addBorder {
  border-inline-start: 1px solid var(--color-border-section);
}

@media (--handhelds) {
  .hideOnDesktop {
    display: block;
  }

  .hideOnMobile {
    display: none;
  }
}
