.header {
  align-items: center;
  display: flex;
}

.header > svg {
  margin-inline-end: rem(8px);
}

.title {
  margin: 0;
}
