.valuesRow {
  display: flex;
  background-color: var(--color-bg-card);
  width: 100%;
  justify-content: space-between;
  padding-inline: var(--spacing-16);
  padding-block: var(--spacing-08);
  border: 1px solid var(--color-border);
}

.valuesRow:hover {
  background-color: var(--color-table-row--hover);
}

.valuesRow:first-child {
  border-block-end: 0;
}

.valuesRow > span {
  margin-inline: 0;
  margin-block: auto;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-base);
  color: var(--color-text-primary);
}

.valuesRow > div {
  display: flex;
}

.valuesRowButtons > label {
  margin-inline-end: var(--spacing-16);
}
