.deadline label {
  color: var(--color-text-secondary);
}

.deadlineInfo {
  display: block;
  margin-block-start: 12px;
  font-size: var(--font-size-base);
  color: var(--color-text-secondary);
}
