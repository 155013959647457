.indicator {
  width: 8px;
  height: 8px;
  background-color: var(--color-status-highlight);
  border-radius: 4px;
}

.message {
  white-space: pre-line;
}
