.pageContentWrapper {
  width: 100%; /* ensure full width if placed inside a flex container */
  margin-inline: auto;
}

.medium {
  max-width: 928px;
}

.full {
  max-width: 100%;
}

/*
  px values are hardcoded to mimic the bootstrap grid — this makes the large variant adaptive.
  This is only needed as there are pages where there are other adaptive elements that it needs to align with.
*/
@media (min-width: 576px) {
  .large {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .large {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .large {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .large {
    max-width: 1140px;
  }
}
