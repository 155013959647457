.root {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  overflow: hidden;
  padding-block: var(--spacing-16) 0;
  padding-inline: var(--spacing-16) 0;
}

.loader {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: var(--spacing-48);
}

.table {
  background-color: var(--color-bg-card);
  margin-block-start: var(--spacing-16);
  max-width: 900px;
  table-layout: fixed;
  width: 100%;
}

@media (max-width: 640px) {
  .table {
    width: auto;
  }
}

.table th {
  background-color: var(--color-bg-primary);
  text-align: start;
}

.table th,
.table td {
  height: 48px;
  padding-block: var(--spacing-08);
  padding-inline: var(--spacing-16);
  vertical-align: center;
}

@media (max-width: 640px) {
  .table th,
  .table td {
    height: auto;
  }
}

.table tbody tr td {
  background-color: var(--color-bg-card);
  border-block-end: 1px solid var(--color-border);
  border-inline-end: 1px solid var(--color-border);
}

.table tbody tr:last-child td {
  border-block-end: 0;
}

.table td:last-child {
  border-inline-end: 0;
}
