.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.attributes {
  flex-shrink: 0;
  padding-inline: 24px;
  padding-block: 26px;
}

.header {
  display: flex;
  align-items: center;
}

.headerTitle {
  font-size: var(--font-size-md);
  margin-block-end: 0;
  font-weight: var(--font-weight-bold);
}

.icon {
  margin-inline-end: 8px;
}

.attribute {
  padding-block-start: 24px;
  padding-inline-start: 32px;
}

.emptyAttributeRow {
  display: flex;
  flex-direction: column;
  padding-inline-end: 2.5rem;
}

.estimate {
  background-color: var(--color-bg-card);
  border-block-start: 1px solid var(--color-border);
  flex-grow: 0;
  flex-shrink: 0;
  padding: 24px;
}

.estimate label {
  text-transform: uppercase;
}

.estimate .number {
  font-size: rem(26px);
  font-weight: bold;
}

.actions,
.numbers {
  display: flex;
}

.actions {
  align-items: center;
  justify-content: center;
  margin-block-start: 18px;
}

.numbers .item {
  flex-grow: 1;
  flex-shrink: 0;
}

.numbers h1 {
  margin-block-end: 0;
}

.total {
  color: var(--color-text-tertiary);
  font-weight: var(--font-weight-bold);
}

.total::before {
  content: '/';
}

.percentage::after {
  color: var(--color-text-tertiary);
  content: '%';
  font-size: var(--font-size-base);
}

.filledAttributeRow {
  display: flex;
  flex-direction: row;
  padding-block-start: var(--spacing-24);
  padding-inline-start: var(--spacing-32);
}

.removeAttributeBtn {
  align-self: flex-end;
}

.selectWrapper {
  width: 100%;
}
