.label strong {
  font-weight: var(--font-weight-bold);
}

.source {
  display: inline-flex;
  flex-wrap: wrap;
  font-weight: var(--font-weight-bold);
  color: var(--color-text-tertiary);
}

.source > span {
  display: inline-block;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.root.clean .label {
  font-size: var(--font-size-3xs);
}

.root.clean .label.small {
  font-size: var(--font-size-2xs);
}

.root.clean .label.xSmall {
  font-size: var(--font-size-3xs);
}

.root.clean .label.xSmall .source > span {
  white-space: break-spaces;
  display: contents;
}

.root.clean .label.tiny {
  font-size: var(--font-size-3xs);
}

.root.slidein .label {
  font-size: var(--font-size-base);
}
