.button {
  align-items: center;
  display: flex;
  padding: var(--spacing-16);
}

.content {
  margin-inline-start: var(--spacing-16);
}

.content strong {
  display: block;
  margin-block-end: var(--spacing-08);
}

.content span {
  margin-inline-start: var(--spacing-04);
}
