.container {
  min-height: rem(180px);
  padding-block: var(--spacing-64);
}

@media (--handhelds) {
  .container {
    padding: var(--spacing-16);
  }
}

.container.withTable {
  padding-block-start: 0;
}

.spinner {
  display: flex;
  justify-content: center;
  padding: var(--spacing-16);
}

.root {
  width: 100%;
}

@media (--handhelds) {
  .root {
    overflow-x: auto;
  }
}
