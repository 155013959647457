.topLevelNavItem {
  position: relative;
  -webkit-font-smoothing: inherit; /* reset style from peak-btn-reset */
  padding-block: var(--spacing-16);
  font-size: var(--font-size-lg);
  color: var(--color-text-primary);
  font-weight: var(--font-weight-bold);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-inline-start: var(--spacing-08);
}

.topLevelNavItem:hover,
.topLevelNavItem:focus {
  color: var(--color-text-secondary);
  text-decoration: none;
}

.topLevelNavItem:hover {
  background-color: var(--color-controls--selected-bg);
}

.topLevelNavItem.active {
  background-color: var(--color-controls--selected-bg);
}

.topLevelNavItem:focus {
  box-shadow: var(--focus-border);
}
