.container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-block-start: 40px;
}

.container:focus {
  outline: 0;
}

.container.separator {
  border-block-start: 1px solid var(--color-border-section);
  margin-block-start: 40px;
  padding-block-start: 40px;
}

.container.spaced {
  margin-block-start: 40px;
  padding-block-start: 40px;
}

.side {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
}

.icon > * {
  width: 48px;
  height: 48px;
}

.content {
  flex-basis: 75%;
  flex-grow: 1;
  flex-shrink: 1;
  opacity: 1;
  transition: opacity 300ms ease-in-out;
}

.collapsible {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  max-height: 100%;
  overflow-y: hidden;
}

.animate.collapsible {
  transition: max-height 300ms ease-in-out;
}

.arrow path {
  fill: var(--color-text-primary);
}

[dir='rtl'] .arrow {
  transform: scaleX(-1);
}

.header {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  margin-block-end: 10px;
}

.sort {
  display: none;
}

.headerTitle {
  color: var(--color-text-primary);
  font-size: var(--font-size-md);
  margin-block-end: 0;
  font-weight: var(--font-weight-bold);
}

.footer {
  margin-block-start: 20px;
}

.list.animate.collapsible {
  transition: max-height 600ms ease-in-out;
}

.list.collapsible {
  max-height: calc(70px * 5 + 1px);
  overflow: hidden;
}

.buttonMore {
  align-items: center;
  background-color: transparent;
  border: 0;
  display: inline-flex;
  height: 24px;
  justify-content: center;
  padding: 0;
  margin-block-end: 24px;
}

@media (--handhelds) {
  .side {
    margin-inline: 0 0;
    margin-block: 0 15px;
  }

  .header {
    margin-inline: 0;
    margin-block: 10px 20px;
  }
}

@media (--tablets-min) {
  .container {
    margin-block-start: 30px;
  }

  .side {
    align-items: flex-start;
    flex-basis: auto;
    flex-direction: column;
    justify-content: flex-start;
    margin-block-end: var(--spacing-24);
  }

  .content .header {
    justify-content: flex-end;
  }

  .header.right {
    justify-content: flex-end !important;
  }

  .header.left {
    justify-content: flex-start !important;
  }

  .group {
    margin-block-end: 24px;
  }

  .group .header {
    justify-content: flex-start;
  }

  .header {
    margin-block: 0 10px;
  }

  .footer {
    margin-block-start: 0;
  }

  .sort {
    display: block;
  }

  .collapsible > .list {
    margin-block-end: 24px;
  }
}

@media (--desktop) {
  .side {
    padding-inline: 0 20px;
    padding-block: 0 0;
    width: 25%;
  }

  .container {
    flex-direction: row;
    margin-block-start: 0;
  }
}

@media (--handhelds) {
  .group {
    padding-block-end: 12px;
  }

  .buttonMore {
    margin-block-end: 0;
  }
}
