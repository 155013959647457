.root {
  width: 100%;
  display: flex;
  max-height: 100px;
  border-block-end: 1px solid var(--color-border);
  padding: 15px;
  margin-block-start: -1px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  transition: ease all 0.2s;
}

.root:hover {
  box-shadow: 0 2px 25px 0 rgba(44, 47, 55, 0.13);
  z-index: 2;
}

.root.draft .thumbnail,
.root.draft .content {
  opacity: 0.5;
}

.thumbnail {
  width: 126px;
  height: 70px;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.content {
  max-width: calc(100% - (144px * 2));
  padding-inline: 15px 24px;
  padding-block: 0 0;
}

.label {
  display: flex;
}

.title {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-bold);
  color: var(--color-text-primary);
  padding-inline: 0 0;
  padding-block: 10px 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description {
  font-size: var(--font-size-base);
  color: var(--color-text-secondary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.actions {
  display: flex;
  place-content: flex-end flex-end;
  align-items: center;
  margin-inline-start: auto;
}

.actions button {
  margin-inline-start: 10px;
}

.actions .draft {
  font-size: var(--font-size-2xs);
  font-weight: 600;
  color: var(--color-text-tertiary);
  margin-inline-end: 10px;
}

.contentWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: calc(100% - 126px); /* 126px is for thumbnail width */
}

@media (--handhelds) {
  .root {
    flex-direction: column;
    max-height: 100%;
  }

  .contentWrapper {
    max-width: 100%;
    padding-block-start: var(--spacing-08);
  }

  .thumbnail {
    max-width: 100%;
  }

  .content {
    max-width: calc(100% - 150px); /* 150px is for actions width */
    padding-inline: 0;
  }

  .actions {
    margin-inline-start: 0;
  }
}
