.group {
  position: relative;
}

.header {
  align-items: center;
  display: flex;
  flex-grow: 1;
}

.header .icon {
  align-self: center;
  display: flex;
  padding-inline: 11px 16px;
  padding-block: 16px 16px;
}

.header .title {
  font-size: var(--font-size-sm);
  font-weight: normal;
  margin-block-end: 0;
}

.driver {
  font-size: var(--font-size-sm);
  margin-inline-start: 16px;
}

.checkAll {
  inset-block-start: 29px;
  position: absolute;
  inset-inline-end: 39px;
  z-index: 3;
  display: flex;
  align-items: center;
  margin-inline-end: rem(12px);
}

.driverWarning {
  display: flex;
  width: 100%;
}

.warningMessage {
  margin-inline-start: auto;
  color: var(--color-text-secondary);
  padding-inline-end: var(--spacing-24);
}
