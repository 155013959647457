.root {
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-bold);
  padding: rem(20px);
}

.row {
  align-items: stretch;
  display: flex;
  margin-block-end: rem(22px);
}

.label {
  align-items: center;
  display: flex;
  flex: rem(0) rem(0) rem(80px);
  margin-inline-end: rem(13px);
}

.label.lastRange {
  flex: 1;
}

.info {
  color: var(--color-text-tertiary);
  display: block;
  font-size: var(--font-size-2xs);
  margin-block-end: rem(20px);
}

.input {
  flex: 2;
}

.invalid input {
  border: 1px solid var(--color-status-negative) !important;
}

.actions {
  align-items: center;
  display: flex;
  flex: 0 0 90px;
  margin-inline-start: rem(20px);
}

.actions button:not(:first-child):last-child {
  margin-inline-start: rem(6px);
}
