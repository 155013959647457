.root {
  flex: 1;
  width: 100%;
}

.container {
  margin-inline: auto;
  margin-block-start: var(--spacing-48);
  padding-inline: var(--spacing-16);
  max-width: 1200px;
  width: 100%;
}

.cards {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
  padding: 0;
}

.card {
  flex: 0 0 25%;
  padding: 10px;
  list-style: none;
  display: grid;
}

@media (--handhelds) {
  .cards {
    flex-direction: column;
    align-content: center;
  }

  .card {
    width: 100%;
  }
}

@media (--collapsed) {
  .card {
    flex: 0 0 33.33%;
    width: 33.33%;
  }
}
