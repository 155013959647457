.ahm {
  margin-inline-end: rem(16px);
  font-family: var(--font-family-base);
  display: flex;
  max-width: rem(300px);
}

@media (--handhelds) {
  .ahm {
    padding-block-start: var(--spacing-08);
    max-width: 100%;
  }
}

.ahmReadMore {
  display: inline-flex;
}

.ahmIcon {
  margin-inline-end: rem(4px);
  color: var(--color-text-tertiary);
}

.ahmContent {
  display: inline;
  color: var(--color-text-tertiary);
}

.checkbox {
  margin-inline-end: rem(9px);
}

.actions {
  align-items: center;
  display: flex;
}

.delete {
  margin-inline-end: rem(10px);
}

.selected {
  color: var(--color-text-primary);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-sm);
}

.selected span {
  color: var(--color-text-tertiary);
  font-weight: var(--font-weight-bold);
}

.suggestions {
  align-items: center;
  background-color: var(--color-bg-info);
  display: flex;
  padding: rem(15px);
  justify-content: space-between;
}

.suggestions.animated {
  animation-duration: 0.6s;
}

.suggestionsIcon {
  margin-inline-end: rem(16px);
}

.suggestionsContent {
  align-items: center;
  display: flex;
}

.suggestionsText {
  padding-block: 0;
  padding-inline-end: 15px;
}

.group {
  position: relative;
}

.group::after {
  background-color: var(--color-controls-border);
  content: '';
  height: 100%;
  inset-inline-start: 65px;
  position: absolute;
  inset-block-start: 0;
  width: 1px;
  z-index: 1;
}

@media (--handhelds) {
  .group::after {
    inset-inline-start: 45px;
  }
}

.group:first-child::after,
.group:last-child::after {
  height: calc(100% - var(--default-hierarchy-item-height) / 2);
}

.group:first-child::after {
  inset-block-start: calc(var(--default-hierarchy-item-height) / 2);
}

.group:only-child::after {
  height: calc(100% - (var(--default-hierarchy-item-height)));
}

.group:last-child {
  border-block-end: 0;
}

.fixedRow {
  display: flex;
  align-items: center;
  padding-inline: rem(20px);
  padding-block: rem(12px);
  background-color: var(--color-table-row-alt);
  justify-content: space-between;
}

.checkboxWrapper {
  display: flex;
  align-items: center;
}

.mobileWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.actionWrapper {
  display: flex;
  align-self: center;
}

@media (--handhelds) {
  .actionWrapper {
    align-self: flex-start;
  }
}

div[role='region'][aria-hidden='false'][id^='accordion'] {
  max-height: fit-content;
}

.loading {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-block: var(--spacing-08);
}
