.toggler {
  border-block-end: 1px dotted var(--color-text-hint); /** custom underline for text */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tableWrapper {
  width: 100%;
}

.table {
  width: 100%;
}

.header {
  border-block-end: 1px solid var(--color-border);
}

.table td,
.table th {
  padding-inline: var(--spacing-24);
  padding-block: var(--spacing-08);
}

.table th {
  color: var(--color-text-body);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-bold);
  text-align: start;
}

.row.current {
  font-weight: var(--font-weight-bold);
}

.row:first-child td {
  padding-block-start: var(--spacing-16);
}

.percentileToggler {
  position: relative;
}

:global([data-theme='deuteranopia']) .row.current.top {
  background-color: rgba(51, 136, 255, 0.1);
}

:global([data-theme='deuteranopia']) .row.current.bottom {
  background-color: rgba(255, 181, 77, 0.1);
}
