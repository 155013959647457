.main {
  padding-inline: var(--spacing-16);
}

@media (--desktop) {
  .main {
    margin-inline: calc(-1 * var(--spacing-24));
  }
}

.title {
  font-size: var(--font-size-2xl);
  margin-block-end: 0;
}

.subtitle {
  margin-block-start: 0;
  color: var(--color-text-tertiary);
}

.titleContainer {
  border-block-end: 1px solid var(--color-border-section);
}

@media (--handhelds) {
  .titleContainer > div {
    flex-direction: column-reverse;
  }
}

.scoreToggler {
  height: 100%;
  display: flex;
  gap: var(--spacing-08);
  align-items: flex-end;
  width: fit-content;
  padding-block-end: var(--spacing-16);
  margin-inline-start: auto;
}

@media (--handhelds) {
  .scoreToggler {
    margin-inline-start: unset;
    margin-block-start: var(--spacing-16);
    padding-block-end: var(--spacing-08);
  }
}

.timeRadio {
  width: 300px;
  padding-block-end: var(--spacing-16);
}

.scoreCard {
  display: flex;
  justify-content: space-between;
}

@media (--handhelds) {
  .scoreCard {
    flex-direction: column;
  }
}

.timeframeCombobox {
  display: flex;
  color: var(--color-text-tertiary);
}

.tableScore {
  display: flex;
  flex-direction: column;
  width: 66%;
  border-inline-start: 1px solid var(--color-border-section);
  align-items: center;
}

@media (--handhelds) {
  .tableScore {
    width: 100%;
    margin: auto;
    padding: var(--spacing-08) var(--spacing-16);
    border-inline-start: none;
    border-block-start: 1px solid var(--color-border-section);
  }
}

.categoryText {
  margin-inline: 0;
  margin-block: auto;
  font-weight: var(--font-weight-bold);
}

.sectionTitle {
  color: var(--color-text-tertiary);
  margin-block-start: auto;
}

.categoryName {
  padding-inline: var(--spacing-16);
  padding-block: var(--spacing-32);
  width: 34%;
  display: flex;
  flex-direction: row;
  margin-block: auto;
  justify-content: space-between;
}

@media (--handhelds) {
  .categoryName {
    width: 100%;
    padding-block: var(--spacing-16) var(--spacing-08);
    padding-inline: var(--spacing-16);
  }
}

.categoryName > div {
  display: flex;
}

.participationCardContent {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
}

@media (--handhelds) {
  .participationCardContent {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.scoreChangeRoot {
  display: flex;
  flex-direction: column;
  width: 100px;
  margin-inline: var(--spacing-08);
  margin-block: 14px;
  flex-shrink: 0;
}

@media (--handhelds) {
  .scoreChangeRoot {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 90px;
    margin: var(--spacing-08);
    margin-inline-end: 0;
  }
}

.scoreChangeBadge {
  display: flex;
  flex-direction: row;
  border-radius: 15px;
  padding-inline: var(--spacing-08);
  padding-block: 0;
  align-items: center;
  width: fit-content;
  margin-block-end: 8px;
}

.scoreChangeBadgeWithBorder {
  border: 1px solid var(--color-border);
}

@media (--handhelds) {
  .benchmarkDiff {
    width: 300px;
    text-align: end;
  }
}

.scoreChangeUp {
  background-color: var(--color-score-diff-15);
}

.scoreChangeUpDeuteranopia {
  background-color: var(--color-score-diff-15-deuteranopia);
}

.scoreChangeDown {
  background-color: var(--color-score-diff-09);
}

.scoreChangeDownDeuteranopia {
  background-color: var(
    --color-score-diff-09
  ); /* negative colors does not have deuteranopia */
}

.noScoreChange {
  background: var(--color-bg-primary);
  border: 1px solid var(--color-viz-grid-line);
}

.scoreNumber {
  color: var(--color-text-primary);
  font-family: var(--font-family-numbers);
  font-feature-settings:
    'kern' on,
    'liga' on,
    'calt' on;
  font-size: var(--font-size-md);
  font-variation-settings: 'fill' 100;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.7);

  /* numbers always displayed ltr */
  direction: ltr;
  unicode-bidi: bidi-override;
  margin: 0;
  margin-inline-end: 5px;
}

.scoreNumberNoChange {
  font-weight: var(--font-weight-bold);
  margin-block: 2px;
  font-size: var(--font-size-sm);
  font-family: var(--font-family-numbers);
}

.participationGraph {
  width: 100%;
  margin-block: var(--spacing-08) var(--spacing-16);
}

@media (--handhelds) {
  .participationGraph {
    order: -1;
    margin: 0;
  }

  .participationGraph::after {
    content: '';
    flex-basis: 100%;
    height: 0;
  }
}

.graphTopLabel {
  margin-inline: var(--spacing-16);
  margin-block: 14px;
}

@media (--handhelds) {
  .graphTopLabel {
    height: 100%;
    margin: var(--spacing-08);
    font-size: var(--font-size-md);
    text-align: end;
    flex-grow: 10;
    margin-inline-end: 0;
  }

  .participationCardContent .graphTopLabel:first-child {
    text-align: start;
    margin-inline-end: var(--spacing-08);
  }
}

.categoryIcon {
  width: 32px;
  height: 32px;
  margin-inline-end: var(--spacing-08);
}

.resultsSection {
  margin-inline: var(--spacing-32);
  margin-block: var(--spacing-16);
  width: 100%;
}

@media (--handhelds) {
  .resultsSection {
    margin: var(--spacing-16);
  }
}

.loadingIndicator {
  margin: auto;
  padding-block: var(--spacing-32);
}

.fullWidthSection {
  width: 100%;
}

.missingDataLabel {
  color: var(--color-text-tertiary);
  font-size: var(--font-size-md);
  text-align: center;
  margin-block-start: var(--spacing-08);
}

.missingDataIcon > svg {
  margin-inline: auto;
  margin-block: var(--spacing-16) 0;
  display: block;
}

.help {
  margin-inline-start: 5px;
}

.titleAndHelp {
  display: flex;
}

.connectorLine {
  margin-inline-start: 30px;
  border-inline-start: 1px solid var(--color-controls-active-border);
  height: 50%;
}

.connectorLineFull {
  height: 100%;
}

.connectorLineHorizontal {
  border-block-start: 1px solid var(--color-controls-active-border);
  width: var(--spacing-16);
  margin-block: auto;
}

@media (--handhelds) {
  .connectorLine,
  .connectorLineFull,
  .connectorLineHorizontal {
    display: none;
  }
}
