.root {
  width: 100%;
  margin-inline: auto;
  margin-block: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  background-color: var(--color-bg-primary);
}

.header {
  font-size: var(--font-size-lg);
  margin-block-end: var(--spacing-48);
  text-align: center;
  font-weight: var(--font-weight-bold);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item {
  display: flex;
  margin-block-end: var(--spacing-40);
}

.icon {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.textContainer {
  max-width: 370px;
  margin-inline-start: 30px;
}

.text {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
}

.description {
  font-size: var(--font-size-base);
}

.special {
  display: flex;
  width: 448px;
  padding: 22px;
  border: 1px solid var(--color-controls-hover-border);
  border-radius: 6px;
  background: var(--color-controls--selected-bg);
  margin-block-end: 40px;
}

.specialText {
  margin-inline-start: 14px;
}

.specialIcon {
  width: 30px;
  height: 30px;
}
