.wrapper {
  box-sizing: border-box;
  transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0)
    scaleX(var(--scale-x, 1)) scaleY(var(--scale-y, 1));
  transform-origin: 0 0;
  touch-action: manipulation;
  display: flex;
  flex-direction: column;
}

.item {
  align-items: flex-start;
  box-shadow: inset 0 -1px 0 0 var(--color-border-section);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 64px;
  padding-inline: rem(14px);
  padding-block: rem(12px);
  position: relative;
  border-inline-end: 4px solid transparent;
  background-color: var(--color-bg-primary);
}

.item:hover,
.item:focus {
  text-decoration: none;
  background-color: var(--color-controls-hover-bg);
  transition:
    background-color 0.25s ease-in-out,
    border-color 0.25s ease-in-out;
}

.item:focus {
  box-shadow: var(--focus-border) !important;
}

.itemActive {
  text-decoration: none;
  background-color: var(--color-controls--selected-bg);
  border-color: var(--color-controls--selected-marker);
}

.itemActive:hover {
  background-color: var(--color-controls--selected-hover-bg);
}

.itemActive .count {
  color: var(--color-text);
}

.itemTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  padding-inline: var(--spacing-04) rem(24px);
  color: var(--color-text);
}

.status {
  inset-inline-start: rem(6px);
  position: absolute;
  inset-block-start: rem(20px);
}

.footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-block-start: var(--spacing-08);
  padding-inline-start: var(--spacing-04);
}

.count {
  display: flex;
  align-items: center;
}

.drag {
  cursor: move;
  inset-inline-end: rem(12px);
  inset-block-start: rem(6px);
  position: absolute;
  display: inline-flex;
}

.icon {
  display: block;
  margin-inline-start: var(--spacing-04);
  height: 0.75rem;
  width: 0.75rem;
}

.isDragging {
  z-index: 2;
  opacity: var(--dragging-opacity, 0.5);
}
