.header {
  align-items: center;
  display: flex;
  margin-block-end: 10px;
}

.language {
  color: var(--color-text-tertiary);
  flex: 2;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-bold);
}

.actions {
  margin-block-start: 15px;
}
