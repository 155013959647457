.root > div {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  transition: transform 400ms ease-in;
  transform: none;
}

.root.appear {
  transform: perspective(500px) rotateX(-0.25turn);
}

.root.appear.appearActive {
  transform: none;
}

.heading {
  font-weight: var(--font-weight-bold);
}

.text {
  overflow-wrap: break-word;
  word-break: break-word;
  word-wrap: break-word;
}
