.container {
  display: flex;
  justify-content: space-between;
  height: inherit;
}

.left {
  width: calc(100% - 326px);
  display: flex;
  padding: 20px;
}

.right {
  width: 326px;
  border-inline-start: 1px solid var(--color-border);
}

.right svg {
  color: var(--color-icon-decorative);
}

.rightTop {
  height: calc(100% / 2);
  border-block-end: 1px solid var(--color-border);
}

.benchmark.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.rightBottom {
  height: calc(100% / 2);
  display: flex;
  justify-content: space-between;
}

.rightTop,
.rightBottomContainer {
  padding: 30px;
}

.rightBottomContainer {
  width: calc(100% / 2);
}

.rightBottomContainer:last-child {
  border-inline-start: 1px solid var(--color-border);
}

.iconContainer {
  margin-inline: 0 20px;
  margin-block: 0 0;
}

.range {
  margin-inline: 0 0;
  margin-block: 5px 0;
}

.summary {
  margin-inline: 0;
  margin-block: 20px;
  font-size: var(--font-size-sm);
  color: var(--color-text-secondary);
}

.rightHeader {
  display: flex;
}

.rightHeaderIcon {
  height: 24px;
  width: 24px;
  vertical-align: middle;
  display: inline-block;
  margin-inline-start: -3px;
}

.rightHeaderText {
  font-size: var(--font-size-xs);
  color: var(--color-text-tertiary);
  margin: 4px;
  font-weight: var(--font-weight-bold);
}

.rightContent > span {
  font-size: var(--font-size-xl);
  color: var(--color-text-primary);
  font-weight: var(--font-weight-bold);
}

.benchmarkEmpty span {
  font-size: var(--font-size-2xs);
  color: var(--color-text-tertiary);
  text-transform: uppercase;
  text-align: center;
  font-weight: var(--font-weight-bold);
}

@media (--handhelds) {
  .container {
    flex-direction: column;
  }

  .left,
  .right {
    width: 100%;
  }
}
