.root {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding: var(--spacing-16);
  padding-inline-end: var(--spacing-08);
}

.header {
  font-size: var(--font-size-xs);
  margin-block-end: 0;
}

.score {
  font-size: var(--font-size-2xl);
  font-family: var(--font-family-numbers);
  font-feature-settings:
    'kern' on,
    'liga' on,
    'calt' on;
  font-variation-settings: 'fill' 100;
  margin-block-end: var(--spacing-16);
}

.score .scoreMax {
  margin-inline-start: rem(2px);
  font-size: var(--font-size-md);
  color: var(--color-text-tertiary);
}

.benchmarkLabel {
  word-break: break-word;
}
