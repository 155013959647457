.root {
  align-items: center;
  display: flex;
  padding-inline-start: 20px;
}

.neverSeen {
  align-items: center;
  color: var(--color-text-tertiary);
  display: inline-flex;
  font-size: var(--font-size-2xs);
  margin-inline: 10px;
  margin-block: 0;
  text-align: end;
}

.eyeIcon {
  margin-inline-end: 10px;
}

[dir='rlt'] .eyeIcon {
  margin-inline: 10px 0;
}
