.title {
  color: var(--color-text-secondary);
  font-weight: normal;
  margin-block-end: var(--spacing-08);
}

.segments {
  display: grid;
  gap: var(--spacing-08);
}

.description {
  margin-inline-start: 0;
}
