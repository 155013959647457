.root {
  display: flex;
  align-items: center;
  max-width: 1180px;
  margin-inline: auto;
  padding: var(--spacing-16);
  border: solid 1px var(--color-border-section);
  border-radius: var(--border-radius-card);
  box-shadow: var(--depth--1-box-shadow);
  background-color: var(--color-bg-secondary);
  justify-content: space-between;
}

.label {
  padding-inline: var(--spacing-08);
  padding-block: var(--spacing-04);
  color: var(--color-text-inverted);
  background-color: var(--color-status-highlight);
  border-radius: 50px;
}

.loader {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  color: var(--color-status-highlight);
  padding-block-start: var(--spacing-32);
}

@media (--handhelds) {
  .root {
    width: auto;
    flex-direction: column;
  }
}
