.segment {
  align-items: center;
  display: flex;
}

.segment .badge {
  display: inline-flex;
}

.button {
  margin-inline-end: var(--spacing-16);
}

.button > button {
  height: 100%;
}

@media (--handhelds) {
  .segment {
    align-items: flex-start;
    flex-direction: column;
  }

  .segment .badge {
    margin-inline-start: 0;
    margin-block-start: 5px;
  }

  .button {
    margin-inline-end: var(--spacing-08);
  }
}
