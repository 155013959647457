.container {
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
}

.field {
  align-items: center;
  display: inline-flex;
}

.input {
  margin-inline: 5px;
  margin-block: 0;
  flex-grow: 1;
  width: 60px;
}

.label {
  min-width: 60px;
}

.actions {
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
}

.actions > button {
  margin-inline-end: 10px;
}

[dir='rtl'] .actions > button {
  margin-inline: 10px 0;
}
