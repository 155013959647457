.root {
  padding-block-end: rem(100px);
}

.root h3 {
  margin-block-end: var(--spacing-16);
}

.intro {
  align-items: flex-start;
  display: flex;
}

.image {
  margin-inline-start: var(--spacing-32);
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-08);
}
