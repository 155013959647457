.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.inner {
  display: flex;
  flex-direction: column;
  line-height: 1;
  text-align: center;
}

.inner.placeholder {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 40px;
  justify-content: space-around;
}

.inner.placeholder .label {
  border: none;
}

.label {
  display: inline-block;
  font-weight: normal;
  text-transform: none;
}

.impact {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tooltipLabel {
  border-block-end: 1px dotted var(--color-border-section);
  font-size: var(--font-size-xs);
  line-height: 1;
  font-weight: var(--font-weight-bold);
}

.tooltip {
  width: rem(320px);
  text-align: start;
}
