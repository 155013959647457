button.header {
  border-block-end: 1px solid var(--color-border);
  border-block-start: none;
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  padding-inline-end: 15px;
  position: relative;
}

.checkbox button.header {
  border-block-end: none;
}

.switch button.header::after {
  box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.1);
  content: '';
  height: 100%;
  inset-inline-start: 0;
  opacity: 0;
  position: absolute;
  inset-block-start: 0;
  transition: opacity 0.2s;
  width: 100%;
}

button.header:hover::after {
  opacity: 1;
  z-index: 1;
}

button.header:focus {
  z-index: 2;
}

.container:first-of-type button.header {
  border-block-start: 1px solid var(--color-border);
}

.headerContainer {
  align-items: center;
  display: flex;
  flex-basis: auto;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: space-around;
}

.headerContainer .space {
  width: 28px;
}

.item {
  align-items: center;
  border-block-end: 1px solid var(--color-border);
  display: flex;
  min-height: 59px;
}

.check {
  min-height: 48px;
  padding-inline-start: 64px;
}

.check .content {
  display: flex;
  align-items: center;
  border-block-start: 1px solid var(--color-border);
  min-height: 48px;
  padding-inline-end: 39px;
}

.check .title {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.header .item {
  border: none;
  flex: 2;
}

.header .itemTitle {
  line-height: 1;
  font-weight: var(--font-weight-bold);
}

.header .itemContent {
  padding: 0;
}

.itemAction {
  display: flex;
  flex-basis: 42px;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: flex-end;
  margin-inline-end: 15px;
  min-width: 50px;
}

.itemIcon {
  align-items: center;
  display: flex;
  flex-basis: 64px;
  flex-grow: 0;
  flex-shrink: 0;
  padding-inline: 20px;
  padding-block: 0;
  color: var(--color-icon-decorative);
}

.itemContent {
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: flex-start;
  padding-inline: 20px;
  padding-block: 0;
}

.itemTitle {
  align-items: center;
  display: inline-flex;
  font-size: var(--font-size-base);
  text-align: start;
  font-weight: var(--font-weight-bold);
}

.itemTitle,
.itemDescription {
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltip {
  margin-inline-start: 5px;
}

.itemDescription {
  color: var(--color-text-secondary);
  font-size: var(--font-size-2xs);
  text-align: start;
}

.itemReason {
  color: var(--color-text-secondary);
  font-size: var(--font-size-2xs);
  margin-inline-end: 20px;
  text-align: end;
}

.headerCount {
  flex-shrink: 0;
  margin-inline-start: 5px;
  padding-inline-end: 20px;
}

.container .innerContent {
  /* stylelint-disable-next-line */
  margin: 0 !important;
  /* stylelint-disable-next-line */
  padding: 0 !important;
}

.container.checkbox {
  border-block-end: 1px solid var(--color-border);
  position: relative;
}

.container.checkbox .innerContent {
  background-color: var(--color-bg-card);
}

.container.switch .innerContent {
  background-color: var(--color-bg-card);
  box-shadow:
    inset 0 3px 3px 0 rgba(0, 0, 0, 0.05),
    inset 0 -3px 3px 0 rgba(0, 0, 0, 0.05);
}

.settings {
  border: 1px solid var(--color-border);
  border-block-end: none;
  border-block-start: none;
}

.settings .item {
  padding: 5px;
}

.helpLink,
.helpLink:hover,
.helpLink:visited,
.helpLink:active,
.helpLink:focus {
  color: var(--color-text);
  text-decoration: underline;
}

.warnings {
  display: flex;
  flex-direction: column;
}
