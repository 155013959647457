.root {
  padding: 20px;
}

.schedule {
  border-block-end: 1px solid var(--color-border);
  margin-block-end: 20px;
  padding-block-end: 20px;
}

.schedule:last-child {
  border: 0;
  margin-block-end: 0;
}

.header {
  border-block-end: solid 1px var(--color-border);
  border-block-start: solid 1px var(--color-border);
  background-color: var(--color-bg-navigation);
  font-size: var(--font-size-md);
  padding-inline: 20px;
  padding-block: 15px;
  position: sticky;
  inset-block-start: 0;
  z-index: 1;
  font-weight: var(--font-weight-bold);
}

.loader {
  padding-inline: 0;
  padding-block: var(--spacing-64);
  display: flex;
  justify-content: center;
}

.emptyState {
  padding: 20px;
  text-align: center;
}

.emptyTitle {
  font-size: var(--font-size-md);
  margin-inline: 0;
  margin-block: 20px 15px;
  font-weight: var(--font-weight-bold);
}

.warning {
  padding-inline: 0 0;
  padding-block: 0 20px;
  text-align: start;
}
