.graph,
.bigGraph {
  align-items: center;
  display: flex;
  justify-content: center;
}

.bigGraph {
  padding-block: var(--spacing-08) var(--spacing-16);
}

.graph :global(.peak-nps-pie--donut) path {
  stroke-width: 1px;
}

.npsDistributionBigLabel {
  font-size: var(--font-size-2xs);
  font-weight: var(--font-weight-bold);
}

.placeholder {
  height: var(--spacing-08);
  width: var(--spacing-32);
  margin-block-start: var(--spacing-04);
}
