.group,
.groupTeam {
  padding-block-end: 28px;
}

.group:first-child {
  padding-block-start: 32px;
}

.group:last-child,
.groupTeam:last-child {
  padding-block-end: 0;
}

@media (--handhelds) {
  .group:first-child {
    padding-block-start: 28px;
  }

  .group,
  .groupTeam {
    padding-block-end: 24px;
  }
}
