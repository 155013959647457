.label {
  font-size: var(--font-size-sm);
  color: var(--color-text-hint);
}

@media (--min-width-sm) {
  .root {
    margin-block-start: var(--spacing-16);
  }
}
