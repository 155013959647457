.list {
  display: grid;
  gap: var(--spacing-16);
}

.spaceBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.emptyIllustration {
  max-width: 100%;
}
