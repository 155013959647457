.root {
  width: 100%;
  max-width: 420px;
  margin-inline: auto;
}

.otherReason {
  margin-block-start: var(--spacing-32);
}

.fieldset {
  /* reset for fieldset */
  border: 0;
  padding: 0;
  margin: 0;
}
