.filter {
  border-block-end: 1px solid var(--color-border);
  margin-block-end: 20px;
  padding-block-end: 20px;
}

.filter:last-child {
  border: 0;
  padding-block-end: 0;
}
