.actions {
  margin-block-start: var(--spacing-32);
}

.selectorHint {
  color: var(--color-text-tertiary);
  font-size: var(--font-size-2xs);
  margin-inline-start: var(--spacing-08);
}

.selectorInfo {
  margin-block-start: var(--spacing-08);
}

.spinner {
  color: var(--color-status-highlight);
  margin-block: var(--spacing-40);
  display: flex;
  align-items: center;
  flex-direction: column;
}
