.container {
  margin-inline: auto;
  margin-block: 5% 0;
  max-width: 928px;
  width: 100%;
}

.add {
  fill: white;
}
