.adjustment {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding-block: rem(20px);
  padding-inline: rem(16px) rem(40px);
  box-shadow: inset 0 -1px 0 0 var(--color-border);
  /* flex-shrink needed for IE to compute heights properly */
  flex-shrink: 0;
}

.title {
  display: flex;
  align-items: center;
}

.title h3 {
  margin-block-end: 0;
  font-weight: var(--font-weight-normal);
  color: var(--color-text-tertiary);
}

.title h3 strong {
  color: var(--color-text-primary);
}

.title svg {
  margin-inline-end: 0.625rem;
}

.adjustmentValue {
  font-size: 1.1875rem;
}
