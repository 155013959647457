@import '../../variables.css';

.menu {
  position: relative;
  background-color: var(--color-controls-bg);
  padding-block: var(--spacing-16);
  width: var(--nav-side-width);
  box-shadow: var(--nav-box-shadow);
  display: flex;
  flex-direction: column;
}

.menu.isCollapsible {
  padding: 0;
  width: 100%;
  max-width: var(--nav-side-collapsed-width);
  box-shadow: none;
  height: calc(100vh - var(--nav-top-height));
  overflow-y: auto;
}

.menu.isCollapsible > .list {
  padding: var(--spacing-24);
}

.list,
.item {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.list.accordion {
  display: none;
}

.list.accordion.isExpanded {
  display: block;
}

.item:not(:last-child) > .list {
  margin-block-end: calc(var(--spacing-24) - rem(10px));
}

.title {
  font-size: var(--font-size-md);
  color: var(--color-text-primary);
  font-weight: var(--font-weight-bold);
  display: flex;
  align-items: center;
  margin: 0;
}

.title:not(.withAccordion) {
  padding-block: rem(12px);
  padding-inline: rem(20px) rem(10px);
}

.isCollapsible .title:not(.withAccordion) {
  padding-block: rem(12px);
  padding-inline: var(--spacing-24) var(--spacing-16);
}

.titleButton {
  position: relative;
  -webkit-font-smoothing: inherit; /* reset style from peak-btn-reset */
  display: flex;
  flex: 1;
  align-items: center;
  padding-block: rem(12px);
  padding-inline: rem(20px) rem(10px);
}

.isCollapsible .titleButton {
  padding-inline: var(--spacing-24) var(--spacing-08);
}

.titleLabel {
  flex: 1;
}

.titleChevron {
  display: block;
}

.titleButton[aria-expanded='true'] .titleChevron {
  transform: scaleY(-100%);
}

.itemLink {
  -webkit-font-smoothing: inherit; /* reset style from peak-btn-reset */
  display: flex;
  padding-block: rem(10px);
  padding-inline: rem(20px) rem(10px);
  font-size: var(--font-size-sm);
  color: var(--color-text-tertiary);
  text-decoration: none;
  width: 100%;
  cursor: pointer;
  overflow-wrap: anywhere;
}

.isCollapsible .itemLink {
  border-radius: var(--border-radius-md);
  padding-inline: var(--spacing-24) var(--spacing-16);
  font-size: var(--font-size-md);
}

.itemLink:hover {
  color: var(--color-text-secondary);
  background-color: var(--color-controls--selected-bg);
  text-decoration: none;
}

.itemLink:focus {
  /* FIXME: remove important once :focus is removed from reset.css */
  text-decoration: none;
}

.itemLink:focus-visible {
  /* FIXME: remove important once :focus is removed from reset.css */
  /* stylelint-disable-next-line */
  box-shadow: var(--focus-inset) !important;
  /* stylelint-disable-next-line */
  outline: var(--focus-outline) !important;
  text-decoration: none;
}

.itemLink.isActive {
  color: var(--color-text-primary);
  text-decoration: none;
  background-color: var(--color-controls--selected-bg);
  position: relative;
}

.menu:not(.isCollapsible) .itemLink.isActive::before {
  content: '';
  position: absolute;
  width: 0.25rem;
  height: 100%;
  inset-block-start: 0;
  inset-inline-start: 0;
  background-color: var(--color-controls--selected-marker);
}

.spacer {
  margin-inline: rem(20px);
  margin-block: var(--spacing-16);
  border-block-end: 1px solid var(--color-border);
}

.spacer.fill {
  flex: 1;
}

.isCollapsible .spacer {
  margin-inline: 0;
}

.badge {
  margin-inline-start: var(--spacing-08);
  border-radius: rem(10px);
  background-color: var(--color-status-highlight-loud);
  min-width: rem(20px);
  padding-inline: rem(5px) rem(6px);
  height: rem(20px);
  line-height: 1.8;
  text-align: center;
  color: var(--color-text-inverted);
  font-size: 0.6875rem;
  font-weight: var(--font-weight-bold);
}
