.header {
  margin-block-end: var(--spacing-24);
}

.disabled {
  opacity: 0.7;
}

:global(.editContent) {
  max-height: 0;
  overflow: hidden;
  transition:
    max-height 0.5s cubic-bezier(0, 1, 0, 1),
    visibility 0.5s;
  visibility: hidden; /* to prevent tabbing children when collapsed */
}

:global(.editContent.expanded) {
  padding-inline: var(--spacing-40) 0;
  padding-block: var(--spacing-24) var(--spacing-04);
  max-height: 1200px;
  transition:
    max-height 1s ease-in-out,
    visibility 1s;
  visibility: visible;
}

:global(.editContent.expanded.single) {
  padding-block-start: 0;
}
