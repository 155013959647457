/*
 * Be careful when changing this: https://css-tricks.com/popping-hidden-overflow/
 * In order for the datepicker to not be `overflow: hidden`, we need to ensure it's
 * relative positioned to an ancestor outside of the `overflow: hidden` div.
 */
.dateFilter > div > label + div,
.dateFilter > div > label + div > input + div {
  position: static !important;
}

/*
 * Corrects the padding lost for positioning it relative to the ancestor
 * instead of the input field
 */
.dateFilter > div > label + div > input + div > div {
  inset-inline-start: 20px;
}

.dateTo {
  margin-block-start: 10px;
}
