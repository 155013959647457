.root {
  display: flex;
  flex-direction: column;
}

.root button {
  width: 100%;
}

.sendViaSms {
  margin-block-end: 15px;
}

.sendViaSms button,
.oneTouch button,
.tokenInput button {
  position: relative;
}

.tokenInput label {
  color: var(--color-text-primary);
  font-weight: var(--font-weight-bold);
}

.tokenInput input {
  margin-block-end: 20px;
}

.oneTouch {
  margin-block-start: 25px;
}
