.container {
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: flex-start;
  margin-inline: auto;
  margin-block: 0;
  max-width: 1200px;
  padding-inline: 20px;
  padding-block: 40px;
  width: 100%;
}

.tabsContainer {
  --divider-color: var(--color-border-section);
}
