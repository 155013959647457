.title {
  margin-block-end: 12px;
}

.action {
  margin-block-end: 12px;
}

.action:last-child {
  margin-block-end: 0;
}
