.content {
  word-break: break-word;
}

.optOut {
  flex: 2;
}

.optOut label {
  line-height: 1;
}

@media (--handhelds) {
  .container {
    width: 100%;
  }

  .content {
    flex: 2;
  }

  .footer {
    flex-wrap: wrap;
    margin-inline: 0;
    margin-block: rem(-5px);
    gap: var(--spacing-04) 0;
  }
}
