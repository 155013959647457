.root {
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius-card);
  background-color: var(--color-bg-secondary);
  box-shadow: var(--depth--1-box-shadow);
  padding-inline: 12px;
  padding-block: 16px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-block-end: 12px;
}

.children > div:not(:first-of-type) {
  margin-inline: 0;
  margin-block: 4px;
}

.actions {
  display: flex;
}

.showMore {
  position: relative;
  display: inline-flex;
  gap: var(--spacing-08);
  align-items: center;
  margin-block: 16px 0;
  color: var(--color-text-secondary);
}

.showMoreIcon {
  transition: all 0.15s ease;
}

.showMore.expanded .showMoreIcon {
  transform: rotate(-180deg);
}

.showMoreText {
  font-size: var(--font-size-xs);
  color: var(--color-text-primary);
  font-weight: var(--font-weight-bold);
}

.showMore:hover {
  text-decoration: underline;
}
