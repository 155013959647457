.container {
  align-items: center;
  background-color: var(--color-bg-primary);
  box-shadow: inset -1px -1px 0 0 rgba(0, 0, 0, 0.1);
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.container.score {
  transition: background-color 300ms ease-in-out;
}

a.container:focus,
a.container:hover {
  opacity: 0.8;
  text-decoration: none;
}

a.container.score:focus {
  box-shadow: var(--focus-inset);
}

.container.classification {
  font-size: var(--font-size-xs);
  justify-content: flex-start;
  padding-inline-start: 10px;
}

.container.classification .risk {
  margin-inline-start: 5px;
  text-decoration: underline;
}

.container.anonymity {
  background-color: var(--color-bg-primary);
}

.container.column {
  border-inline-start: 2px solid var(--color-bg-primary);
  box-shadow:
    inset 1px 0 0 0 var(--color-border),
    inset -1px -1px 0 0 rgba(0, 0, 0, 0.1);
  z-index: 2;
}

[dir='rtl'] .container.column {
  box-shadow:
    inset -1px 0 0 0 var(--color-border),
    inset 1px -1px 0 0 rgba(0, 0, 0, 0.1);
}

.percentage {
  color: var(--color-text-primary);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-bold);
}

.percentage.positive::before {
  content: '+';
}

.percentage::after {
  content: '%';
}

.number {
  color: var(--color-text-primary);
  font-family: var(--font-family-numbers);
  font-feature-settings:
    'kern' on,
    'liga' on,
    'calt' on;
  font-size: var(--font-size-md);
  font-variation-settings: 'fill' 100;

  /* numbers always displayed ltr */
  direction: ltr;
  unicode-bidi: bidi-override;
}
