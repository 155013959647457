.title {
  font-size: var(--font-size-md);
  margin-block-end: 5px;
  font-weight: var(--font-weight-bold);
}

.description {
  font-size: var(--font-size-2xs);
  margin-block-end: 10px;
}

.root :global(.peak-radio-group-item) {
  flex-basis: auto;
}

.item {
  align-items: center;
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 0;
  padding: 13px;
  transition: background-color 0.2s ease;
}

.item svg {
  margin-inline-end: 13px;
}
