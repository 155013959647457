.cardContainer {
  padding: var(--spacing-24);
}

.arrow {
  height: var(--spacing-16);
  transition: transform 0.3s ease;
  vertical-align: middle;
}

.topicLink {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
}

.topicCardTheme {
  width: fit-content;
  height: var(--spacing-24);
  border: 1px solid var(--color-border);
  border-radius: var(--spacing-24);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-inline: var(--spacing-08);
}

.topicThemeBadge {
  height: var(--spacing-16);
  width: var(--spacing-16);
}

.topicThemeBadgeLabel {
  margin-inline-start: var(--spacing-04);
}

.detailsWrapper {
  height: 64px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.semanticTopicsScoreVal {
  padding: var(--spacing-08) var(--spacing-16);
  border: 1px solid var(--color-border);
  font-size: var(--font-size-2xl);
  font-family: var(--font-family-numbers);
  font-variation-settings: 'fill' 100;
  font-feature-settings:
    'kern' on,
    'liga' on,
    'calt' on;
  font-weight: var(--font-weight-bold);
}

.label {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-2xs);
}

.topicsCommentsVal {
  width: 96px;
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  background-color: var(--color-bg-primary);
}
