.root {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.text {
  flex: 3;
}

.content {
  align-items: center;
  display: flex;
}

.icon {
  margin-inline-end: rem(8px);
  color: var(--color-icon-decorative);
}

.button {
  align-items: center;
  display: flex;
  font-weight: bold;
  margin-inline-start: rem(4px);
  text-decoration: underline;
}

@media (--handhelds) {
  .root {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }

  .button {
    margin-inline-start: 0;
    margin-block-start: rem(8px);
  }
}
