.root {
  margin: auto;
  width: 100%;
}

.spinner {
  color: var(--color-status-highlight);
  display: flex;
  justify-content: center;
  padding-block: var(--spacing-32);
}

.container {
  margin-block: var(--spacing-64);
  padding-inline: var(--spacing-16);
}

@media (--min-width-lg) {
  .container {
    padding-inline: 0;
  }
}

.container.grid {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media (--handhelds) {
  .hideOnMobile {
    display: none;
  }
}
