.root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.settings,
.container {
  background-color: var(--color-bg-primary);
}

.settings {
  padding: 20px;
}

.container {
  overflow: hidden;
}
