:root {
  --improve-card-label-action-color: var(--color-bg-info);
  --improve-card-label-course-color: var(--color-bg-card);
}

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 44px;
  height: 20px;
  border-radius: var(--border-radius-base);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-2xs);
  background-color: var(--color-bg-card);
  border: 1px solid var(--color-border);
}

.root.action {
  background-color: var(--improve-card-label-action-color);
  border: 0;
}

.root.featured {
  background-color: var(--color-status-highlight);
  color: var(--color-text-inverted);
}

.root span {
  padding-inline: 4px;
  padding-block: 2px;
}
