.root {
  position: relative;
}

.score {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset: 0;
  z-index: 1;
  line-height: 1;
  font-size: var(--font-size-2xs);
  font-weight: var(--font-weight-bold);
}

/* stylelint-disable-next-line selector-class-pattern -- external library @peakon/peakon-graphs */
.root :global(.nps-pie-no-data) {
  background-color: var(--color-viz-neutral-fill) !important;
}
