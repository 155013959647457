.detailsRoot {
  overflow: hidden;
  height: 100%;
}

.detailsRoot,
.detailsRoot > div[data-reach-tabs] {
  height: 100%;
}

.detailsRoot > div[data-reach-tabs] {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.panelHeader {
  display: flex;
  width: max-content;
  padding: var(--spacing-08);
}

.detailsContent {
  align-items: stretch;
  border-block-start: 1px solid var(--color-border);
  display: flex;
  width: 100%;
  height: 100%;
}

.detailsContent.withTabs {
  height: calc(100% - 3rem);
}

.detailsSegments {
  height: 100%;
  border-inline-end: 1px solid var(--color-border);
  max-width: 360px;
}

.detailsTable {
  flex: 3;
  background-color: var(--color-bg-primary);
}

.detailsTable div[data-reach-tab-panels],
.detailsTable div[data-reach-tab-panel] {
  height: 100%;
}

.detailsTable div[data-reach-tab-panel] {
  background-color: var(--color-bg-primary);
}

.virtuosoContainer {
  height: 100%;
  position: relative;
}
