@import '../../../../styles/variables.css';
@import '../../../../../../shared/src/components/Navigation/variables.css';

.root {
  align-items: stretch;
  display: flex;
  height: 100%;
  position: absolute;
  inset-inline-end: 0;
  inset-block-start: 0;
  width: 100%;
}

.mainContainer {
  height: 100%;
  width: 100%;
}

.fakeFlex {
  align-items: stretch;
  display: flex;
  height: 100%;
}

.fakeCard {
  flex: 3;
  height: 100%;
  padding: 25px;
  width: 100%;
}

.container {
  flex: 2;
  position: relative;
}

.row {
  height: 100%;
}

.content {
  height: 100%;
  inset-inline-start: 0;
  position: absolute;
  inset-block-start: 0;
  width: 100%;
  z-index: 5;
}

.main {
  border-inline-start: 1px solid var(--color-border);
  border-radius: 0 var(--border-radius-card) var(--border-radius-card) 0;
  box-shadow: none;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-inline: 25px;
  padding-block: 15px;
}

.header {
  align-items: center;
  display: flex;
  min-height: 30px;
  justify-content: center;
  line-height: 1;
  margin-block-end: 20px;
  position: relative;
}

.back {
  inset-inline-start: 0;
  position: absolute;
  inset-block-start: 0;
}

.title {
  text-align: center;
  font-weight: var(--font-weight-bold);
}

.help {
  margin-inline-start: 5px;
}

.subdrivers {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  margin: -6px;
  width: 100%;
}

.subdriver {
  flex: 1;
  min-width: 50%;
  padding: 6px;
}

.appear .content,
.enter .content,
.exit .content {
  overflow: hidden;
  transform-origin: left;
}

.appear .content,
.enter .content {
  transform: scaleX(0);
}

.appear.appearActive .content,
.enter.enterActive .content {
  animation: enter 0.3s forwards cubic-bezier(0.165, 0.84, 0.44, 1);
}

.exit.exitActive .content {
  animation: exit 0.2s forwards cubic-bezier(0.165, 0.84, 0.44, 1);
  animation-delay: 0.2s;
}

.exit .content {
  opacity: 1;
  transform: scaleX(1);
}

.appear .title,
.appear .subdrivers,
.appear .help,
.enter .title,
.enter .subdrivers,
.enter .help {
  opacity: 0;
}

.appear.appearActive .title,
.appear.appearActive .subdrivers,
.appear.appearActive .help,
.enter.enterActive .title,
.enter.enterActive .subdrivers,
.enter.enterActive .help {
  animation: fadeIn 0.2s forwards ease-in;
  animation-delay: 0.1s;
}

.exit.exitActive .title,
.exit.exitActive .subdrivers,
.exit.exitActive .help {
  animation: fadeOut 0.2s forwards ease-out;
}

@keyframes enter {
  from {
    transform: scale3d(0.3, 1, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes exit {
  0% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }

  100% {
    opacity: 0.1;
    transform: scale3d(0.1, 1, 1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@media (--large-screens) {
  .container {
    flex: 0 0 300px;
  }
}

@media (--handhelds) {
  .fakeCard {
    display: none;
  }

  .root,
  .content {
    inset-inline-start: 0 !important;
    position: fixed;
    inset-block-start: calc(
      var(--navbar-mobile-height) + var(--context-switcher-toggler-height)
    );
    width: 100vw;
    z-index: 3;
  }

  .content {
    background-color: var(--color-bg-card);
    position: fixed;
    height: calc(100vh - var(--app-header-height-mobile));
    overflow-y: scroll;
  }

  .content.withProxyView {
    width: calc(100% - 8px); /* 8px: proxyView border */
    height: calc(100vh - (2 * var(--nav-top-height)));
    margin-inline-start: 4px;
  }

  /* TODO: this css has no effect, because the selector is wrong */
  /* stylelint-disable selector-class-pattern */
  :global(.app-shareable-dashboard) .root,
  :global(.app-shareable-dashboard) .content {
    height: calc(100vh - var(--shared-dashboard-header-height));
    inset-block: var(--shared-dashboard-header-height) auto;
  }
  /* stylelint-enable selector-class-pattern */

  .main {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    width: 100vw;
  }

  .subdrivers {
    flex-direction: column;
  }

  .subdriver {
    flex: 1;
    max-width: 100%;
    min-width: 100%;
  }
}

@media (--tablets) {
  .fakeCard {
    display: none;
  }

  .content {
    width: 100%;
    position: fixed;
    inset-block-start: calc(2 * var(--nav-top-height));
  }

  .content.withProxyView {
    width: calc(100% - 8px); /* 8px: proxyView border */
    height: calc(100vh - calc(2 * var(--nav-top-height)) - 4px);
    margin-inline-start: 4px;
  }
}

/* fix for <UnstyledButton> which used to have position: relative; */
.relative {
  position: relative;
}
