@import '../../../../../../../../../styles/variables.css';
@import 'animate.css/source/fading_entrances/fadeIn.css';

:root {
  --topic-timeline-height: 112px;
}

.root {
  align-items: flex-start;
  display: flex;
}

.content {
  flex: 2;
}

.graphContainer {
  align-items: stretch;
  display: flex;
  height: var(--topic-timeline-height);
}

.graph {
  border-block-end: 1px solid var(--color-viz-grid-line);
  display: flex;
  flex-direction: column;
  flex: 2;
  height: 100%;
  justify-content: flex-end;
  position: relative;
}

.loader,
.empty {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: var(--topic-timeline-height);
  justify-content: center;
}

.bars {
  align-items: flex-end;
  box-shadow: 0 -1px 0 var(--color-border);
  display: flex;
  height: calc(var(--topic-timeline-height) - 12px);
  position: relative;
  width: 100%;
}

.bars::after,
.bars::before {
  background-color: var(--color-viz-grid-line);
  content: '';
  height: 1px;
  inset-inline-start: 0;
  position: absolute;
  width: 100%;
  z-index: -1;
}

.bars::before {
  inset-block-start: 33.33%;
}

.bars::after {
  inset-block-end: 33.33%;
}

.yAxis3 .bars::before {
  display: none;
}

.yAxis3 .bars::after {
  inset-block-end: 50%;
}

.item {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
}

.expanded.large .item {
  padding-inline: 1px;
  padding-block: 0;
}

.size {
  animation: fadeIn 0.2s forwards cubic-bezier(0.165, 0.84, 0.44, 1);
  color: var(--color-text-secondary);
  font-size: var(--font-size-3xs);
  inset-inline-start: 50%;
  opacity: 0;
  position: absolute;
  inset-block-start: 0;
  transform: translate(-50%, -12px);
  font-weight: var(--font-weight-bold);
}

.distribution {
  height: 100%;
  width: 8px;
}

.expanded.large .distribution {
  width: 3px;
}

.expanded.large .size {
  animation: none;
  display: none;
  opacity: 1;
}

.value {
  animation: grow 0.2s forwards cubic-bezier(0.165, 0.84, 0.44, 1);
  background-color: var(--color-viz-neutral-fill);
  border-radius: 2px;
  border: none;
  box-shadow: 0 0 0 1px inset rgba(0, 0, 0, 30%);
  opacity: 0;
  transform-origin: bottom;
  transform: translate3d(0, 0, 0) scaleY(0);
  width: 100%;
}

.value:first-child {
  border-block-start: 0;
}

.expanded.large .value {
  animation: none;
  opacity: 1;
  transform: translate3d(0, 0, 0) scaleY(1);
}

.item.question .value {
  height: 100%;
  margin: 0;
}

.value.promoters {
  background-color: var(--color-viz-positive-fill);
}

[data-theme='deuteranopia'] .value.promoters {
  background-color: var(--color-viz-positive-fill--deuteranopia);
}

.value.detractors {
  background-color: var(--color-viz-negative-fill);
}

.yAxis {
  color: var(--color-text-secondary);
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-3xs);
  height: calc(var(--topic-timeline-height) - 10px);
  line-height: 1;
  margin-block-start: 10px;
  min-width: 12px;
  padding-inline-end: 5px;
}

.yAxis > div {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
}

.yAxis > div:first-child {
  transform: translateY(-5px);
}

.yAxis > div:nth-child(2) {
  transform: translateY(3px);
}

.yAxis3 .yAxis > div:nth-child(2),
.yAxis > div:nth-child(3) {
  transform: translateY(11px);
}

.yAxis > div:last-child {
  transform: translateY(19px);
}

.yAxis3 .yAxis > div:last-child {
  transform: translateY(28px);
}

.yAxis2 .yAxis > div:last-child {
  transform: translateY(46px);
}

.xAxis {
  align-items: center;
  display: flex;
  min-height: 20px;
  padding-inline: 0;
  padding-block: 5px 0;
}

.xAxis time {
  color: var(--color-text-secondary);
  display: block;
  flex: 1;
  font-size: var(--font-size-3xs);
  text-align: center;
}

.expanded .xAxis time {
  font-size: var(--font-size-2xs);
}

.xAxis time.year {
  font-weight: var(--font-weight-bold);
}

.legend {
  margin-block-start: 11px;
}

@keyframes grow {
  0% {
    opacity: 0;
    transform: translate3d(0, 1px, 0) scaleY(0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scaleY(1);
  }
}
