.root {
  align-items: stretch;
  cursor: pointer;
  display: flex;
  position: relative;
  width: 100%;
  border-radius: 2px;
}

.button,
.input {
  min-height: 44px;
}

.button {
  display: flex;
  align-items: center;
  border-radius: 2px 0 0 2px;
  justify-content: center;
  width: 44px;
  padding: 0;
  background-color: var(--color-btn--primary-bg);
  border-color: var(--color-btn--primary-border);
  color: var(--color-btn--primary-text);
}

.root:hover .button {
  background-color: var(--color-btn--primary-hover-bg);
}

.input {
  align-items: center;
  border-radius: 0 2px 2px 0;
  display: flex;
  flex: 2;
  padding: 5px;
}

.placeholder {
  color: var(--color-controls-placeholder-text);
  margin-inline-start: 10px;
}

.popper {
  border: solid 1px var(--color-border);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.21);
  z-index: 2000;
}

:global([data-placement^='top']) .arrow {
  border-color: var(--color-border) transparent transparent !important;
}

.target {
  padding: 0 !important;
}

.segments {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-block-end: -5px;
}

.segment {
  margin-inline: 0 5px;
  margin-block: 0 5px;
}

@media (--handhelds) {
  .popper {
    height: 100vh !important;
    width: 100vh !important;
    transform: none !important;
    position: fixed !important;
    inset-block-start: 0 !important;
    inset-inline-start: 0 !important;
  }
}
