.link {
  display: none;
  font-size: var(--font-size-2xs);
  color: var(--color-text-primary);
  text-decoration: underline;
  font-weight: var(--font-weight-bold);
}

.link:hover {
  color: var(--color-text-primary);
  text-decoration: none;
}

@media (--tablets-min) {
  .link {
    display: inline;
  }
}
