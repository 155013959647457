.root {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-block: var(--spacing-08);
  padding-inline: var(--spacing-24);
  align-items: center;
  border-block-end: 1px solid var(--color-border-section);
}

.comboboxWrapper {
  width: rem(296px);
  padding-inline-end: var(--spacing-08);
}

.spinner {
  width: 100%;
  padding: var(--spacing-16);
  display: flex;
  justify-content: center;
}
