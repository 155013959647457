.header {
  background-color: var(--color-bg-primary);
  border-block-end: solid 1px var(--color-border-section);
  border-block-start: solid 1px var(--color-border-section);
  outline: 0 !important;
  padding-inline: var(--spacing-24);
  padding-block: var(--spacing-16);
  position: relative;
}

.header::after {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  content: '';
  height: 100%;
  inset-inline-start: 0;
  opacity: 0;
  position: absolute;
  inset-block-start: 0;
  transition: opacity 0.2s;
  width: 100%;
}

.header:hover::after {
  opacity: 1;
  z-index: 1;
}

.title {
  flex: 2;
  color: var(--color-text-primary);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
}

.innerContent {
  margin: 0 !important;
  padding: 0 !important;
}
