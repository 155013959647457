:root {
  --rounds-min-position: 60%;
  --rounds-max-position: 80%;
}

.container {
  height: 86px;
  position: relative;
}

.range,
.graph {
  position: absolute;
  inset-block-start: 0;
}

.range.min {
  inset-inline-start: var(--rounds-min-position);
}

.range.max {
  inset-inline-start: var(--rounds-max-position);
}

.graph {
  height: 80px;
  inset-inline-start: 0;
  width: 100%;
  z-index: 2;
}

.range {
  color: var(--color-text-secondary);
  font-size: var(--font-size-3xs);
  height: 100%;
  z-index: 1;
  font-weight: var(--font-weight-bold);
}

.rangeContainer {
  height: 100%;
  position: relative;
  text-align: center;
  transform: translateX(calc(-50% - 1px));
  transition: all 0.3s linear;
}

[dir='rtl'] .rangeContainer {
  transform: translateX(calc(50% + 1px));
}

.rangeContainer::after {
  background-color: var(--color-viz-grid-line-emphasis);
  border-radius: 1px;
  content: '';
  height: calc(100% - 14px);
  inset-inline-start: 50%;
  position: absolute;
  transform: translateX(-50%);
  inset-block-start: 14px;
  width: 2px;
}

[dir='rtl'] .rangeContainer::after {
  transform: translateX(50%);
}

.bar {
  background-color: var(--color-viz-graph-bg);
  border-radius: 4px 0 0 4px;
  box-shadow: 0 0 0 1px inset var(--color-border);
  height: 16px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.barValue {
  align-items: center;
  background-color: var(--color-viz-positive-fill);
  border-radius: 4px 0 0 4px;
  border: 1px solid var(--color-viz-positive);
  display: flex;
  height: 100%;
  inset-inline-start: 0;
  line-height: 1;
  position: absolute;
  inset-block-start: 0;
  transition: width 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  width: 100%;
}

.barValue.warning {
  background-color: var(--color-viz-negative-fill);
  border-color: var(--color-viz-negative);
}

.count {
  position: absolute;
  inset-block: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  padding-inline: 4px;
}
