.root {
  width: 100%;
  background-color: var(--color-bg-primary);
}

.loader {
  display: flex;
  justify-content: center;
  color: var(--color-status-highlight);
  padding: var(--spacing-24);
}

.account {
  padding: var(--spacing-24);
}

.header {
  margin-block: var(--spacing-24);
}
