.container {
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: flex-start;
  gap: var(--spacing-16);
}

.spinnerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
