.container {
  display: flex;
}

.content {
  display: flex;
  flex-direction: column;
  margin-inline-start: rem(22px);
}

.content.noBenchmark {
  justify-content: center;
}

.header {
  display: inline-flex;
  align-items: center;
  margin-block-end: 4px;
}

.score {
  font-size: rem(18px);
  font-weight: var(--font-weight-normal);
  color: var(--color-text);
  line-height: 1;
}

.tier {
  margin-inline-start: 6px;
}

.icon {
  align-items: center;
  display: inline-flex;
  height: 20px;
  justify-content: center;
  width: 20px;
}

.icon > svg {
  margin-block-start: -2px;
}

/* screen-w-md */
@media (max-width: 768px) {
  .icon {
    height: 24px;
    width: 24px;
  }

  .content {
    margin-inline-start: var(--spacing-08);
  }
}
