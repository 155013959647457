.root {
  margin-inline: auto;
  max-width: rem(580px);
  padding: rem(40px) var(--spacing-16);
}

.results {
  display: flex;
  margin-block: var(--spacing-20);
}

.illustration,
.status {
  flex: 1;
}

.statusItem {
  color: var(--color-status-negative);
  font-size: var(--font-size-md);
  line-height: 1.5;
  font-weight: var(--font-weight-bold);
}

.statusItem.added {
  color: var(--color-status-positive);
}

.log {
  border-block-end: 1px var(--color-border) solid;
  padding-block: var(--spacing-16);
}

.done {
  margin-block: var(--spacing-20) 0;
  text-align: center;
}
