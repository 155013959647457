.translationContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-block-end: var(--spacing-16);
}

.translationContainer > div {
  padding-inline-start: 0;
}

.translationContainer > div:last-child {
  align-self: flex-end;
}

.translationContainer button {
  margin-block-end: auto;
}

.translationsGrid {
  margin-block: var(--spacing-16);
  margin-inline-start: 0;
  padding-inline-start: 0;
}

@media (--handhelds) {
  .translationContainer {
    flex-direction: column;
  }

  .translationContainer > div {
    margin-block-end: var(--spacing-16);
  }
}
