.withFrame {
  padding: var(--spacing-24);
  border-radius: var(--border-radius-card);
  border: 1px solid var(--color-border-section);
}

.illustration {
  width: 240px;
  max-width: 100%;
  height: auto;
}
