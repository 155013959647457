.navActions {
  border: none;
  padding: 0;
  height: auto;
}

.scoreModeToggler {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-08);
}

.main {
  align-items: flex-start !important;
  display: flex;
  height: auto !important;
  padding-inline: 0 !important;
  padding-block: var(--spacing-24) !important;
  font-size: var(--font-size-md);
}

.main.topic {
  align-items: stretch;
  padding-inline: 25px;
  padding-block: 0;
}

.topic .info {
  flex: 2;
  padding-inline: 0;
  padding-block: 30px;
  width: 100%;
}

.timeline {
  border-inline-start: 1px solid var(--color-border-section);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-inline: 30px 0;
  margin-block: 0 0;
  min-width: 380px;
  padding-inline: 30px 0;
  padding-block: 30px 30px;
}

.expanded .info {
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-name: infoOut;
  animation-timing-function: ease-in;
  position: absolute;
  transform-origin: left;
  width: 660px;
}

.expanded .timeline {
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-name: timelineIn;
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  border-inline-start: 0;
  margin: 0;
  padding: 0;
  transform-origin: right;
  width: 100%;
}

.navMobile {
  display: none;
}

@keyframes timelineIn {
  from {
    opacity: 0.4;
    transform: scaleX(0.3);
  }

  to {
    opacity: 1;
    transform: scaleX(1);
  }
}

@keyframes infoOut {
  from {
    opacity: 0.6;
  }

  to {
    display: none;
    opacity: 0;
  }
}

.dropdownContainer {
  display: flex;
  align-items: center;
  padding-inline: var(--spacing-08);
}

.dropdownContainer:first-child {
  padding-inline-start: 0;
}

.addBorder {
  border-inline-start: 1px solid var(--color-border-section);
}

.hideOnDesktop {
  display: none;
}

@media (--handhelds) {
  .main {
    flex-direction: column !important;
  }

  .navMobile {
    display: flex;
  }

  .navActions {
    margin: 0;
    justify-content: space-between;
    margin-block: var(--spacing-04) var(--spacing-16);
    padding-inline: var(--spacing-16) var(--spacing-24);
  }

  .export,
  .breadcrumbs {
    display: none;
  }

  .scoreModeToggler {
    margin: 0;
  }

  .timeline {
    border-inline-start: 0;
    border-block-start: 1px solid var(--color-border-section);
    margin-inline: 0;
    margin-block: 30px 0;
    min-width: 100%;
    padding-inline: 0;
    padding-block: 30px;
  }

  .hideOnMobile {
    display: none;
  }

  .hideOnDesktop {
    display: block;
  }

  .dropdownContainer:first-child {
    padding-inline-start: var(--spacing-08);
  }
}

@media (--tablets) {
  .section {
    padding-inline: 0;
    padding-block: 2.5px;
  }

  .section > div {
    padding-inline: 5px;
    padding-block: 2.5px;
  }

  .navActions {
    margin: 0;
    justify-content: space-between;
    margin-block: var(--spacing-04) var(--spacing-16);
    padding-inline: var(--spacing-16) var(--spacing-24);
  }
}
