.root {
  display: flex;
  flex-direction: column;
  height: calc(100% - 96px);
}

.container {
  padding: 20px;
}

.container.kiosk {
  padding: 0;
}

.card {
  margin-block-end: 5px;
}

.card:last-child {
  margin-block-end: 0;
}
