.root {
  display: flex;
  flex-direction: column;
  padding-inline: 16px;
  padding-block: 24px;
}

.loader {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: var(--spacing-24);
}

.empty {
  margin-inline: 4px;
  margin-block: 0;
  border-radius: var(--border-radius-card);
  border: 1px dashed var(--color-border);
  background-color: var(--color-bg-secondary);
  font-size: var(--font-size-sm);
  padding-inline: 32px;
  padding-block: 24px;
  text-align: center;
  color: var(--color-text-secondary);
}
