.content {
  display: flex;
  flex-direction: column;
}

.side {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.sideContent {
  align-items: center;
  display: flex;
  justify-content: center;
}

.footer {
  margin-block-start: var(--spacing-16);
}
