.root {
  width: 100%;
}

.root tr th:first-of-type,
.root tr td:last-of-type {
  padding-inline-end: 15px;
}

.root tr th:first-of-type,
.root tr td:first-of-type {
  padding-inline-start: 15px;
}

.root.loading .spinner {
  color: var(--color-status-highlight);
  justify-content: center;
  display: flex;
}

.root tbody td,
.root thead td,
.root thead th {
  padding-inline-end: 5px;
}

.root thead td,
.root thead th {
  max-width: 115px;
  text-align: start;
}

.root tr {
  height: 48px;
  border-block-end: 1px solid var(--color-border);
}

.root tr.withSegments {
  border-block-end: 0;
}

.root tbody tr {
  background-color: var(--color-bg-card);
  height: 57px;
  transition:
    background-color 0.35s,
    box-shadow 0.2s;
}

.root tbody td {
  font-weight: var(--font-weight-bold);
}

.root tbody td.loader {
  padding: 60px;
}

.root tbody tr.empty td {
  text-align: center !important;
}

.root tbody tr td:first-of-type {
  border-inline-start: 1px solid var(--color-border);
  max-width: 500px;
  padding-inline: 15px 25px;
  padding-block: 10px 10px;
  white-space: normal;
  font-weight: var(--font-weight-bold);
}

.root tbody tr td:last-of-type {
  border-inline-end: 1px solid var(--color-border);
  text-align: end;
}

/* FIXME: ContextLabel modifier? */
.root tbody tr td:first-of-type > div > span {
  font-size: rem(13px);
}

td.targetSegmentsCell {
  padding-block-start: 0 !important;
  text-align: start !important;
}

.noAccess {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--spacing-04);
  font-weight: normal;
  color: var(--color-text-hint);
}
