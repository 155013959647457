.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1px;
  border-radius: 8px;
  background-color: var(--color-border);
  overflow: hidden;
}

.card {
  flex: 1 0 40%;
  min-width: 300px;
  background-color: var(--color-bg-card);
  padding: var(--spacing-16);
}

.cardFull {
  flex-basis: 100%;
}

.change {
  font-size: var(--font-size-2xl);
}

.commentChange {
  font-weight: bold;
}

.scoreChange {
  font-family: var(--font-family-numbers);
  font-variation-settings: 'fill' 100;
  font-feature-settings:
    'kern' on,
    'liga' on,
    'calt' on;
}

.icon {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.relatedDriversButton {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.rightArrow {
  color: var(--color-text-tertiary);
}

.listReset {
  list-style-type: none;
  padding-inline-start: 0;
  margin-block: 0;
}

.bold {
  font-weight: var(--font-weight-bold);
}

.noData {
  padding-block-start: var(--spacing-16);
}
