.root {
  margin-block: var(--spacing-16);
}

.actions {
  display: flex;
  gap: var(--spacing-08);
}

.cardContainer {
  padding: var(--spacing-24);
  display: flex;
  flex-direction: row;
  gap: var(--spacing-16);
  background-color: var(--color-bg-info);
  border-radius: var(--border-radius-card);
}

.icon {
  font-size: var(--font-size-md);
}

.cardHeader {
  display: flex;
  justify-content: space-between;
}

.cardContent {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
}
