:root {
  --resource-width: 250px;
  --resource-height: 273px;
  --resource-footer-height: 50px;
}

.wrapper {
  padding-inline: 0;
  padding-block: var(--spacing-16);
  width: var(--resource-width);
  display: flex;
}

.container,
.header,
.footer {
  box-sizing: border-box !important;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: var(--resource-height);
  width: 100%;
  transition:
    box-shadow 0.2s ease-in-out,
    transform 0.2s ease-in-out;
}

.container:hover,
.container:focus-within {
  outline: 3px solid transparent;
  transform: scale(1.06);
  z-index: 4;
}

.header {
  height: var(--resource-height);
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-height: var(--resource-footer-height);
  height: auto;
  padding: 15px;
  margin-block-start: auto;
}
