.headerWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.headerRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.headerLeft .titleContainer {
  display: flex;
  align-items: center;
}

.headerActive > svg {
  color: var(--color-controls-active-border);
}

.headerDone > svg {
  color: var(--color-score-positive);
}

.headerDone .titleContainer {
  margin-block-end: var(--spacing-08);
}

.domainDisabled > svg {
  color: var(--color-viz-warning);
}

.headerLeft {
  display: flex;
  flex-direction: row;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  padding-inline-start: var(--spacing-16);
}
