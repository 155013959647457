.arrow {
  margin-inline-end: 10px;
  margin-block: auto;
}

.categoryName {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.categoryNameRoot {
  width: 34%;
  display: flex;
  padding-inline-end: var(--spacing-16);
}

.categoryNameRoot:hover {
  background: var(--color-bg-info);
}

.categoryNameRoot:hover .arrow {
  margin-inline-end: 0;
}
