.root {
  align-items: center;
  display: flex;
  line-height: 1;
  font-size: inherit;
  margin-block-end: 0;
  text-transform: none;
}

.value {
  align-items: center;
  display: flex;
}

.help > div {
  margin-inline-start: var(--spacing-04);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.badge {
  position: relative;
  color: var(--color-text-tertiary);
}
