@import '../../../styles/animations.css';

.root {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: var(--font-size-sm);
  height: 28px;
  line-height: 1;
  padding: 5px;
  position: relative;
  transition: 0.2s ease color;
  user-select: none;
  font-weight: var(--font-weight-bold);
}

.root.active,
.root:hover {
  color: var(--color-status-highlight);
}

.checkmark {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 18px;
  justify-content: center;
  margin-inline-end: var(--spacing-04);
}

.root.active .checkmarkIcon path,
.root:hover .checkmarkIcon path {
  color: var(--color-status-highlight);
}

/* .menu {
  animation: pop-upwards 0.15s forwards linear;
  position: absolute;
 inset-inline-start: -20px;
 inset-block-start: -55px;
  z-index: 10;
} */

.menu ul {
  display: flex;
}
