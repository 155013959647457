.form {
  display: grid;
  gap: var(--spacing-32);
}

.formTitle {
  margin-block-end: var(--spacing-16);
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
}
