.actionPlanContainer,
.teamSuggestionsContainer,
.suggestedContainer {
  padding-inline: var(--spacing-24) var(--spacing-16);
  padding-block: var(--spacing-16);
}

.suggestedContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
}

.divider {
  margin-inline-start: 56px;
  width: calc(100% - 56px);
  border-block-start: 1px solid var(--color-border);
}

.actionIcon {
  margin-inline: 0 10px;
  margin-block: 0 -7px;
}

.improveMessageContainer {
  color: var(--color-text-primary);
  font-size: var(--font-size-2xs);
}

.suggestedTooltip {
  margin-inline-start: calc(var(--spacing-32) + var(--spacing-04));
}

.suggestedTooltipContent ul {
  margin: 0;
  padding-inline: 20px 0;
  padding-block: 0 10px;
  color: var(--color-text-primary);
}

.suggestedTooltipContent strong {
  font-weight: var(--font-weight-bold);
}

.suggestedTooltipContent a {
  width: 100%;
}

.buttonContainer {
  display: flex;
  justify-content: space-around;
  z-index: 6;
}

.button {
  margin-inline-start: 4px;
}

.actionsButtonContainer,
.actionsButtonContainer a {
  pointer-events: all;
}

@media (--handhelds) {
  .suggestedTooltip {
    margin-block-end: 10px;
  }

  .buttonContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .button {
    margin-inline-start: unset;
    flex: 1;
    margin-block-start: 3px;
  }

  .button:last-of-type {
    margin-inline-start: 3px;
  }
}
