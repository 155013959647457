@import 'animate.css/source/fading_entrances/fadeIn.css';
@import '../../../../../shared/src/components/Navigation/variables.css';

.root {
  color: var(--color-text-primary);
  cursor: pointer;
  font-size: var(--font-size-base);
  position: relative;
  font-weight: var(--font-weight-bold);
  height: var(--context-switcher-toggler-height);
  background-color: var(--color-bg-card);
  box-shadow: var(--nav-box-shadow);
  border-block-end: 1px solid var(--color-border);
}

.root.isCollapsible {
  position: fixed;
  inset-inline-start: 0;
  width: 100%;
  background-color: var(--color-bg-primary);
  box-shadow: none;
}

.root.withRounds {
  position: relative;
}

@media (max-width: 1199px) {
  .root.isCollapsible.withProxyView {
    width: calc(100% - 8px); /* 8px: blue border on View As mode */
    inset-inline-start: 4px;
  }
}

.root:focus {
  box-shadow: var(--nav-focus-ring) !important;
}

.root.loader {
  cursor: default;
}

.root .container {
  align-items: center;
  display: flex;
  height: 100%;
  padding-inline: 1.25rem 1.125rem; /* right is 10px + 0.5rem */
  transition:
    background 0.15s ease-in-out,
    color 0.15s ease-in-out;
  width: 100%;
}

.root.open .container {
  color: var(--color-text-secondary);
}

.context {
  flex: 2;
}

.indicator {
  transform: rotate(-180deg);
  transition: transform 0.15s ease;
}

.open .indicator {
  transform: rotate(0) translateY(2px);
}

.loader .context {
  align-items: center;
  display: flex;
}

.loader .avatar {
  padding-inline-end: var(--spacing-16);
}

.loader .indicator path {
  fill: var(--color-text-tertiary);
}
