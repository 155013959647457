:root {
  --card-min-height: 54px;
  --card-padding-top-bottom: 15px;
  --card-max-width: 720px;
  --course-text-color: var(--color-text);
}

.card {
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  border-block-start: 1px solid var(--color-border);
  padding: 15px;
  transition: box-shadow 300ms ease-in-out;
  min-height: calc(
    var(--card-min-height) + (var(--card-padding-top-bottom) * 2)
  );
  width: 100%;
}

.revisitSummaryContainer {
  margin-inline-start: 115px;
}

.completedText {
  color: var(--color-text-tertiary);
}

.inProgressText {
  color: var(--color-status-highlight);
}

.link,
.link:hover,
.link:focus,
.link:active {
  align-items: center;
  color: var(--color-text-secondary);
  display: flex;
  flex-grow: 1;
  justify-content: center;
  text-decoration: none;
}

.link:focus {
  outline: 2px solid transparent;
}

.cardContainer {
  cursor: pointer;
  max-width: var(--card-max-width);
  transition: transform 300ms ease-in-out;
  width: 100%;
  position: relative;
}

.expanded {
  transform: scaleY(1.03);
}

.lessonInfoContainer {
  display: flex;
  flex-direction: row;
}

.card:hover {
  box-shadow: var(--depth-2-box-shadow);
}

.card h3:hover {
  text-decoration: underline;
}

.inner {
  display: flex;
  flex-basis: 0;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}

.media {
  display: none;
  margin-inline: auto;
  margin-block: 0;
  text-align: center;
  min-width: 54px;
  height: 54px;
}

.media .duration {
  margin-block-start: 10px;
  text-align: center;
}

.content .duration {
  display: none;
  margin-block: 0 15px;
  text-align: start;
}

.thumbnail {
  background-color: var(--color-border);
  background-position: center;
  background-size: cover;
  border-radius: 4px;
  display: block;
  height: 100%;
}

.thumbnail img {
  max-width: calc(160px / 2);
}

.thumbnailCompleted {
  background-color: rgba(100, 100, 100, 0.6);
  border-radius: 4px;
  height: 100%;
  position: relative;
}

.thumbnailCompletedIcon {
  float: left;
  inset-inline-start: 50%;
  position: absolute;
  text-align: center;
  inset-block-start: 50%;
  transform: translate(-50%, -50%);
}

[dir='rtl'] .thumbnailCompletedIcon {
  float: right;
}

.content {
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
  max-width: 454px;
  width: 100%;
}

.contentHeader {
  margin-block-end: 15px;
  max-width: 454px;
}

.learnings {
  padding-inline-start: 15px;
}

.learning {
  line-height: 1.8;
}

.suggestActions {
  margin-block-start: 15px;
}

.expandedContent {
  margin-block-end: 10px;
}

.revisitSummary {
  margin-block-start: 8px;
  font-size: var(--font-size-base);
  color: var(--course-text-color);
  align-items: center;
  display: inline-flex;
  text-decoration: none;
  font-weight: var(--font-weight-bold);
}

.summaryDivider {
  border-block-start: 1px solid var(--color-border);
  width: 124px;
}

.summaryLinkArrow {
  align-items: center;
  display: flex;
}

.summaryLinkArrow path {
  fill: var(--course-text-color);
}

.arrowContainer {
  align-items: center;
  display: flex;
  height: 54px;
  margin-inline-start: 15px;
  transition: opacity 300ms ease-in-out;
  width: 34px;
}

[dir='rtl'] .arrow {
  transform: rotate(180deg);
}

.expandArrowContainer {
  align-items: flex-start;
  display: flex;
  justify-content: center;
  width: 40px;
  height: 54px;
}

.progressBarContainer {
  background-color: var(--color-viz-08);
  border-radius: 0 0 3px 3px;
  height: 5px;
  margin-block-start: -5px;
  position: relative;
  display: none;
}

.progressBar {
  background-color: var(--color-viz-12);
  border-radius: 0 3px 3px 3px;
  inset-block-end: 0;
  height: 100%;
  position: absolute;
}

.lessonContainer {
  position: relative;
  max-width: var(--card-max-width);
}

.lessonContainer:not(.lessonContainerExpanded) {
  margin-block-end: -1px;
}

.lessonContainer.lessonContainerExpanded {
  margin-inline: 0;
  margin-block: 15px;
}

.lessonContainer.lessonContainerExpanded:first-child {
  margin-block-start: 0;
}

.progress,
.progressLine {
  position: absolute;
  inset-inline-start: -78px;
}

.progress {
  width: 40px;
  height: 40px;
  background-color: var(--color-bg-card);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-border);
}

.progress span {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
  transition: ease all 0.15s;
}

.lessonContainer.lessonContainerExpanded .progress {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
}

.lessonContainer.lessonContainerExpanded .progress span {
  color: var(--color-status-highlight);
}

.circularProgressBarPath {
  stroke: var(--color-status-highlight);
}

.circularProgressBarTrail {
  stroke: var(--color-viz-neutral-fill);
}

.progress.completed > svg:first-of-type {
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
}

.progress.completed .circularProgressBarPath {
  stroke: var(--color-status-highlight);
}

.circularProgressBarText {
  color: var(--color-status-warning);
  dominant-baseline: middle;
  font-size: rem(
    36px
  ); /* has to be a higher font-size because of the width of the svg container */

  font-weight: var(--font-weight-bold);
  text-anchor: middle;
}

.progressLine {
  inset-inline-start: -60px;
  inset-block-start: 50px;
  height: calc(100% - 60px);
  width: 2px;
  background: var(--color-border-section);
}

@media (--tablets-min) {
  .card {
    padding-inline: 0 15px;
    padding-block: 15px 15px;
  }

  .expanded {
    transform: scale(1.03);
  }

  .expanded .arrowContainer {
    align-self: flex-start;
  }

  .expanded .arrowContainer > div {
    align-self: center;
  }

  .content .duration {
    display: none;
  }

  .inner {
    flex-direction: row;
  }

  .media {
    display: block;
    margin-inline: 0 23px;
    margin-block: 0 0;
    text-align: start;
  }

  .arrowContainer {
    align-self: center;
    min-width: 130px;
    width: auto;
  }

  .arrowContainer .duration {
    margin-inline-end: 15px;
  }

  .arrow {
    height: 54px;
    width: 54px;
  }

  .lessonContainer {
    width: 100%;
  }
}

@media (--handhelds-tablets) {
  .expanded {
    transform: scale(1);
  }

  .progressBarContainer {
    display: block;
  }

  .card,
  .progressBar,
  .progressBarContainer {
    border-radius: 0;
  }

  .expanded .card {
    height: auto;
  }

  .content .header {
    margin-block-end: 5px;
    font-size: var(--font-size-md);
  }

  .arrowContainer,
  .arrowContainer svg {
    width: 34px;
    height: 34px;
    justify-content: flex-end;
  }

  .arrowContainer .duration {
    display: none;
  }

  .content .duration {
    display: block;
    margin-block-end: 0;
    margin-inline-start: -5px;
  }

  .progress,
  .progressLine {
    display: none;
  }
}

@media (--tablets) {
  .cardContainer,
  .xainer {
    max-width: 900px;
    width: auto;
  }
}

@media (--handhelds) {
  .revisitSummaryContainer {
    margin-inline-start: 38px;
  }
}
