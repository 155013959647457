.anonymousAvatar {
  /* values taken from avatar component */
  --avatar-size-medium: 30px;
  --avatar-size-conversation: 35px;

  color: var(--color-text-inverted);
  background-color: var(--color-viz-neutral);
  align-items: center;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  padding: var(--spacing-04);
}

.medium {
  height: var(--avatar-size-medium);
  width: var(--avatar-size-medium);
}

.conversation {
  height: var(--avatar-size-conversation);
  width: var(--avatar-size-conversation);
}

.light {
  background-color: var(--color-placeholder-bg-1);
  color: var(--color-placeholder-text-1);
}
