.title {
  margin-block-end: 0;
}

.toggler {
  border-block-end: 1px dotted var(--color-text-tertiary);
  cursor: help;
}

.overview {
  border-radius: 0 0 4px 4px;
  height: 100%;
  padding-inline: 24px;
  padding-block: 16px;
}

.title,
.rating {
  margin-block-end: 0;
}

.high {
  background-color: transparent;
}

.medium,
.unknown {
  background-color: transparent;
}

.medium .rating,
.unknown .rating {
  color: var(--color-text-tertiary);
}

.ratingPositive {
  color: var(--color-status-positive);
}

.ratingNegative {
  color: var(--color-status-negative);
}

.low {
  background-color: transparent;
}

.transparent {
  background-color: transparent;
}

:global([data-theme='deuteranopia']) .high {
  background-color: rgba(51, 136, 255, 0.1);
}

:global([data-theme='deuteranopia']) .low {
  background-color: rgba(255, 181, 77, 0.1);
}

/* fix for <UnstyledButton> which used to have position: relative; */
.relative {
  position: relative;
}
