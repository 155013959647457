.row {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-32);
}

.section {
  flex: 1;
}

@media (--min-width-md) {
  .row {
    flex-direction: row;
  }
}
