.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.container {
  max-width: 1040px;
  width: 100%;
  margin-inline: auto;
  margin-block: 40px 80px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  padding: 40px;
}

.header {
  display: flex;
  justify-content: space-around;
  margin-inline: 0 0;
  margin-block: 12px 0;
}

.headerTitle {
  margin-block-end: var(--spacing-08);
}

.headerDescription {
  font-size: rem(18px);
  color: var(--color-text-primary);
  padding-inline: 0;
  padding-block: 20px;
  width: 685px;
}

.headerRight {
  min-width: 114px;
  margin-inline-start: auto;
}

.reportMeta span,
.reportMeta strong {
  display: block;
}

.reportMeta strong {
  font-size: var(--font-size-sm);
  color: var(--color-text-primary);
}

.reportMetaTitle {
  font-size: var(--font-size-sm);
  color: var(--color-text-tertiary);
  font-weight: var(--font-weight-bold);
  margin: 0;
}

strong.reportMetaYear {
  color: var(--color-text-tertiary);
}

.reportMetaSeparator {
  width: 26px;
  height: 2px;
  background: var(--color-border);
  margin-inline: 0;
  margin-block: 16px;
}

.content {
  display: flex;
  justify-content: space-between;
  margin-block-start: 34px;
}

.contentLeft {
  min-width: 200px;
  max-width: 200px;
  margin-inline-end: 40px;
}

.contentRight {
  width: 100%;
}

.contentLeft,
.contentRight {
  border-block-start: 5px solid var(--color-border);
  padding-block-start: 23px;
}

.leftContainer {
  padding-block-end: 20px;
  border-block-end: 1px solid var(--color-border);
}

.leftContainer:not(:first-child) {
  padding-block-start: 20px;
}

.leftContainerSmall {
  padding-block: 15px;
  border-block-end: 1px solid var(--color-border);
}

.avgEngagementScore {
  font-size: rem(36px);
  color: var(--color-text-primary);
  text-shadow: 6px 6px 0 rgba(0, 0, 0, 0.05);
  font-weight: var(--font-weight-bold);
}

.empty span {
  font-size: var(--font-size-2xs);
  font-weight: var(--font-weight-bold);
  color: var(--color-text-tertiary);
  text-transform: uppercase;
  text-align: center;
}

.employeeTitle {
  display: block;
}

.employeesTotal {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  display: inline-block;
}

.employeeIcon {
  width: 24px;
  height: 24px;
  display: inline;
  position: relative;
  inset-block-start: 5px;
  color: var(--color-icon-decorative);
}

.employeeIcon svg {
  width: 24px;
  height: 24px;
}

.employeeLink {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-bold);
  color: var(--color-text-tertiary);
}

.employeeLinkArrow {
  color: var(--color-text-tertiary);
  display: inline-block;
  height: 20px;
  position: relative;
  inset-block-start: 7px;
  transition: transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  width: 20px;
}

.employeeLinkArrow svg {
  width: 20px;
  height: 20px;
}

.employeeLink:hover .employeeLinkArrow {
  transform: translate3d(5px, 0, 0);
}

.participationBlock {
  margin-block-start: 15px;
}

.participationRate {
  padding-inline: 0;
  padding-block: 20px 0;
}

.surveyed {
  padding-block-end: 14px;
}

.contentHeader {
  padding-block-end: 40px;
}

.contentHeaderTitle {
  padding-block-end: 10px;
}

.stories {
  display: grid;
  min-height: 115px;
  gap: var(--spacing-24);
  padding-block-end: 20px;

  @media (--min-width-sm) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.segmentsContainer {
  border-block-start: 5px solid var(--color-border);
  padding-block-start: 20px;
}

.driversContainer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-32);
  padding-block-end: var(--spacing-40);
}

.emptyContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100px;
}

.drivers {
  display: flex;
  justify-content: space-between;
}

.driversLeft {
  display: flex;
  min-width: 238px;
  padding-inline-end: 28px;
  padding-block-end: 10px;
}

.driversLeftIcon {
  width: 24px;
  height: 24px;
}

.driversLeft .driversBenchmark {
  font-size: rem(18px);
  padding-inline-start: 10px;
}

.driversRight {
  width: 100%;
}

.driver {
  border-block-start: 1px solid var(--color-border);
  padding-inline: 0;
  padding-block: 12px 0;
}

.driver:last-child {
  border-block-end: 1px solid var(--color-border);
}

.segmentsTitle {
  font-size: rem(18px);
  margin: 0;
}

.segments {
  padding-block-start: 30px;
}

.segment:hover a {
  z-index: 100;
}

.segment > a {
  border-radius: 0;
}

.segments .segment:not(:first-child) a {
  border-block-start: none;
}

@media (--tablets) {
  .container {
    padding: 30px;
  }
}

@media (max-width: 940px) {
  .container {
    margin: 0;
    padding: 20px;
  }

  .header {
    flex-direction: column;
  }

  .headerDescription {
    width: 100%;
  }

  .headerRight {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .reportMeta:last-of-type {
    margin-inline: 30px 0;
    margin-block: 0 0;
  }

  .reportMetaSeparator {
    display: none;
  }

  .content {
    flex-direction: column;
  }

  .contentLeft {
    width: 100%;
    max-width: 100%;
  }

  .driversHeader {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
  }

  .driversExplain {
    padding-inline: 0 0;
    padding-block: 5px 0;
  }

  .drivers {
    flex-direction: column;
  }
}
