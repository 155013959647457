:root {
  --negative-spacing-04: calc(-1 * var(--spacing-04));
}

.scrollHint {
  visibility: collapse;
  height: var(--spacing-04);
  z-index: 1;
}

.scrollHint.show {
  visibility: visible;
  position: sticky;
}

.scrollHint.up {
  inset-block-start: 0;
  box-shadow: inset 0 var(--spacing-04) var(--spacing-04)
    var(--negative-spacing-04) rgba(0, 0, 0, 0.25);
}

.scrollHint.down {
  inset-block-end: 0;
  box-shadow: inset 0 var(--negative-spacing-04) var(--spacing-04)
    var(--negative-spacing-04) rgba(0, 0, 0, 0.25);
}
