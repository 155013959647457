.footer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-08);
}

@media (--min-width-sm) {
  .footer {
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }
}
