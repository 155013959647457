.title {
  margin-block-end: 0;
}

.breakdown {
  display: flex;
  margin-block-start: 10px;
}

.tiny .breakdown {
  margin-block-start: 0;
}

.graph {
  margin-inline-end: 20px;
}

.tiny .graph {
  margin-inline-end: 5px;
}

.item {
  color: var(--color-text-tertiary);
  flex: 0 0 auto;
  margin-inline-end: 20px;
  padding-inline-end: 20px;
  position: relative;
}

.item:last-child {
  margin-inline-end: 0;
  padding-inline-end: 0;
}

.item:last-child::after {
  display: none;
}

.tiny .item {
  margin-inline-end: 5px;
  padding-inline-end: 5px;
}

.tiny .item::after {
  display: none;
}

.percent {
  align-items: center;
  display: flex;
  font-size: var(--font-size-md);
  line-height: 1;
  margin-block-end: 3px;
  font-weight: var(--font-weight-bold);
}

.tiny .percent {
  height: 100%;
  font-size: var(--font-size-sm);
}

.tiny .percentValue {
  margin-inline-end: 3px;
}

.percentValue {
  font-size: var(--font-size-md);
  margin-inline: 6px 0;
  margin-block: 0 0;
}

.passives .percent {
  color: var(--color-text-tertiary);
}

.total {
  align-items: center;
  display: flex;
  font-size: var(--font-size-xs);
}

.totalLabel {
  display: inline-block;
  margin-inline-end: 3px;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tooltip {
  display: inline-block;
  text-transform: none;
}

@media (--handhelds) {
  .item {
    flex: 1;
    margin-inline-end: 10px;
    padding-inline-end: 10px;
  }

  .graph {
    display: none;
  }
}

@media (--collapsed) {
  .root {
    font-size: var(--font-size-2xs);
  }

  .item {
    margin-inline-end: 15px;
    padding-inline-end: 15px;
  }

  .graph {
    margin-inline-end: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1380px) {
  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-inline-end: 15px;
    padding-inline-end: 15px;
  }

  .graph {
    margin-inline-end: 10px;
  }

  .total {
    font-size: var(--font-size-2xs);
  }
}
