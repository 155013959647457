.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  gap: var(--spacing-04);
}

.label {
  display: block;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: none;
  white-space: nowrap;
  width: 40px;
}

@media (--tablets-min) {
  .label {
    border-block-end: 1px solid transparent;
    width: unset;
  }

  .label.tooltip {
    border-block-end: 1px dotted var(--color-border-section);
  }
}
