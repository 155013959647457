.detailsSegments {
  height: 100%;
  border-inline-end: 1px solid var(--color-border);
  width: 22.5rem;
  min-width: 19.75rem;
  display: flex;
  flex-direction: column;
}

.detailsSegments.fullWidth {
  width: 100%;
}

.toggleSidePanelButtonContainer {
  padding-inline: var(--spacing-16);
  padding-block: 2.125rem 0;
}

.virtuosoScrollContainer {
  height: 100%;
  position: relative;
  border-block-start: 1px solid var(--color-border);
  overflow: auto;
}

.virtuosoInner {
  position: absolute;
  inset: 0;
}

.detailsFilters {
  padding: var(--spacing-16);
  border-block-end: 1px solid var(--color-border);
}

.detailsFilters > button {
  margin-block-start: var(--spacing-16);
}

.detailsFilters > button:first-child {
  margin-block-start: 0;
}

.segmentSearch {
  margin: var(--spacing-16);
  position: relative;
}

.segmentHeader {
  padding: var(--spacing-16);
}

.segmentsListContainer {
  height: 100%;
}

.segmentRow {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-bg-card);
  border-block-end: 1px solid var(--color-border) !important;
  width: 100%;
  padding: var(--spacing-08);
  padding-inline: var(--spacing-16);
  gap: var(--spacing-08);
}

.segmentRow > div {
  flex-grow: 1;
  width: 100%;
  /* Overwrite the bar chart's z-index */
  z-index: 0 !important;
}

.segmentRowSelected {
  border-inline-start: var(--spacing-04) solid
    var(--color-controls-active-border);
  padding-inline-start: calc(var(--spacing-16) - var(--spacing-04));
  background-color: var(--color-controls--selected-bg);
  box-shadow: var(--depth-1-box-shadow);
  order: 1;
}

.segmentRow:hover {
  background-color: var(--color-controls-hover-bg);
}

.segmentRow:last-child {
  border-block-end: none;
}

.footerLoader {
  padding-block: var(--spacing-16);
}

.spinner {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.searchCancel,
.searchIcon {
  position: absolute;
  inset-inline-end: rem(10px);
  inset-block-start: 50%;
  transform: translateY(-50%);
}

.searchCancel {
  height: rem(25px);
  opacity: 0.45;
  inset-inline-end: rem(5px);
  transition: opacity 0.35s;
}

.searchCancel:hover,
.searchCancel:focus {
  opacity: 1;
}

.emptyStateContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.emptyStateLabel {
  margin-block-start: var(--spacing-08);
}

.emptyImageContainer {
  width: 180px;
  height: 90px;
}

.emptyImage {
  height: 100%;
  width: 100%;
}
