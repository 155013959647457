.root {
  display: flex;
  width: 240px;
  margin-inline: auto;
  margin-block: 0;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-inline: 0 0;
  padding-block: 24px 36px;
}

.title {
  font-size: var(--font-size-sm);
  padding-block-start: 12px;
  font-weight: var(--font-weight-bold);
}

.description {
  font-size: var(--font-size-xs);
}

.ctaContainer {
  margin-block-start: var(--spacing-16);
}
