.attribute {
  /* flex-shrink needed for IE to compute heights properly */
  flex-shrink: 0;
}

.headerWrapper {
  padding-block: rem(20px);
  padding-inline: rem(16px) rem(8px);
  box-shadow: inset 0 -1px 0 0 var(--color-border);
  /* Needed to reset safari's browser-agent styles */
  margin: 0;
}

/* specificity needed to overwrite the component's default stylings */

.attribute.root .header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.attribute.root .title {
  display: flex;
  align-items: center;
}

.title svg {
  margin-inline-end: rem(8px);
}

.attribute.root h4 {
  margin-block-end: 0;
}

.attribute.root .adjustmentValue {
  font-size: var(--font-size-sm);
}

.attribute.root .icon {
  margin-inline-start: rem(8px);
}

.attribute.root .content {
  background-color: var(--color-bg-primary);
  margin: 0;
  padding: 0;
}

.details {
  padding-block: 1.25rem;
  padding-inline: rem(24px) rem(40px);
}

.info {
  margin-inline: 0 0;
  margin-block: 0 0.625rem;
}

.table {
  width: 100%;
}

.headerCell {
  text-align: initial;
  padding-inline: 0;
  padding-block: 0.9375rem;
  font-size: var(--font-size-2xs);
}

.lightHeader {
  color: var(--color-text-tertiary);
  padding-inline-end: 1.25rem;
  text-align: end;
}

.lightHeader strong {
  color: var(--color-text);
}

.row {
  box-shadow: inset 0 -1px 0 0 var(--color-border);
}

.row:last-child {
  box-shadow: none;
}

.rowCell {
  padding-inline: rem(20px);
  padding-block: rem(16px);
}

.rowCell:last-child {
  text-align: end;
  padding-inline: 0;
  padding-block: rem(16px);
}

.rowCell:first-child {
  padding-inline: 0;
  padding-block: rem(16px);
}

.rowCell.number {
  text-align: end;
}

.rowCell.number::after {
  content: '%';
}
