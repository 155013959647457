.actions,
.preview {
  align-items: center;
  display: flex;
}

.preview > div {
  flex: 1;
}

.preview p {
  margin-block-start: 0;
}

.actions > button {
  margin-inline-end: var(--spacing-08);
}

.actions > button:last-child {
  margin-inline-end: 0;
}

.title {
  font-weight: var(--font-weight-bold);
}
