.root {
  margin-inline: 0;
  margin-block: var(--spacing-32);
}

.formInput {
  margin-block-end: var(--spacing-16);
}

.spacer {
  height: var(--spacing-16);
}
