.container {
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 1;
  width: 100%;
  z-index: 1;
  height: 100%;
}

.container.isConsultant {
  height: calc(100% - 20px);
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-block-start: 8px;
  overflow: hidden;
}

.errorContainer {
  margin-block-start: 15%;
}
