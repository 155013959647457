.progress {
  background-color: var(--color-viz-graph-bg);
  box-shadow: inset 0 0 0 2px var(--color-viz-grid-line);
  border-radius: 8px;
  height: 16px;
  overflow: hidden;
}

.progressFill {
  background-color: var(--color-status-highlight);
  border-radius: 8px;
  height: 100%;
  transition: width 0.35s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  width: 0;
}
