.targetLabelTitle {
  color: var(--color-text-tertiary);
  font-size: var(--font-size-2xs);
  font-weight: var(--font-weight-bold);
}

.filtersContainer {
  padding: var(--spacing-08) 0;
}

.filtersContainer > button {
  max-width: 250px;
  margin-inline-start: var(--spacing-16);
}

.filtersContainer > button:first-child {
  margin-inline-start: 0;
}

@media (--handhelds) {
  .filtersContainer {
    display: flex;
    flex-direction: column;
  }

  .filtersContainer > button {
    max-width: unset;
    margin-inline-start: 0;
    margin-block-start: var(--spacing-16);
  }

  .filtersContainer > button:first-child {
    margin-block-start: 0;
  }
}
