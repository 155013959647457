.root {
  width: 100%;
}

.container {
  margin-inline: auto;
  margin-block: 0;
  max-width: 1200px;
  padding-inline: 20px;
  padding-block: 30px;
}
