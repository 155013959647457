.root {
  align-items: center;
  display: flex;
  line-height: 1;
}

.type {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-2xs);
  margin-inline-end: rem(5px);
  text-transform: uppercase;
}
