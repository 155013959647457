.recommended {
  background-color: var(--color-bg-card);
  border: 1px solid var(--color-text);
  border-radius: 2px;
  color: var(--color-text);
  display: inline-block;
  font-size: var(--font-size-3xs);
  line-height: 1;
  margin-inline-start: 5px;
  padding: 2px;
  text-transform: uppercase;
  font-weight: var(--font-weight-bold);
}

.custom {
  color: var(--color-text-secondary);
  font-size: var(--font-size-2xs);
  margin-inline-start: 5px;
  text-transform: uppercase;
  font-weight: var(--font-weight-bold);
}
