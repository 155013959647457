.messageInputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: sticky;
  inset-block-end: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: var(--spacing-16);
  gap: var(--spacing-08);
}

.messageInput {
  width: 100%;
  display: grid;
  gap: var(--spacing-08);
}

.messageInput textarea {
  min-height: 6rem;
  max-height: 30vh;
}

.inputLayoutWrapper {
  display: flex;
  gap: var(--spacing-08);
  flex-direction: column;
}

.inputLayoutWrapper > button {
  width: max-content;
  align-self: flex-end;
}

@media (--handhelds) {
  .messageInputContainer {
    border-radius: 0;
    /* offset grid padding on mobile views */
    margin: 0 calc(-1 * var(--spacing-16));
  }

  .inputLayoutWrapper textarea {
    font-size: var(--font-size-md);
    min-height: 4rem;
  }

  .inputLayoutWrapper {
    flex-direction: row;
  }
}
