@import '../../../styles/animations.css';
@import '../../../styles/z-index.css';

.notification {
  animation-duration: 300ms;
  animation-iteration-count: 1;
  animation-name: bounceInRight;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  background-color: var(--color-bg-card);
  border-radius: var(--border-radius-base);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border: 1px solid var(--color-border);
  color: var(--color-text-primary);
  font-size: var(--font-size-base);
  min-height: 48px;
  padding: 0;
  transition: border-color 0.35s;
  width: 380px;
  margin-block-end: 20px;
}

.notification p {
  margin: 0;
}

.notification.success {
  border-color: var(--color-status-highlight);
}

.notification.error {
  border-color: var(--color-status-negative);
}

.header {
  display: grid;
  grid-template-columns: 1fr 2.5rem;
  align-items: center;
  padding: var(--spacing-16);
  background-color: var(--color-bg-info);
  color: var(--color-text-secondary);
  transition: background-color 350ms;
}

.notification.success .header {
  background-color: var(--color-status-highlight);
}

.notification.error .header {
  background-color: var(--color-status-negative);
}

.headerText {
  font-size: var(--font-size-base);
}

.notification.success .headerText {
  color: var(--color-text-inverted);
}

.notification.error .headerText {
  color: var(--color-text-inverted);
}

.contentContainer {
  padding: var(--spacing-16);
}

.contentContainer.withIcon {
  display: grid;
  grid-template-columns: 1.5rem 1fr;
  gap: var(--spacing-24);
}

.contentContainer.withIcon.largeIcon {
  grid-template-columns: 3rem 1fr;
}
