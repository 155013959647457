@import 'animate.css/source/fading_entrances/fadeIn.css';

.options {
  margin-block-start: 15px;
}

.enter {
  animation-duration: 0.2s;
  animation-fill-mode: both;
}

.enter.enterActive {
  animation-name: fadeIn;
}

.customField {
  margin-block-start: 15px;
}

.label {
  display: block;
  font-size: var(--font-size-2xs);
  margin-block-end: 10px;
  font-weight: var(--font-weight-bold);
}

.dateTo {
  margin-block-start: 10px;
}
