@import 'animate.css/source/flippers/flipInX.css';
@import 'animate.css/source/flippers/flipOutX.css';

.enter,
.exit {
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

.enter.enterActive {
  animation-name: flipInX;
}

.exit.exitActive {
  animation-name: flipOutX;
}

.footer {
  align-items: center;
  display: flex;
  padding-block: 13px 5px;
  padding-inline-end: 35px;
}

.readMore {
  flex: 2;
  padding-inline-end: 22px;
  text-align: end;
}

.readMore a span {
  display: inline-block;
  transition: transform 0.2s ease;
}

.readMore a:hover span {
  transform: translateX(3px);
}
