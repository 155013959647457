.root {
  padding-block-end: 20px;
}

.form {
  align-items: center;
  display: flex;
  height: 44px;
  position: relative;
  z-index: 1;
}

.form button {
  max-width: 252px;
}
