.export {
  display: none; /* FIXME */
  margin-inline-start: 20px;
}

.jumpTo {
  border: 0;
}

.jumpTo > div {
  margin-inline-end: 10px;
}

.main {
  justify-content: flex-end;
}

.tabs {
  margin: 0;
}

@media (--handhelds) {
  .export {
    display: none;
  }
}
