.toggler {
  border-block-end: 1px dotted var(--color-border-section);
  cursor: help;
  font-weight: var(--font-weight-bold);
}

.toggler.disabled {
  cursor: default;
}

.toggler.noBorder {
  border-block-end: 0;
}

.header,
.content,
.main {
  text-align: start;
}

.title,
.content {
  overflow-wrap: break-word;
  word-break: break-word;
  word-wrap: break-word;
}

.header,
.section {
  padding: 10px;
}

.title,
.subtitle {
  font-weight: var(--font-weight-bold);
}

.subtitle {
  color: var(--color-text-hint);
  font-size: var(--font-size-xs);
  margin-inline: 0;
  margin-block: 5px;
}

.header {
  align-items: center;
  background-color: var(--color-bg-primary);
  border-block-end: 1px solid var(--color-border);
  border-radius: 2px 2px 0 0;
  display: flex;
  justify-content: center;
  min-height: 50px;
}

.header.mobile {
  padding-inline-end: 55px;
}

/* stylelint-disable-next-line selector-class-pattern -- external library react-tippy */
:global(.tippy-tooltip.graph-theme) .header {
  background-color: var(--color-bg-card);
  padding: 15px;
}

.title {
  color: var(--color-text-primary);
  flex: 2;
  line-height: 1;
  text-align: start;
}

/* stylelint-disable-next-line selector-class-pattern -- external library react-tippy */
:global(.tippy-tooltip.graph-theme) .title {
  font-size: var(--font-size-sm);
}

.link {
  align-items: center;
  background-color: var(--color-bg-card);
  border: solid 1px rgba(0, 0, 0, 0.07);
  border-radius: 2px;
  box-shadow: inset 0 -1px 1px 0 rgba(0, 0, 0, 0.1);
  color: var(--color-text);
  cursor: pointer;
  display: flex;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-2xs);
  height: 32px;
  line-height: 1.5;
  padding-inline: 12px 6px;
  padding-block: 5px 5px;
  text-decoration: none;
}

.link:hover,
.link:focus,
.link:active {
  color: var(--color-text-primary);
  outline: 0;
  text-decoration: none;
}

.link.focused {
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.13);
  color: var(--color-text-tertiary);
}

.link:active {
  color: var(--color-text-tertiary);
}

.linkArrow {
  width: 18px;
  color: var(--color-text-primary);
}

[dir='rtl'] .linkArrow {
  transform: scaleX(-1);
}

/* Disable until the end of the file */
/* stylelint-disable selector-class-pattern -- external library react-tippy */
:global(.tippy-tooltip.peakon-theme),
:global(.tippy-tooltip.graph-theme) {
  background-color: var(--color-bg-card);
  border-radius: 2px;
  box-shadow: 0 1px 40px 0 rgba(0, 0, 0, 0.13);
  color: var(--color-text-primary);
  min-height: 70px;
  padding: 0;
  width: 380px;
}

:global(.tippy-tooltip) {
  font-family: var(--font-family-base);
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
}

:global(.tippy-tooltip.graph-theme) {
  width: 260px;
}

:global(.tippy-popper[x-placement^='top'] .peakon-theme [x-arrow]),
:global(.tippy-popper[x-placement^='top'] .graph-theme [x-arrow]) {
  border-block-start-color: var(--color-bg-card);
}

:global(.tippy-popper[x-placement^='bottom'] .peakon-theme [x-arrow]),
:global(.tippy-popper[x-placement^='bottom'] .graph-theme [x-arrow]) {
  border-block-end-color: var(--color-border);
}

:global(.tippy-popper[x-placement^='right'] .peakon-theme [x-arrow]) {
  border-inline-end-color: var(--color-border);
}

:global(.tippy-popper[x-placement^='left'] .peakon-theme [x-arrow]) {
  border-inline-start-color: var(--color-border);
}

:global(.tippy-popper[x-placement^='right'] .graph-theme [x-arrow]) {
  border-inline-end-color: var(--color-bg-card);
}

:global(.tippy-popper[x-placement^='left'] .graph-theme [x-arrow]) {
  border-inline-start-color: var(--color-bg-card);
}
/* stylelint-enable selector-class-pattern */
