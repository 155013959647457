.grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  margin-block-start: 25px;
  padding-inline: 10px;
  padding-block: 0;
}

.grid > div {
  margin-inline-end: 0;
  margin-block-end: 25px;
}

.title {
  display: flex;
  align-items: center;
  gap: var(--spacing-04);
  flex-wrap: wrap;
  word-break: break-word;
}

.side {
  padding-inline: 0 15px;
  padding-block: 20px;
}

.about {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-08);
  padding-block-start: 10px;
}

div.footer {
  align-items: center;
  display: none;
  margin-block-start: 0;
}

.footer .buttonMore {
  margin-block-start: 0;
}

.indicator {
  margin-inline-start: 3px;
}

.meta {
  color: var(--color-text-secondary);
  font-size: var(--font-size-xs);
  margin-block-end: 10px;
}

.metaTitle {
  font-weight: bolder;
  margin-block-end: 3px;
}

.seeMore {
  font-weight: bolder;
}

.buttonMoreLink {
  text-decoration: none !important;
}

.buttonMore {
  align-items: center;
  background-color: transparent;
  border: 0;
  color: var(--color-text-primary);
  display: inline-flex;
  font-weight: bolder;
  height: 1.5rem;
  justify-content: center;
  line-height: 1;
  margin-block: 15px 0;
  padding: 0;
  text-decoration: none;
}

.buttonMore:hover,
.buttonMore:focus,
.buttonMore.focused {
  text-decoration: none;
}

[dir='rtl'] .arrow {
  transform: rotateY(180deg);
}

@media (--tablets-min) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
  }

  .grid > div {
    margin-inline-end: 25px;
    margin-block-end: 25px;
  }
}

@media (--desktop) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    margin-block-start: 0;
  }

  .grid > div {
    margin-inline-end: 25px;
    margin-block-end: 0;
  }
}

@media (--handhelds) {
  .grid {
    padding-inline-start: 42px;
  }

  div.footer {
    display: block;
  }

  .buttonMore {
    justify-content: flex-start !important;
    padding-inline-start: 36px;
  }
}

@media (--handhelds-tablets) {
  .grid {
    margin-block-start: 0;
    padding: 0;
  }
}
