.root {
  position: relative;
  width: rem(100px);
  height: rem(8px);
}

.outline {
  position: absolute;
  inset-block: 0;
  inset-inline: 0;
  border-radius: rem(4px);
  border: 1px solid var(--color-border);
}
