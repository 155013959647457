.content {
  padding: 20px;
}

.row {
  margin-block-end: 20px;
}

.row:last-child {
  margin-block-end: 0;
}

.title {
  margin-block-end: 5px;
  font-weight: var(--font-weight-bold);
}
