.configContainer {
  margin-block-start: 2rem;
}

.separator {
  border-block-end: 1px solid var(--color-border-section);
  height: 1px;
  margin-inline: 0;
  margin-block: 2rem;
  width: 100%;
}

.formGrid {
  display: flex;
  flex-wrap: wrap;
}

.formGrid > :last-child {
  margin-inline-end: 0;
}

.formGrid > div,
.formGrid > form {
  flex-basis: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  margin-block-end: 20px;
}

.copyField {
  margin-block: 10px 20px;
}

.copyField > div {
  align-items: flex-end;
}

.footer {
  margin-block-start: var(--spacing-16);
  display: flex;
  gap: var(--spacing-16);
}

@media (--tablets-min) {
  .formGrid > div,
  .formGrid > form {
    flex-basis: calc(50% - 20px);
    margin-inline-end: 20px;
  }

  .formGrid > div.formFieldSingle {
    flex-grow: 0;
  }
}
