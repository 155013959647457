.alert {
  margin-block-end: var(--spacing-16);
  padding: var(--spacing-16);
}

.accountPassword {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: var(--spacing-16);
  margin: var(--spacing-16);
}

.accountPasswordText {
  flex: 1;
  padding-block: var(--spacing-08);
  margin: auto;
  border: 1px solid var(--color-border-section);
  border-radius: var(--border-radius-base);
  text-align: center;
}
