.root {
  background-color: var(--color-bg-card);
  display: flex;
  flex-direction: row;
  width: 100%;
}

.loginContainer {
  height: 100%;
  width: 40%;
}

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  overflow-y: auto;
}

.footer {
  display: flex;
  font-size: var(--font-size-md);
  width: 320px;
  margin-block-start: auto;
  height: 60px;
  padding-inline-end: 30px;
}

.marketingContainer {
  width: 60%;
  display: flex;
}

.formContainer {
  display: flex;
  align-items: center;
  width: 320px;
}

.formContainer.redirecting {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  inset-block-start: 0;
}

.header {
  max-width: 320px;
  width: 100%;
  margin-block-end: 40px;
  padding-block-start: rem(60px);
}

.subHeader {
  font-size: var(--font-size-md);
}

.footerText {
  align-self: start;
}

.consultantBarContainer {
  position: fixed;
  inset-block-end: 0;
  width: 100%;
  background-color: var(--color-status-negative);
  padding-inline: 0;
  padding-block: 10px;
}

@media (max-width: 1110px) {
  .marketingPlaceholder,
  .marketingPlaceholder img {
    width: calc(581px / 1.5);
  }
}

@media (max-width: 1024px) {
  .loginContainer {
    width: 100%;
  }

  .login {
    align-items: center;
  }

  .header {
    padding-block-start: 10%;
  }

  .marketingContainer {
    display: none;
  }

  .footer {
    text-align: center;
    justify-content: center;
  }
}

@media (--handhelds) {
  .formContainer,
  .footer {
    width: 100vw;
    padding-inline: 15px 15px;
    padding-block: 0 0;
    justify-content: center;
  }

  .header {
    margin-block-end: 15px;
  }

  .footer {
    font-size: var(--font-size-sm);
  }

  .formContainer input {
    font-size: var(--font-size-md);
  }

  .formContainer {
    justify-content: center;
  }
}

@media (--tablets-min) {
  .header,
  .footer {
    flex-shrink: 0;
    width: 320px;
    margin-block-start: auto;
    height: 100px;
  }

  .footer {
    flex-shrink: 0;
  }

  .formContainer {
    flex-grow: 1;
  }
}

/* for small screens */
@media (max-width: 320px) {
  .footer {
    font-size: var(--font-size-sm);
  }

  .formContainer,
  .footer {
    padding-inline: 25px 25px;
    padding-block: 0 0;
  }

  .header {
    padding-inline-start: 25px;
  }
}
