.root {
  border: 1px solid var(--color-border);
  border-radius: 4px;
  display: block;
  height: 100%;
  padding: 10px;
  position: relative;
}

.root,
.root:hover,
.root:active,
.root:visited,
.root:focus {
  text-decoration: none;
}

.root::after {
  content: '';
  height: 100%;
  inset-inline-start: 0;
  opacity: 0;
  position: absolute;
  inset-block-start: 0;
  transition: opacity 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  width: 100%;
  pointer-events: none;
}

.root:hover::after {
  opacity: 1;
}

.root.positive {
  background-color: rgba(190, 236, 215, 0.1);
}

.root.negative {
  background-color: rgba(255, 221, 207, 0.1);
}

.root.small {
  height: 70px;
  width: 130px;
  padding-inline: 10px;
  padding-block: 7px;
}

.root.small .content {
  margin-block-start: 5px;
}

.header,
.title {
  align-items: center;
  display: flex;
}

.title {
  color: var(--color-text);
  line-height: 1;
  font-weight: var(--font-weight-bold);
}

.help {
  margin-inline-start: 5px;
}

.content {
  align-items: center;
  display: flex;
  margin-block-start: 15px;
}

.content svg {
  width: 11px;
  height: 11px;
  margin-block-start: 8px;
  margin-inline-start: 5px;
}

.score {
  color: var(--color-text);
  font-size: rem(28px);
  line-height: 1;
  font-weight: var(--font-weight-bold);
}

.benchmark {
  color: var(--color-text-tertiary);
  flex: 2;
  margin-inline-start: 10px;
}

.noData {
  color: var(--color-text-tertiary);
}

.benchmark > p {
  margin: 0;
}
