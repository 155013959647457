.icon {
  width: 24px;
  height: 24px;
  margin-inline-start: -4px;
}

.text {
  margin-block-start: 10px;
  font-size: var(--font-size-base);
  line-height: 1.46;
}
