:root {
  --acknowledgement-icon-size-huge: rem(44px);
  --acknowledgement-icon-size-large: rem(32px);
  --acknowledgement-icon-size: rem(24px);
  --acknowledgement-icon-size-small: rem(18px);
  --acknowledgement-icon-size-tiny: rem(16px);
}

.root.normal,
.root.normal svg {
  height: var(--acknowledgement-icon-size);
  width: var(--acknowledgement-icon-size);
}

.root.tiny,
.root.tiny svg {
  height: var(--acknowledgement-icon-size-tiny);
  width: var(--acknowledgement-icon-size-tiny);
}

.root.small,
.root.small svg {
  height: var(--acknowledgement-icon-size-small);
  width: var(--acknowledgement-icon-size-small);
}

.root.large,
.root.large svg {
  height: var(--acknowledgement-icon-size-large);
  width: var(--acknowledgement-icon-size-large);
}

.root.huge,
.root.huge svg {
  height: var(--acknowledgement-icon-size-huge);
  width: var(--acknowledgement-icon-size-huge);
}
