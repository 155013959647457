.anonymity {
  align-items: center;
  border-block-end: none;
  display: inline-flex !important;
  color: var(--color-text-tertiary);
}

.content {
  padding-inline: 16px;
  padding-block: 14px 16px;
}

.content p {
  margin: 0;
}

.content a {
  margin-block-start: 14px;
}
