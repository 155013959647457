.modal {
  min-width: 720px;
  padding: var(--spacing-24);
  text-align: start;
}

.header,
.content,
.footer {
  padding: 0;
}

.header {
  border-block-end: 0;
  display: block;
}

.headerActions {
  position: relative;
}

.headerActions > button {
  inset-block-start: 0;
  inset-inline-end: 0;
  position: absolute;
  z-index: var(--z-index-1);
}

.illustration {
  align-items: center;
  display: flex;
  justify-content: center;
}

.illustration img {
  display: block;
}

.title {
  font-size: var(--font-size-lg);
  margin-block: var(--spacing-24);
}

.content p {
  font-size: var(--font-size-sm);
  margin-block: 0 var(--spacing-32);
}

.footer {
  align-items: center;
  border-block-start: 0;
  display: flex;
  justify-content: space-between;
}

.progress {
  width: 302px;
}

.actions button {
  margin-inline-end: var(--spacing-16);
}

.actions button:last-child {
  margin-inline-end: 0;
}

.completed {
  align-items: center;
  color: var(--color-text-hint);
  display: flex;
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
}

.completed svg {
  display: block;
  margin-inline-start: var(--spacing-08);
}

.confettiWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.confettis {
  height: rem(10px);
  inset-inline-start: 50%;
  position: absolute;
  inset-block-start: 50%;
  transform: translate(rem(-5px), rem(-5px));
  width: rem(10px);
}

@media (--handhelds) {
  .modal {
    margin-inline: var(--spacing-04);
    min-width: auto;
    width: calc(100vw - var(--spacing-08));
  }

  .headerActions {
    display: flex;
    flex-direction: column-reverse;
  }

  .headerActions > button {
    align-self: flex-end;
    display: inline-block;
    position: relative;
  }

  .title {
    font-size: var(--font-size-md);
  }

  .footer {
    align-items: stretch;
  }

  .actions,
  .progress {
    width: 100%;
  }

  .actions {
    align-items: center;
    display: flex;
    margin-block-start: var(--spacing-24);
  }

  .actions > button {
    display: block;
    flex: 1;
    width: 100%;
  }
}
