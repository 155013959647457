.container {
  align-items: center;
  background-color: var(--color-controls-bg);
  border-radius: var(--border-radius-base);
  border: 1px solid var(--color-controls-border);
  display: inline-flex;
  flex-grow: 0;
  padding-inline: 10px 6px;
  padding-block: 4px 4px;
  transition: box-shadow 300ms ease-in;
  width: 100%;
}

.label {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 90px;
}

.cross {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-inline-start: 10px;
}

.cross:hover,
.cross:focus {
  opacity: 0.6;
}

.cross > svg > path {
  fill: var(--color-text);
}

@media (--tablets-min) {
  .label {
    max-width: 180px;
  }
}
