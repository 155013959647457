.root {
  margin-block-start: 30px;
  margin-inline-start: 10px;
  border-block-start: 1px solid var(--color-border);
  padding-block-start: 30px;
}

.slideInRoot label {
  margin-block-start: 20px;
}

.resetPasswordAlert {
  margin-block: var(--spacing-08) var(--spacing-16);
  width: fit-content;
}

.resetPasswordButton {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-08);
  align-items: center;
}
