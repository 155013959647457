/* reset without breaking anywhere else */
.attribute > div > div {
  padding: 0 !important;
}

.alertContainer {
  margin-inline: 0;
  margin-block: 24px;
}

.graphSetting {
  padding-inline: 16px;
  padding-block: 24px;
  border: 1px solid var(--color-border-section);
}

.graphSetting .graphPreview {
  justify-content: center;
}

.graphPreview {
  display: flex;
  margin: 12px;
}

.graphRadioGroup {
  margin-block-start: 12px;
}

.graphMinMax {
  display: flex;
}

.graphMinMaxContainer {
  max-width: 136px;
}

.graphMinMaxContainer > div:last-of-type {
  margin-block-start: 12px;
}

@media (--handhelds) {
  .graphMinMax {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .graphMinMaxContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-08);
    max-width: 100%;
  }

  .graphMinMaxContainer > div:last-of-type {
    margin-block-start: 0;
  }

  .graphPreview {
    margin-inline: 0;
    margin-block-start: var(--spacing-24);
    overflow-x: auto;
    justify-content: flex-start !important;
  }
}
