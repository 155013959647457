.root {
  background-color: var(--color-viz-graph-bg);
  box-shadow: inset 0 0 0 1px var(--color-viz-grid-line);
  border-radius: rem(5px);
  height: rem(10px);
  overflow: hidden;
  width: 100%;
}

.root.small {
  border-radius: rem(4px);
  height: rem(8px);
}

.root.loading {
  background-color: transparent;
}

.progress {
  background-color: var(--color-viz-graph-bg);
  border-radius: rem(5px);
  height: 100%;
}

.small .progress {
  border-radius: rem(4px) 0 0 rem(4px);
}

.progress.self {
  background-color: var(--color-employee-self);
  box-shadow: inset 0 0 0 1px var(--color-employee-self-outer);
}

.progress.company {
  background-color: var(--color-employee-company);
  box-shadow: inset 0 0 0 1px var(--color-employee-company-outer);
}

.progress.team {
  background-color: var(--color-employee-team);
  box-shadow: inset 0 0 0 1px var(--color-employee-team-outer);
}

.progress.grow {
  background-color: var(--color-viz-09);
  box-shadow: inset 0 0 0 1px var(--color-viz-10);
}
