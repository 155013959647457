.info {
  margin-inline: 20px;
  margin-block: 10px 10px;
  max-width: 941px;
  padding-block-end: 0;
}

@media (--tablets-min) {
  .info {
    margin-inline: auto;
    margin-block: 10px 32px;
  }
}
