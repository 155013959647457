.header {
  padding: var(--spacing-16);
  padding-block-end: 0;
}

@media (min-width: 1200px) {
  .header {
    padding: 0;
  }
}

.tabs {
  overflow: auto;
  maxwidth: 100%;
}
