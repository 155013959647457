.root {
  margin-block-end: var(--spacing-48);
}

.root h2 {
  margin-block-end: var(--spacing-24);
}

.audits {
  border: 1px solid var(--color-border);
  background-color: var(--color-bg-card);
  border-radius: 0.5rem;
  overflow: hidden;
  width: 100%;
  max-width: calc(100vw - var(--spacing-16) - var(--spacing-16));
}

.auditsOverflowWrapper {
  display: block;
  overflow-x: auto;
}

.audits table {
  width: 100%;
  font-size: var(--font-size-xs);
  white-space: nowrap;
}

.audits table th {
  font-weight: normal;
  color: var(--color-text-tertiary);
  text-align: start;
}

.audits table th,
.audits table td {
  padding: var(--spacing-16);
}

.audits table td {
  border-block-start: 1px solid var(--color-border);
}

.audits table tbody tr:nth-child(odd) td {
  background-color: var(--color-table-row-alt);
}

.audits table th:not(:last-child),
.audits table td:not(:last-child) {
  border-inline-end: 1px solid var(--color-border);
}

.audits table tfoot tr td {
  background-color: var(--color-table-row);
}

.spinner {
  display: flex;
  justify-content: center;
  color: var(--color-status-highlight);
}
