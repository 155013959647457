.filter {
  padding-block-start: var(--spacing-24);
  border-block-start: 1px solid var(--color-border-section);
}

/**
 *
 * NOTE: breakpoints here are hardcoded to the longest possible combinations of filter values
 *
 */

@media (max-width: 840px) {
  .desktop {
    display: none;
  }
}

@media (min-width: 841px) {
  .mobile {
    display: none;
  }

  .filter {
    padding-block: var(--spacing-08);
    border-block: 1px solid var(--color-border-section);
  }
}
