@import '../../../../../../styles/variables.css';

.overview {
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 0;
  height: 100%;
}

.overlay {
  background-color: rgba(69, 72, 79, 0.7);
  inset-block: 0;
  inset-inline: 0;
  position: fixed;
  z-index: 401;
}

.list {
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 0;
  max-height: calc(100% - var(--navbar-height));
}

@media (--tablets-min) {
  .list {
    max-height: none;
  }
}
