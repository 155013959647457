.loading {
  padding: 60px;
  color: var(--color-status-highlight);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.group {
  margin-block-end: 26px;
}

.group:last-child {
  margin-block-end: 0;
}

.groupWithoutMain {
  margin-inline: 0 0;
  margin-block: 26px 0;
}

.empty {
  border: 1px dashed var(--color-border-section);
  border-radius: 4px;
  padding: var(--spacing-40);
}

.emptyActions {
  margin-block-start: 20px;
}
