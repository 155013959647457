.label,
.source {
  color: var(--color-text-primary);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
}

.graph {
  margin-block-start: 10px;
  max-width: 192px;
}

.benchmarkOnly {
  color: var(--color-text-secondary);
}

.root.small {
  display: flex;
  height: 38px;
}

.root.small > * {
  margin-inline-start: var(--spacing-04);
}

.root.small .container {
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-block-start: -5px;
}

.root.small .container > * {
  margin-inline-start: var(--spacing-04);
}

.root.small .label {
  margin-block-end: 0;
}

.root.small .score {
  color: var(--color-text-primary);
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  justify-content: center;
  line-height: 1;
}

@media (--handhelds) {
  .label,
  .source {
    font-size: var(--font-size-md);
  }
}

@media (--intermediate) {
  .label,
  .source {
    font-size: var(--font-size-md);
  }
}
