.resultFeedback {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid 1px var(--color-border);
  border-radius: 0 0 var(--border-radius-card) var(--border-radius-card);
  margin-inline: var(--spacing-16);
  padding-inline: var(--spacing-16);
  background-color: var(--color-bg-card);
  height: rem(52px);
  border-block-start: 1px solid var(--color-border);
}

.divider {
  border-block-start: 1px solid var(--color-border);
  height: 1px;
  margin-block: 10px;
}

.checkbox {
  margin-block: var(--spacing-08);
  font-weight: var(--font-weight-bold);
}

.commentDetails {
  display: flex;
  justify-content: space-between;
  margin-block-end: var(--spacing-08);
}

.alert {
  margin-block-end: var(--spacing-16);
}

@media (--handhelds) {
  .resultFeedback {
    font-size: var(--font-size-2xs);
    margin-inline: var(--spacing-32);
    padding-block: var(--spacing-32);
  }
}

.fieldset {
  /* Small reset */
  border: 0;
  margin: 0;
  padding: 0;
}

.legend {
  /* Small reset */
  padding: 0;
  font-weight: bold;
}

/* <fieldset /> and flex still has some issues so we don't use flex+gap */
.fieldset > * + * {
  margin-block-start: var(--spacing-16);
}
