.root,
.title {
  align-items: center;
  display: flex;
}

.content {
  flex: 2;
}

.title {
  font-size: var(--font-size-2xs);
  font-weight: var(--font-weight-bold);
}

.description {
  margin-block-start: 10px;
}

.toggle {
  padding-inline-start: 20px;
}
