.container {
  display: flex;
  flex-direction: column;
}

.title {
  margin-block: var(--spacing-32) var(--spacing-16);
}

.toggle {
  display: flex;
  flex-direction: row;
}

.buttons {
  display: flex;
  flex-direction: row;
  padding-block-start: var(--spacing-16);
}

.button {
  margin-inline-end: var(--spacing-16);
}

.deleteWarning {
  margin-block-end: var(--spacing-08);
}

.button .disabled {
  cursor: not-allowed;
  opacity: 0.3;
  pointer-events: none;
}
