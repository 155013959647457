.root {
  align-items: center;
  background-color: var(--color-bg-card);
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06);
  display: inline-flex;
  padding: 5px;
}

.compact {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
}

.included {
  background-color: var(--color-bg-card);
}

.excluded {
  background-color: var(--color-bg-alert);
}

.clear {
  margin-inline-start: 5px;
}

.clear,
.clear svg {
  height: 16px;
}

.clear g {
  fill: var(--color-text);
}
