@import '../../../styles/animations.css';
@import '../../../styles/z-index.css';
@import '../../../../../shared/src/components/Navigation/variables.css';

:root {
  --page-header-height: rem(70px);
}

.root {
  position: relative;
}

.input {
  align-items: center;
  background-color: var(--color-bg-card);
  display: flex;
  height: var(--page-header-height);
  position: relative;
  border-block-end: 1px solid var(--color-border);
}

.input :global(.peak-form-field) {
  flex: 1;
}

.icon {
  inset-inline-start: var(--spacing-08);
  line-height: 1;
  position: absolute;
  inset-block-start: 50%;
  transform: translateY(-50%);
}

.input input {
  -webkit-appearance: none;
  background-color: transparent;
  border: 0;
  box-shadow: none;
  color: var(--color-text-primary);
  min-height: 0;
  padding-inline-start: rem(33px);
  width: 100%;
  font-weight: var(--font-weight-bold);
  border-radius: 0;
}

.input input:focus {
  box-shadow: var(--nav-focus-ring) !important;
}

/* clears the 'X' from Chrome */
.input input::-webkit-search-decoration,
.input input::-webkit-search-cancel-button,
.input input::-webkit-search-results-button,
.input input::-webkit-search-results-decoration {
  display: none;
}

.loader {
  position: absolute;
  inset-inline-end: 30px;
  inset-block-start: 50%;
  transform: translateY(-50%);
}

.empty {
  color: var(--color-text-inverted);
  padding: var(--spacing-16);
}
