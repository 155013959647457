.container {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  font-weight: var(--font-weight-bold);
}

.content {
  display: flex;
  flex-direction: column;
  margin-inline-start: var(--spacing-16);
  font-size: var(--font-size-xs);
}

.icon {
  height: 40px;
  width: 50px;
}

.title {
  font-size: var(--font-size-md);
}
