.main {
  padding-block: 20px;
}

.group > div {
  margin-block-end: 20px;
}

.group > div:last-child {
  margin-block-end: 40px;
}

@media (--tablets-min) {
  .main {
    padding-block: 35px;
  }
}
