.form {
  margin-block-start: 20px;
  max-width: 600px;
  border-block-start: 1px solid var(--color-border);
  margin-block: var(--spacing-24);
  padding-block-start: var(--spacing-16);
}

.formRow {
  padding-block-end: 15px;
}

.checkboxContainer h4 {
  margin-block-end: 0;
}

.checkboxContainer .grid {
  display: grid;
  gap: var(--spacing-04);
}

.subOptionContainer {
  padding-inline-start: var(--spacing-32);
  margin-block-start: var(--spacing-04);
}

.subOptionAlertContainer {
  margin-block-start: var(--spacing-08);
}

.nonBoldLabel .label {
  font-weight: initial;
  margin-inline-start: initial;
}
