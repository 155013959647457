:root {
  --nav-top-height: 4rem;
  --nav-top-zindex: 500;
  --nav-side-width: rem(224px);
  --nav-side-collapsed-width: rem(311px);
  --context-switcher-toggler-height: 4rem;
  --context-switcher-proxy-view-height: calc(
    var(--font-size-3xs) + var(--spacing-16)
  );
  --context-switcher-round: 6rem;
  --nav-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  --nav-focus-ring: inset 0 0 0 0.125rem var(--color-focus-outer),
    inset 0 0 0 0.25rem var(--color-focus-inner);
}

@custom-media --nav-collapsed (max-width: 1199px);
