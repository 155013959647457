@keyframes animation-keyframes {
  0% {
    opacity: 0.4;
    transform: scale(1, 1);
  }

  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }

  100% {
    opacity: 0.4;
    transform: scale(1, 1);
  }
}

.commentSummarySlideInLoadingState {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.loadingIcons {
  color: var(--color-ai-decorative);
  text-align: center;
}

.icon {
  animation: animation-keyframes 1.5s infinite ease-in-out;
}

.icon:nth-child(2) {
  animation-delay: 0.5s;
}

.icon:nth-child(3) {
  animation-delay: 1s;
}

@media (prefers-reduced-motion) {
  .icon {
    animation: animation-keyframes 4.5s infinite ease-in-out;
  }

  .icon:nth-child(2) {
    animation-delay: 1.5s;
  }

  .icon:nth-child(3) {
    animation-delay: 3s;
  }
}
