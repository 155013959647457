@import '../../../../../../../styles/animations.css';

.root {
  margin-inline: 0 16px;
  margin-block: 0 0;
  position: absolute;
  inset-inline-end: -4px;
  inset-block-start: 12px;
  transition: ease all 0.15s;
}
