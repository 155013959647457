.menuTriggerLabel {
  text-decoration: underline;
}

.menuTriggerLabel:hover {
  background-color: var(--color-btn--tertiary-hover-bg);
  border-radius: var(--border-radius-base);
}

.menuTriggerLabelIcon {
  display: block;
}
