.root {
  align-items: flex-start;
  display: flex;
}

.graph {
  flex: 0 0 24px;
  margin-inline: 0 16px;
  margin-block: 2px 0;
}

.label {
  font-size: var(--font-size-md);
  margin-block-start: -3px;
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
}

.label strong {
  font-weight: var(--font-weight-bold);
}

.small .label {
  font-size: var(--font-size-base);
  max-width: 100%;
}

.small .graph {
  flex: 0 0 12px;
}

@media (--handhelds) {
  .label {
    font-size: var(--font-size-md);
    max-width: 100%;
  }
}

@media (--intermediate) {
  .label {
    font-size: var(--font-size-base);
  }
}
