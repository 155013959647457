@import 'animate.css/source/attention_seekers/rubberBand.css';
@import '../../styles/z-index.css';

:root {
  --page-header-side-padding: 10px;
  --page-header-nav-side-padding: 5px;
  --page-header-top-padding: 15px;
  --page-header-height: rem(70px);
  --page-header-nav-inline-padding: 3px;
  --page-header-sub-nav-height: rem(60px);
  --page-filters-height: rem(60px);
  --page-filters-side-padding: 0px;
}

body:global(.noOverflow) {
  height: 100vh;
  inset-inline-start: 0;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  inset-block-start: 0;
  width: 100vw;
}

.container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}

.overlay {
  background-color: var(--color-backgrounds-primary-faded);
  inset: 0;
  position: absolute;
  z-index: 20;
}

.container.isLoading {
  visibility: hidden;
}

.nav {
  align-items: center;
  border-block-end: 1px solid var(--color-border-section);
  display: flex;
  flex-direction: row;
  height: var(--page-header-height);
  justify-content: space-between;
  padding-inline: var(--page-header-side-padding);
  padding-block: 0;
  width: 100%;
}

.topic .nav {
  height: auto;
  padding-block: 25px;
}

.navItem {
  cursor: pointer;
  align-items: center;
  align-self: center;
  color: var(--color-text-tertiary);
  display: inline-flex;
  height: 100%;
  line-height: 1;
  text-decoration: none;
  transition:
    border-color 0.2s,
    color 0.2s;
  padding-block-start: 3px;
  padding-inline: var(--page-header-nav-inline-padding);
  position: relative;
}

.navItem:hover,
.navItem:focus {
  text-decoration: none;
  color: var(--color-text-primary);
}

.navItem:focus {
  /* stylelint-disable-next-line */
  box-shadow: var(--focus-inset) !important;
  /* stylelint-disable-next-line */
  outline: none !important;
}

.navItem + .navItem {
  margin-inline-start: 30px;
}

.navItemActive {
  color: var(--color-text-primary);
}

/* Same as apps/shared/src/components/Navigation/Top/Tab/styles.css */
.navItemActive::after {
  content: '';
  position: absolute;
  pointer-events: none;
  inset-block-end: 0;
  inset-inline: 0;
  border-radius: 0.25rem 0.25rem 0 0;
  height: 0.25rem;
  background-color: var(--color-controls--selected-marker);
}

.navItemActive:focus::after {
  inset-inline: 0.125rem;
  inset-block-end: 0.125rem;
  height: 0.125rem;
  border: 0.125rem solid var(--color-controls--selected-bg);
  border-block-end-width: 0;
  box-sizing: content-box;
}

.subnav {
  composes: nav;
  height: var(--page-header-sub-nav-height);
}

.subnav .tabs {
  margin-inline-start: 0;
}

.title,
.container h1.title {
  color: var(--color-text-primary);
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-lg);
  margin-block-end: 0;
  overflow: hidden;
}

.breadcrumbs {
  align-items: center;
  display: flex;
  flex: 1;
  min-width: 0;
  position: relative;
}

.criticalIcon {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: rubberBand;
  border-radius: 100%;
  height: 20px;
  inset-inline-start: 30px;
  position: absolute;
  inset-block-start: 0;
  width: 20px;
}

.breadcrumbsWrapper {
  flex: 1;
  min-width: 0;
}

.breadcrumbs .title {
  display: block;
  flex: 1;
  font-size: var(--font-size-xl);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.breadcrumbsContent {
  color: var(--color-text-secondary);
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: var(--font-size-xs);
}

.topic .breadcrumbsContent {
  margin-inline: 0;
  margin-block: 0 25px;
}

.breadcrumbsIcon {
  margin-inline-end: 15px;
  height: 45px;
  width: 45px;
  color: var(--color-text-tertiary);
}

.backContent {
  display: flex;
  align-items: center;
  font-size: var(--font-size-lg);
}

.backContent:hover {
  text-decoration: none;
}

.backIcon {
  margin-inline-end: 10px;
}

.backButton,
.backButtonIcon,
.currentItem {
  color: var(--color-text-secondary);
}

.backButton:hover,
.currentItem:hover {
  text-decoration: underline;
}

.backButton {
  align-items: center;
  cursor: pointer;
  display: flex;
}

span.backButton {
  cursor: default;
}

.backButtonIcon {
  margin-inline: var(--spacing-04);
  width: 0.75rem;
  height: 0.75rem;
}

.tabs {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  height: 100%;
  margin-inline-start: 30px;
  max-width: 100%;
  overflow: auto hidden;
}

.tabsNav {
  background-color: transparent;
  display: flex;
  font-size: 0.8125rem;
  white-space: nowrap;
  padding-inline-start: calc(
    var(--page-header-nav-side-padding) - var(--page-header-nav-inline-padding)
  );
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-08);
}

.actions a[role='button'] > div {
  height: 100%;
}

.actions :global(.peak-radio-group-item) {
  flex-basis: auto;
}

.filters {
  align-items: stretch;
  border-block-end: 1px solid var(--color-border-section);
  display: flex;
  flex-direction: row;
  min-height: var(--page-filters-height);
  padding-inline: var(--page-filters-side-padding);
  padding-block: 0;
  width: 100%;
}

.filters.actionsOnly {
  justify-content: flex-end;
}

.mobileTabs {
  padding-inline: 16px;
  padding-block: 24px 12px;
  width: 100%;
}

.section {
  align-items: center;
  border-inline-end: 1px solid var(--color-border-section);
  display: flex;
  flex-shrink: 0;
}

.section.main {
  flex-grow: 1;
  flex-shrink: 1;
  flex-wrap: wrap;
}

.wrap {
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
}

.section:last-child {
  border-inline-end: 0;
  justify-content: flex-end;
}

.actionsOnly .section {
  border-inline-start: 1px solid var(--color-border-section);
  border-inline-end: 0;
}

.filter {
  align-items: center;
  padding-inline: 5px;
  padding-block: 0;
  min-height: 0;
}

.filters.mobile {
  display: none;
}

.wrap .filter {
  margin: 5px;
  padding: 0;
}

@media (--handhelds) {
  body:global(.noOverflow) {
    height: 100%;
  }

  .nav {
    display: block;
    padding: 15px;
    height: auto;
  }

  .subnav {
    padding-inline: 15px;
    padding-block: 0;
  }

  .nav .tabs {
    height: var(--page-header-height);
  }

  .subnav .tabs {
    height: var(--page-header-sub-nav-height);
  }

  .breadcrumbsIcon,
  .criticalIcon {
    display: none;
  }

  .filters {
    display: none;
  }

  .filters.showMobile,
  .filters.mobile {
    align-items: center;
    display: flex;
    padding-inline: 15px;
    padding-block: 0;
    width: 100%;
  }

  .actions {
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-block-start: var(--spacing-16);
  }
}
