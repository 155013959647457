.root {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: var(--spacing-20);
}

.progressBar {
  width: 100%;
}

@media (--min-width-md) {
  .progressBar {
    width: rem(350px);
  }
}
