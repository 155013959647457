@import '../../../../shared/src/components/Navigation/variables.css';

.root {
  position: fixed;
  inset-block-end: 0;
  margin-block-end: 0;
  width: 100%;
  z-index: 998;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

@media (--nav-collapsed) {
  .root {
    padding-inline-start: 0;
  }
}

.root.hidden {
  display: none;
  visibility: hidden;
}

.root.positive {
  background: var(--color-bg-info);
}

.root.negative {
  background: var(--color-status-negative);
}

.content {
  inset-block-end: auto;
  inset-inline-start: auto;
  position: relative;
  width: 100%;
  padding-inline: 0;
  padding-block: 10px;
}
