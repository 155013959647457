.wrapper {
  width: 100%;
}

.sections > * {
  margin-block-end: var(--spacing-48);
}

.grid {
  margin-block-start: var(--spacing-32);
}

.sectionTitle {
  margin: 0;
}

.cardTitleSection {
  padding-inline: var(--spacing-24);
  padding-block: var(--spacing-16);
  border-block-end: 1px solid var(--color-border);
}

.reserveSpaceBar {
  /* reserve the space to avoid overlapping when scrolling to bottom */
  margin-block-start: calc(75px + var(--spacing-48));
}

[aria-expanded='false'] .trigger {
  border-block-end: none;
}

/* accordion */
.trigger {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-inline: var(--spacing-24);
  padding-block: var(--spacing-16);
  border-block-end: 1px solid var(--color-border);
}

.trigger span {
  margin-inline-end: var(--spacing-08);
}

.panel {
  padding: var(--spacing-24);
  padding-block-start: var(--spacing-16);
}

@media (--handhelds) {
  .wrapper {
    padding-inline: var(--spacing-16);
  }
}
