.root {
  position: relative;
  border-radius: var(--border-radius-card);
  margin-block-end: 16px;
}

.root.targeted .header {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-block-end: none;
}

.card {
  background-color: var(--color-bg-card);
  box-shadow: var(--depth-1-box-shadow);
  padding: 0;
  width: 100%;
}

.header {
  border-radius: var(--border-radius-card);
  border: 1px solid var(--color-border);
  flex-direction: column;
}

.inner {
  background-color: var(--color-bg-card);
  border-inline-start: 1px solid var(--color-border);
  border-inline-end: 1px solid var(--color-border);
  padding-inline: 0;
  padding-block: 3px;
}

.inner .content {
  margin-inline-start: 52px;
  border-block-start: 1px solid var(--color-border);
}

.footer {
  border-bottom-left-radius: var(--border-radius-card);
  border-bottom-right-radius: var(--border-radius-card);
  border: 1px solid var(--color-border);
  border-block-start: none;
}

.targeted .header .row {
  padding-inline-end: 0;
}

.header .row {
  padding-inline: 0;
  padding-block: 18px;
  margin: 0;
}

.row {
  display: flex;
  align-items: center;
}

.footer .row {
  padding-inline-start: 18px;
}

.hide {
  display: none;
}

.column {
  display: inline-flex;
  align-items: center;
}

.column.arrow {
  flex-grow: 0;
}

.arrow .icon {
  transition: transform 300ms ease-in-out;
  color: var(--color-text-primary);
}

.collapsed .arrow .icon {
  transform: rotate(180deg);
}

.column.help {
  align-items: center;
  border-block-start: 1px solid var(--color-border);
  color: var(--color-text-tertiary);
  display: flex;
  flex-grow: 1;
  font-size: var(--font-size-2xs);
  height: 32px;
}

.column.action {
  flex-grow: 0;
}

.name {
  padding-inline-start: 18px;
  font-weight: var(--font-weight-bold);
}

.name svg {
  color: var(--color-icon-decorative);
}

.button {
  position: absolute;
  inset-block-start: 14px;
  inset-inline-end: 18px;
}

.button button {
  padding: 3px !important;
}

.icon {
  display: flex;
  margin-inline-end: 10px;
}

.required {
  font-size: var(--font-size-2xs);
  text-transform: uppercase;
  font-weight: var(--font-weight-bold);
}

.enterDone {
  overflow: inherit !important;
}

.section {
  display: flex;
  align-items: flex-start;
  padding-inline: 0 10px;
  padding-block: 6px 0;
  border-block-end: 1px solid var(--color-border-section);
}

.section > span {
  flex-grow: 1;
  max-width: 124px;
  width: 100%;
}

.section:last-of-type {
  padding-block-start: 10px;
  border-block-end: none;
}

.segments {
  display: flex;
  margin-inline-start: 20px;
  flex-wrap: wrap;
  flex-grow: 1;
}

.segments > div {
  margin-inline-end: 10px;
  margin-block-end: 8px;
}
