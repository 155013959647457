.root {
  width: 100%;
}

.componentRoot {
  padding: var(--spacing-16);
  margin-inline: auto;
  margin-block: 0;
}

@media (--desktop) {
  .componentRoot {
    padding: var(--spacing-16);
    padding-inline-start: 100px;
    max-width: 1200px;
    margin-inline: auto;
    margin-block: 0;
  }
}
