.pageHeading {
  display: flex;
  flex-direction: row;
  margin-inline: auto;
  margin-block: 10px 0;
}

.driverIcon {
  margin-inline-end: 18px;
}

.headerText {
  margin-block-end: 36px;
  max-width: 800px;
  color: var(--color-text-secondary);
}

.pageHeadingIcon {
  display: none;
}

@media (--tablets-min) {
  .pageHeadingIcon {
    display: block;
  }
}
