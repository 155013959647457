.root {
  background-color: var(--color-bg-card);
  min-height: calc(100vh - 184px);
  position: relative; /* to prevent jumpy scrollable div caused by checkbox's position */
}

.tabs {
  background-color: var(--color-bg-card);
}

.tabs [role='tablist'] {
  padding-inline: rem(24px);
}

.export {
  margin-inline: auto;
  margin-block: 0;
  max-width: rem(580px);
  padding: rem(40px) var(--spacing-16);
}

.loader {
  padding-block-start: rem(80px);
}

.comboboxWrapper {
  padding: var(--spacing-16);
}
