.cardContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--spacing-16);
  gap: var(--spacing-24);
  min-height: 100%;
  min-width: 264px;
}

.resource {
  position: relative;
}

.image {
  background-position: center;
  background-size: cover;
  border-radius: var(--border-radius-sm);
  width: 100%;
  vertical-align: middle;
  aspect-ratio: 16 / 9;
  position: relative;
}

.image::before {
  content: '';
  position: absolute;
  inset: 0;
}

.label,
.source {
  position: absolute;
  inset: var(--spacing-08);
  z-index: 1;
}

.source {
  inset-block-start: unset;
}

.playIcon {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.enternalLinkIcon {
  vertical-align: bottom;
}
