.root {
  cursor: pointer;
}

.root,
.attribute,
.actions {
  align-items: center;
  display: flex;
}

.attribute {
  flex: 2;
  padding-inline: 20px;
  padding-block: 15px;
  max-width: 70%;
}

.name {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  font-weight: var(--font-weight-bold);
}

.count {
  color: var(--color-text-tertiary);
  font-size: var(--font-size-2xs);
  margin-inline-start: 15px;
}

.critical {
  display: inline-flex;
  align-items: center;
  margin-inline-start: 15px;
}

.criticalCount {
  display: inline-flex;
  align-items: center;
  margin-inline-end: 10px;
}

.criticalCount .count {
  margin-inline-start: 5px;
}

.restricted {
  align-items: center;
  display: flex;
  font-size: var(--font-size-2xs);
  margin-inline-start: 15px;
}

.restrictedIcon {
  margin-inline-end: 5px;
  height: 0.75rem;
  width: 0.75rem;
}

.actions {
  flex: 1;
  justify-content: flex-end;
}

.hidden {
  visibility: hidden;
}

.limit {
  color: var(--color-text-tertiary);
  flex: 2;
  font-size: var(--font-size-2xs);
  text-align: end;
}

.selectedCount {
  color: var(--color-status-highlight);
  font-size: var(--font-size-2xs);
  margin-inline-start: 15px;
}
