@import 'animate.css/source/fading_entrances/fadeIn.css';

.expanded {
  align-items: stretch;
  display: flex;
}

.expanded .main {
  flex: 2;
  padding-inline: 0;
  padding-block: 17px 30px;
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-height: 20px;
  position: relative;
}

.expand {
  font-size: var(--font-size-sm);
}

.expanded .header {
  height: 32px;
  justify-content: center;
}

.graph {
  margin-block-start: 11px;
}

.expanded .graph {
  margin-block-start: 12px;
}

.back {
  inset-inline-start: 0;
  position: absolute;
  inset-block-start: 0;
}

@media (--handhelds) {
  .expand {
    display: none;
  }
}
