.wrapper {
  max-width: 740px;
  padding-block-start: 20px;
  padding-inline: var(--spacing-20);
}

.header {
  display: flex;
  align-items: center;
  margin-block-end: 20px;
}

.header .title {
  flex: 1;
  font-size: var(--font-size-md);
  font-weight: 600;
}

.count {
  margin-inline-start: 5px;
}

.scroller {
  max-height: 30vh;
}

.scroller:focus {
  box-shadow: none;
  outline: none;
}

.scroller .item {
  padding-inline: 15px;
  padding-block: 15px;
  background-color: var(--color-bg-card);
  border: solid 1px var(--color-border);
  border-block-end: 0;
}

.scroller div:first-of-type > a {
  border-top-left-radius: var(--border-radius-card);
  border-top-right-radius: var(--border-radius-card);
}

.scroller div:last-of-type > a {
  border-bottom-left-radius: var(--border-radius-card);
  border-bottom-right-radius: var(--border-radius-card);
}

.item .name {
  font-size: var(--font-size-md);
  font-weight: 600;
}

.link {
  border-radius: 0;
  display: flex;
  cursor: pointer;
  align-items: center;
  text-decoration: none;
  justify-content: space-between;
  transition: background-color 0.3s linear;
}

.link:hover {
  background-color: var(--color-controls-hover-bg);
}

.link:hover,
.link:active,
.link:visited,
.link:focus {
  text-decoration: none;
}

.link:hover .arrow {
  color: var(--color-text-tertiary);
}

.item .info {
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-block-start: var(--spacing-08);
}

.status {
  color: var(--color-text-heading);
}

.divider {
  border-block-start: 1px solid var(--color-border-section);
  margin-block: 20px;
}

.separator {
  background-color: var(--color-text-hint);
  border-radius: 100%;
  height: 3px;
  margin-inline: 5px;
  margin-block: 0;
  width: 3px;
}

.separator,
.memberCount {
  color: var(--color-text-hint);
}

.spinner {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-block: var(--spacing-20);
  color: var(--color-status-highlight);
}
