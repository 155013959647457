.root {
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  animation-name: tooltipEnter;
  animation-timing-function: cubic-bezier(0.53, 2, 0.36, 0.85);
  background-color: var(--color-bg-card);
  border-radius: 4px;
  box-shadow: 0 1px 40px 0 rgba(0, 0, 0, 0.13);
  color: var(--color-text-secondary);
  -webkit-font-smoothing: antialiased;
  max-width: 322px;
  min-width: 222px;
  position: absolute;
  transform: translate3d(-50%, -100%, 0);
  z-index: 401;
}

.root::after {
  border-color: var(--color-bg-card) transparent transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  inset-block-end: -5px;
  box-shadow: 0 1px 40px 0 rgba(0, 0, 0, 0.13);
  content: '';
  height: 0;
  inset-inline-start: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 0;
}

.header {
  background-color: var(--color-bg-primary);
  border-block-end: 1px solid var(--color-border-section);
  border-radius: 4px 4px 0 0;
  padding: 15px;
}

.link {
  color: var(--color-text);
  text-decoration: none;
}

.link:hover,
.link:visited,
.link:focus,
.link:active {
  color: var(--color-text);
  text-decoration: none;
}

.items {
  margin-inline: 0;
  margin-block: 11px;
}

.label,
.value {
  padding-inline: 7px;
  padding-block: 4px;
}

.label {
  color: var(--color-text-tertiary);
  padding-inline-end: 15px;
}

.value {
  font-size: var(--font-size-md);
  padding-inline-start: 15px;
  font-weight: var(--font-weight-bold);
}

@keyframes tooltipEnter {
  from {
    opacity: 0;
    transform: translate3d(-50%, -90%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(-50%, -100%, 0);
  }
}
