.sort {
  border-inline-start: 1px solid var(--color-border-section);
}

.categoryGroup {
  margin-inline-end: 15px;
}

.subnav {
  display: flex;
}
