.root {
  padding-inline: 0;
  padding-block: 0 var(--spacing-48);
}

.radioContainer {
  display: grid;
  gap: rem(20px);
  margin-block-end: 80px;
}

.limitReached {
  padding: var(--spacing-32);
  margin-inline: 0;
  margin-block: var(--spacing-16) var(--spacing-32);
}

.limitReachedTitle {
  color: var(--color-status-warning);
  font-size: rem(15px);
  font-weight: var(--font-weight-bold);
}

.limitReached span {
  display: block;
}

.link {
  margin-inline-start: var(--spacing-04);
}

.conceptsEmpty {
  padding-inline: var(--spacing-32) 0;
  padding-block: 0 var(--spacing-32);
}

.conceptsTable {
  width: 100%;
}

.conceptsTable > thead > tr > th {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-bold);
  color: var(--color-text-body);
  cursor: default;
  padding-inline: var(--spacing-32);
  padding-block: var(--spacing-16) var(--spacing-08);
  text-align: start;
}

.conceptsTable > tbody > tr > td {
  padding-inline: var(--spacing-32);
  padding-block: var(--spacing-12);
}

.conceptsTableForm {
  padding: 0 !important;
}

.conceptsTable > thead > tr > th:nth-child(2) {
  width: 100%;
}

.conceptsTable > thead > tr > th:nth-child(4) {
  text-align: center;
}

.conceptsTable > thead > tr > th:last-child {
  width: 36px;
}

.conceptsTable > tbody > tr {
  height: 50px;
  box-shadow: inset 0 1px 0 0 rgba(222, 226, 231, 0.7);
}

.sensitiveConcept {
  transition:
    ease color,
    opacity 0.15s;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-base);
  color: var(--color-text-body);
  text-transform: capitalize;
  max-width: 250px;
}

.sensitiveConcept > div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sensitiveSwitch {
  padding-inline: var(--spacing-12);
  padding-block: 0;
  text-align: center;
}

.sensitiveSwitch > div {
  height: 25px;
}

.sensitiveDropDown {
  text-align: end;
}

.sensitiveConcept .disabled {
  color: var(--color-text-disabled);
}

.sensitiveWord {
  display: inline;
}

.word {
  transition: ease all 0.15s;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
}

.wordDisabled {
  color: var(--color-text-disabled);
  border-color: var(--color-text-disabled);
}

.wordMore {
  font-weight: var(--font-weight-bold);
}

.group {
  border-radius: var(--border-radius-card);
  background-color: var(--color-bg-card);
  box-shadow: var(--depth-1-box-shadow);
  margin-inline: 0;
  margin-block: var(--spacing-32);
}

.groupName {
  padding-inline: var(--spacing-32) 0;
  padding-block: var(--spacing-24) var(--spacing-32);
}

.opened {
  background-color: var(--color-bg-alert);
}

.monitoredWordsHeader {
  display: flex;
  justify-content: space-between;
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (--handhelds) {
  .radioContainer :global(.peak-radio-group) {
    flex-direction: column;
  }

  .conceptsTableWrapper {
    width: 100%;
    overflow: auto;
  }
}
