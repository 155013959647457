.root {
  color: var(--color-text-hint);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-bold);
  line-height: 1.2;
}

.root.light {
  color: var(--color-text-hint);
}

.tiny {
  font-size: var(--font-size-xs);
}
