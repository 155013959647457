.container {
  margin-block-end: 32px;
}

.container:last-of-type {
  margin-block-end: 0;
}

.title {
  margin-block-end: 12px;
}

.list {
  padding-inline: 16px 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: var(--border-radius-card);
  border: 1px solid var(--color-border);
  background-color: var(--color-bg-card);
}

.item {
  padding-inline: 0;
  padding-block: 16px;
  border-block-end: 1px solid var(--color-border);
}

.item:last-of-type {
  border-block-end: none;
}

.button {
  align-items: center;
  display: flex;
  font-weight: var(--font-weight-bold);
  text-decoration: underline;
  margin-inline: 0;
  margin-block: 16px;
  font-size: var(--font-size-sm);
  color: var(--color-text-primary);
}

@media (--handhelds) {
  .root {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }

  .button {
    margin-inline-start: 0;
    margin-block-start: rem(8px);
  }
}
