.count {
  align-items: center;
  display: flex;
  flex: 2;
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
  justify-content: flex-end;
}

.loader {
  flex: 2;
}
