.container {
  margin-block: var(--spacing-64);
}

.spinnerColor {
  color: var(--color-status-highlight);
}

@media (--handhelds) {
  .hideOnMobile {
    display: none;
  }
}
