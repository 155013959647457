.icon {
  width: 24px;
  height: 24px;
}

.suggestionsMessageContainer {
  display: flex;
  align-items: center;
  gap: var(--spacing-12);
}
