.syncInfo {
  display: flex;
  width: 100%;
  max-height: rem(720px);
  background: var(--color-controls--disabled-bg);
  padding: var(--spacing-04);
  border-radius: var(--border-radius-base);
  border: 1px solid var(--color-controls-border);
  overflow: scroll;
}

.syncInfoPre {
  padding: var(--spacing-08) var(--spacing-16);
  margin: 0;
  font-family: inherit;
}

.labelWrapper {
  padding-block-end: var(--spacing-16);
}

.loaderWrapper {
  padding: var(--spacing-08) var(--spacing-16);
}
