.root thead th,
.root tbody td {
  border-inline-end: 1px solid var(--color-table-border);
  padding-inline: 0 0.75rem;
  padding-block: 0.75rem;
  overflow: visible;
}

.root thead th {
  border-block: 1px solid var(--color-table-border);
  box-shadow: 0 rem(2px) rem(4px) rgb(0 0 0 / 8%);
  padding: 0.75rem;
  position: sticky;
  text-align: start;
  inset-block-start: 0;
  z-index: 1;
}

.root tbody td {
  padding-block: 0;
  padding-inline: rem(10px);
}

.root thead th:first-child,
.root tbody tr td:first-child {
  padding-inline: 0;
  padding-block: 0.75rem;
  padding-inline-start: var(--spacing-40);
}

.root tbody tr:last-child td {
  border-block-end: 1px solid var(--color-table-border);
}

.root tbody tr:hover td {
  background-color: var(--color-bg-card) !important;
}

.segmentName {
  width: 100%;
  display: inline-flex;
  align-items: center;
}

.segmentNameContent {
  margin-inline-start: rem(10px);
}

.segmentNameContent > :last-child {
  display: flex;
  width: max-content;
}

.segmentNameTranslated {
  color: var(--color-text-tertiary);
}

.segmentNameCustom {
  color: var(--color-text-tertiary);
  font-weight: var(--font-weight-bold);
}

.segmentName small {
  padding-block-start: rem(2px);
  margin-inline-start: rem(10px);
}

.directStatus {
  margin-inline-start: rem(4px);
  color: var(--color-text-hint);
  font-weight: normal;
}

.segmentEmployeesCount {
  text-align: end;
}

.segmentEmployeesCount button {
  text-decoration: underline;
}

.segmentManagers {
  text-align: end;
}

.buttonContainer {
  vertical-align: middle;
}

.segmentManager,
.remainingManagersCountWrapper {
  display: inline-block;
  margin-inline-end: rem(5px);
  position: relative;
  vertical-align: middle;
}

.segmentManagerIconContainer {
  position: absolute;
  inset-block-start: rem(23px);
  inset-inline-end: rem(-3px);
  background-color: var(--color-bg-card);
  border: 1px solid var(--color-border);
  display: flex;
}

.segmentManagerIcon {
  height: 0.75rem;
  width: 0.75rem;
}

.remainingManagersCount {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: var(--font-weight-bold);
  background-color: var(--color-viz-neutral-fill);
  border-radius: 50%;
  /* Pixel values to match the <Avatar/> size */
  width: 30px;
  height: 30px;
  font-size: var(--font-size-sm);
}

.root tbody tr td:last-child {
  text-align: center;
  margin-inline: auto;
  margin-block: 0;
  padding-inline-end: 0;
}

.root tbody tr td.segmentEdit {
  padding-inline: var(--spacing-32);
}
