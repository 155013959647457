.label,
.link {
  color: var(--color-text-secondary);
  font-size: var(--font-size-base);
}

.link {
  transition: color 0.2s;
}

.link:hover,
.link:active,
.link:focus {
  color: var(--color-text);
}

.arrow {
  display: flex;
  margin-inline: var(--spacing-04);
}

.chevronIcon {
  height: 0.75rem;
  width: 0.75rem;
}

[dir='rtl'] .arrow {
  transform: rotate(180deg);
}

@media (--handhelds) {
  .label,
  .link {
    font-size: var(--font-size-xs);
  }
}
