.scoreMainNumber {
  font-size: var(--font-size-3xl);
  font-weight: var(--font-weight-bold);
}

.cardMenu {
  margin-inline-end: var(--spacing-08);
}

.hintText {
  color: var(--color-text-hint);
}

.diffToBenchmark {
  display: flex;
  flex-direction: row;
  height: var(--spacing-16);
  margin-block-start: var(--spacing-08);
  gap: var(--spacing-08);
  align-items: center;
}

.mainSpinner {
  padding-block: 50%;
}

.mainSpinner > div {
  margin: auto;
}

@media (--handhelds-tablets) {
  .modal {
    height: 100vh;
    width: 100vw;
  }
}

/* fix for <UnstyledButton> which used to have position: relative; */
.relative {
  position: relative;
}
