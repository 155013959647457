/* All of these are deprecated and should not be used */

/**
 * Bootstrap grid classes
 * Only classes used in the product are copied to this stylesheet from the original Bootstrap 4 grid package
 * Usage of these are documented in this spreadsheet: https://docs.google.com/spreadsheets/d/1ohUsZLMD9P--loS1dRjJAxBPx_oXP-JXZnEDdyjlKzE/edit?gid=452187466#gid=452187466
 */
.bsContainer {
  width: 100%;
  padding-inline: 15px;
  margin-inline: auto;
}

.bsRow {
  display: flex;
  flex-wrap: wrap;
  margin-inline: -15px;
}

.bsColSm2,
.bsColSm4,
.bsColSm6,
.bsColSm7,
.bsColSm10,
.bsColMd3,
.bsColMd5 {
  position: relative;
  width: 100%;
  padding-inline: 15px;
}

@media (min-width: 576px) {
  .bsContainer {
    max-width: 540px;
  }

  .bsColSm2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .bsColSm4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .bsColSm6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .bsColSm7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .bsColSm10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .bsOffsetSm1 {
    margin-inline-start: 8.3333333333%;
  }
}

@media (min-width: 768px) {
  .bsContainer {
    max-width: 720px;
  }

  .bsColMd3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .bsColMd5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .bsOffsetMd3 {
    margin-inline-start: 25%;
  }
}

@media (min-width: 992px) {
  .bsContainer {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .bsContainer {
    max-width: 1140px;
  }
}
