.accordionTitle {
  margin: 0;
}

.header {
  margin: 0;
}

.trigger {
  align-items: center;
  display: flex;
  justify-content: space-between;
  border-block-end: 1px solid var(--color-border);
  padding: var(--spacing-16);
}

[aria-expanded='false'] .trigger {
  border-block-end: none;
}

.trigger span {
  margin-inline-end: var(--spacing-08);
}

.panel {
  padding: var(--spacing-24);
  padding-block-start: var(--spacing-16);
}

.errorIndicator {
  display: inline-flex;
  align-items: center;
  font-weight: var(--font-weight-normal);
}
