.toggler,
.benchmarkOnly,
.label {
  font-size: var(--font-size-md);
}

.toggler,
.label {
  color: var(--color-text-primary);
  font-weight: var(--font-weight-bold);
}

.label > div {
  display: inline-block;
}

.toggler {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  flex-wrap: wrap;
  text-decoration: none;
}

.toggler:hover,
.toggler:active,
.toggler:visited,
.toggler:focus {
  color: var(--color-text);
  text-decoration: none;
}

.linkArrow {
  color: var(--color-text-primary);
  height: 20px;
  transition: transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  width: 20px;
}

[dir='rtl'] .linkArrow {
  transform: scaleX(-1);
}

.toggler:hover .linkArrow {
  transform: translate3d(5px, 0, 0);
}

[dir='rtl'] .toggler:hover .linkArrow {
  transform: translate3d(-5px, 0, 0) scaleX(-1);
}

.root.small .toggler,
.root.small .benchmarkOnly,
.root.small .label {
  font-size: var(--font-size-xs);
}

.root.tiny .toggler,
.root.tiny .benchmarkOnly,
.root.tiny .label {
  font-size: var(--font-size-3xs);
}

@media (--handhelds) {
  .toggler,
  .benchmarkOnly {
    font-size: var(--font-size-md);
  }
}

@media (--intermediate) {
  .toggler,
  .benchmarkOnly {
    font-size: var(--font-size-md);
  }
}
