.settingsContainer {
  border-block-end: 1px solid var(--color-border);
  padding-block: var(--spacing-16) var(--spacing-24);
}

.copyFields {
  display: flex;
  flex-direction: row;
  padding-block-start: var(--spacing-08);
}

.copyField {
  flex-direction: column;
  margin-inline-end: var(--spacing-24);
  width: 100%;
}

.buttons {
  display: flex;
  gap: var(--spacing-16);
}

.dnsFieldset {
  border: 0;
}
