.image {
  height: 90px;
  width: auto;
}

@media (--min-width-xs) {
  .image {
    height: 100%;
  }
}
