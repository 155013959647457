.segmentPicker {
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.button {
  line-height: 2;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

[data-test-id='segment-badge'] > div > div:last-of-type > span {
  white-space: break-spaces;
}
