.seeAllButton {
  align-items: center;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  font-size: var(--font-size-base);
  height: 24px;
  justify-content: center;
  margin-block: 20px 24px;
  padding: 0;
  font-weight: var(--font-weight-bold);
}

.seeAllButton:hover,
.seeAllButton.focused {
  opacity: 0.8;
}

.seeAllButton:not(.focused):focus {
  outline: 0;
}

.seeAllButton .menuIcon {
  margin-inline-end: 12px;
}

@media (--tablets-min) {
  .seeAllButton {
    padding-inline: 5px;
    padding-block: 0;
    margin-block-end: 0;
  }
}
