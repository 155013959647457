.badge {
  background-color: var(--color-status-highlight);
  border-radius: var(--border-radius-lg);
  color: var(--color-text-inverted);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-bold);
  padding-inline: var(--spacing-04);
  text-align: center;
  white-space: nowrap;
}

.badge.sensitive {
  background-color: var(--color-status-negative);
}

.unreadCommentsHeader {
  background-color: var(--color-border);
  border-radius: var(--border-radius-card) var(--border-radius-card) 0 0;
  padding: var(--spacing-16);
  padding-block-end: 0;
  margin-block-start: var(--spacing-16);
  margin-inline: var(--spacing-16);
}

@media (--min-width-sm) {
  .unreadCommentsHeader {
    margin-block-start: 0;
    margin-inline: 0;
  }
}
