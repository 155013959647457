.container {
  display: flex;
  flex-direction: column;
  padding-block-end: 30px;
  position: relative;
  width: 100%;
}

/* TODO: remove when we have a real page container */
.imitateGridPadding {
  padding-inline: 15px; /* 15px is due to the padding in the peak-grid being 15px */
}
