.root {
  width: 100%;
}

.header {
  font-weight: var(--font-weight-bold);
  padding-inline: 0;
  padding-block: 10px;
  margin-inline: 2px 0;
  margin-block: 0 0;
}

.header > div {
  display: flex;
  align-items: center;
}

.header button {
  display: inline-flex;
}

.name {
  padding-inline-start: 50px;
}
