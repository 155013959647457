.scoreModeToggler :global(.peak-radio-group-item) {
  flex-basis: auto;
}

.mobileFilterMenu {
  margin-inline: 12px 8px;
  margin-block: 12px 12px;
}

.segmentsPicker > button:global(.peak-btn-block) {
  margin-inline-start: rem(15px);
  width: inherit;
}

@media (--handhelds) {
  .hiddenFilters > div {
    display: none;
  }
}
