.header button {
  margin-inline: 8px 0;
  margin-block: 0 0;
}

.title {
  align-items: center;
  display: flex;
  font-weight: var(--font-weight-bold);
  font-size: rem(15px);
  margin-block-end: 15px;
}

.titleText {
  flex: 2;
}

.list {
  margin-inline: -30px;
  margin-block: 30px 0;
}

.item {
  align-items: center;
  box-shadow: inset 0 1px 0 0 var(--color-border);
  display: flex;
  padding-inline: 30px;
  padding-block: 15px;
}

.itemContext {
  align-self: center;
  display: flex;
  flex: 2;
  align-items: center;
}

.itemActions {
  align-items: center;
  display: flex;
}

.itemActions a {
  color: var(--color-text-tertiary);
  margin-inline-end: 20px;
  text-decoration: underline;
}

.itemActions a:hover,
.itemActions a:active,
.itemActions a:visited,
.itemActions a:focus {
  color: var(--color-text-tertiary);
}

.switch {
  display: inline-flex;
  margin-inline: 0 20px;
  margin-block: 0 0;
  min-width: 200px;
  max-width: 200px;
  justify-content: flex-end;
}

.switch > div {
  display: flex;
}

.switch label {
  display: flex;
  align-items: center;
  margin-inline: 20px;
  margin-block: 0;
}

.createdAt {
  color: var(--color-text-tertiary);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-2xs);
  margin-inline-start: 20px;
}

@media (--handhelds) {
  .title {
    align-items: flex-start;
    flex-direction: column;
  }

  .header button {
    margin-inline: 0;
    margin-block: var(--spacing-08) 0;
  }

  .list {
    margin-block: var(--spacing-16);
    margin-inline: 0;
  }

  .item {
    align-items: flex-start;
    flex-direction: column;
    padding-inline: var(--spacing-08);
  }

  .itemContext {
    align-self: flex-start;
  }

  .itemActions {
    align-items: flex-start;
    margin-block-start: var(--spacing-16);
  }

  .switch {
    min-width: auto;
    max-width: 100%;
  }
}

.loader {
  display: flex;
  justify-content: center;
}
