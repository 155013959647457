.container {
  display: flex;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 1;
}

.avatar {
  align-items: center;
  display: flex;
  flex-shrink: 0;
}

.anonymousIcon,
.avatarEmpty {
  align-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}

.avatarEmpty {
  border: 2px dashed var(--color-border);
  height: 34px;
  width: 34px;
}

.anonymousIcon {
  background-color: var(--color-border);
  color: var(--color-text);
  height: 30px;
  width: 30px;
}

.anonymousIcon svg {
  height: 16px;
  width: 16px;
}

.nameContainer {
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 1;
  justify-content: center;
  margin-inline-start: 9px;
}

.plain .nameContainer {
  margin-inline-start: 0;
}

.nameContainer.new {
  justify-content: center;
}

.nameContainer.new .name {
  color: var(--color-text-tertiary);
}

.name {
  color: var(--color-text-primary);
  display: flex;
  flex: 0 1 auto;
  font-weight: var(--font-weight-bold);
}

.plain .name {
  color: var(--color-text);
}

.details {
  align-items: center;
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  margin-inline-start: -10px;
}

.email,
.phone {
  color: var(--color-text-tertiary);
  font-size: var(--font-size-2xs);
}

.email {
  align-items: center;
  display: inline-flex;
  margin-inline: 10px 3px;
  max-width: 190px;
}

.list .email {
  max-width: 176px;
}

.slideIn .email {
  max-width: 360px;
}

.email.bounced {
  color: var(--color-status-negative);
}

.warning {
  margin-inline-end: 5px;
}

.phone {
  align-items: center;
  display: flex;
  height: 15px;
  margin-inline-start: 4px;
  overflow: hidden;
}
