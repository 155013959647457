.container {
  display: grid;
  gap: var(--spacing-24);
  margin-block-start: var(--spacing-24);
}

.headerContainer {
  display: flex;
  align-items: center;
  gap: var(--spacing-24);
}

.spacer {
  flex: 1 1 auto;
}

.logTable {
  width: 100%;
}

.logTable thead {
  position: sticky;
  inset-block-start: calc(-1 * var(--spacing-24));
}

.logTable thead tr {
  background-color: var(--color-table-header);
}

.logTable tbody tr {
  background-color: var(--color-table-row);
  border-block: 1px solid var(--color-table-border);
}

.logTable th {
  font-size: var(--font-size-sm);
}

.logTable tr > * {
  padding: var(--spacing-16);
  text-align: start;
  vertical-align: top;
}

.logTable td p {
  margin: 0;
}

.logTable .fetchLogsContainer {
  padding-block: 0;
  background-color: var(--color-bg-info);
  height: var(--spacing-48);
  font-weight: var(--font-weight-normal);
}

.fetchLogs {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-08);
}

.fetchLogsButton:hover {
  text-decoration: underline;
}

.fetchLogsIcon {
  color: var(--color-text-hint);
}

.fetchLogsIndicator {
  height: 22px;
  width: 22px;
  display: grid;
  place-content: center;
}

.logTable .time {
  width: 6rem;
}

.logTable .status {
  height: 1.25rem;
  border-radius: 0.625rem;
  font-size: var(--font-size-3xs);
  font-weight: var(--font-weight-bold);
  display: inline-flex;
  align-items: center;
  padding-inline: var(--spacing-08);
}

.logTable .status.warn {
  background-color: var(--color-status-warning);
}

.logTable .status.error {
  background-color: var(--color-status-highlight-loud);
}

.logTable .status.info {
  background-color: var(--color-status-highlight);
}

.logTable .status.error,
.logTable .status.info {
  color: var(--color-text-inverted);
}

.logTable .message {
  display: grid;
  gap: var(--spacing-08);
  justify-items: start;
}

.loader {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 15px;
}
