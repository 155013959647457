.root {
  align-items: stretch;
  display: flex;
  padding: 0;
}

.engagement {
  align-items: stretch;
  border: 0;
  border-radius: var(--border-radius-card) 0 0 var(--border-radius-card);
  box-shadow: none;
  display: flex;
  flex: 3;
  flex-direction: column;
  min-height: 385px;
  position: relative;
}

.engagement::after {
  box-shadow: 0 2px 25px 0 rgba(44, 47, 55, 0.13);
  content: '';
  height: 100%;
  inset-inline-start: 0;
  opacity: 0;
  position: absolute;
  inset-block-start: 0;
  transition: opacity 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  width: 100%;
  z-index: -1;
}

.engagement.active::after {
  opacity: 1;
}

.engagement.positive {
  background-color: rgba(190, 236, 215, 0.1);
}

.engagement.negative {
  background-color: rgba(255, 221, 207, 0.1);
}

.changes {
  flex: 2;
}

.noScore .engagement {
  background-color: var(--color-bg-card);
  background-image: url('./no-data-bg@2x.png');
  background-position: top right;
  background-repeat: no-repeat;
  background-size: auto 100%;
  height: auto;
}

.header,
.title {
  align-items: center;
  display: flex;
  line-height: 1;
}

.expand {
  align-items: center;
  display: flex;
  flex: 2;
  height: 16px;
  justify-content: flex-end;
  line-height: 1;
  text-transform: none;
  color: var(--color-text-primary);
}

.contentWrapper {
  align-items: stretch;
  display: flex;
  flex: 2;
  flex-direction: column;
}

.breakdown {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
}

.hidden {
  animation: fadeOut 0.3s forwards ease-out;
  pointer-events: none;
}

.fadeIn {
  animation: fadeIn 0.2s forwards linear;
  animation-delay: 0.4s;
  opacity: 0;
}

.transparent {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}

.loading {
  align-items: center;
  display: flex;
}

.mobile {
  display: none;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0.1;
  }

  to {
    opacity: 1;
  }
}

@media (--handhelds-tablets) {
  .root {
    flex-direction: column;
  }
}

@media (--handhelds) {
  .changes,
  .tier {
    display: none;
  }

  .mobile {
    align-items: stretch;
    border-block-end: 1px solid var(--color-border);
    border-block-start: 1px solid var(--color-border);
    display: flex;
    margin-inline: -20px;
    margin-block: 0 20px;
  }

  .mobileColumn {
    border-inline-end: 1px solid var(--color-border);
    flex: 1;
    padding: 20px;
  }

  .mobileColumn:last-child {
    border-inline-end: 0;
  }

  .expandTimeline {
    margin-block-start: 5px;
    padding-inline-start: 25px;
    font-weight: var(--font-weight-bold);
  }
}

@media (--intermediate) {
  .score {
    font-size: rem(110px);
  }
}

@media (--large-screens) {
  .changes {
    flex: 0 0 300px;
  }
}

/* fix for <UnstyledButton> which used to have position: relative; */
.relative {
  position: relative;
}
