.root {
  padding-inline: 0;
  padding-block: var(--spacing-16) rem(100px);
}

.section {
  border-block-end: 1px solid var(--color-border);
  padding-inline: 0;
  padding-block: var(--spacing-24);
}
