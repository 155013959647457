.root {
  position: relative;
  height: 100%;
}

.root::after {
  content: '';
  position: absolute;
  inset-block-start: 50%;
  inset-inline: 0;
  height: 1px;
  background-color: var(--color-border);
}

.graph {
  height: 100%;
}

.dates {
  position: absolute;
  inset-block-end: var(--spacing-08);
  inset-inline: var(--spacing-16);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  pointer-events: none;
}
