.root {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 0 0 var(--border-radius-base) var(--border-radius-base);
  display: flex;
  padding-block: var(--spacing-08);
  width: 100%;
}

.root.isCollapsible {
  height: var(--context-switcher-round);
  background-color: var(--color-bg-primary);
  border-block: 1px solid var(--color-border-section);
  border-radius: 0;
}

.inner {
  display: inline-flex;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
}

.value {
  align-items: center;
  color: var(--color-text);
  display: inline-flex;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}

.value svg {
  margin-inline-end: var(--spacing-08);
}

.value span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.dropdown {
  padding-inline: var(--spacing-08);
  width: 100%;
}

.value use {
  fill: var(--color-text);
}

.closeContainer {
  flex: 0 0 rem(28px);
  padding-inline-end: var(--spacing-04);
}

.close {
  align-items: center;
  background-color: var(--color-text-primary);
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  height: 1rem;
  justify-content: center;
  width: 1rem;
}

.close svg use {
  fill: var(--color-text-inverted);
}

.spinner {
  width: 100%;
  display: flex;
  justify-content: center;
}
