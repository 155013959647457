.containerEmpty {
  margin-inline: var(--spacing-04);
  margin-block: 0;
  border-radius: var(--border-radius-card);
  border: 1px dashed var(--color-border);
  background-color: var(--color-bg-secondary);
  font-size: var(--font-size-sm);
  padding-inline: var(--spacing-32);
  padding-block: var(--spacing-24);
  text-align: center;
  color: var(--color-text-secondary);
}

.card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 16px;
  border-radius: var(--border-radius-card);
  border: 1px solid var(--color-border);
  background-color: var(--color-bg-card);
  font-size: var(--font-size-md);
  color: var(--color-text-primary);
}

.card:hover {
  text-decoration: none;
}

.cardInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actionsIcon {
  margin-inline-end: var(--spacing-08);
}

.addBtnContainer {
  margin-block-start: var(--spacing-08);
}

.viewButton {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-sm);
  line-height: 1.5;
  text-decoration: underline;
  color: var(--color-text-primary);
}
