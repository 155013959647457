.root,
.main {
  align-items: stretch;
  display: flex;
}

.root {
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
}

.container {
  margin-inline: auto;
  margin-block: 0;
  max-width: 640px;
  width: 100%;
}

.count {
  color: var(--color-text-secondary);
}

.hideCount .count {
  display: none;
}

.main {
  flex: 2;
  overflow: hidden;
}

.content {
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: stretch;
}

.sectionHeader {
  align-items: center;
  display: flex;
  flex: 0 0 70px;
  margin-inline: auto;
  margin-block: 0;
  max-width: 640px;
  width: 100%;
}

.headerTitle {
  font-size: var(--font-size-lg);
}

.headerTitleV2 {
  font-size: var(--font-size-lg);
  padding-block-start: 8px;
  margin-block-end: -12px;
}

.actions {
  display: flex;
  flex: 2;
  flex-direction: column;
  overflow: auto;
  position: relative;
}

@media (--handhelds) {
  .mobile {
    padding-inline: 15px 15px;
    padding-block: 15px 0;
    flex-wrap: wrap;
  }

  .mobileTabs {
    width: 100%;
    height: initial;
    margin-inline-start: 0;
    flex-grow: 0;
  }
}
