.root {
  font-size: var(--font-size-md);
}

.root strong {
  font-weight: var(--font-weight-bold);
}

.root.positive {
  color: var(--color-score-positive);
}

.root.negative {
  color: var(--color-score-negative);
}

.root.small {
  font-size: var(--font-size-xs);
}

.root.tiny {
  font-size: var(--font-size-3xs);
}

.root.primary {
  color: var(--color-text-primary);
}
