.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.top {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-block: 20px -20px;
}

.container {
  display: inline-flex;
  flex-direction: row;
}

.title {
  font-weight: var(--font-weight-bold);
}

.arrow {
  align-items: center;
  color: var(--color-text);
  display: inline-flex;
  font-family: var(--font-family-base);
  margin-inline-start: 10px;
}

[dir='rtl'] .arrow {
  transform: scaleX(-1);
}

.link {
  color: var(--color-text-secondary);
  text-decoration: none;
  display: flex;
  align-items: baseline;
}

.link:hover {
  text-decoration: none;
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretch;
  padding: 10px;
}

.empty {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  padding: 60px;
}

.grid {
  align-content: baseline;
  display: flex;
  flex-basis: auto;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: wrap;
  padding-inline: 0;
  padding-block: 30px;
  width: 100%;
}

.grid.gridEnter {
  opacity: 0.7;
  transform: translateY(20%);
}

.grid.gridEnter.gridEnterActive {
  opacity: 1;
  transform: translateY(0);
  transition:
    transform 500ms ease-in-out 200ms,
    opacity 500ms ease-in-out 200ms;
}

.gridItem {
  display: flex;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  margin-inline: 15px;
  margin-block: 0 0;
}

.noResourcesHeader {
  margin-inline: 5px 5px;
  margin-block: 15px 0;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-md);
}

.noResourcesInfo {
  margin-inline: 5px 5px;
  margin-block: 5px 20px;
  max-width: 334px;
}

@media (--handhelds-tablets) {
  .laneViewGrid {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .laneViewItem {
    flex: 0 0 auto;
  }
}
