:root {
  --pattern-channel-1: black;
}

.container {
  padding-block: var(--spacing-24) var(--spacing-40);
  padding-inline: var(--spacing-16);
  text-wrap: balance;
}

.contentHeader > div {
  justify-content: space-between;
}

@media (--handhelds-tablets) {
  /* This content is part of a scrollable row */
  /* The max-width ensures a better experience on mobile, so the text will fit the screen without scrolling */
  .chartContent,
  .participationByChannelContainer h3 {
    max-width: calc(100vw - 3rem);
  }

  .detailsTable {
    order: 2;
  }

  /* Targeting the Stack container */
  /* There is no easy way to target tablets in JS */
  .contentContainer > div {
    flex-direction: column;
  }
}

@media (--desktop) {
  .participationByChannelTable {
    min-width: 450px;
  }
}

.detailsTable {
  height: 100%;
}

.detailsTable tr :is(td, th),
.participationByChannelTable tr :is(td, th) {
  background-color: transparent;
}

.detailsTable p {
  font-size: var(--font-size-sm);
}

.detailsTable > tbody > tr:nth-child(odd) td {
  background-color: var(--color-table-row-alt);
}

.detailsTable .actions {
  text-align: end;
}

.detailsTable,
.participationByChannelTable {
  border-collapse: collapse;
  max-width: calc(100vw - 3rem);
}

.detailsTable tbody td,
.participationByChannelTable tbody td {
  vertical-align: bottom;
  padding-block: var(--spacing-16);
}

.detailsTable thead th,
.detailsTable tbody td,
.participationByChannelTable thead th,
.participationByChannelTable tbody td {
  border: 1px solid var(--color-table-border);
}

.participationByChannelTable thead th {
  word-wrap: normal;
  white-space: normal;
}

.participationByChannelTable tbody td svg {
  vertical-align: inherit;
  margin-inline-end: var(--spacing-08);
}

.modalContent p {
  margin-block-end: var(--spacing-16);
}

.modalContent p:last-child {
  margin-block-end: 0;
}

.loadingContainer {
  /* Height of the chart */
  min-height: 200px;
  min-width: 100%;
  color: var(--color-status-highlight);

  /* <Stack> component was not working with the <Spinner /> */
  display: flex;
  align-items: center;
  justify-content: center;
}
