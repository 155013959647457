.saml .form {
  max-width: 100%;
}

.saml .form .setting label {
  margin-block-end: 0;
}

.saml .form .setting {
  margin-block-end: 0;
  padding-block-start: 26px;
}

.form {
  margin-block-start: 20px;
  max-width: 600px;
}

.samlFormGrid {
  display: grid;
  gap: var(--spacing-16) var(--spacing-24);
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
}

.formField.withLabel {
  display: grid;
  gap: var(--spacing-04);
  grid-auto-rows: max-content;
}

.samlCertificate {
  display: grid;
  grid-template-columns: auto max-content;
  gap: var(--spacing-08);
}

.samlCheckboxContainer {
  align-self: center;
  padding-block-start: 1.1rem;
}

@media (--tablets-min) {
  .label {
    margin-inline-start: 10px;
    margin-block-start: 0;
    font-weight: bold;
  }
}

.footer {
  margin-block-start: var(--spacing-16);
  display: flex;
  gap: var(--spacing-16);
}
