.divider {
  border-block-end: 1px solid var(--color-border);
}

.iconContainer {
  width: 32px;
  height: 32px;
}

.icon {
  width: 100%;
  height: 100%;
}

.buttonWrapper {
  align-self: start;
}

.message {
  background: var(--color-bg-primary);
  padding: var(--spacing-16);
  border-radius: var(--border-radius-base);
  border: 1px solid var(--color-conversation-other-border);
  flex: 1;
  white-space: pre-line;
}

.message.reply {
  background: var(--color-conversation-user-bg);
  border: 1px solid var(--color-conversation-user-border);
}
