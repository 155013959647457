.root {
  padding-inline: 0;
  padding-block: var(--spacing-24) var(--spacing-48);
}

.root label {
  font-weight: var(--font-weight-bold);
}

.title {
  font-size: rem(21px);
  font-weight: normal;
  margin-inline: 0;
  margin-block: var(--spacing-16);
  text-align: center;
}

.image {
  display: block;
  margin-inline: auto;
  margin-block: var(--spacing-16);
}

.description {
  font-size: var(--font-size-2xs);
}

.option {
  margin-inline: 0;
  margin-block: var(--spacing-32) var(--spacing-16);
}

.actions {
  margin-block-start: var(--spacing-32);
}
