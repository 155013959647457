@import 'animate.css/source/fading_entrances/fadeIn.css';

.root {
  width: 100%;
  height: 100%;
  position: relative;
}

.loader {
  display: flex;
  justify-content: center;
  padding: var(--spacing-08);
}

.attributeIcon,
.dragIcon,
.lockIcon {
  color: var(--color-icon-decorative);
}

.attributeIcon {
  display: flex;
  margin-inline-end: rem(20px);
}

.attribute {
  position: relative;
}

.dragIcon {
  position: absolute;
  inset-block-start: 50%;
  transform: translateY(-50%);
  inset-inline-end: rem(15px);
  display: flex;
  cursor: move;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: fadeIn;
}

.name {
  flex: 2;
  text-overflow: ellipsis;
  font-size: var(--font-size-sm);
}

.attributeButton {
  width: 100%;
  display: flex;
  align-items: center;
  padding-block: rem(12px);
  padding-inline: rem(20px) rem(43px);
}

.attributeButton:hover {
  background-color: var(--color-controls-hover-bg);
}

.attributeButton:focus {
  box-shadow: var(--focus-border) !important;
}

.attributeButton.active {
  background-color: var(--color-controls--selected-bg);
  transition: background-color 0.25s;
  position: relative;
}

.attributeButton.active::after {
  background-color: var(--color-controls--selected-marker);
  content: '';
  inset-inline-start: 0;
  position: absolute;
  inset-block: 0;
  width: rem(4px);
}

.inactive {
  height: rem(6px);
  width: rem(6px);
  margin-inline-end: rem(6px);
  background-color: var(--color-status-negative);
  border-radius: 100%;
}

.lockIcon {
  display: flex;
}

.attributeNameTranslated {
  color: var(--color-text-tertiary);
  display: block;
}

.textAndIconWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.iconWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
