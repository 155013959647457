.searchManagerSection {
  border-block-end: 1px solid var(--color-border);
  padding-block-end: var(--spacing-16);
  margin-block-end: var(--spacing-16);
}

.descTitle {
  margin-block-end: var(--spacing-08);
}

.descContent {
  margin: var(--spacing-08) 0;
}
