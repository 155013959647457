.failed,
.failing {
  color: var(--color-btn--warning-text);
}

.pending {
  color: var(--color-viz-warning);
}

.verified {
  color: var(--color-score-positive);
}

.dot {
  width: 0.3rem;
  height: 0.3rem;
  margin-inline-end: 0.3rem;
  border-radius: 50%;
}

.dot.verified {
  background-color: var(--color-score-positive);
}

.dot.failed,
.dot.failing {
  background-color: var(--color-btn--warning-text);
}

.dot.pending {
  background-color: var(--color-viz-warning);
}

.verificationContainer {
  display: inline-flex;
  align-items: center;
}

.lastChecked {
  color: var(--color-text-tertiary);
}

.actionButtonContainer {
  display: flex;
  gap: var(--spacing-16);
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}
