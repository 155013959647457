.microsoftTeams {
  margin-block-start: var(--spacing-40);
}

.settings {
  display: flex;
  flex-direction: column;
}

.setting {
  display: flex;
  flex-direction: column-reverse;
  margin-block-end: 20px;
}

@media (--tablets-min) {
  .setting {
    display: inline-flex;
    flex-direction: row;
    align-items: flex-start;
  }
}

.toggle {
  display: flex;
  align-items: flex-start;
}
