@import '../../../../../../../../styles/variables.css';

.root {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  overflow: hidden auto;
  width: var(--attributes-width);
  box-shadow: 2px 0 0 0 rgb(0 0 0 / 2%);
  transition: all 0.2s;
  text-align: end;
}

.root.hidden {
  width: rem(40px);
  overflow: unset;
}

.list {
  padding: 0;
  list-style: none;
  width: var(--side-width);
  opacity: 1;
}

.list.hidden {
  transform: translateX(-100%);
  opacity: 0;
}

[dir='rtl'] .list.hidden {
  transform: translateX(100%);
}

.metric {
  align-items: center;
  align-items: flex-start;
  border-block-end: 1px solid var(--color-border-section);
  color: var(--color-text-secondary);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  min-height: 48px;
  padding-inline: var(--spacing-24) 30px;
  padding-block: 12px 12px;
  position: relative;
  text-decoration: none;
}

.metric:hover,
.metric:active,
.metric:visited,
.metric:focus {
  color: var(--color-text-tertiary);
  text-decoration: none;
}

.metric.active {
  background-color: var(--color-controls--selected-bg);
  transition: background-color 0.25s;
  position: relative;
}

.metric.active::after {
  background-color: var(--color-controls--selected-marker);
  content: '';
  inset-inline-start: 0;
  position: absolute;
  inset-block: 0;
  width: 4px;
}

.inactive {
  background-color: var(--color-controls--danger-bg);
  border-radius: 100%;
  height: 6px;
  position: absolute;
  inset-inline-end: 10px;
  inset-block-start: 50%;
  transform: translateY(-50%);
  width: 6px;
}

.metric span {
  color: var(--color-text-primary);
  font-weight: var(--font-weight-bold);
  display: block;
}

.attribute {
  margin-block-start: 4px;
}

.hideButtonWrapper {
  padding: var(--spacing-04);
  border-block-end: 1px solid var(--color-border-section);
  display: flex;
  justify-content: flex-end;
}

.showIconWrapper {
  height: fit-content;
  padding: var(--spacing-04);
  padding-block: var(--spacing-08) rem(10px);
  border-block-end: 1px solid transparent;
}
