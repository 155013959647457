.content {
  overflow-x: hidden;
  margin-block-start: var(--spacing-20);
}

.form {
  padding: rem(30px);
  min-height: rem(250px);
  display: flex;
  align-items: center;
  width: 100%;
}

.footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.discardButton {
  font-weight: var(--font-weight-bold);
  text-decoration: underline;
  margin-block-start: var(--spacing-12);
}

.accordionHandle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-12) var(--spacing-16);
  background-color: var(--color-controls-bg);
}

.accordionHandleTitle {
  display: flex;
  gap: var(--spacing-08);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
  align-items: center;
}

.stepIndicator {
  color: var(--color-status-highlight);
  width: rem(24px); /* equal to icon width so the text align */
  text-align: center;
}

.disabled {
  color: var(--color-text-disabled);
}
