.radioOption {
  margin-inline: 0;
  margin-block: 10px;
}

@media (--desktop) {
  .groupName {
    max-width: 50%;
  }
}

.single > div {
  margin-block-start: 15px;
  padding: 0;
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
}

.icon {
  display: block;
  flex-shrink: 0;
  height: 32px;
  margin-block-end: 10px;
  position: relative;
  width: 32px;
  z-index: 0;
}

.image {
  display: block;
  position: absolute;
  z-index: 0;
}

.checkmark {
  inset-block-end: -12px;
  height: 40px;
  position: absolute;
  inset-inline-end: -12px;
  width: 40px;
  z-index: 1;
}

.title {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-bold);
}

.text {
  color: var(--color-text-secondary);
  font-size: var(--font-size-2xs);
}
