.header {
  display: flex;
  align-items: center;
  height: 68px;
  position: relative;
  width: 100%;
  z-index: 15;
}

.view {
  flex-shrink: 0;
  padding-inline: 14px 10px;
  padding-block: 0 0;
  width: 272px;
}

.round,
.infoIcon {
  margin-inline-start: 8px;
}

.infoIcon {
  display: flex;
  color: var(--color-icon-decorative);
}

.value::before {
  content: '-';
  padding-inline: 5px;
  color: var(--color-text-primary);
}

.option {
  align-items: center;
  display: inline-flex;
  flex-grow: 1;
  justify-content: flex-start;
}

.option .label {
  align-self: center;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 80px;
}

.option .info {
  color: var(--color-text-secondary);
  display: inline-block;
  font-size: var(--font-size-3xs);
  margin-inline-start: 5px;
  text-transform: uppercase;
}

.type {
  border-top-left-radius: 8px;
  border: 1px solid var(--color-border);
  border-block-end: none;
  display: flex;
  flex-grow: 1;
  height: 100%;
  padding: 10px;
  align-items: center;
}

.actions,
.target {
  justify-content: flex-end;
  flex-grow: 1;
}

.actions {
  display: none;
}

.target {
  display: flex;
}

.buttonIcon {
  margin-inline-end: 8px;
}

.actions > button {
  margin-inline-start: 4px;
  min-width: 125px;
}

@media (--large-screens) {
  .actions {
    display: flex;
  }

  .target {
    display: none;
  }
}
