.cards {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  margin-inline: -10px;
  margin-block: 0;
  padding-inline: 0;
  padding-block: 20px 0;
  width: calc(100% + 20px);
  position: relative;
}

.segment {
  flex-basis: 33.33%;
  flex-grow: 1;
  flex-shrink: 0;
  max-width: 33.33%;
  padding-inline: 10px;
  padding-block: 0;
}

.card {
  height: 100%;
  padding-block-end: rem(20px);
}

.cardWrapper {
  height: 100%;
}

.loader {
  padding: var(--spacing-32);
}

@media (--handhelds) {
  .segment {
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media (--tablets) {
  .segment {
    flex-basis: 50%;
    max-width: 50%;
  }
}

@media (--large-screens) {
  .segment {
    flex-basis: 1;
    min-width: 275px;
    max-width: 360px;
  }
}
