.section {
  border-block-end: 1px solid var(--color-border-section);
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  max-width: 100%;
  padding-inline: 0;
  padding-block: 10px;
}

.section:last-child {
  border-block-end: 0;
}

.title {
  color: var(--color-text);
  font-size: var(--font-size-md);
  margin-block-end: 10px;
  font-weight: var(--font-weight-bold);
}

.fields {
  display: flex;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  flex-wrap: wrap;
}

.fields.personal {
  flex-direction: column;
}
