.attributesListItem {
  display: grid;
  grid-template-columns: calc(50% - 50px) minmax(0, 1fr) min-content;
  gap: var(--spacing-08);
}

.searchSelectContainer > * {
  /*
    FIXME: This is a temp fix to used the shared SearchSelect inside a Modal, until the shared SearchSelect has been migrated to bedrock
  */
  position: initial;
}

.rulesContainer {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
}
