@import 'animate.css/source/flippers/flipInX.css';

.root > div {
  margin-block-end: var(--spacing-16);
}

.root > div:last-child {
  margin-block-end: 0;
}

.alert {
  animation-duration: 0.3s;
  animation-name: flipInX;
  animation-fill-mode: both;
}
