:root {
  --alertbar-height: 48px;
  --attributes-width: 280px;
  --navbar-height: 56px;
  --navbar-mobile-height: 60px;
  --navbar-width-collapsed: 68px;
  --navbar-width: 225px;
  --topbar-height: 60px;
  --member-width: calc(var(--app-max-width) - var(--attributes-width));
  --sidebar-width: 280px;
  --shared-dashboard-header-height: 66px;

  --app-header-height: 70px;
  --app-header-height-mobile: 60px;
  --app-navigation-margin: 22px;
  --app-navigation-width: 220px;
  --app-navigation-width-compact: 60px;
  --app-max-width: 1450px;

  --default-hierarchy-item-height: 51px;
}
