.topBottomSegmentsContainer {
  margin-block-start: var(--spacing-16);
}

.row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-block: var(--spacing-16);
}

.segmentName {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50%;
}

.score {
  text-align: end;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-bold);
  min-width: 4ch;
  margin-inline-end: var(--spacing-08);
}

/* Targeting the ScoreBar component */
.score + div {
  width: 100%;
}

.scoreBadge {
  width: 6rem;
  margin-inline-start: var(--spacing-08);
}

.barChartWrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  max-width: 50%;
}
