.error {
  display: flex;
  flex-direction: row;
  width: 420px;
  max-height: 90px;
  border-radius: 2px;
  background: var(--color-bg-card);
  border: 1px solid var(--color-status-negative);
  padding: 15px;
  margin-block-end: 10px;
}

.errorIcon,
.errorIcon svg {
  width: 20px;
  height: 20px;
}

.errorMessage {
  margin-inline-start: 15px;
}

.errorMessage strong,
.errorMessage span {
  display: block;
}

.errorMessage strong {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
  color: var(--color-text-primary);
}

.errorMessage span {
  font-size: var(--font-size-base);
  color: var(--color-text-tertiary);
  padding-block-start: 5px;
}
