.root {
  margin-block-start: var(--spacing-32);
}

.type {
  padding: var(--spacing-16) 0;
}

.radio {
  border-radius: var(--spacing-04);
  padding: var(--spacing-08);
}

.radio.enabled:hover {
  background-color: var(--color-controls-hover-bg);
}

.radio.selected {
  background-color: var(--color-controls--selected-bg);
}

.radio.enabled.selected:hover {
  background-color: var(--color-controls--selected-hover-bg);
}

.radioContent {
  padding-inline-start: var(--spacing-24);
}

.ephemeralWarning {
  padding-block-start: var(--spacing-08);
}
