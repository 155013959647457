.container.bundle {
  border-radius: 8px;
  border: 1px solid var(--color-border-section);
}

.container {
  padding-inline: 20px;
  padding-block: 17px;
}

.title {
  margin-block-end: 5px;
}

.container.bundle .topics {
  margin-block-start: 10px;
}

.topics > div {
  margin-block-end: 20px;
}

.container.bundle .topics > div {
  margin-block-end: 15px;
}
