:root {
  --color-score-range-1: hsl(86.36942675159236, 0%, 42.9411764706%);
  --color-score-range-2: rgb(159, 171, 144);
  --color-score-range-3: rgb(154, 180, 121);
  --color-score-range-4: rgb(146, 184, 96);
  --color-score-range-5: var(--color-score-number-promoters);

  --color-score-range-2-deuteranopia: rgb(144, 159, 175);
  --color-score-range-3-deuteranopia: rgb(121, 152, 183);
  --color-score-range-4-deuteranopia: rgb(96, 140, 185);
  --color-score-range-5-deuteranopia: var(
    --color-score-number-promoters--deuteranopia
  );
}

.root {
  font-family: var(--font-family-numbers);
  font-feature-settings:
    'kern' on,
    'liga' on,
    'calt' on;
  font-size: 8rem;
  height: 8rem;
  line-height: 1;
  position: relative;
  width: 100%;
}

.inline {
  display: inline-block;
}

.score {
  color: var(--color-score-number-promoters);
}

[data-theme='deuteranopia'] .score {
  color: var(--color-score-number-promoters--deuteranopia);
}

.animated .score .passives,
.animated .score .detractors {
  inset-inline-start: 0;
  position: absolute;
  inset-block-start: 0;
}

.animated .score span {
  transition:
    font-variation-settings 0.3s ease,
    color 0.2s ease-in;
  will-change: font-variation-settings, color;
}

.animated .score .background {
  color: var(--color-bg-primary);
  font-variation-settings: 'fill' 100;
  transition: color 0.2s ease-in;
}

.animated:hover .score .background {
  /* stylelint-disable-next-line */
  color: var(--color-score-number-promoters) !important;
}

[data-theme='deuteranopia'] .animated:hover .score .background {
  /* stylelint-disable-next-line */
  color: var(--color-score-number-promoters--deuteranopia) !important;
}

.animated:hover .score .passives span,
.animated:hover .score .detractors span {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}

.animated:hover .score .passives span {
  color: var(--color-score-number-passives);
}

.animated:hover .score .detractors span {
  color: var(--color-score-number-detractors);
}

.animated.delay .score .passives span,
.animated.delay .score .detractors span {
  transition-delay: 0.1s;
}

.animated.delay .score .passives span:nth-child(2),
.animated.delay .score .detractors span:nth-child(2) {
  transition-delay: 0.1s;
}

.animated.delay .score .passives span:nth-child(3),
.animated.delay .score .detractors span:nth-child(3) {
  transition-delay: 0.15s;
}

.animated.delay .score .passives span:nth-child(4),
.animated.delay .score .detractors span:nth-child(4) {
  transition-delay: 0.5s;
}

.static {
  height: auto;
  width: auto;
}

.noScore {
  margin-inline: auto;
  margin-block: 0;
  width: 50%;
  text-align: center;
}

.noScore .score,
.static .score {
  font-variation-settings: 'fill' 100;
}

.noScore .score,
.static.noScore .score {
  color: var(--color-text-tertiary);
}

.static .score {
  color: inherit;
}

@media (--handhelds) {
  .root {
    font-size: 6rem;
    height: 6rem;
  }

  .static {
    height: auto;
  }
}

@media (--intermediate) {
  .root {
    font-size: 7rem;
    height: 7rem;
  }

  .static {
    height: auto;
  }
}

/* ranges */
.animated.range1 .score .background {
  color: var(--color-score-range-1);
}

.animated.range1 .score .passives {
  color: rgb(88, 88, 88);
}

.animated.range1 .score .detractors {
  color: rgb(66, 66, 66);
}

.animated.range2 .score .background {
  color: var(--color-score-range-2);
}

.animated.range2 .score .passives {
  color: rgb(114, 132, 92);
}

.animated.range2 .score .detractors {
  color: rgb(76, 88, 61);
}

.animated.range3 .score .background {
  color: var(--color-score-range-3);
}

.animated.range3 .score .passives {
  color: rgb(112, 149, 65);
}

.animated.range3 .score .detractors {
  color: rgb(75, 99, 43);
}

.animated.range4 .score .background {
  color: var(--color-score-range-4);
}

.animated.range4 .score .passives {
  color: rgb(99, 159, 23);
}

.animated.range4 .score .detractors {
  color: rgb(66, 106, 15);
}

.animated.range5 .score .background {
  color: var(--color-score-range-5);
}

.animated.range5 .score .passives {
  color: rgb(82, 147, 0);
}

.animated.range5 .score .detractors {
  color: rgb(61, 110, 0);
}

[data-theme='deuteranopia'] .animated.range2 .score .background {
  color: var(--color-score-range-2-deuteranopia);
}

[data-theme='deuteranopia'] .animated.range2 .score .passives {
  color: rgb(108, 108, 107);
}

[data-theme='deuteranopia'] .animated.range2 .score .detractors {
  color: rgb(72, 72, 71);
}

[data-theme='deuteranopia'] .animated.range3 .score .background {
  color: var(--color-score-range-3-deuteranopia);
}

[data-theme='deuteranopia'] .animated.range3 .score .passives {
  color: rgb(90, 103, 116);
}

[data-theme='deuteranopia'] .animated.range3 .score .detractors {
  color: rgb(60, 69, 77);
}

[data-theme='deuteranopia'] .animated.range4 .score .background {
  color: var(--color-score-range-4-deuteranopia);
}

[data-theme='deuteranopia'] .animated.range4 .score .passives {
  color: rgb(22, 86, 151);
}

[data-theme='deuteranopia'] .animated.range4 .score .detractors {
  color: rgb(14, 57, 100);
}

[data-theme='deuteranopia'] .animated.range5 .score .background {
  color: var(--color-score-range-5-deuteranopia);
}

[data-theme='deuteranopia'] .animated.range5 .score .passives {
  color: rgb(0, 69, 139);
}

[data-theme='deuteranopia'] .animated.range5 .score .detractors {
  color: rgb(0, 46, 93);
}
