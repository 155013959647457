.tableContainer {
  overflow-x: auto;
}

.tableContainer th {
  background: none !important;
  border: none !important;
}

.tableContainer tr {
  border-block-start: 0.1px solid var(--color-table-border);
}

.tableContainer tr:last-child {
  border-block-end: 1px solid var(--color-table-border);
}

.tableContainer td {
  border: 0.1px solid var(--color-table-border);
}

.emptyColumn {
  border-block-end-color: transparent;
  text-transform: none;
  color: var(--color-text-tertiary);
  font-weight: var(--font-weight-bold);
  border: none;
  text-align: center;
}

.emptyColumn td,
.emptyColumn td:hover {
  background: none !important;
}

.tableBtn {
  margin-inline-start: var(--spacing-08);
}

.lastUsed {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
