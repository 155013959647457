@import '../../../../styles/variables.css';
@import '../../../../../../shared/src/components/Navigation/variables.css';

.root {
  height: 100%;
  inset-inline: 0;
  position: absolute;
  inset-block-start: 0;
  width: calc(100% - 20px);
  z-index: 5;
}

.main {
  border: 0;
  border-radius: var(--border-radius-card);
  padding-inline: 25px 285px;
  padding-block: 15px 15px;
}

.graph,
.header {
  animation: fadeIn 0.2s forwards linear;
}

.graph {
  padding-block-start: var(--spacing-20);
  z-index: 0;
  position: relative;
}

.header {
  align-items: center;
  display: flex;
}

.title {
  flex: 2;
  text-align: center;
}

.benchmark {
  align-items: center;
  color: var(--color-text-secondary);
  display: flex;
  font-family: var(--font-family-base);
  text-transform: none;
}

.benchmarkLegend {
  border-block-start: 2px dashed var(--color-text-tertiary);
  height: 0;
  margin-inline-end: 5px;
  width: 20px;
}

.sidebar {
  animation: slideInRight 0.25s forwards cubic-bezier(0.165, 0.84, 0.44, 1);
  animation-delay: 0.4s;
  border: 0;
  border-inline-start: 1px solid var(--color-border);
  border-radius: 0 var(--border-radius-card) var(--border-radius-card) 0;
  box-shadow: none;
  position: absolute;
  inset-inline-end: 0;
  inset-block-start: 0;
  transform: translate3d(100%, 0, 0);
  width: 260px;
}

.appear,
.enter,
.exit {
  overflow: hidden;
}

.appear,
.enter {
  opacity: 0;
  transform: scaleX(0);
  transform-origin: 70% 0;
}

.appear.appearActive,
.enter.enterActive {
  animation: enter 0.2s forwards cubic-bezier(0.165, 0.84, 0.44, 1);
  animation-delay: 0.2s;
}

.exit.exitActive {
  animation: exit 0.2s forwards cubic-bezier(0.165, 0.84, 0.44, 1);
  animation-delay: 0.2s;
}

.exit {
  opacity: 1;
  transform: scaleX(1);
}

.exit .main {
  border-color: transparent;
  box-shadow: none;
}

.exit.exitActive .graph,
.exit.exitActive .header,
.exit.exitActive .sidebar {
  animation: fadeOut 0.2s forwards linear;
}

@keyframes enter {
  from {
    opacity: 0;
    transform: scale3d(0.3, 1, 1);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@keyframes exit {
  0% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }

  60% {
    opacity: 1;
    transform: scale3d(0.5, 1, 1);
  }

  100% {
    opacity: 0.1;
    transform: scale3d(0.3, 1, 1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@media (--handhelds) {
  .root {
    background-color: var(--color-bg-card);
    height: calc(100vh - var(--navbar-mobile-height));
    inset-inline-start: 0;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    padding-block-end: 60px;
    position: fixed;
    inset-block-start: calc(
      var(--navbar-mobile-height) + var(--context-switcher-toggler-height)
    );
    width: 100%;
    z-index: 3;
    max-width: 100%;
  }

  .root.withProxyView {
    width: calc(100% - 8px); /* 8px: proxyView border */
    height: calc(100vh - calc(2 * var(--nav-top-height)) - 4px);
  }

  /* TODO: this css has no effect, because the selector is wrong */
  /* stylelint-disable-next-line selector-class-pattern */
  :global(.app-shareable-dashboard) .root {
    height: calc(100vh - var(--shared-dashboard-header-height));
    inset-block: var(--shared-dashboard-header-height) auto;
  }

  .main,
  .sidebar {
    border-radius: 0;
    height: auto;
  }

  .main {
    min-height: 350px;
    padding: 15px;
  }

  .sidebar {
    box-shadow: inset 0 3px 3px 0 rgba(0, 0, 0, 0.05);
    position: relative;
    width: 100%;
  }
}
