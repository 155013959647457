.root {
  margin-block-start: var(--spacing-16);
}

.actions {
  align-items: center;
  display: flex;
  margin-block-start: var(--spacing-16);
}

.actions button:first-child {
  margin-inline-end: var(--spacing-04);
}

.button {
  display: block;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-bold);
  line-height: 2;
  margin-block-start: var(--spacing-16);
  text-decoration: underline;
}

.disabled {
  color: var(--color-text-disabled);
}

.disabled:hover {
  color: var(--color-text-disabled);
}
