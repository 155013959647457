@import '../../../../styles/animations.css';

.prefix {
  font-weight: var(--font-weight-bold);
  margin-inline-end: var(--spacing-04);
}

.domainInput {
  display: flex;
  flex-direction: row;
  margin-block-end: var(--spacing-08);
  align-items: baseline;
  width: 300px;
}

.selectorInput {
  display: flex;
  flex-direction: column;
  margin-block-end: var(--spacing-04);
  width: 300px;
}

.headerBottom {
  animation: pop-upwards 0.3s forwards linear;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerBottom .warning {
  color: var(--color-btn--warning-text);
}

.editModeButtons {
  display: flex;
  gap: var(--spacing-16);
}

.hint {
  padding-block-end: var(--spacing-16);
}
