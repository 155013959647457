.header {
  background: var(--color-bg-card);
  border-block-end: 1px solid var(--color-border);
  border-block-start: 1px solid var(--color-border);
  padding-inline: 0 20px;
  padding-block: 0 0;
  outline: none;
  position: relative;
  cursor: pointer;
  z-index: 0;
  text-align: start;
}

.header::after {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  content: '';
  height: 100%;
  inset-inline-start: 0;
  opacity: 0;
  position: absolute;
  inset-block-start: 0;
  transition: opacity 0.2s;
  width: 100%;
}

.header:hover::after {
  opacity: 1;
  z-index: 1;
}

.sectionHeader {
  border: 0 !important;
  flex: 2;
}

.content {
  margin: 0 !important;
}
