.root {
  flex-wrap: wrap;
  margin-block-start: 5px;
}

.root,
.target {
  align-items: center;
  display: flex;
}

.label {
  font-size: var(--font-size-2xs);
  margin-inline-end: 5px;
  font-weight: var(--font-weight-bold);
}

.company {
  font-weight: var(--font-weight-bold);
}

.segments {
  margin-inline-start: 10px;
}

@media (--handhelds) {
  .root {
    margin-block-start: var(--spacing-08);
  }

  .segments {
    margin-inline-start: 0;
  }
}
