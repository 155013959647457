.notificationBadge {
  display: flex;
  align-items: center;
  /* Custom value to match padding used on figma. */
  padding: 0.125rem 0.3125rem;
  border-radius: var(--border-radius-lg);
  color: var(--color-text-inverted);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-2xs);
  line-height: normal;
}

.notificationBadgeDefault {
  background-color: var(--color-status-highlight-loud);
}

.notificationBadgeHighlight {
  background-color: var(--color-status-highlight);
}
